.info{
    display: flex;
    align-items: flex-start;
    padding: 12px 16px;
    background: #f7f7f7;
    border-radius: 6px;
    margin-bottom: 16px;
}
.info-p {
    margin: 0;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}
.form-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}
.red{
    color: red;
}
.sml-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 6px;
}
.body-scroll{
    overflow-y: scroll;
    height: 419px;
}
.ml-lt-hdr{
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    padding: 10px;
}
.ml-lt-p{
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--color-text-body);
    margin-top: 16px;
    padding: 10px;
}
.ml-lt-logo{
    text-align: left;
    margin-bottom: 35px;
    padding-left: 10px;
    margin-top: 10px;
}