.menu-sticky {
    border-bottom: 1px solid #e1e1e1;
    height: 69px;
    background: #fff;
    margin: 0;
    z-index: 999;
    width: 100%;
    top: 0;
    position: relative;
}
.fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}
.menu-sticky {
    border-bottom: 1px solid #e1e1e1;
    height: 69px;
    background: #fff;
    margin: 0;
    z-index: 999;
    width: 100%;
    top: 0;
    position: relative;
}
.text-right {
    text-align: right!important;
}
.full-width-header .rs-header .menu-area .rs-menu-area {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: end;
    align-items: end;
    -webkit-justify-content: end;
    justify-content: end;
    margin-top: 8px;
}
.display-flex {
    display: flex;
}
.full-width-header .rs-header .menu-area .logo-area {
    position: relative;
    /* height: 60px; */
    line-height: 59px;
}
.text-right {
    text-align: right!important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: Poppins,sans-serif;
    color: #101010;
    position: relative;
    padding: 5px 10px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li {
    display: inline-block;
    padding: 0;
    /* margin-right: 40px; */
}
.no-user-logo {
    border-radius: 50%; 
    margin-right: 5px;
    border: 2px solid #ffffff;
}
.u-n .dropdown-toggle{
    border: transparent!important;
    background: transparent!important;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: Poppins,sans-serif;
    color: #101010!important;
    position: relative;
}