.font-normal {
    font-weight: 400 !important;
}
.pad-45-0 {
    padding: 45px 0;
}
.line-height-66 {
    line-height: 66px;
}
.line-height-30 {
    line-height: 30px;
}
.white {
    color: #fff !important;
}
.bnr-btn-ln {
    padding: 5px 30px !important;
}
.pointer {
    cursor: pointer !important;
}
.req-demo {
    background: #fca800 !important;
    border-radius: 3px;
}
.blue-btn {
    background: #4766b0;
    border-radius: 3px;
}
.welcome-ts-bg {
    background:url('../assets/images/welcome-ts-bg.jpeg');
    position: relative;
    height: 566px;
    width: 602px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.all-section-header {
    font-size: 36px;
    font-weight: normal;
    max-width: 1300px !important;
}
.line-ht-unset {
    line-height: unset !important;
}
.p-r-l-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.jog {
    border: 1px solid #ccc;
    padding: 30px;
}
.services-wraps {
    /* padding: 0px 30px; */
    -webkit-box-shadow: unset;
    box-shadow: unset;
    text-align: -webkit-center;
    border-radius: 30px;
}
.wum-img {
    padding: 44px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: inline-block;
}
.services-wraps .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 12px;
    text-align: center;
    margin-top: 15px;
    letter-spacing: .3px;
    line-height: 1.5;
}
.services-wraps .desc {
    color: #343a40;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    min-height: 71px;
}
.dt-icon1 {
    background: #f6f7fc url('../assets/images/icon/project-managers.png') no-repeat 50% 50%;
    background-size: 50%;
}
.dt-icon2 {
    background: #f6f7fc url('../assets/images/icon/pm-100-100.png') no-repeat 50% 50%;
    background-size: 50%;
}
.dt-icon3 {
    background: #f6f7fc url('../assets/images/icon/code.png') no-repeat 50% 50%;
    background-size: 50%;
}
.dt-icon4 {
    background: #f6f7fc url('../assets/images/icon/qm-100-100.png') no-repeat 50% 50%;
    background-size: 50%;
}
.dt-icon5 {
    background: #f6f7fc url('../assets/images/icon/am-100-100.png') no-repeat 50% 50%;
    background-size: 50%;
}
.dt-icon6 {
    background: #f6f7fc url('../assets/images/icon/am-100-100.png') no-repeat 50% 50%;
    background-size: 50%;
}
.dt-icon7 {
    background: #f6f7fc url('../assets/images/icon/devops.png') no-repeat 50% 50%;
    background-size: 50%;
}
.dt-icon8 {
    background: #f6f7fc url('../assets/images/icon/sd-100-100.png') no-repeat 50% 50%;
    background-size: 50%;
}
.dt-icon9 {
    background: #f6f7fc url('../assets/images/icon/wc-100-100.png') no-repeat 50% 50%;
    background-size: 50%;
}
.section-bg-blue {
    background: #f6f7fc !important;
}
.container-padding {
    margin: 0px 30px;
}
.part-section-header-new {
    font-size: 36px;
    font-weight: normal;
}
.line-ht-unset {
    line-height: unset !important;
}
.display-flex {
    display: -moz-box;
    display: flex;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    flex-wrap: wrap;
    /* flex-wrap: wrap-reverse; */
}
.tab-nav-sec {
    padding: 0;
}
.nav-item-text .nav-item {
    text-transform: unset !important;
    padding: 0px;
    margin: 0;
}
.nav-item {
    display: -moz-inline-box;
    display: inline-flex;
    /* margin-right: 9px; */
    margin-right: 28px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 15px;
    font-weight: normal;
    /* text-transform: uppercase; */
    padding: 0;
    font-family: 'Poppins', sans-serif;
}
.lt-new-clr .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background: #f6f7fc !important;
    /* padding: 20px; */
    padding: 20px;
    border-left: 5px solid #4766b0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.lt-new-clr .nav-link {
    border-left: 5px solid #f6f7fc;
    height: 188px;
    background: #f6f7fc !important;
}
.nav-item-text .nav-pills .nav-link.active, .nav-pills .show>.nav-link p {
    color: #676767 !important;
    font-size: 14px;
    height: 215px;
}
.padding-0 {
    padding: 0 !important;
}
.sec-title4 .sub-title.new {
    color: #4766b0 !important;
    letter-spacing: 1px !important;
}
.sec-title4 .sub-title {
    font-family: poppins, sans-serif;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #090E40;
    display: block;
    margin-bottom: 0;
}
.nt-text .nav-pills p {
    color: #676767 !important;
    font-size: 14px !important;
}
.nav-item-text .nav-link, .nav-link:link, .nav-link:active {
    font-size: 15px;
    font-weight: 500 !important;
    /* padding: 20px; */
    padding: 20px 30px 20px 30px !important;
}
.lt-new-clr .active {
    color: #676767 !important;
    font-size: 14px;
    height: 188px !important;
}
.target-icon {
    background: #f6f7fc url('../assets/images/icon/pm-target.png') no-repeat;
    background-size: 35%;
    width: 100px;
    height: 100px;
}
.how-tabs-img {
    background:url('../assets/images/Minutly_service_step2.jpeg');
    position: relative;
    height: 566px;
    width: 773px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}


.accord-icon.rotate {
    transform: rotate(180deg);
}
.faq .qa {
    font-size: 18px;
    font-weight: 600;
    padding: 15px 0;
    cursor: pointer;
}

.faq .accord-icon {
    float: right;
    padding-top: 4px;
    transition: transform 0.3s;
}

.faq .content {
    background: #ffff;
    padding: 10px 25px;
    display: block;
}

.bbs {
    border-bottom: 1px solid rgb(223, 225, 230);
}
.p21 {
    padding: 21px;
}
.fixed-bottom {
    position: relative!important;
}
.footer-bg {
    background: #1c3988;
}
.login-signup {
    background: #4766b0;
    border-radius: 3px;
    color: #fff !important;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: Poppins, sans-serif;
    color: #101010;
    position: relative;
    padding: 5px 10px;
}
.lh-52{
    line-height: 52px;
}