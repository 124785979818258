.signin-content {
    padding-top: 67px;
    padding-bottom: 87px;
    display: flex;
}
.signin-image {
    margin-left: 110px;
    margin-right: 20px;
    margin-top: 10px;
}
.signup-image-link {
    font-size: 14px;
    color: #4766b0;
    display: block;
    text-align: center;
    text-decoration: underline!important;
}
.signin-form {
    /* margin-right: 90px;
    margin-left: 80px; */
    padding: 0px 40px;
}
.form-title {
    margin-bottom: 33px;
}
.register-form {
    width: 100%;
}
.form-group {
    position: relative;
    margin-bottom: 25px;
    overflow: hidden;
}
.labels {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    color: #222;
}
.material-icons-name {
    font-size: 18px;
}
.input {
    width: 100%;
    display: block;
    border: none;
    border-bottom: 1px solid #999;
    padding: 6px 30px;
    font-family: Poppins;
    box-sizing: border-box;
}
.form-group:last-child {
    margin-bottom: 0px;
}
.card-container {
    width: 900px;
    background: #fbfbfb;
    margin: 3% auto;
    box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    /* -webkit-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05); */
    -webkit-box-shadow: 0px 23px 15.83px 7.17px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -o-border-radius: 20px;
    -ms-border-radius: 20px;
    max-width: 990px!important;
}
.lbl{
    font-weight: 600;
    margin-bottom: 10px;
}
.forget-link {
    font-size: 14px;
    color: #4766b0;
    /* display: block; */
    text-align: end;
    text-decoration: underline!important;
}
.float-right {
    float: right;
}
.img-trans{
    mix-blend-mode: multiply;
}
