.social-hd{
    font-size: 14px;
    color: #4766b0;
}
.google{
    background: #DB4437;
    color: white;
    padding: 8px;
    border-radius: 4px;
}
.linkedin{
    background: #0077b5;
    color: white;
    padding: 8px;
    border-radius: 4px;
}