.alert {
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 15px;
    position: relative;
}

.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    background: none;
    border: none;
    font-size: 1.5em;
    line-height: 1;
    color: inherit;
    cursor: pointer;
    text-align: end;
}
