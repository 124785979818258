@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&amp;display=swap');

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&amp;display=swap');
.full-width-header .rs-header {
    z-index: 99;
}

html,
body {
  font-size: 14px;
  color: #1c1b1b;
  font-family: 'Poppins', sans-serif;
  vertical-align: baseline;
  line-height: 1.7;
  font-weight: 400;
  scroll-behavior:smooth;
  /*overflow-x: hidden;*/
}
img {
  max-width: 100%;
  height: auto;
}
p {
  margin: 0 0 26px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  color: #1c1b1b;
  margin: 0 0 26px;
  font-weight: 700;
}
h1 {
  font-size: 54px;
  line-height: 64px;
}
h2 {
  font-size: 36px;
  line-height: 40px;
}
h3 {
  font-size: 28px;
  line-height: 32px;
}
h4 {
  font-size: 20px;
  line-height: 28px;
}
h5 {
  font-size: 18px;
  line-height: 28px;
}
h6 {
  font-size: 16px;
  line-height: 20px;
}
a {
  color: #4766b0;
  transition: all 0.3s ease;
  text-decoration: none !important;
  outline: none !important;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  color: #4766b0;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.uppercase {
  text-transform: uppercase;
}
.clear {
  clear: both;
}
::-moz-selection {
  background: #4766b0;
  text-shadow: none;
  color: #ffffff;
}
::selection {
  background: #4766b0;
  text-shadow: none;
  color: #ffffff;
}
.width-unset {
  width: unset !important;
}
.white-bg {
  background-color: #ffffff;
}
.primary-bg {
  background: #106eea;
}
.secondary-bg {
  background: #228bfd;
}
.title-bg {
  background: #1c1b1b;
}
.gray-bg {
  background: #f1f6fc;
}
.gray-bg2 {
  background: #fbfbfb;
}
.gray-bg3 {
  background: #fafafa;
}
.gray-bg4 {
  background: #f8f8f8;
}
.gray-bg5 {
  background: #f9f9f9;
}
.gray-bg6 {
  background: #f7f7f7;
}
.gray-bg7 {
  background: #f2e1e1;
}
.blue-bg {
  background: #4766b0!important;
}
.blue-color {
  color: #4766b0!important;
}
.red-color {
  color: #ec4c6f;
}
.red-bg {
  background: #ec4c6f;
}
.red-bg2 {
  background: #d83030;
}
.red-color2 {
  color: #d83030!important;
}
.green-bg {
  background: #67c27c;
}
.green-color {
  color: #67c27c;
}
.law-color {
  color: #b1976b;
}
.law-bg {
  background: #b1976b;
}
.navy-color {
  color: #282e3f;
}
.navy-bg {
  background: #282e3f;
}
.blue-light {
  background-image: linear-gradient(160deg, #0052f5 0%, #4899f9 100%);
}
.transparent-bg {
  background: transparent;
}
/* .app-container {
    min-height: 350px;
} */

/* Important CSS */
.dual-btn .dual-btn-wrap a.btn-left {
    margin-right: calc(0px/2);
}

.dual-btn .dual-btn-wrap a.btn-right {
    margin-left: calc(0px/2);
}

.rs-services.style13 .service-wrap .content-part:after,
.rs-services.style13 .service-wrap .content-part:before,
.rs-blog.style3 .blog-wrap .content-part:after,
.rs-blog.style3 .blog-wrap .content-part:before {
    border-radius: 100px 100px 0 0px / 75px 75px 0 0;
}
.text-left {
  text-align: left !important;
}
.copyright-text {
  font-size: 16px;
  color: #6b7280;
}
/* container control start */
@media screen and (min-width: 576px) {
    .container{
        max-width: 570px;
    }
}

@media screen and (min-width: 768px) {
    .container{
        max-width: 750px;
    }
}

@media screen and (min-width: 992px) {
    .container {
        max-width: 990px;
    }
}

@media only screen and (min-width: 1200px) {
    .container{
        max-width: 1265px;
    }
}

@media only screen and (min-width: 1300px) {


    .container {
        max-width: 1265px;
        width: 100%;
    }

    .container.custom {
        max-width: 1400px;
    }

    .container {
        max-width: 1230px;
    }

    /* .blogs .react-multi-carousel-item {
        width: 264.4px!important;
    } */
}

@keyframes spinner-animation { 100% { transform: rotate(360deg) } }