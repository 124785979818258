.newcars {
    border-radius: 8px;
    /* height: 463px; */
    border: 1px solid #f2f2f2;
    box-shadow: 19px 19px 19px 0 #dbdada40;
    width: 23% !important;
    /* height: 442px !important; */
    height: 377px !important;
    margin-bottom: 20px;
    margin-right: 11px;
    margin-left: 11px;
    color: #1c1b1b !important;
    padding-top: calc(var(--bs-gutter-x)* .5);
    padding-bottom: calc(var(--bs-gutter-x)* .5);
}
.newcars-title {
    height: 75px;
    margin: 16px 0px;
    border-radius: 4px;
}
.newcars-title-header {
    background: #f6f6f6;
    height: 76px;
    padding: 12px;
    left: 15px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 15px;
}
.pay-later-empty {
    width: 95%;
    text-align: center;
    border-bottom: 1px solid #eee;
    line-height: .1em;
    margin: 20px 3px;
    padding: 0 0px;
}
.color-#343a40 {
    color: #343a40!important;
}
.newcars-counts {
    top: 224px;
    margin-top: 0;
    padding: 10px p5x;
}
.word-wraping {
    word-break: break-word;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 10px;
    margin-bottom: 0!important;
    height: 69px;
  }
  .newrate {
    font-size: 19px;
    font-weight: 600;
}
.span-btn {
    border: 1px solid #fca800!important;
    background: #fca800!important;
    padding: 7px;
    border-radius: 5px;
    color: #333;
}
.display-flex {
    display: flex;
}
.pg-hd{
    margin-bottom: 40px;
    border-bottom: 1px solid #e1e1e1;
    font-size: 1.75rem;
    font-weight: 500;
    padding-bottom: 10px;
}




.search{
    /* position: absolute; */
    /* top:50%;
    left:50%;
    transform: translate(-50% , -50%); */
    border:1px solid #343a40 ;
    height: 40px;
    border-radius: 40px;
    padding: px;
}

.search-btn{
   /*  color: red; */
    float: right;
    width: 33px;
    height: 32px;
    border-radius: 50%;
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-txt{
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 6px 10px;
    /* color: wheat; */
     font-size: 16px; 
    /* transition: ease-in 0.4s; */
    /* line-height: 40px; */
    /* width: 110px; */
    /* font-family: 'Pangolin', cursive; */
}
.fa-search{
    font-weight: 200;
    font-size: 20px;
    color: #343a40;
}
.h3d{
    font-size: 1.75rem;
    font-weight: 500;
}
.color-black{
    color: #1c1b1b!important;
}

/* .newcars-banne {
    background: rgb(170,196,203);
    background: linear-gradient(142deg, rgba(170,196,203,1) 0%, rgba(64,143,182,1) 90%, rgba(16,83,144,1) 100%);
} */

.portfolio-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -100px;
    padding: 0 .5rem;
    text-align: center;

}
.portfolio-h4 {
    font-size: 18px;
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)); */
    margin: 0;
    color: white;
}

.background {
    background:url('../assets/images/gntr-img-2.jpg');
    position: relative;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-layer {
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgb(141 140 140 / 50%), rgb(0 0 0 / 10%));
    text-align: center;
    display: -moz-box;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
}
.background-layer-text {
    color: white;
    font-size: 18px;
    font-weight: 700;
}
.f-16{
font-size: 16px;
} 
.fw-700{
font-weight: 700;
}


/* ////// NEW CARD ////// */


.p-r-l-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.box-oneplatform {
    border: 1px solid #ccc !important;
    border-radius: 30px !important;
    -moz-box-sizing: unset !important;
    box-sizing: unset !important;
    -webkit-box-sizing: unset !important;
    height: auto !important;
    padding: 38px 30px 36px;
    min-height: 239px;
}
.line {
    position: relative;
    overflow: visible;
    border-bottom: 1px solid #ccc;
    margin-bottom: 86px;
    margin-top: 50px;
}
.op-logo {
    position: absolute;
    left: 10%;
    margin-left: -14px;
    margin-top: -47px;
    min-height: 0;
    min-width: 15px;
    padding: 44px;
    border: 1px solid #ccc;
    border-radius: 50%;
}
.mh-57 {
    min-height: 57px;
}
.pad-inh {
    padding: 13px 0;
}
.black{
    color: black!important;
}
.fm-logo {
    background: pink url(../../src/assets/images/icon/wd-50.png) no-repeat 50% 50%;  
}
.d-logo {
    background: yellow url(../../src/assets/images/icon/code-50.png) no-repeat 50% 50%;
}
.hfr-logo {
    background: #faae02 url(../../src/assets/images/icon/ecom-50.png) no-repeat 50% 50%;
}


.dm-logo {
    background: pink url(../../src/assets/images/icon/dm-50.png) no-repeat 50% 50%;  
}
.pm-logo {
    background: yellow url(../../src/assets/images/icon/pm-50.png) no-repeat 50% 50%;
}
.qat-logo {
    background: #faae02 url(../../src/assets/images/icon/qat-50.png) no-repeat 50% 50%;
}


.c-logo {
    background: pink url(../../src/assets/images/icon/c-50.png) no-repeat 50% 50%;  
}
.a-logo {
    background: yellow url(../../src/assets/images/icon/a-50.png) no-repeat 50% 50%;
}
.ts-logo {
    background: #faae02 url(../../src/assets/images/icon/ts-50.png) no-repeat 50% 50%;
}