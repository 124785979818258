/**
*
* -----------------------------------------------------------------------------
*
* Author : rs-theme
* Author URL : http://www.rstheme.com/
* Version: 1.5;
*
* -----------------------------------------------------------------------------
*
**/


/*--------------Table Of Content------------
01. PADDING TOP (1-200)
02. PADDING BOTTOM (1-200)
03. PADDING RIGHT (1-200)
04. PADDING LEFT (1-200)

05. MARGIN TOP (1-200)
06. MARGIN BOTTOM (1-200)
07. MARGIN RIGHT (1-100)
08. MARGIN LEFT (1-100)

09. MARGIN TOP REVERSE (1-200)
10. MARGIN BOTTOM REVERSE (1-200)
11. MARGIN LEFT REVERSE (1-200)
12. MARGIN RIGHT REVERSE (1-200)

13. LG PADDING TOP (1-200)
14. LG PADDING BOTTOM (1-200)
15. LG PADDING RIGHT (1-200)
16. LG PADDING LEFT (1-200)

17. LG MARGIN TOP (1-200)
18. LG MARGIN BOTTOM (1-200)
19. LG MARGIN RIGHT (1-100)
20. LG MARGIN LEFT (1-100)

21. LG MARGIN TOP REVERSE (1-200)
22. LG MARGIN BOTTOM REVERSE (1-200)
23. LG MARGIN LEFT REVERSE (1-200)
24. LG MARGIN RIGHT REVERSE (1-200)

25. MD PADDING TOP (1-200)
26. MD PADDING BOTTOM (1-200)
27. MD PADDING RIGHT (1-200)
28. MD PADDING LEFT (1-200)

29. MD MARGIN TOP (1-200)
30. MD MARGIN BOTTOM (1-200)
31. MD MARGIN RIGHT (1-100)
32. MD MARGIN LEFT (1-100)

33. MD MARGIN TOP REVERSE (1-200)
34. MD MARGIN BOTTOM REVERSE (1-200)
35. MD MARGIN LEFT REVERSE (1-200)
36. MD MARGIN RIGHT REVERSE (1-200)

37. SM PADDING TOP (1-200)
38. SM PADDING BOTTOM (1-200)
39. SM PADDING RIGHT (1-200)
40. SM PADDING LEFT (1-200)

41. SM MARGIN TOP (1-200)
42. SM MARGIN BOTTOM (1-200)
43. SM MARGIN RIGHT (1-100)
44. SM MARGIN LEFT (1-100)

45. SM MARGIN TOP REVERSE (1-200)
46. SM MARGIN BOTTOM REVERSE (1-200)
47. SM MARGIN LEFT REVERSE (1-200)
48. SM MARGIN RIGHT REVERSE (1-200)

49. XS PADDING TOP (1-200)
50. XS PADDING BOTTOM (1-200)
51. XS PADDING RIGHT (1-200)
52. XS PADDING LEFT (1-200)

53. XS MARGIN TOP (1-200)
54. XS MARGIN BOTTOM (1-200)
55. XS MARGIN RIGHT (1-100)
56. XS MARGIN LEFT (1-100)

57. XS MARGIN TOP REVERSE (1-200)
58. XS MARGIN BOTTOM REVERSE (1-200)
59. XS MARGIN LEFT REVERSE (1-200)
60. XS MARGIN RIGHT REVERSE (1-200)
------------------------------------------*/
.pad-40{
	padding: 40px!important;
}
.pad-32 {
	padding: 32px;
}
.padding-0 {
	padding: 0!important;
}
.margin-0 {
	margin: 0;
}
.p-20 {
	padding: 20px;
 }
 .p-3 {
	padding: 3px!important;
 }

 .p-85{
	padding: 85px;
 }
/*------------------------------------------
	01. PADDING TOP
-------------------------------------------*/
/*.pt {*/
	.pt-0 {
		padding-top: 0px!important;
	}
	.pl-0{
		padding-left: 0!important;
	}
	.pr-0{
		padding-right: 0!important;
	}
	.pt-1 {
		padding-top: 1px;
	}
	.pt-2 {
		padding-top: 2px;
	}
	.pt-3 {
		padding-top: 3px!important;
	}
	.pt-4 {
		padding-top: 4px!important;
	}
	.pt-5 {
		padding-top: 5px!important;
	}
	.pt-6 {
		padding-top: 6px;
	}
	.pt-7 {
		padding-top: 7px;
	}
	.pt-8 {
		padding-top: 8px;
	}
	.pt-9 {
		padding-top: 9px;
	}
	.pt-10 {
		padding-top: 10px!important;
	}
	.pt-11 {
		padding-top: 11px;
	}
	.pt-12 {
		padding-top: 12px;
	}
	.pt-13 {
		padding-top: 13px;
	}
	.pt-14 {
		padding-top: 14px;
	}
	.pt-15 {
		padding-top: 15px;
	}
	.pt-16 {
		padding-top: 16px;
	}
	.pt-17 {
		padding-top: 17px;
	}
	.pt-18 {
		padding-top: 18px;
	}
	.pt-19 {
		padding-top: 19px;
	}
	.pt-20 {
		padding-top: 20px;
	}
	.pt-21 {
		padding-top: 21px;
	}
	.pt-22 {
		padding-top: 22px;
	}
	.pt-23 {
		padding-top: 23px;
	}
	.pt-24 {
		padding-top: 24px!important;
	}
	.pt-25 {
		padding-top: 25px;
	}
	.pt-26 {
		padding-top: 26px;
	}
	.pt-27 {
		padding-top: 27px;
	}
	.pt-28 {
		padding-top: 28px;
	}
	.pt-29 {
		padding-top: 29px;
	}
	.pt-30 {
		padding-top: 30px;
	}
	.pt-31 {
		padding-top: 31px;
	}
	.pt-32 {
		padding-top: 32px;
	}
	.pt-33 {
		padding-top: 33px;
	}
	.pt-34 {
		padding-top: 34px;
	}
	.pt-35 {
		padding-top: 35px;
	}
	.pt-36 {
		padding-top: 36px;
	}
	.pt-37 {
		padding-top: 37px;
	}
	.pt-38 {
		padding-top: 38px;
	}
	.pt-39 {
		padding-top: 39px;
	}
	.pt-40 {
		padding-top: 40px!important;
	}
	.pt-41 {
		padding-top: 41px;
	}
	.pt-42 {
		padding-top: 42px;
	}
	.pt-43 {
		padding-top: 43px;
	}
	.pt-44 {
		padding-top: 44px;
	}
	.pt-45 {
		padding-top: 45px;
	}
	.pt-46 {
		padding-top: 46px;
	}
	.pt-47 {
		padding-top: 47px;
	}
	.pt-48 {
		padding-top: 48px;
	}
	.pt-49 {
		padding-top: 49px;
	}
	.pt-50 {
		padding-top: 50px;
	}
	.pt-51 {
		padding-top: 51px;
	}
	.pt-52 {
		padding-top: 52px;
	}
	.pt-53 {
		padding-top: 53px;
	}
	.pt-54 {
		padding-top: 54px;
	}
	.pt-55 {
		padding-top: 55px;
	}
	.pt-56 {
		padding-top: 56px;
	}
	.pt-57 {
		padding-top: 57px;
	}
	.pt-58 {
		padding-top: 58px;
	}
	.pt-59 {
		padding-top: 59px;
	}
	.pt-60 {
		padding-top: 60px;
	}
	.pt-61 {
		padding-top: 61px;
	}
	.pt-62 {
		padding-top: 62px;
	}
	.pt-63 {
		padding-top: 63px;
	}
	.pt-64 {
		padding-top: 64px;
	}
	.pt-65 {
		padding-top: 65px;
	}
	.pt-66 {
		padding-top: 66px;
	}
	.pt-67 {
		padding-top: 67px;
	}
	.pt-68 {
		padding-top: 68px;
	}
	.pt-69 {
		padding-top: 69px;
	}
	.pt-70 {
		padding-top: 70px;
	}
	.pt-71 {
		padding-top: 71px;
	}
	.pt-72 {
		padding-top: 72px;
	}
	.pt-73 {
		padding-top: 73px;
	}
	.pt-74 {
		padding-top: 74px;
	}
	.pt-75 {
		padding-top: 75px;
	}
	.pt-76 {
		padding-top: 76px;
	}
	.pt-77 {
		padding-top: 77px;
	}
	.pt-78 {
		padding-top: 78px;
	}
	.pt-79 {
		padding-top: 79px;
	}
	.pt-80 {
		padding-top: 80px;
	}
	.pt-81 {
		padding-top: 81px;
	}
	.pt-82 {
		padding-top: 82px;
	}
	.pt-83 {
		padding-top: 83px;
	}
	.pt-84 {
		padding-top: 84px;
	}
	.pt-85 {
		padding-top: 85px;
	}
	.pt-86 {
		padding-top: 86px;
	}
	.pt-87 {
		padding-top: 87px;
	}
	.pt-88 {
		padding-top: 88px;
	}
	.pt-89 {
		padding-top: 89px;
	}
	.pt-90 {
		padding-top: 90px;
	}
	.pt-91 {
		padding-top: 91px;
	}
	.pt-92 {
		padding-top: 92px;
	}
	.pt-93 {
		padding-top: 93px;
	}
	.pt-94 {
		padding-top: 94px;
	}
	.pt-95 {
		padding-top: 95px;
	}
	.pt-96 {
		padding-top: 96px;
	}
	.pt-97 {
		padding-top: 97px;
	}
	.pt-98 {
		padding-top: 98px;
	}
	.pt-99 {
		padding-top: 99px;
	}
	.pt-100 {
		padding-top: 100px;
	}

	.pt-101 {
		padding-top: 101px;
	}
	.pt-102 {
		padding-top: 102px;
	}
	.pt-103 {
		padding-top: 103px;
	}
	.pt-104 {
		padding-top: 104px;
	}
	.pt-105 {
		padding-top: 105px;
	}
	.pt-106 {
		padding-top: 106px;
	}
	.pt-107 {
		padding-top: 107px;
	}
	.pt-108 {
		padding-top: 108px;
	}
	.pt-109 {
		padding-top: 109px;
	}
	.pt-110 {
		padding-top: 110px;
	}
	.pt-111 {
		padding-top: 111px;
	}
	.pt-112 {
		padding-top: 112px;
	}
	.pt-113 {
		padding-top: 113px;
	}
	.pt-114 {
		padding-top: 114px;
	}
	.pt-115 {
		padding-top: 115px;
	}
	.pt-116 {
		padding-top: 116px;
	}
	.pt-117 {
		padding-top: 117px;
	}
	.pt-118 {
		padding-top: 118px;
	}
	.pt-119 {
		padding-top: 119px;
	}
	.pt-120 {
		padding-top: 120px!important;
	}
	.pt-121 {
		padding-top: 121px;
	}
	.pt-122 {
		padding-top: 122px;
	}
	.pt-123 {
		padding-top: 123px;
	}
	.pt-124 {
		padding-top: 124px;
	}
	.pt-125 {
		padding-top: 125px;
	}
	.pt-126 {
		padding-top: 126px;
	}
	.pt-127 {
		padding-top: 127px;
	}
	.pt-128 {
		padding-top: 128px;
	}
	.pt-129 {
		padding-top: 129px;
	}
	.pt-130 {
		padding-top: 130px;
	}
	.pt-131 {
		padding-top: 131px;
	}
	.pt-132 {
		padding-top: 132px;
	}
	.pt-133 {
		padding-top: 133px;
	}
	.pt-134 {
		padding-top: 134px;
	}
	.pt-135 {
		padding-top: 135px;
	}
	.pt-136 {
		padding-top: 136px;
	}
	.pt-137 {
		padding-top: 137px;
	}
	.pt-138 {
		padding-top: 138px;
	}
	.pt-139 {
		padding-top: 139px;
	}
	.pt-140 {
		padding-top: 140px;
	}
	.pt-141 {
		padding-top: 141px;
	}
	.pt-142 {
		padding-top: 142px;
	}
	.pt-143 {
		padding-top: 143px;
	}
	.pt-144 {
		padding-top: 144px;
	}
	.pt-145 {
		padding-top: 145px;
	}
	.pt-146 {
		padding-top: 146px;
	}
	.pt-147 {
		padding-top: 147px;
	}
	.pt-148 {
		padding-top: 148px;
	}
	.pt-149 {
		padding-top: 149px;
	}
	.pt-150 {
		padding-top: 150px;
	}
	.pt-151 {
		padding-top: 151px;
	}
	.pt-152 {
		padding-top: 152px;
	}
	.pt-153 {
		padding-top: 153px;
	}
	.pt-154 {
		padding-top: 154px;
	}
	.pt-155 {
		padding-top: 155px;
	}
	.pt-156 {
		padding-top: 156px;
	}
	.pt-157 {
		padding-top: 157px;
	}
	.pt-158 {
		padding-top: 158px;
	}
	.pt-159 {
		padding-top: 159px;
	}
	.pt-160 {
		padding-top: 160px;
	}
	.pt-161 {
		padding-top: 161px;
	}
	.pt-162 {
		padding-top: 162px;
	}
	.pt-163 {
		padding-top: 163px;
	}
	.pt-164 {
		padding-top: 164px;
	}
	.pt-165 {
		padding-top: 165px;
	}
	.pt-166 {
		padding-top: 166px;
	}
	.pt-167 {
		padding-top: 167px;
	}
	.pt-168 {
		padding-top: 168px;
	}
	.pt-169 {
		padding-top: 169px;
	}
	.pt-170 {
		padding-top: 170px;
	}
	.pt-171 {
		padding-top: 171px;
	}
	.pt-172 {
		padding-top: 172px;
	}
	.pt-173 {
		padding-top: 173px;
	}
	.pt-174 {
		padding-top: 174px;
	}
	.pt-175 {
		padding-top: 175px;
	}
	.pt-176 {
		padding-top: 176px;
	}
	.pt-177 {
		padding-top: 177px;
	}
	.pt-178 {
		padding-top: 178px;
	}
	.pt-179 {
		padding-top: 179px;
	}
	.pt-180 {
		padding-top: 180px;
	}
	.pt-181 {
		padding-top: 181px;
	}
	.pt-182 {
		padding-top: 182px;
	}
	.pt-183 {
		padding-top: 183px;
	}
	.pt-184 {
		padding-top: 184px;
	}
	.pt-185 {
		padding-top: 185px;
	}
	.pt-186 {
		padding-top: 186px;
	}
	.pt-187 {
		padding-top: 187px;
	}
	.pt-188 {
		padding-top: 188px;
	}
	.pt-189 {
		padding-top: 189px;
	}
	.pt-190 {
		padding-top: 190px;
	}
	.pt-191 {
		padding-top: 191px;
	}
	.pt-192 {
		padding-top: 192px;
	}
	.pt-193 {
		padding-top: 193px;
	}
	.pt-194 {
		padding-top: 194px;
	}
	.pt-195 {
		padding-top: 195px;
	}
	.pt-196 {
		padding-top: 196px;
	}
	.pt-197 {
		padding-top: 197px;
	}
	.pt-198 {
		padding-top: 198px;
	}
	.pt-199 {
		padding-top: 199px;
	}
	.pt-200 {
		padding-top: 200px;
	}
	.pt-225 {
		padding-top: 225px;
	}
/*}*/

/*------------------------------------------
	02. PADDING BOTTOM
-------------------------------------------*/
/*.pb {*/
	.pb-0 {
		padding-bottom: 0px!important;
	}
	.pb-1 {
		padding-bottom: 1px!important;
	}
	.pb-2 {
		padding-bottom: 2px;
	}
	.pb-3 {
		padding-bottom: 3px!important;
	}
	.pb-4 {
		padding-bottom: 4px;
	}
	.pb-5 {
		padding-bottom: 5px!important;
	}
	.pb-6 {
		padding-bottom: 6px;
	}
	.pb-7 {
		padding-bottom: 7px;
	}
	.pb-8 {
		padding-bottom: 8px;
	}
	.pb-9 {
		padding-bottom: 9px;
	}
	.pb-10 {
		padding-bottom: 10px!important;
	}
	.pb-11 {
		padding-bottom: 11px;
	}
	.pb-12 {
		padding-bottom: 12px;
	}
	.pb-13 {
		padding-bottom: 13px;
	}
	.pb-14 {
		padding-bottom: 14px;
	}
	.pb-15 {
		padding-bottom: 15px;
	}
	.pb-16 {
		padding-bottom: 16px;
	}
	.pb-17 {
		padding-bottom: 17px;
	}
	.pb-18 {
		padding-bottom: 18px;
	}
	.pb-19 {
		padding-bottom: 19px;
	}
	.pb-20 {
		padding-bottom: 20px;
	}
	.pb-21 {
		padding-bottom: 21px;
	}
	.pb-22 {
		padding-bottom: 22px;
	}
	.pb-23 {
		padding-bottom: 23px;
	}
	.pb-24 {
		padding-bottom: 24px;
	}
	.pb-25 {
		padding-bottom: 25px;
	}
	.pb-26 {
		padding-bottom: 26px;
	}
	.pb-27 {
		padding-bottom: 27px;
	}
	.pb-28 {
		padding-bottom: 28px;
	}
	.pb-29 {
		padding-bottom: 29px;
	}
	.pb-30 {
		padding-bottom: 30px;
	}
	.pb-31 {
		padding-bottom: 31px;
	}
	.pb-32 {
		padding-bottom: 32px;
	}
	.pb-33 {
		padding-bottom: 33px;
	}
	.pb-34 {
		padding-bottom: 34px;
	}
	.pb-35 {
		padding-bottom: 35px;
	}
	.pb-36 {
		padding-bottom: 36px;
	}
	.pb-37 {
		padding-bottom: 37px;
	}
	.pb-38 {
		padding-bottom: 38px;
	}
	.pb-39 {
		padding-bottom: 39px;
	}
	.pb-40 {
		padding-bottom: 40px;
	}
	.pb-41 {
		padding-bottom: 41px;
	}
	.pb-42 {
		padding-bottom: 42px;
	}
	.pb-43 {
		padding-bottom: 43px;
	}
	.pb-44 {
		padding-bottom: 44px;
	}
	.pb-45 {
		padding-bottom: 45px;
	}
	.pb-46 {
		padding-bottom: 46px;
	}
	.pb-47 {
		padding-bottom: 47px;
	}
	.pb-48 {
		padding-bottom: 48px;
	}
	.pb-49 {
		padding-bottom: 49px;
	}
	.pb-50 {
		padding-bottom: 50px!important;
	}
	.pb-51 {
		padding-bottom: 51px;
	}
	.pb-52 {
		padding-bottom: 52px;
	}
	.pb-53 {
		padding-bottom: 53px;
	}
	.pb-54 {
		padding-bottom: 54px;
	}
	.pb-55 {
		padding-bottom: 55px;
	}
	.pb-56 {
		padding-bottom: 56px;
	}
	.pb-57 {
		padding-bottom: 57px;
	}
	.pb-58 {
		padding-bottom: 58px;
	}
	.pb-59 {
		padding-bottom: 59px;
	}
	.pb-60 {
		padding-bottom: 60px;
	}
	.pb-61 {
		padding-bottom: 61px;
	}
	.pb-62 {
		padding-bottom: 62px;
	}
	.pb-63 {
		padding-bottom: 63px;
	}
	.pb-64 {
		padding-bottom: 64px;
	}
	.pb-65 {
		padding-bottom: 65px;
	}
	.pb-66 {
		padding-bottom: 66px;
	}
	.pb-67 {
		padding-bottom: 67px;
	}
	.pb-68 {
		padding-bottom: 68px;
	}
	.pb-69 {
		padding-bottom: 69px;
	}
	.pb-70 {
		padding-bottom: 70px;
	}
	.pb-71 {
		padding-bottom: 71px;
	}
	.pb-72 {
		padding-bottom: 72px;
	}
	.pb-73 {
		padding-bottom: 73px;
	}
	.pb-74 {
		padding-bottom: 74px;
	}
	.pb-75 {
		padding-bottom: 75px;
	}
	.pb-76 {
		padding-bottom: 76px;
	}
	.pb-77 {
		padding-bottom: 77px;
	}
	.pb-78 {
		padding-bottom: 78px;
	}
	.pb-79 {
		padding-bottom: 79px;
	}
	.pb-80 {
		padding-bottom: 80px!important;
	}
	.pb-81 {
		padding-bottom: 81px;
	}
	.pb-82 {
		padding-bottom: 82px;
	}
	.pb-83 {
		padding-bottom: 83px;
	}
	.pb-84 {
		padding-bottom: 84px;
	}
	.pb-85 {
		padding-bottom: 85px;
	}
	.pb-86 {
		padding-bottom: 86px;
	}
	.pb-87 {
		padding-bottom: 87px;
	}
	.pb-88 {
		padding-bottom: 88px;
	}
	.pb-89 {
		padding-bottom: 89px;
	}
	.pb-90 {
		padding-bottom: 90px;
	}
	.pb-91 {
		padding-bottom: 91px;
	}
	.pb-92 {
		padding-bottom: 92px;
	}
	.pb-93 {
		padding-bottom: 93px;
	}
	.pb-94 {
		padding-bottom: 94px;
	}
	.pb-95 {
		padding-bottom: 95px;
	}
	.pb-96 {
		padding-bottom: 96px;
	}
	.pb-97 {
		padding-bottom: 97px;
	}
	.pb-98 {
		padding-bottom: 98px;
	}
	.pb-99 {
		padding-bottom: 99px;
	}
	.pb-100 {
		padding-bottom: 100px;
	}

	.pb-101 {
		padding-bottom: 101px;
	}
	.pb-102 {
		padding-bottom: 102px;
	}
	.pb-103 {
		padding-bottom: 103px;
	}
	.pb-104 {
		padding-bottom: 104px;
	}
	.pb-105 {
		padding-bottom: 105px;
	}
	.pb-106 {
		padding-bottom: 106px;
	}
	.pb-107 {
		padding-bottom: 107px;
	}
	.pb-108 {
		padding-bottom: 108px;
	}
	.pb-109 {
		padding-bottom: 109px;
	}
	.pb-110 {
		padding-bottom: 110px;
	}
	.pb-111 {
		padding-bottom: 111px;
	}
	.pb-112 {
		padding-bottom: 112px;
	}
	.pb-113 {
		padding-bottom: 113px;
	}
	.pb-114 {
		padding-bottom: 114px;
	}
	.pb-115 {
		padding-bottom: 115px;
	}
	.pb-116 {
		padding-bottom: 116px;
	}
	.pb-117 {
		padding-bottom: 117px;
	}
	.pb-118 {
		padding-bottom: 118px;
	}
	.pb-119 {
		padding-bottom: 119px;
	}
	.pb-120 {
		padding-bottom: 120px;
	}
	.pb-121 {
		padding-bottom: 121px;
	}
	.pb-122 {
		padding-bottom: 122px;
	}
	.pb-123 {
		padding-bottom: 123px;
	}
	.pb-124 {
		padding-bottom: 124px;
	}
	.pb-125 {
		padding-bottom: 125px;
	}
	.pb-126 {
		padding-bottom: 126px;
	}
	.pb-127 {
		padding-bottom: 127px;
	}
	.pb-128 {
		padding-bottom: 128px;
	}
	.pb-129 {
		padding-bottom: 129px;
	}
	.pb-130 {
		padding-bottom: 130px;
	}
	.pb-131 {
		padding-bottom: 131px;
	}
	.pb-132 {
		padding-bottom: 132px;
	}
	.pb-133 {
		padding-bottom: 133px;
	}
	.pb-134 {
		padding-bottom: 134px;
	}
	.pb-135 {
		padding-bottom: 135px;
	}
	.pb-136 {
		padding-bottom: 136px;
	}
	.pb-137 {
		padding-bottom: 137px;
	}
	.pb-138 {
		padding-bottom: 138px;
	}
	.pb-139 {
		padding-bottom: 139px;
	}
	.pb-140 {
		padding-bottom: 140px;
	}
	.pb-141 {
		padding-bottom: 141px;
	}
	.pb-142 {
		padding-bottom: 142px;
	}
	.pb-143 {
		padding-bottom: 143px;
	}
	.pb-144 {
		padding-bottom: 144px;
	}
	.pb-145 {
		padding-bottom: 145px;
	}
	.pb-146 {
		padding-bottom: 146px;
	}
	.pb-147 {
		padding-bottom: 147px;
	}
	.pb-148 {
		padding-bottom: 148px;
	}
	.pb-149 {
		padding-bottom: 149px;
	}
	.pb-150 {
		padding-bottom: 150px;
	}
	.pb-151 {
		padding-bottom: 151px;
	}
	.pb-152 {
		padding-bottom: 152px;
	}
	.pb-153 {
		padding-bottom: 153px;
	}
	.pb-154 {
		padding-bottom: 154px;
	}
	.pb-155 {
		padding-bottom: 155px;
	}
	.pb-156 {
		padding-bottom: 156px;
	}
	.pb-157 {
		padding-bottom: 157px;
	}
	.pb-158 {
		padding-bottom: 158px;
	}
	.pb-159 {
		padding-bottom: 159px;
	}
	.pb-160 {
		padding-bottom: 160px;
	}
	.pb-161 {
		padding-bottom: 161px;
	}
	.pb-162 {
		padding-bottom: 162px;
	}
	.pb-163 {
		padding-bottom: 163px;
	}
	.pb-164 {
		padding-bottom: 164px;
	}
	.pb-165 {
		padding-bottom: 165px;
	}
	.pb-166 {
		padding-bottom: 166px;
	}
	.pb-167 {
		padding-bottom: 167px;
	}
	.pb-168 {
		padding-bottom: 168px;
	}
	.pb-169 {
		padding-bottom: 169px;
	}
	.pb-170 {
		padding-bottom: 170px;
	}
	.pb-171 {
		padding-bottom: 171px;
	}
	.pb-172 {
		padding-bottom: 172px;
	}
	.pb-173 {
		padding-bottom: 173px;
	}
	.pb-174 {
		padding-bottom: 174px;
	}
	.pb-175 {
		padding-bottom: 175px;
	}
	.pb-176 {
		padding-bottom: 176px;
	}
	.pb-177 {
		padding-bottom: 177px;
	}
	.pb-178 {
		padding-bottom: 178px;
	}
	.pb-179 {
		padding-bottom: 179px;
	}
	.pb-180 {
		padding-bottom: 180px;
	}
	.pb-181 {
		padding-bottom: 181px;
	}
	.pb-182 {
		padding-bottom: 182px;
	}
	.pb-183 {
		padding-bottom: 183px;
	}
	.pb-184 {
		padding-bottom: 184px;
	}
	.pb-185 {
		padding-bottom: 185px;
	}
	.pb-186 {
		padding-bottom: 186px;
	}
	.pb-187 {
		padding-bottom: 187px;
	}
	.pb-188 {
		padding-bottom: 188px;
	}
	.pb-189 {
		padding-bottom: 189px;
	}
	.pb-190 {
		padding-bottom: 190px;
	}
	.pb-191 {
		padding-bottom: 191px;
	}
	.pb-192 {
		padding-bottom: 192px;
	}
	.pb-193 {
		padding-bottom: 193px;
	}
	.pb-194 {
		padding-bottom: 194px;
	}
	.pb-195 {
		padding-bottom: 195px;
	}
	.pb-196 {
		padding-bottom: 196px;
	}
	.pb-197 {
		padding-bottom: 197px;
	}
	.pb-198 {
		padding-bottom: 198px;
	}
	.pb-199 {
		padding-bottom: 199px;
	}
	.pb-200 {
		padding-bottom: 200px;
	}
/*}*/

/*------------------------------------------
	03. PADDING RIGHT
-------------------------------------------*/
/*.pr {*/
	.pr-0 {
		padding-right: 0px!important;
	}
	.pr-1 {
		padding-right: 1px;
	}
	.pr-2 {
		padding-right: 2px;
	}
	.pb-3 {
		padding-bottom: 3px;
	}
	.pr-4 {
		padding-right: 4px;
	}
	.pr-5 {
		padding-right: 5px;
	}
	.pr-6 {
		padding-right: 6px;
	}
	.pr-7 {
		padding-right: 7px;
	}
	.pr-8 {
		padding-right: 8px;
	}
	.pr-9 {
		padding-right: 9px;
	}
	.pr-10 {
		padding-right: 10px;
	}
	.pr-11 {
		padding-right: 11px;
	}
	.pr-12 {
		padding-right: 12px;
	}
	.pr-13 {
		padding-right: 13px;
	}
	.pr-14 {
		padding-right: 14px;
	}
	.pr-15 {
		padding-right: 15px;
	}
	.pr-16 {
		padding-right: 16px;
	}
	.pr-17 {
		padding-right: 17px;
	}
	.pr-18 {
		padding-right: 18px;
	}
	.pr-19 {
		padding-right: 19px;
	}
	.pr-20 {
		padding-right: 20px;
	}
	.pr-21 {
		padding-right: 21px;
	}
	.pr-22 {
		padding-right: 22px;
	}
	.pr-23 {
		padding-right: 23px;
	}
	.pr-24 {
		padding-right: 24px;
	}
	.pr-25 {
		padding-right: 25px;
	}
	.pr-26 {
		padding-right: 26px;
	}
	.pr-27 {
		padding-right: 27px;
	}
	.pr-28 {
		padding-right: 28px;
	}
	.pr-29 {
		padding-right: 29px;
	}
	.pr-30 {
		padding-right: 30px;
	}
	.pr-31 {
		padding-right: 31px;
	}
	.pr-32 {
		padding-right: 32px;
	}
	.pr-33 {
		padding-right: 33px;
	}
	.pr-34 {
		padding-right: 34px;
	}
	.pr-35 {
		padding-right: 35px;
	}
	.pr-36 {
		padding-right: 36px;
	}
	.pr-37 {
		padding-right: 37px;
	}
	.pr-38 {
		padding-right: 38px;
	}
	.pr-39 {
		padding-right: 39px;
	}
	.pr-40 {
		padding-right: 40px;
	}
	.pr-41 {
		padding-right: 41px;
	}
	.pr-42 {
		padding-right: 42px;
	}
	.pr-43 {
		padding-right: 43px;
	}
	.pr-44 {
		padding-right: 44px;
	}
	.pr-45 {
		padding-right: 45px;
	}
	.pr-46 {
		padding-right: 46px;
	}
	.pr-47 {
		padding-right: 47px;
	}
	.pr-48 {
		padding-right: 48px;
	}
	.pr-49 {
		padding-right: 49px;
	}
	.pr-50 {
		padding-right: 50px;
	}
	.pr-51 {
		padding-right: 51px;
	}
	.pr-52 {
		padding-right: 52px;
	}
	.pr-53 {
		padding-right: 53px;
	}
	.pr-54 {
		padding-right: 54px;
	}
	.pr-55 {
		padding-right: 55px;
	}
	.pr-56 {
		padding-right: 56px;
	}
	.pr-57 {
		padding-right: 57px;
	}
	.pr-58 {
		padding-right: 58px;
	}
	.pr-59 {
		padding-right: 59px;
	}
	.pr-60 {
		padding-right: 60px;
	}
	.pr-61 {
		padding-right: 61px;
	}
	.pr-62 {
		padding-right: 62px;
	}
	.pr-63 {
		padding-right: 63px;
	}
	.pr-64 {
		padding-right: 64px;
	}
	.pr-65 {
		padding-right: 65px;
	}
	.pr-66 {
		padding-right: 66px;
	}
	.pr-67 {
		padding-right: 67px;
	}
	.pr-68 {
		padding-right: 68px;
	}
	.pr-69 {
		padding-right: 69px;
	}
	.pr-70 {
		padding-right: 70px;
	}
	.pr-71 {
		padding-right: 71px;
	}
	.pr-72 {
		padding-right: 72px;
	}
	.pr-73 {
		padding-right: 73px;
	}
	.pr-74 {
		padding-right: 74px;
	}
	.pr-75 {
		padding-right: 75px;
	}
	.pr-76 {
		padding-right: 76px;
	}
	.pr-77 {
		padding-right: 77px;
	}
	.pr-78 {
		padding-right: 78px;
	}
	.pr-79 {
		padding-right: 79px;
	}
	.pr-80 {
		padding-right: 80px;
	}
	.pr-81 {
		padding-right: 81px;
	}
	.pr-82 {
		padding-right: 82px;
	}
	.pr-83 {
		padding-right: 83px;
	}
	.pr-84 {
		padding-right: 84px;
	}
	.pr-85 {
		padding-right: 85px;
	}
	.pr-86 {
		padding-right: 86px;
	}
	.pr-87 {
		padding-right: 87px;
	}
	.pr-88 {
		padding-right: 88px;
	}
	.pr-89 {
		padding-right: 89px;
	}
	.pr-90 {
		padding-right: 90px;
	}
	.pr-91 {
		padding-right: 91px;
	}
	.pr-92 {
		padding-right: 92px;
	}
	.pr-93 {
		padding-right: 93px;
	}
	.pr-94 {
		padding-right: 94px;
	}
	.pr-95 {
		padding-right: 95px;
	}
	.pr-96 {
		padding-right: 96px;
	}
	.pr-97 {
		padding-right: 97px;
	}
	.pr-98 {
		padding-right: 98px;
	}
	.pr-99 {
		padding-right: 99px;
	}
	.pr-100 {
		padding-right: 100px;
	}
	.pr-101 {
		padding-right: 101px;
	}
	.pr-102 {
		padding-right: 102px;
	}
	.pb-103 {
		padding-bottom: 103px;
	}
	.pr-104 {
		padding-right: 104px;
	}
	.pr-105 {
		padding-right: 105px;
	}
	.pr-106 {
		padding-right: 106px;
	}
	.pr-107 {
		padding-right: 107px;
	}
	.pr-108 {
		padding-right: 108px;
	}
	.pr-109 {
		padding-right: 109px;
	}
	.pr-110 {
		padding-right: 110px;
	}
	.pr-111 {
		padding-right: 111px;
	}
	.pr-112 {
		padding-right: 112px;
	}
	.pr-113 {
		padding-right: 113px;
	}
	.pr-114 {
		padding-right: 114px;
	}
	.pr-115 {
		padding-right: 115px;
	}
	.pr-116 {
		padding-right: 116px;
	}
	.pr-117 {
		padding-right: 117px;
	}
	.pr-118 {
		padding-right: 118px;
	}
	.pr-119 {
		padding-right: 119px;
	}
	.pr-120 {
		padding-right: 120px;
	}
	.pr-121 {
		padding-right: 121px;
	}
	.pr-122 {
		padding-right: 122px;
	}
	.pr-123 {
		padding-right: 123px;
	}
	.pr-124 {
		padding-right: 124px;
	}
	.pr-125 {
		padding-right: 125px;
	}
	.pr-126 {
		padding-right: 126px;
	}
	.pr-127 {
		padding-right: 127px;
	}
	.pr-128 {
		padding-right: 128px;
	}
	.pr-129 {
		padding-right: 129px;
	}
	.pr-130 {
		padding-right: 130px;
	}
	.pr-131 {
		padding-right: 131px;
	}
	.pr-132 {
		padding-right: 132px;
	}
	.pr-133 {
		padding-right: 133px;
	}
	.pr-134 {
		padding-right: 134px;
	}
	.pr-135 {
		padding-right: 135px;
	}
	.pr-136 {
		padding-right: 136px;
	}
	.pr-137 {
		padding-right: 137px;
	}
	.pr-138 {
		padding-right: 138px;
	}
	.pr-139 {
		padding-right: 139px;
	}
	.pr-140 {
		padding-right: 140px;
	}
	.pr-141 {
		padding-right: 141px;
	}
	.pr-142 {
		padding-right: 142px;
	}
	.pr-143 {
		padding-right: 143px;
	}
	.pr-144 {
		padding-right: 144px;
	}
	.pr-145 {
		padding-right: 145px;
	}
	.pr-146 {
		padding-right: 146px;
	}
	.pr-147 {
		padding-right: 147px;
	}
	.pr-148 {
		padding-right: 148px;
	}
	.pr-149 {
		padding-right: 149px;
	}
	.pr-150 {
		padding-right: 150px;
	}
	.pr-151 {
		padding-right: 151px;
	}
	.pr-152 {
		padding-right: 152px;
	}
	.pr-153 {
		padding-right: 153px;
	}
	.pr-154 {
		padding-right: 154px;
	}
	.pr-155 {
		padding-right: 155px;
	}
	.pr-156 {
		padding-right: 156px;
	}
	.pr-157 {
		padding-right: 157px;
	}
	.pr-158 {
		padding-right: 158px;
	}
	.pr-159 {
		padding-right: 159px;
	}
	.pr-160 {
		padding-right: 160px;
	}
	.pr-161 {
		padding-right: 161px;
	}
	.pr-162 {
		padding-right: 162px;
	}
	.pr-163 {
		padding-right: 163px;
	}
	.pr-164 {
		padding-right: 164px;
	}
	.pr-165 {
		padding-right: 165px;
	}
	.pr-166 {
		padding-right: 166px;
	}
	.pr-167 {
		padding-right: 167px;
	}
	.pr-168 {
		padding-right: 168px;
	}
	.pr-169 {
		padding-right: 169px;
	}
	.pr-170 {
		padding-right: 170px;
	}
	.pr-171 {
		padding-right: 171px;
	}
	.pr-172 {
		padding-right: 172px;
	}
	.pr-173 {
		padding-right: 173px;
	}
	.pr-174 {
		padding-right: 174px;
	}
	.pr-175 {
		padding-right: 175px;
	}
	.pr-176 {
		padding-right: 176px;
	}
	.pr-177 {
		padding-right: 177px;
	}
	.pr-178 {
		padding-right: 178px;
	}
	.pr-179 {
		padding-right: 179px;
	}
	.pr-180 {
		padding-right: 180px;
	}
	.pr-181 {
		padding-right: 181px;
	}
	.pr-182 {
		padding-right: 182px;
	}
	.pr-183 {
		padding-right: 183px;
	}
	.pr-184 {
		padding-right: 184px;
	}
	.pr-185 {
		padding-right: 185px;
	}
	.pr-186 {
		padding-right: 186px;
	}
	.pr-187 {
		padding-right: 187px;
	}
	.pr-188 {
		padding-right: 188px;
	}
	.pr-189 {
		padding-right: 189px;
	}
	.pr-190 {
		padding-right: 190px;
	}
	.pr-191 {
		padding-right: 191px;
	}
	.pr-192 {
		padding-right: 192px;
	}
	.pr-193 {
		padding-right: 193px;
	}
	.pr-194 {
		padding-right: 194px;
	}
	.pr-195 {
		padding-right: 195px;
	}
	.pr-196 {
		padding-right: 196px;
	}
	.pr-197 {
		padding-right: 197px;
	}
	.pr-198 {
		padding-right: 198px;
	}
	.pr-199 {
		padding-right: 199px;
	}
	.pr-200 {
		padding-right: 200px;
	}
/*}*/

/*------------------------------------------
	04. PADDING LEFT
-------------------------------------------*/
/*.pl {*/
	.pl-0 {
		padding-left: 0px!important;
	}
	.pl-1 {
		padding-left: 1px;
	}
	.pl-2 {
		padding-left: 2px;
	}
	.pb-3 {
		padding-bottom: 3px;
	}
	.pl-4 {
		padding-left: 4px;
	}
	.pl-5 {
		padding-left: 5px;
	}
	.pl-6 {
		padding-left: 6px;
	}
	.pl-7 {
		padding-left: 7px;
	}
	.pl-8 {
		padding-left: 8px;
	}
	.pl-9 {
		padding-left: 9px;
	}
	.pl-10 {
		padding-left: 10px;
	}
	.pl-11 {
		padding-left: 11px;
	}
	.pl-12 {
		padding-left: 12px;
	}
	.pl-13 {
		padding-left: 13px;
	}
	.pl-14 {
		padding-left: 14px;
	}
	.pl-15 {
		padding-left: 15px;
	}
	.pl-16 {
		padding-left: 16px;
	}
	.pl-17 {
		padding-left: 17px;
	}
	.pl-18 {
		padding-left: 18px;
	}
	.pl-19 {
		padding-left: 19px;
	}
	.pl-20 {
		padding-left: 20px;
	}
	.pl-21 {
		padding-left: 21px;
	}
	.pl-22 {
		padding-left: 22px;
	}
	.pl-23 {
		padding-left: 23px;
	}
	.pl-24 {
		padding-left: 24px;
	}
	.pl-25 {
		padding-left: 25px;
	}
	.pl-26 {
		padding-left: 26px;
	}
	.pl-27 {
		padding-left: 27px;
	}
	.pl-28 {
		padding-left: 28px;
	}
	.pl-29 {
		padding-left: 29px;
	}
	.pl-30 {
		padding-left: 30px!important;
	}
	.pl-31 {
		padding-left: 31px;
	}
	.pl-32 {
		padding-left: 32px;
	}
	.pl-33 {
		padding-left: 33px;
	}
	.pl-34 {
		padding-left: 34px;
	}
	.pl-35 {
		padding-left: 35px;
	}
	.pl-36 {
		padding-left: 36px;
	}
	.pl-37 {
		padding-left: 37px;
	}
	.pl-38 {
		padding-left: 38px;
	}
	.pl-39 {
		padding-left: 39px;
	}
	.pl-40 {
		padding-left: 40px;
	}
	.pl-41 {
		padding-left: 41px;
	}
	.pl-42 {
		padding-left: 42px;
	}
	.pl-43 {
		padding-left: 43px;
	}
	.pl-44 {
		padding-left: 44px;
	}
	.pl-45 {
		padding-left: 45px;
	}
	.pl-46 {
		padding-left: 46px;
	}
	.pl-47 {
		padding-left: 47px;
	}
	.pl-48 {
		padding-left: 48px;
	}
	.pl-49 {
		padding-left: 49px;
	}
	.pl-50 {
		padding-left: 50px;
	}
	.pl-51 {
		padding-left: 51px;
	}
	.pl-52 {
		padding-left: 52px;
	}
	.pl-53 {
		padding-left: 53px;
	}
	.pl-54 {
		padding-left: 54px;
	}
	.pl-55 {
		padding-left: 55px;
	}
	.pl-56 {
		padding-left: 56px;
	}
	.pl-57 {
		padding-left: 57px;
	}
	.pl-58 {
		padding-left: 58px;
	}
	.pl-59 {
		padding-left: 59px;
	}
	.pl-60 {
		padding-left: 60px;
	}
	.pl-61 {
		padding-left: 61px;
	}
	.pl-62 {
		padding-left: 62px;
	}
	.pl-63 {
		padding-left: 63px;
	}
	.pl-64 {
		padding-left: 64px;
	}
	.pl-65 {
		padding-left: 65px;
	}
	.pl-66 {
		padding-left: 66px;
	}
	.pl-67 {
		padding-left: 67px;
	}
	.pl-68 {
		padding-left: 68px;
	}
	.pl-69 {
		padding-left: 69px;
	}
	.pl-70 {
		padding-left: 70px;
	}
	.pl-71 {
		padding-left: 71px;
	}
	.pl-72 {
		padding-left: 72px;
	}
	.pl-73 {
		padding-left: 73px;
	}
	.pl-74 {
		padding-left: 74px;
	}
	.pl-75 {
		padding-left: 75px;
	}
	.pl-76 {
		padding-left: 76px;
	}
	.pl-77 {
		padding-left: 77px;
	}
	.pl-78 {
		padding-left: 78px;
	}
	.pl-79 {
		padding-left: 79px;
	}
	.pl-80 {
		padding-left: 80px;
	}
	.pl-81 {
		padding-left: 81px;
	}
	.pl-82 {
		padding-left: 82px;
	}
	.pl-83 {
		padding-left: 83px;
	}
	.pl-84 {
		padding-left: 84px;
	}
	.pl-85 {
		padding-left: 85px;
	}
	.pl-86 {
		padding-left: 86px;
	}
	.pl-87 {
		padding-left: 87px;
	}
	.pl-88 {
		padding-left: 88px;
	}
	.pl-89 {
		padding-left: 89px;
	}
	.pl-90 {
		padding-left: 90px;
	}
	.pl-91 {
		padding-left: 91px;
	}
	.pl-92 {
		padding-left: 92px;
	}
	.pl-93 {
		padding-left: 93px;
	}
	.pl-94 {
		padding-left: 94px;
	}
	.pl-95 {
		padding-left: 95px;
	}
	.pl-96 {
		padding-left: 96px;
	}
	.pl-97 {
		padding-left: 97px;
	}
	.pl-98 {
		padding-left: 98px;
	}
	.pl-99 {
		padding-left: 99px;
	}
	.pl-100 {
		padding-left: 100px;
	}
	.pl-101 {
		padding-left: 101px;
	}
	.pl-102 {
		padding-left: 102px;
	}
	.pb-103 {
		padding-bottom: 103px;
	}
	.pl-104 {
		padding-left: 104px;
	}
	.pl-105 {
		padding-left: 105px;
	}
	.pl-106 {
		padding-left: 106px;
	}
	.pl-107 {
		padding-left: 107px;
	}
	.pl-108 {
		padding-left: 108px;
	}
	.pl-109 {
		padding-left: 109px;
	}
	.pl-110 {
		padding-left: 110px;
	}
	.pl-111 {
		padding-left: 111px;
	}
	.pl-112 {
		padding-left: 112px;
	}
	.pl-113 {
		padding-left: 113px;
	}
	.pl-114 {
		padding-left: 114px;
	}
	.pl-115 {
		padding-left: 115px;
	}
	.pl-116 {
		padding-left: 116px;
	}
	.pl-117 {
		padding-left: 117px;
	}
	.pl-118 {
		padding-left: 118px;
	}
	.pl-119 {
		padding-left: 119px;
	}
	.pl-120 {
		padding-left: 120px;
	}
	.pl-121 {
		padding-left: 121px;
	}
	.pl-122 {
		padding-left: 122px;
	}
	.pl-123 {
		padding-left: 123px;
	}
	.pl-124 {
		padding-left: 124px;
	}
	.pl-125 {
		padding-left: 125px;
	}
	.pl-126 {
		padding-left: 126px;
	}
	.pl-127 {
		padding-left: 127px;
	}
	.pl-128 {
		padding-left: 128px;
	}
	.pl-129 {
		padding-left: 129px;
	}
	.pl-130 {
		padding-left: 130px;
	}
	.pl-131 {
		padding-left: 131px;
	}
	.pl-132 {
		padding-left: 132px;
	}
	.pl-133 {
		padding-left: 133px;
	}
	.pl-134 {
		padding-left: 134px;
	}
	.pl-135 {
		padding-left: 135px;
	}
	.pl-136 {
		padding-left: 136px;
	}
	.pl-137 {
		padding-left: 137px;
	}
	.pl-138 {
		padding-left: 138px;
	}
	.pl-139 {
		padding-left: 139px;
	}
	.pl-140 {
		padding-left: 140px;
	}
	.pl-141 {
		padding-left: 141px;
	}
	.pl-142 {
		padding-left: 142px;
	}
	.pl-143 {
		padding-left: 143px;
	}
	.pl-144 {
		padding-left: 144px;
	}
	.pl-145 {
		padding-left: 145px;
	}
	.pl-146 {
		padding-left: 146px;
	}
	.pl-147 {
		padding-left: 147px;
	}
	.pl-148 {
		padding-left: 148px;
	}
	.pl-149 {
		padding-left: 149px;
	}
	.pl-150 {
		padding-left: 150px;
	}
	.pl-151 {
		padding-left: 151px;
	}
	.pl-152 {
		padding-left: 152px;
	}
	.pl-153 {
		padding-left: 153px;
	}
	.pl-154 {
		padding-left: 154px;
	}
	.pl-155 {
		padding-left: 155px;
	}
	.pl-156 {
		padding-left: 156px;
	}
	.pl-157 {
		padding-left: 157px;
	}
	.pl-158 {
		padding-left: 158px;
	}
	.pl-159 {
		padding-left: 159px;
	}
	.pl-160 {
		padding-left: 160px;
	}
	.pl-161 {
		padding-left: 161px;
	}
	.pl-162 {
		padding-left: 162px;
	}
	.pl-163 {
		padding-left: 163px;
	}
	.pl-164 {
		padding-left: 164px;
	}
	.pl-165 {
		padding-left: 165px;
	}
	.pl-166 {
		padding-left: 166px;
	}
	.pl-167 {
		padding-left: 167px;
	}
	.pl-168 {
		padding-left: 168px;
	}
	.pl-169 {
		padding-left: 169px;
	}
	.pl-170 {
		padding-left: 170px;
	}
	.pl-171 {
		padding-left: 171px;
	}
	.pl-172 {
		padding-left: 172px;
	}
	.pl-173 {
		padding-left: 173px;
	}
	.pl-174 {
		padding-left: 174px;
	}
	.pl-175 {
		padding-left: 175px;
	}
	.pl-176 {
		padding-left: 176px;
	}
	.pl-177 {
		padding-left: 177px;
	}
	.pl-178 {
		padding-left: 178px;
	}
	.pl-179 {
		padding-left: 179px;
	}
	.pl-180 {
		padding-left: 180px;
	}
	.pl-181 {
		padding-left: 181px;
	}
	.pl-182 {
		padding-left: 182px;
	}
	.pl-183 {
		padding-left: 183px;
	}
	.pl-184 {
		padding-left: 184px;
	}
	.pl-185 {
		padding-left: 185px;
	}
	.pl-186 {
		padding-left: 186px;
	}
	.pl-187 {
		padding-left: 187px;
	}
	.pl-188 {
		padding-left: 188px;
	}
	.pl-189 {
		padding-left: 189px;
	}
	.pl-190 {
		padding-left: 190px;
	}
	.pl-191 {
		padding-left: 191px;
	}
	.pl-192 {
		padding-left: 192px;
	}
	.pl-193 {
		padding-left: 193px;
	}
	.pl-194 {
		padding-left: 194px;
	}
	.pl-195 {
		padding-left: 195px;
	}
	.pl-196 {
		padding-left: 196px;
	}
	.pl-197 {
		padding-left: 197px;
	}
	.pl-198 {
		padding-left: 198px;
	}
	.pl-199 {
		padding-left: 199px;
	}
	.pl-200 {
		padding-left: 200px;
	}
/*}*/

/*------------------------------------------
	05. MARGIN TOP
-------------------------------------------*/
/*.mt {*/
	.mt-0 {
		margin-top: 0px;
	}
	.mt-1 {
		margin-top: 1px;
	}
	.mt-2 {
		margin-top: 2px;
	}
	.mt-3 {
		margin-top: 3px!important;
	}
	.mt-4 {
		margin-top: 4px;
	}
	.mt-5 {
		margin-top: 5px!important;
	}
	.mt-6 {
		margin-top: 6px;
	}
	.mt-7 {
		margin-top: 7px;
	}
	.mt-8 {
		margin-top: 8px;
	}
	.mt-9 {
		margin-top: 9px;
	}
	.mt-10 {
		margin-top: 10px;
	}
	.mt-11 {
		margin-top: 11px;
	}
	.mt-12 {
		margin-top: 12px;
	}
	.mt-13 {
		margin-top: 13px;
	}
	.mt-14 {
		margin-top: 14px;
	}
	.mt-15 {
		margin-top: 15px!important;
	}
	.mt-16 {
		margin-top: 16px;
	}
	.mt-17 {
		margin-top: 17px;
	}
	.mt-18 {
		margin-top: 18px;
	}
	.mt-19 {
		margin-top: 19px;
	}
	.mt-20 {
		margin-top: 20px!important;
	}
	.mt-21 {
		margin-top: 21px;
	}
	.mt-22 {
		margin-top: 22px;
	}
	.mt-23 {
		margin-top: 23px;
	}
	.mt-24 {
		margin-top: 24px;
	}
	.mt-25 {
		margin-top: 25px;
	}
	.mt-26 {
		margin-top: 26px;
	}
	.mt-27 {
		margin-top: 27px;
	}
	.mt-28 {
		margin-top: 28px;
	}
	.mt-29 {
		margin-top: 29px;
	}
	.mt-30 {
		margin-top: 30px;
	}
	.mt-31 {
		margin-top: 31px;
	}
	.mt-32 {
		margin-top: 32px;
	}
	.mt-33 {
		margin-top: 33px;
	}
	.mt-34 {
		margin-top: 34px;
	}
	.mt-35 {
		margin-top: 35px;
	}
	.mt-36 {
		margin-top: 36px;
	}
	.mt-37 {
		margin-top: 37px!important;
	}
	.mt-38 {
		margin-top: 38px;
	}
	.mt-39 {
		margin-top: 39px;
	}
	.mt-40 {
		margin-top: 40px;
	}
	.mt-41 {
		margin-top: 41px!important;
	}
	.mt-42 {
		margin-top: 42px;
	}
	.mt-43 {
		margin-top: 43px;
	}
	.mt-44 {
		margin-top: 44px;
	}
	.mt-45 {
		margin-top: 45px;
	}
	.mt-46 {
		margin-top: 46px;
	}
	.mt-47 {
		margin-top: 47px;
	}
	.mt-48 {
		margin-top: 48px;
	}
	.mt-49 {
		margin-top: 49px;
	}
	.mt-50 {
		margin-top: 50px;
	}
	.mt-51 {
		margin-top: 51px;
	}
	.mt-52 {
		margin-top: 52px;
	}
	.mt-53 {
		margin-top: 53px;
	}
	.mt-54 {
		margin-top: 54px;
	}
	.mt-55 {
		margin-top: 55px;
	}
	.mt-56 {
		margin-top: 56px;
	}
	.mt-57 {
		margin-top: 57px;
	}
	.mt-58 {
		margin-top: 58px;
	}
	.mt-59 {
		margin-top: 59px;
	}
	.mt-60 {
		margin-top: 60px;
	}
	.mt-61 {
		margin-top: 61px;
	}
	.mt-62 {
		margin-top: 62px;
	}
	.mt-63 {
		margin-top: 63px;
	}
	.mt-64 {
		margin-top: 64px;
	}
	.mt-65 {
		margin-top: 65px;
	}
	.mt-66 {
		margin-top: 66px;
	}
	.mt-67 {
		margin-top: 67px;
	}
	.mt-68 {
		margin-top: 68px;
	}
	.mt-69 {
		margin-top: 69px;
	}
	.mt-70 {
		margin-top: 70px;
	}
	.mt-71 {
		margin-top: 71px;
	}
	.mt-72 {
		margin-top: 72px;
	}
	.mt-73 {
		margin-top: 73px;
	}
	.mt-74 {
		margin-top: 74px;
	}
	.mt-75 {
		margin-top: 75px;
	}
	.mt-76 {
		margin-top: 76px;
	}
	.mt-77 {
		margin-top: 77px;
	}
	.mt-78 {
		margin-top: 78px;
	}
	.mt-79 {
		margin-top: 79px;
	}
	.mt-80 {
		margin-top: 80px;
	}
	.mt-81 {
		margin-top: 81px;
	}
	.mt-82 {
		margin-top: 82px;
	}
	.mt-83 {
		margin-top: 83px;
	}
	.mt-84 {
		margin-top: 84px;
	}
	.mt-85 {
		margin-top: 85px;
	}
	.mt-86 {
		margin-top: 86px;
	}
	.mt-87 {
		margin-top: 87px;
	}
	.mt-88 {
		margin-top: 88px;
	}
	.mt-89 {
		margin-top: 89px;
	}
	.mt-90 {
		margin-top: 90px;
	}
	.mt-91 {
		margin-top: 91px;
	}
	.mt-92 {
		margin-top: 92px;
	}
	.mt-93 {
		margin-top: 93px;
	}
	.mt-94 {
		margin-top: 94px;
	}
	.mt-95 {
		margin-top: 95px;
	}
	.mt-96 {
		margin-top: 96px;
	}
	.mt-97 {
		margin-top: 97px;
	}
	.mt-98 {
		margin-top: 98px;
	}
	.mt-99 {
		margin-top: 99px;
	}
	.mt-100 {
		margin-top: 100px;
	}

	.mt-101 {
		margin-top: 101px;
	}
	.mt-102 {
		margin-top: 102px;
	}
	.mt-103 {
		margin-top: 103px;
	}
	.mt-104 {
		margin-top: 104px;
	}
	.mt-105 {
		margin-top: 105px;
	}
	.mt-106 {
		margin-top: 106px;
	}
	.mt-107 {
		margin-top: 107px;
	}
	.mt-108 {
		margin-top: 108px;
	}
	.mt-109 {
		margin-top: 109px;
	}
	.mt-110 {
		margin-top: 110px;
	}
	.mt-111 {
		margin-top: 111px;
	}
	.mt-112 {
		margin-top: 112px;
	}
	.mt-113 {
		margin-top: 113px;
	}
	.mt-114 {
		margin-top: 114px;
	}
	.mt-115 {
		margin-top: 115px;
	}
	.mt-116 {
		margin-top: 116px;
	}
	.mt-117 {
		margin-top: 117px;
	}
	.mt-118 {
		margin-top: 118px;
	}
	.mt-119 {
		margin-top: 119px;
	}
	.mt-120 {
		margin-top: 120px;
	}
	.mt-121 {
		margin-top: 121px;
	}
	.mt-122 {
		margin-top: 122px;
	}
	.mt-123 {
		margin-top: 123px;
	}
	.mt-124 {
		margin-top: 124px;
	}
	.mt-125 {
		margin-top: 125px;
	}
	.mt-126 {
		margin-top: 126px;
	}
	.mt-127 {
		margin-top: 127px;
	}
	.mt-128 {
		margin-top: 128px;
	}
	.mt-129 {
		margin-top: 129px;
	}
	.mt-130 {
		margin-top: 130px;
	}
	.mt-131 {
		margin-top: 131px;
	}
	.mt-132 {
		margin-top: 132px;
	}
	.mt-133 {
		margin-top: 133px;
	}
	.mt-134 {
		margin-top: 134px;
	}
	.mt-135 {
		margin-top: 135px;
	}
	.mt-136 {
		margin-top: 136px;
	}
	.mt-137 {
		margin-top: 137px;
	}
	.mt-138 {
		margin-top: 138px;
	}
	.mt-139 {
		margin-top: 139px;
	}
	.mt-140 {
		margin-top: 140px;
	}
	.mt-141 {
		margin-top: 141px;
	}
	.mt-142 {
		margin-top: 142px;
	}
	.mt-143 {
		margin-top: 143px;
	}
	.mt-144 {
		margin-top: 144px;
	}
	.mt-145 {
		margin-top: 145px;
	}
	.mt-146 {
		margin-top: 146px;
	}
	.mt-147 {
		margin-top: 147px;
	}
	.mt-148 {
		margin-top: 148px;
	}
	.mt-149 {
		margin-top: 149px;
	}
	.mt-150 {
		margin-top: 150px;
	}
	.mt-151 {
		margin-top: 151px;
	}
	.mt-152 {
		margin-top: 152px;
	}
	.mt-153 {
		margin-top: 153px;
	}
	.mt-154 {
		margin-top: 154px;
	}
	.mt-155 {
		margin-top: 155px;
	}
	.mt-156 {
		margin-top: 156px;
	}
	.mt-157 {
		margin-top: 157px;
	}
	.mt-158 {
		margin-top: 158px;
	}
	.mt-159 {
		margin-top: 159px;
	}
	.mt-160 {
		margin-top: 160px;
	}
	.mt-161 {
		margin-top: 161px;
	}
	.mt-162 {
		margin-top: 162px;
	}
	.mt-163 {
		margin-top: 163px;
	}
	.mt-164 {
		margin-top: 164px;
	}
	.mt-165 {
		margin-top: 165px;
	}
	.mt-166 {
		margin-top: 166px;
	}
	.mt-167 {
		margin-top: 167px;
	}
	.mt-168 {
		margin-top: 168px;
	}
	.mt-169 {
		margin-top: 169px;
	}
	.mt-170 {
		margin-top: 170px;
	}
	.mt-171 {
		margin-top: 171px;
	}
	.mt-172 {
		margin-top: 172px;
	}
	.mt-173 {
		margin-top: 173px;
	}
	.mt-174 {
		margin-top: 174px;
	}
	.mt-175 {
		margin-top: 175px;
	}
	.mt-176 {
		margin-top: 176px;
	}
	.mt-177 {
		margin-top: 177px;
	}
	.mt-178 {
		margin-top: 178px;
	}
	.mt-179 {
		margin-top: 179px;
	}
	.mt-180 {
		margin-top: 180px;
	}
	.mt-181 {
		margin-top: 181px;
	}
	.mt-182 {
		margin-top: 182px;
	}
	.mt-183 {
		margin-top: 183px;
	}
	.mt-184 {
		margin-top: 184px;
	}
	.mt-185 {
		margin-top: 185px;
	}
	.mt-186 {
		margin-top: 186px;
	}
	.mt-187 {
		margin-top: 187px;
	}
	.mt-188 {
		margin-top: 188px;
	}
	.mt-189 {
		margin-top: 189px;
	}
	.mt-190 {
		margin-top: 190px;
	}
	.mt-191 {
		margin-top: 191px;
	}
	.mt-192 {
		margin-top: 192px;
	}
	.mt-193 {
		margin-top: 193px;
	}
	.mt-194 {
		margin-top: 194px;
	}
	.mt-195 {
		margin-top: 195px;
	}
	.mt-196 {
		margin-top: 196px;
	}
	.mt-197 {
		margin-top: 197px;
	}
	.mt-198 {
		margin-top: 198px;
	}
	.mt-199 {
		margin-top: 199px;
	}
	.mt-200 {
		margin-top: 200px;
	}
/*}*/

/*------------------------------------------
	06. MARGIN BOTTOM
-------------------------------------------*/
/*.mb {*/
	.mb-0 {
		margin-bottom: 0px;
	}
	.mb-1 {
		margin-bottom: 1px;
	}
	.mb-2 {
		margin-bottom: 2px;
	}
	.mb-3 {
		margin-bottom: 3px;
	}
	.mb-4 {
		margin-bottom: 4px;
	}
	.mb-5 {
		margin-bottom: 5px!important;
	}
	.mb-6 {
		margin-bottom: 6px;
	}
	.mb-7 {
		margin-bottom: 7px;
	}
	.mb-8 {
		margin-bottom: 8px;
	}
	.mb-9 {
		margin-bottom: 9px;
	}
	.mb-10 {
		margin-bottom: 10px;
	}
	.mb-11 {
		margin-bottom: 11px;
	}
	.mb-12 {
		margin-bottom: 12px;
	}
	.mb-13 {
		margin-bottom: 13px;
	}
	.mb-14 {
		margin-bottom: 14px;
	}
	.mb-15 {
		margin-bottom: 15px!important;
	}
	.mb-16 {
		margin-bottom: 16px;
	}
	.mb-17 {
		margin-bottom: 17px;
	}
	.mb-18 {
		margin-bottom: 18px;
	}
	.mb-19 {
		margin-bottom: 19px;
	}
	.mb-20 {
		margin-bottom: 20px;
	}
	.mb-21 {
		margin-bottom: 21px;
	}
	.mb-22 {
		margin-bottom: 22px;
	}
	.mb-23 {
		margin-bottom: 23px;
	}
	.mb-24 {
		margin-bottom: 24px;
	}
	.mb-25 {
		margin-bottom: 25px;
	}
	.mb-26 {
		margin-bottom: 26px!important;
	}
	.mb-27 {
		margin-bottom: 27px;
	}
	.mb-28 {
		margin-bottom: 28px;
	}
	.mb-29 {
		margin-bottom: 29px;
	}
	.mb-30 {
		margin-bottom: 30px;
	}
	.mb-31 {
		margin-bottom: 31px;
	}
	.mb-32 {
		margin-bottom: 32px;
	}
	.mb-33 {
		margin-bottom: 33px;
	}
	.mb-34 {
		margin-bottom: 34px;
	}
	.mb-35 {
		margin-bottom: 35px;
	}
	.mb-36 {
		margin-bottom: 36px;
	}
	.mb-37 {
		margin-bottom: 37px;
	}
	.mb-38 {
		margin-bottom: 38px;
	}
	.mb-39 {
		margin-bottom: 39px;
	}
	.mb-40 {
		margin-bottom: 40px;
	}
	.mb-41 {
		margin-bottom: 41px;
	}
	.mb-42 {
		margin-bottom: 42px;
	}
	.mb-43 {
		margin-bottom: 43px;
	}
	.mb-44 {
		margin-bottom: 44px;
	}
	.mb-45 {
		margin-bottom: 45px;
	}
	.mb-46 {
		margin-bottom: 46px;
	}
	.mb-47 {
		margin-bottom: 47px;
	}
	.mb-48 {
		margin-bottom: 48px;
	}
	.mb-49 {
		margin-bottom: 49px;
	}
	.mb-50 {
		margin-bottom: 50px!important;
	}
	.mb-51 {
		margin-bottom: 51px;
	}
	.mb-52 {
		margin-bottom: 52px;
	}
	.mb-53 {
		margin-bottom: 53px;
	}
	.mb-54 {
		margin-bottom: 54px;
	}
	.mb-55 {
		margin-bottom: 55px;
	}
	.mb-56 {
		margin-bottom: 56px;
	}
	.mb-57 {
		margin-bottom: 57px;
	}
	.mb-58 {
		margin-bottom: 58px;
	}
	.mb-59 {
		margin-bottom: 59px;
	}
	.mb-60 {
		margin-bottom: 60px!important;
	}
	.mb-61 {
		margin-bottom: 61px;
	}
	.mb-62 {
		margin-bottom: 62px;
	}
	.mb-63 {
		margin-bottom: 63px;
	}
	.mb-64 {
		margin-bottom: 64px;
	}
	.mb-65 {
		margin-bottom: 65px;
	}
	.mb-66 {
		margin-bottom: 66px;
	}
	.mb-67 {
		margin-bottom: 67px;
	}
	.mb-68 {
		margin-bottom: 68px;
	}
	.mb-69 {
		margin-bottom: 69px;
	}
	.mb-70 {
		margin-bottom: 70px;
	}
	.mb-71 {
		margin-bottom: 71px;
	}
	.mb-72 {
		margin-bottom: 72px;
	}
	.mb-73 {
		margin-bottom: 73px;
	}
	.mb-74 {
		margin-bottom: 74px;
	}
	.mb-75 {
		margin-bottom: 75px;
	}
	.mb-76 {
		margin-bottom: 76px;
	}
	.mb-77 {
		margin-bottom: 77px;
	}
	.mb-78 {
		margin-bottom: 78px;
	}
	.mb-79 {
		margin-bottom: 79px;
	}
	.mb-80 {
		margin-bottom: 80px;
	}
	.mb-81 {
		margin-bottom: 81px;
	}
	.mb-82 {
		margin-bottom: 82px;
	}
	.mb-83 {
		margin-bottom: 83px;
	}
	.mb-84 {
		margin-bottom: 84px;
	}
	.mb-85 {
		margin-bottom: 85px!important;
	}
	.mb-86 {
		margin-bottom: 86px;
	}
	.mb-87 {
		margin-bottom: 87px;
	}
	.mb-88 {
		margin-bottom: 88px;
	}
	.mb-89 {
		margin-bottom: 89px;
	}
	.mb-90 {
		margin-bottom: 90px;
	}
	.mb-91 {
		margin-bottom: 91px;
	}
	.mb-92 {
		margin-bottom: 92px;
	}
	.mb-93 {
		margin-bottom: 93px;
	}
	.mb-94 {
		margin-bottom: 94px;
	}
	.mb-95 {
		margin-bottom: 95px;
	}
	.mb-96 {
		margin-bottom: 96px;
	}
	.mb-97 {
		margin-bottom: 97px;
	}
	.mb-98 {
		margin-bottom: 98px;
	}
	.mb-99 {
		margin-bottom: 99px;
	}
	.mb-100 {
		margin-bottom: 100px;
	}

	.mb-101 {
		margin-bottom: 101px;
	}
	.mb-102 {
		margin-bottom: 102px;
	}
	.mb-103 {
		margin-bottom: 103px;
	}
	.mb-104 {
		margin-bottom: 104px;
	}
	.mb-105 {
		margin-bottom: 105px;
	}
	.mb-106 {
		margin-bottom: 106px;
	}
	.mb-107 {
		margin-bottom: 107px;
	}
	.mb-108 {
		margin-bottom: 108px;
	}
	.mb-109 {
		margin-bottom: 109px;
	}
	.mb-110 {
		margin-bottom: 110px;
	}
	.mb-111 {
		margin-bottom: 111px;
	}
	.mb-112 {
		margin-bottom: 112px;
	}
	.mb-113 {
		margin-bottom: 113px;
	}
	.mb-114 {
		margin-bottom: 114px;
	}
	.mb-115 {
		margin-bottom: 115px;
	}
	.mb-116 {
		margin-bottom: 116px;
	}
	.mb-117 {
		margin-bottom: 117px;
	}
	.mb-118 {
		margin-bottom: 118px;
	}
	.mb-119 {
		margin-bottom: 119px;
	}
	.mb-120 {
		margin-bottom: 120px;
	}
	.mb-121 {
		margin-bottom: 121px;
	}
	.mb-122 {
		margin-bottom: 122px;
	}
	.mb-123 {
		margin-bottom: 123px;
	}
	.mb-124 {
		margin-bottom: 124px;
	}
	.mb-125 {
		margin-bottom: 125px;
	}
	.mb-126 {
		margin-bottom: 126px;
	}
	.mb-127 {
		margin-bottom: 127px;
	}
	.mb-128 {
		margin-bottom: 128px;
	}
	.mb-129 {
		margin-bottom: 129px;
	}
	.mb-130 {
		margin-bottom: 130px;
	}
	.mb-131 {
		margin-bottom: 131px;
	}
	.mb-132 {
		margin-bottom: 132px;
	}
	.mb-133 {
		margin-bottom: 133px;
	}
	.mb-134 {
		margin-bottom: 134px;
	}
	.mb-135 {
		margin-bottom: 135px;
	}
	.mb-136 {
		margin-bottom: 136px;
	}
	.mb-137 {
		margin-bottom: 137px;
	}
	.mb-138 {
		margin-bottom: 138px;
	}
	.mb-139 {
		margin-bottom: 139px;
	}
	.mb-140 {
		margin-bottom: 140px;
	}
	.mb-141 {
		margin-bottom: 141px;
	}
	.mb-142 {
		margin-bottom: 142px;
	}
	.mb-143 {
		margin-bottom: 143px;
	}
	.mb-144 {
		margin-bottom: 144px;
	}
	.mb-145 {
		margin-bottom: 145px;
	}
	.mb-146 {
		margin-bottom: 146px;
	}
	.mb-147 {
		margin-bottom: 147px;
	}
	.mb-148 {
		margin-bottom: 148px;
	}
	.mb-149 {
		margin-bottom: 149px;
	}
	.mb-150 {
		margin-bottom: 150px;
	}
	.mb-151 {
		margin-bottom: 151px;
	}
	.mb-152 {
		margin-bottom: 152px;
	}
	.mb-153 {
		margin-bottom: 153px;
	}
	.mb-154 {
		margin-bottom: 154px;
	}
	.mb-155 {
		margin-bottom: 155px;
	}
	.mb-156 {
		margin-bottom: 156px;
	}
	.mb-157 {
		margin-bottom: 157px;
	}
	.mb-158 {
		margin-bottom: 158px;
	}
	.mb-159 {
		margin-bottom: 159px;
	}
	.mb-160 {
		margin-bottom: 160px;
	}
	.mb-161 {
		margin-bottom: 161px;
	}
	.mb-162 {
		margin-bottom: 162px;
	}
	.mb-163 {
		margin-bottom: 163px;
	}
	.mb-164 {
		margin-bottom: 164px;
	}
	.mb-165 {
		margin-bottom: 165px;
	}
	.mb-166 {
		margin-bottom: 166px;
	}
	.mb-167 {
		margin-bottom: 167px;
	}
	.mb-168 {
		margin-bottom: 168px;
	}
	.mb-169 {
		margin-bottom: 169px;
	}
	.mb-170 {
		margin-bottom: 170px;
	}
	.mb-171 {
		margin-bottom: 171px;
	}
	.mb-172 {
		margin-bottom: 172px;
	}
	.mb-173 {
		margin-bottom: 173px;
	}
	.mb-174 {
		margin-bottom: 174px;
	}
	.mb-175 {
		margin-bottom: 175px;
	}
	.mb-176 {
		margin-bottom: 176px;
	}
	.mb-177 {
		margin-bottom: 177px;
	}
	.mb-178 {
		margin-bottom: 178px;
	}
	.mb-179 {
		margin-bottom: 179px;
	}
	.mb-180 {
		margin-bottom: 180px;
	}
	.mb-181 {
		margin-bottom: 181px;
	}
	.mb-182 {
		margin-bottom: 182px;
	}
	.mb-183 {
		margin-bottom: 183px;
	}
	.mb-184 {
		margin-bottom: 184px;
	}
	.mb-185 {
		margin-bottom: 185px;
	}
	.mb-186 {
		margin-bottom: 186px;
	}
	.mb-187 {
		margin-bottom: 187px;
	}
	.mb-188 {
		margin-bottom: 188px;
	}
	.mb-189 {
		margin-bottom: 189px;
	}
	.mb-190 {
		margin-bottom: 190px;
	}
	.mb-191 {
		margin-bottom: 191px;
	}
	.mb-192 {
		margin-bottom: 192px;
	}
	.mb-193 {
		margin-bottom: 193px;
	}
	.mb-194 {
		margin-bottom: 194px;
	}
	.mb-195 {
		margin-bottom: 195px;
	}
	.mb-196 {
		margin-bottom: 196px;
	}
	.mb-197 {
		margin-bottom: 197px;
	}
	.mb-198 {
		margin-bottom: 198px;
	}
	.mb-199 {
		margin-bottom: 199px;
	}
	.mb-200 {
		margin-bottom: 200px;
	}
/*}*/

/*------------------------------------------
	07. MARGIN RIGHT
-------------------------------------------*/
/*.mr {*/
	.mr-0 {
		margin-right: 0px;
	}
	.mr-1 {
		margin-right: 1px;
	}
	.mr-2 {
		margin-right: 2px;
	}
	.mr-3 {
		margin-right: 3px;
	}
	.mr-4 {
		margin-right: 4px!important;
	}
	.mr-5 {
		margin-right: 5px!important;
	}
	.mr-6 {
		margin-right: 6px!important;
	}
	.mr-7 {
		margin-right: 7px!important;
	}
	.mr-8 {
		margin-right: 8px!important;
	}
	.mr-9 {
		margin-right: 9px;
	}
	.mr-10 {
		margin-right: 10px;
	}
	.mr-11 {
		margin-right: 11px;
	}
	.mr-12 {
		margin-right: 12px;
	}
	.mr-13 {
		margin-right: 13px;
	}
	.mr-14 {
		margin-right: 14px;
	}
	.mr-15 {
		margin-right: 15px;
	}
	.mr-16 {
		margin-right: 16px;
	}
	.mr-17 {
		margin-right: 17px;
	}
	.mr-18 {
		margin-right: 18px;
	}
	.mr-19 {
		margin-right: 19px;
	}
	.mr-20 {
		margin-right: 20px;
	}
	.mr-21 {
		margin-right: 21px;
	}
	.mr-22 {
		margin-right: 22px;
	}
	.mr-23 {
		margin-right: 23px;
	}
	.mr-24 {
		margin-right: 24px;
	}
	.mr-25 {
		margin-right: 25px;
	}
	.mr-26 {
		margin-right: 26px;
	}
	.mr-27 {
		margin-right: 27px;
	}
	.mr-28 {
		margin-right: 28px;
	}
	.mr-29 {
		margin-right: 29px;
	}
	.mr-30 {
		margin-right: 30px;
	}
	.mr-31 {
		margin-right: 31px;
	}
	.mr-32 {
		margin-right: 32px;
	}
	.mr-33 {
		margin-right: 33px;
	}
	.mr-34 {
		margin-right: 34px;
	}
	.mr-35 {
		margin-right: 35px;
	}
	.mr-36 {
		margin-right: 36px;
	}
	.mr-37 {
		margin-right: 37px;
	}
	.mr-38 {
		margin-right: 38px;
	}
	.mr-39 {
		margin-right: 39px;
	}
	.mr-40 {
		margin-right: 40px;
	}
	.mr-41 {
		margin-right: 41px;
	}
	.mr-42 {
		margin-right: 42px;
	}
	.mr-43 {
		margin-right: 43px;
	}
	.mr-44 {
		margin-right: 44px;
	}
	.mr-45 {
		margin-right: 45px;
	}
	.mr-46 {
		margin-right: 46px;
	}
	.mr-47 {
		margin-right: 47px;
	}
	.mr-48 {
		margin-right: 48px;
	}
	.mr-49 {
		margin-right: 49px;
	}
	.mr-50 {
		margin-right: 50px;
	}
	.mr-51 {
		margin-right: 51px;
	}
	.mr-52 {
		margin-right: 52px;
	}
	.mr-53 {
		margin-right: 53px;
	}
	.mr-54 {
		margin-right: 54px;
	}
	.mr-55 {
		margin-right: 55px;
	}
	.mr-56 {
		margin-right: 56px;
	}
	.mr-57 {
		margin-right: 57px;
	}
	.mr-58 {
		margin-right: 58px;
	}
	.mr-59 {
		margin-right: 59px;
	}
	.mr-60 {
		margin-right: 60px;
	}
	.mr-61 {
		margin-right: 61px;
	}
	.mr-62 {
		margin-right: 62px;
	}
	.mr-63 {
		margin-right: 63px;
	}
	.mr-64 {
		margin-right: 64px;
	}
	.mr-65 {
		margin-right: 65px;
	}
	.mr-66 {
		margin-right: 66px;
	}
	.mr-67 {
		margin-right: 67px;
	}
	.mr-68 {
		margin-right: 68px;
	}
	.mr-69 {
		margin-right: 69px;
	}
	.mr-70 {
		margin-right: 70px;
	}
	.mr-71 {
		margin-right: 71px;
	}
	.mr-72 {
		margin-right: 72px;
	}
	.mr-73 {
		margin-right: 73px;
	}
	.mr-74 {
		margin-right: 74px;
	}
	.mr-75 {
		margin-right: 75px;
	}
	.mr-76 {
		margin-right: 76px;
	}
	.mr-77 {
		margin-right: 77px;
	}
	.mr-78 {
		margin-right: 78px;
	}
	.mr-79 {
		margin-right: 79px;
	}
	.mr-80 {
		margin-right: 80px;
	}
	.mr-81 {
		margin-right: 81px;
	}
	.mr-82 {
		margin-right: 82px;
	}
	.mr-83 {
		margin-right: 83px;
	}
	.mr-84 {
		margin-right: 84px;
	}
	.mr-85 {
		margin-right: 85px;
	}
	.mr-86 {
		margin-right: 86px;
	}
	.mr-87 {
		margin-right: 87px;
	}
	.mr-88 {
		margin-right: 88px;
	}
	.mr-89 {
		margin-right: 89px;
	}
	.mr-90 {
		margin-right: 90px;
	}
	.mr-91 {
		margin-right: 91px;
	}
	.mr-92 {
		margin-right: 92px;
	}
	.mr-93 {
		margin-right: 93px;
	}
	.mr-94 {
		margin-right: 94px;
	}
	.mr-95 {
		margin-right: 95px;
	}
	.mr-96 {
		margin-right: 96px;
	}
	.mr-97 {
		margin-right: 97px;
	}
	.mr-98 {
		margin-right: 98px;
	}
	.mr-99 {
		margin-right: 99px;
	}
	.mr-100 {
		margin-right: 100px;
	}
/*}*/

/*------------------------------------------
	08. MARGIN LEFT
-------------------------------------------*/
/*.ml {*/
	.ml-0 {
		margin-left: 0px;
	}
	.ml-1 {
		margin-left: 1px;
	}
	.ml-2 {
		margin-left: 2px;
	}
	.ml-3 {
		margin-left: 3px;
	}
	.ml-4 {
		margin-left: 4px!important;
	}
	.ml-5 {
		margin-left: 5px!important;
	}
	.ml-6 {
		margin-left: 6px!important;
	}
	.ml-7 {
		margin-left: 7px!important;
	}
	.ml-8 {
		margin-left: 8px!important;
	}
	.ml-9 {
		margin-left: 9px;
	}
	.ml-10 {
		margin-left: 10px;
	}
	.ml-11 {
		margin-left: 11px;
	}
	.ml-12 {
		margin-left: 12px;
	}
	.ml-13 {
		margin-left: 13px;
	}
	.ml-14 {
		margin-left: 14px;
	}
	.ml-15 {
		margin-left: 15px;
	}
	.ml-16 {
		margin-left: 16px;
	}
	.ml-17 {
		margin-left: 17px;
	}
	.ml-18 {
		margin-left: 18px;
	}
	.ml-19 {
		margin-left: 19px;
	}
	.ml-20 {
		margin-left: 20px;
	}
	.ml-21 {
		margin-left: 21px;
	}
	.ml-22 {
		margin-left: 22px;
	}
	.ml-23 {
		margin-left: 23px;
	}
	.ml-24 {
		margin-left: 24px;
	}
	.ml-25 {
		margin-left: 25px;
	}
	.ml-26 {
		margin-left: 26px;
	}
	.ml-27 {
		margin-left: 27px;
	}
	.ml-28 {
		margin-left: 28px;
	}
	.ml-29 {
		margin-left: 29px;
	}
	.ml-30 {
		margin-left: 30px;
	}
	.ml-31 {
		margin-left: 31px;
	}
	.ml-32 {
		margin-left: 32px;
	}
	.ml-33 {
		margin-left: 33px;
	}
	.ml-34 {
		margin-left: 34px;
	}
	.ml-35 {
		margin-left: 35px;
	}
	.ml-36 {
		margin-left: 36px;
	}
	.ml-37 {
		margin-left: 37px;
	}
	.ml-38 {
		margin-left: 38px;
	}
	.ml-39 {
		margin-left: 39px;
	}
	.ml-40 {
		margin-left: 40px;
	}
	.ml-41 {
		margin-left: 41px;
	}
	.ml-42 {
		margin-left: 42px;
	}
	.ml-43 {
		margin-left: 43px;
	}
	.ml-44 {
		margin-left: 44px;
	}
	.ml-45 {
		margin-left: 45px;
	}
	.ml-46 {
		margin-left: 46px;
	}
	.ml-47 {
		margin-left: 47px;
	}
	.ml-48 {
		margin-left: 48px;
	}
	.ml-49 {
		margin-left: 49px;
	}
	.ml-50 {
		margin-left: 50px;
	}
	.ml-51 {
		margin-left: 51px;
	}
	.ml-52 {
		margin-left: 52px;
	}
	.ml-53 {
		margin-left: 53px;
	}
	.ml-54 {
		margin-left: 54px;
	}
	.ml-55 {
		margin-left: 55px;
	}
	.ml-56 {
		margin-left: 56px;
	}
	.ml-57 {
		margin-left: 57px;
	}
	.ml-58 {
		margin-left: 58px;
	}
	.ml-59 {
		margin-left: 59px;
	}
	.ml-60 {
		margin-left: 60px;
	}
	.ml-61 {
		margin-left: 61px;
	}
	.ml-62 {
		margin-left: 62px;
	}
	.ml-63 {
		margin-left: 63px;
	}
	.ml-64 {
		margin-left: 64px;
	}
	.ml-65 {
		margin-left: 65px;
	}
	.ml-66 {
		margin-left: 66px;
	}
	.ml-67 {
		margin-left: 67px;
	}
	.ml-68 {
		margin-left: 68px;
	}
	.ml-69 {
		margin-left: 69px;
	}
	.ml-70 {
		margin-left: 70px;
	}
	.ml-71 {
		margin-left: 71px;
	}
	.ml-72 {
		margin-left: 72px;
	}
	.ml-73 {
		margin-left: 73px;
	}
	.ml-74 {
		margin-left: 74px;
	}
	.ml-75 {
		margin-left: 75px;
	}
	.ml-76 {
		margin-left: 76px;
	}
	.ml-77 {
		margin-left: 77px;
	}
	.ml-78 {
		margin-left: 78px;
	}
	.ml-79 {
		margin-left: 79px;
	}
	.ml-80 {
		margin-left: 80px;
	}
	.ml-81 {
		margin-left: 81px;
	}
	.ml-82 {
		margin-left: 82px;
	}
	.ml-83 {
		margin-left: 83px;
	}
	.ml-84 {
		margin-left: 84px;
	}
	.ml-85 {
		margin-left: 85px;
	}
	.ml-86 {
		margin-left: 86px;
	}
	.ml-87 {
		margin-left: 87px;
	}
	.ml-88 {
		margin-left: 88px;
	}
	.ml-89 {
		margin-left: 89px;
	}
	.ml-90 {
		margin-left: 90px;
	}
	.ml-91 {
		margin-left: 91px;
	}
	.ml-92 {
		margin-left: 92px;
	}
	.ml-93 {
		margin-left: 93px;
	}
	.ml-94 {
		margin-left: 94px;
	}
	.ml-95 {
		margin-left: 95px;
	}
	.ml-96 {
		margin-left: 96px;
	}
	.ml-97 {
		margin-left: 97px;
	}
	.ml-98 {
		margin-left: 98px;
	}
	.ml-99 {
		margin-left: 99px;
	}
	.ml-100 {
		margin-left: 100px;
	}
/*}*/

/*------------------------------------------
	09. MARGIN TOP REVERSE
-------------------------------------------*/
/*.mt- {*/
	.mt--1 {
		margin-top: -1px;
	}
	.mt--2 {
		margin-top: -2px;
	}
	.mt--3 {
		margin-top: -3px;
	}
	.mt--4 {
		margin-top: -4px;
	}
	.mt--5 {
		margin-top: -5px;
	}
	.mt--6 {
		margin-top: -6px;
	}
	.mt--7 {
		margin-top: -7px;
	}
	.mt--8 {
		margin-top: -8px;
	}
	.mt--9 {
		margin-top: -9px;
	}
	.mt--10 {
		margin-top: -10px;
	}
	.mt--11 {
		margin-top: -11px;
	}
	.mt--12 {
		margin-top: -12px;
	}
	.mt--13 {
		margin-top: -13px;
	}
	.mt--14 {
		margin-top: -14px;
	}
	.mt--15 {
		margin-top: -15px;
	}
	.mt--16 {
		margin-top: -16px;
	}
	.mt--17 {
		margin-top: -17px;
	}
	.mt--18 {
		margin-top: -18px;
	}
	.mt--19 {
		margin-top: -19px;
	}
	.mt--20 {
		margin-top: -20px;
	}
	.mt--21 {
		margin-top: -21px;
	}
	.mt--22 {
		margin-top: -22px;
	}
	.mt--23 {
		margin-top: -23px;
	}
	.mt--24 {
		margin-top: -24px;
	}
	.mt--25 {
		margin-top: -25px;
	}
	.mt--26 {
		margin-top: -26px;
	}
	.mt--27 {
		margin-top: -27px;
	}
	.mt--28 {
		margin-top: -28px;
	}
	.mt--29 {
		margin-top: -29px;
	}
	.mt--30 {
		margin-top: -30px;
	}
	.mt--31 {
		margin-top: -31px;
	}
	.mt--32 {
		margin-top: -32px;
	}
	.mt--33 {
		margin-top: -33px;
	}
	.mt--34 {
		margin-top: -34px;
	}
	.mt--35 {
		margin-top: -35px;
	}
	.mt--36 {
		margin-top: -36px;
	}
	.mt--37 {
		margin-top: -37px;
	}
	.mt--38 {
		margin-top: -38px;
	}
	.mt--39 {
		margin-top: -39px;
	}
	.mt--40 {
		margin-top: -40px;
	}
	.mt--41 {
		margin-top: -41px;
	}
	.mt--42 {
		margin-top: -42px;
	}
	.mt--43 {
		margin-top: -43px;
	}
	.mt--44 {
		margin-top: -44px;
	}
	.mt--45 {
		margin-top: -45px;
	}
	.mt--46 {
		margin-top: -46px;
	}
	.mt--47 {
		margin-top: -47px;
	}
	.mt--48 {
		margin-top: -48px;
	}
	.mt--49 {
		margin-top: -49px;
	}
	.mt--50 {
		margin-top: -50px;
	}
	.mt--51 {
		margin-top: -51px;
	}
	.mt--52 {
		margin-top: -52px;
	}
	.mt--53 {
		margin-top: -53px;
	}
	.mt--54 {
		margin-top: -54px;
	}
	.mt--55 {
		margin-top: -55px;
	}
	.mt--56 {
		margin-top: -56px;
	}
	.mt--57 {
		margin-top: -57px;
	}
	.mt--58 {
		margin-top: -58px;
	}
	.mt--59 {
		margin-top: -59px;
	}
	.mt--60 {
		margin-top: -60px;
	}
	.mt--61 {
		margin-top: -61px;
	}
	.mt--62 {
		margin-top: -62px;
	}
	.mt--63 {
		margin-top: -63px;
	}
	.mt--64 {
		margin-top: -64px;
	}
	.mt--65 {
		margin-top: -65px;
	}
	.mt--66 {
		margin-top: -66px;
	}
	.mt--67 {
		margin-top: -67px;
	}
	.mt--68 {
		margin-top: -68px;
	}
	.mt--69 {
		margin-top: -69px;
	}
	.mt--70 {
		margin-top: -70px;
	}
	.mt--71 {
		margin-top: -71px;
	}
	.mt--72 {
		margin-top: -72px;
	}
	.mt--73 {
		margin-top: -73px;
	}
	.mt--74 {
		margin-top: -74px;
	}
	.mt--75 {
		margin-top: -75px;
	}
	.mt--76 {
		margin-top: -76px;
	}
	.mt--77 {
		margin-top: -77px;
	}
	.mt--78 {
		margin-top: -78px;
	}
	.mt--79 {
		margin-top: -79px;
	}
	.mt--80 {
		margin-top: -80px;
	}
	.mt--81 {
		margin-top: -81px;
	}
	.mt--82 {
		margin-top: -82px;
	}
	.mt--83 {
		margin-top: -83px;
	}
	.mt--84 {
		margin-top: -84px;
	}
	.mt--85 {
		margin-top: -85px;
	}
	.mt--86 {
		margin-top: -86px;
	}
	.mt--87 {
		margin-top: -87px;
	}
	.mt--88 {
		margin-top: -88px;
	}
	.mt--89 {
		margin-top: -89px;
	}
	.mt--90 {
		margin-top: -90px;
	}
	.mt--91 {
		margin-top: -91px;
	}
	.mt--92 {
		margin-top: -92px;
	}
	.mt--93 {
		margin-top: -93px;
	}
	.mt--94 {
		margin-top: -94px;
	}
	.mt--95 {
		margin-top: -95px;
	}
	.mt--96 {
		margin-top: -96px;
	}
	.mt--97 {
		margin-top: -97px;
	}
	.mt--98 {
		margin-top: -98px;
	}
	.mt--99 {
		margin-top: -99px;
	}
	.mt--100 {
		margin-top: -100px;
	}
	.mt--101 {
		margin-top: -101px;
	}
	.mt--102 {
		margin-top: -102px;
	}
	.mt--103 {
		margin-top: -103px;
	}
	.mt--104 {
		margin-top: -104px;
	}
	.mt--105 {
		margin-top: -105px;
	}
	.mt--106 {
		margin-top: -106px;
	}
	.mt--107 {
		margin-top: -107px;
	}
	.mt--108 {
		margin-top: -108px;
	}
	.mt--109 {
		margin-top: -109px;
	}
	.mt--110 {
		margin-top: -110px;
	}
	.mt--111 {
		margin-top: -111px;
	}
	.mt--112 {
		margin-top: -112px;
	}
	.mt--113 {
		margin-top: -113px;
	}
	.mt--114 {
		margin-top: -114px;
	}
	.mt--115 {
		margin-top: -115px;
	}
	.mt--116 {
		margin-top: -116px;
	}
	.mt--117 {
		margin-top: -117px;
	}
	.mt--118 {
		margin-top: -118px;
	}
	.mt--119 {
		margin-top: -119px;
	}
	.mt--120 {
		margin-top: -120px;
	}
	.mt--121 {
		margin-top: -121px;
	}
	.mt--122 {
		margin-top: -122px;
	}
	.mt--123 {
		margin-top: -123px;
	}
	.mt--124 {
		margin-top: -124px;
	}
	.mt--125 {
		margin-top: -125px;
	}
	.mt--126 {
		margin-top: -126px;
	}
	.mt--127 {
		margin-top: -127px;
	}
	.mt--128 {
		margin-top: -128px;
	}
	.mt--129 {
		margin-top: -129px;
	}
	.mt--130 {
		margin-top: -130px;
	}
	.mt--131 {
		margin-top: -131px;
	}
	.mt--132 {
		margin-top: -132px;
	}
	.mt--133 {
		margin-top: -133px;
	}
	.mt--134 {
		margin-top: -134px;
	}
	.mt--135 {
		margin-top: -135px;
	}
	.mt--136 {
		margin-top: -136px;
	}
	.mt--137 {
		margin-top: -137px;
	}
	.mt--138 {
		margin-top: -138px;
	}
	.mt--139 {
		margin-top: -139px;
	}
	.mt--140 {
		margin-top: -140px;
	}
	.mt--141 {
		margin-top: -141px;
	}
	.mt--142 {
		margin-top: -142px;
	}
	.mt--143 {
		margin-top: -143px;
	}
	.mt--144 {
		margin-top: -144px;
	}
	.mt--145 {
		margin-top: -145px;
	}
	.mt--146 {
		margin-top: -146px;
	}
	.mt--147 {
		margin-top: -147px;
	}
	.mt--148 {
		margin-top: -148px;
	}
	.mt--149 {
		margin-top: -149px;
	}
	.mt--150 {
		margin-top: -150px;
	}
	.mt--151 {
		margin-top: -151px;
	}
	.mt--152 {
		margin-top: -152px;
	}
	.mt--153 {
		margin-top: -153px;
	}
	.mt--154 {
		margin-top: -154px;
	}
	.mt--155 {
		margin-top: -155px;
	}
	.mt--156 {
		margin-top: -156px;
	}
	.mt--157 {
		margin-top: -157px;
	}
	.mt--158 {
		margin-top: -158px;
	}
	.mt--159 {
		margin-top: -159px;
	}
	.mt--160 {
		margin-top: -160px;
	}
	.mt--161 {
		margin-top: -161px;
	}
	.mt--162 {
		margin-top: -162px;
	}
	.mt--163 {
		margin-top: -163px;
	}
	.mt--164 {
		margin-top: -164px;
	}
	.mt--165 {
		margin-top: -165px;
	}
	.mt--166 {
		margin-top: -166px;
	}
	.mt--167 {
		margin-top: -167px;
	}
	.mt--168 {
		margin-top: -168px;
	}
	.mt--169 {
		margin-top: -169px;
	}
	.mt--170 {
		margin-top: -170px;
	}
	.mt--171 {
		margin-top: -171px;
	}
	.mt--172 {
		margin-top: -172px;
	}
	.mt--173 {
		margin-top: -173px;
	}
	.mt--174 {
		margin-top: -174px;
	}
	.mt--175 {
		margin-top: -175px;
	}
	.mt--176 {
		margin-top: -176px;
	}
	.mt--177 {
		margin-top: -177px;
	}
	.mt--178 {
		margin-top: -178px;
	}
	.mt--179 {
		margin-top: -179px;
	}
	.mt--180 {
		margin-top: -180px;
	}
	.mt--181 {
		margin-top: -181px;
	}
	.mt--182 {
		margin-top: -182px;
	}
	.mt--183 {
		margin-top: -183px;
	}
	.mt--184 {
		margin-top: -184px;
	}
	.mt--185 {
		margin-top: -185px;
	}
	.mt--186 {
		margin-top: -186px;
	}
	.mt--187 {
		margin-top: -187px;
	}
	.mt--188 {
		margin-top: -188px;
	}
	.mt--189 {
		margin-top: -189px;
	}
	.mt--190 {
		margin-top: -190px;
	}
	.mt--191 {
		margin-top: -191px;
	}
	.mt--192 {
		margin-top: -192px;
	}
	.mt--193 {
		margin-top: -193px;
	}
	.mt--194 {
		margin-top: -194px;
	}
	.mt--195 {
		margin-top: -195px;
	}
	.mt--196 {
		margin-top: -196px;
	}
	.mt--197 {
		margin-top: -197px;
	}
	.mt--198 {
		margin-top: -198px;
	}
	.mt--199 {
		margin-top: -199px;
	}
	.mt--200 {
		margin-top: -200px;
	}
/*}*/

/*------------------------------------------
	10. MARGIN BOTTOM REVERSE
-------------------------------------------*/
/*.mb- {*/
	.mb--1 {
		margin-bottom: -1px;
	}
	.mb--2 {
		margin-bottom: -2px;
	}
	.mb--3 {
		margin-bottom: -3px;
	}
	.mb--4 {
		margin-bottom: -4px;
	}
	.mb--5 {
		margin-bottom: -5px;
	}
	.mb--6 {
		margin-bottom: -6px;
	}
	.mb--7 {
		margin-bottom: -7px;
	}
	.mb--8 {
		margin-bottom: -8px;
	}
	.mb--9 {
		margin-bottom: -9px;
	}
	.mb--10 {
		margin-bottom: -10px;
	}
	.mb--11 {
		margin-bottom: -11px;
	}
	.mb--12 {
		margin-bottom: -12px;
	}
	.mb--13 {
		margin-bottom: -13px;
	}
	.mb--14 {
		margin-bottom: -14px;
	}
	.mb--15 {
		margin-bottom: -15px;
	}
	.mb--16 {
		margin-bottom: -16px;
	}
	.mb--17 {
		margin-bottom: -17px;
	}
	.mb--18 {
		margin-bottom: -18px;
	}
	.mb--19 {
		margin-bottom: -19px;
	}
	.mb--20 {
		margin-bottom: -20px;
	}
	.mb--21 {
		margin-bottom: -21px;
	}
	.mb--22 {
		margin-bottom: -22px;
	}
	.mb--23 {
		margin-bottom: -23px;
	}
	.mb--24 {
		margin-bottom: -24px;
	}
	.mb--25 {
		margin-bottom: -25px;
	}
	.mb--26 {
		margin-bottom: -26px;
	}
	.mb--27 {
		margin-bottom: -27px;
	}
	.mb--28 {
		margin-bottom: -28px;
	}
	.mb--29 {
		margin-bottom: -29px;
	}
	.mb--30 {
		margin-bottom: -30px;
	}
	.mb--31 {
		margin-bottom: -31px;
	}
	.mb--32 {
		margin-bottom: -32px;
	}
	.mb--33 {
		margin-bottom: -33px;
	}
	.mb--34 {
		margin-bottom: -34px;
	}
	.mb--35 {
		margin-bottom: -35px;
	}
	.mb--36 {
		margin-bottom: -36px;
	}
	.mb--37 {
		margin-bottom: -37px;
	}
	.mb--38 {
		margin-bottom: -38px;
	}
	.mb--39 {
		margin-bottom: -39px;
	}
	.mb--40 {
		margin-bottom: -40px;
	}
	.mb--41 {
		margin-bottom: -41px;
	}
	.mb--42 {
		margin-bottom: -42px;
	}
	.mb--43 {
		margin-bottom: -43px;
	}
	.mb--44 {
		margin-bottom: -44px;
	}
	.mb--45 {
		margin-bottom: -45px;
	}
	.mb--46 {
		margin-bottom: -46px;
	}
	.mb--47 {
		margin-bottom: -47px;
	}
	.mb--48 {
		margin-bottom: -48px;
	}
	.mb--49 {
		margin-bottom: -49px;
	}
	.mb--50 {
		margin-bottom: -50px;
	}
	.mb--51 {
		margin-bottom: -51px;
	}
	.mb--52 {
		margin-bottom: -52px;
	}
	.mb--53 {
		margin-bottom: -53px;
	}
	.mb--54 {
		margin-bottom: -54px;
	}
	.mb--55 {
		margin-bottom: -55px;
	}
	.mb--56 {
		margin-bottom: -56px;
	}
	.mb--57 {
		margin-bottom: -57px;
	}
	.mb--58 {
		margin-bottom: -58px;
	}
	.mb--59 {
		margin-bottom: -59px;
	}
	.mb--60 {
		margin-bottom: -60px;
	}
	.mb--61 {
		margin-bottom: -61px;
	}
	.mb--62 {
		margin-bottom: -62px;
	}
	.mb--63 {
		margin-bottom: -63px;
	}
	.mb--64 {
		margin-bottom: -64px;
	}
	.mb--65 {
		margin-bottom: -65px;
	}
	.mb--66 {
		margin-bottom: -66px;
	}
	.mb--67 {
		margin-bottom: -67px;
	}
	.mb--68 {
		margin-bottom: -68px;
	}
	.mb--69 {
		margin-bottom: -69px;
	}
	.mb--70 {
		margin-bottom: -70px;
	}
	.mb--71 {
		margin-bottom: -71px;
	}
	.mb--72 {
		margin-bottom: -72px;
	}
	.mb--73 {
		margin-bottom: -73px;
	}
	.mb--74 {
		margin-bottom: -74px;
	}
	.mb--75 {
		margin-bottom: -75px;
	}
	.mb--76 {
		margin-bottom: -76px;
	}
	.mb--77 {
		margin-bottom: -77px;
	}
	.mb--78 {
		margin-bottom: -78px;
	}
	.mb--79 {
		margin-bottom: -79px;
	}
	.mb--80 {
		margin-bottom: -80px;
	}
	.mb--81 {
		margin-bottom: -81px;
	}
	.mb--82 {
		margin-bottom: -82px;
	}
	.mb--83 {
		margin-bottom: -83px;
	}
	.mb--84 {
		margin-bottom: -84px;
	}
	.mb--85 {
		margin-bottom: -85px;
	}
	.mb--86 {
		margin-bottom: -86px;
	}
	.mb--87 {
		margin-bottom: -87px;
	}
	.mb--88 {
		margin-bottom: -88px;
	}
	.mb--89 {
		margin-bottom: -89px;
	}
	.mb--90 {
		margin-bottom: -90px;
	}
	.mb--91 {
		margin-bottom: -91px;
	}
	.mb--92 {
		margin-bottom: -92px;
	}
	.mb--93 {
		margin-bottom: -93px;
	}
	.mb--94 {
		margin-bottom: -94px;
	}
	.mb--95 {
		margin-bottom: -95px;
	}
	.mb--96 {
		margin-bottom: -96px;
	}
	.mb--97 {
		margin-bottom: -97px;
	}
	.mb--98 {
		margin-bottom: -98px;
	}
	.mb--99 {
		margin-bottom: -99px;
	}
	.mb--100 {
		margin-bottom: -100px;
	}
	.mb--101 {
		margin-bottom: -101px;
	}
	.mb--102 {
		margin-bottom: -102px;
	}
	.mb--103 {
		margin-bottom: -103px;
	}
	.mb--104 {
		margin-bottom: -104px;
	}
	.mb--105 {
		margin-bottom: -105px;
	}
	.mb--106 {
		margin-bottom: -106px;
	}
	.mb--107 {
		margin-bottom: -107px;
	}
	.mb--108 {
		margin-bottom: -108px;
	}
	.mb--109 {
		margin-bottom: -109px;
	}
	.mb--110 {
		margin-bottom: -110px;
	}
	.mb--111 {
		margin-bottom: -111px;
	}
	.mb--112 {
		margin-bottom: -112px;
	}
	.mb--113 {
		margin-bottom: -113px;
	}
	.mb--114 {
		margin-bottom: -114px;
	}
	.mb--115 {
		margin-bottom: -115px;
	}
	.mb--116 {
		margin-bottom: -116px;
	}
	.mb--117 {
		margin-bottom: -117px;
	}
	.mb--118 {
		margin-bottom: -118px;
	}
	.mb--119 {
		margin-bottom: -119px;
	}
	.mb--120 {
		margin-bottom: -120px;
	}
	.mb--121 {
		margin-bottom: -121px;
	}
	.mb--122 {
		margin-bottom: -122px;
	}
	.mb--123 {
		margin-bottom: -123px;
	}
	.mb--124 {
		margin-bottom: -124px;
	}
	.mb--125 {
		margin-bottom: -125px;
	}
	.mb--126 {
		margin-bottom: -126px;
	}
	.mb--127 {
		margin-bottom: -127px;
	}
	.mb--128 {
		margin-bottom: -128px;
	}
	.mb--129 {
		margin-bottom: -129px;
	}
	.mb--130 {
		margin-bottom: -130px;
	}
	.mb--131 {
		margin-bottom: -131px;
	}
	.mb--132 {
		margin-bottom: -132px;
	}
	.mb--133 {
		margin-bottom: -133px;
	}
	.mb--134 {
		margin-bottom: -134px;
	}
	.mb--135 {
		margin-bottom: -135px;
	}
	.mb--136 {
		margin-bottom: -136px;
	}
	.mb--137 {
		margin-bottom: -137px;
	}
	.mb--138 {
		margin-bottom: -138px;
	}
	.mb--139 {
		margin-bottom: -139px;
	}
	.mb--140 {
		margin-bottom: -140px;
	}
	.mb--141 {
		margin-bottom: -141px;
	}
	.mb--142 {
		margin-bottom: -142px;
	}
	.mb--143 {
		margin-bottom: -143px;
	}
	.mb--144 {
		margin-bottom: -144px;
	}
	.mb--145 {
		margin-bottom: -145px;
	}
	.mb--146 {
		margin-bottom: -146px;
	}
	.mb--147 {
		margin-bottom: -147px;
	}
	.mb--148 {
		margin-bottom: -148px;
	}
	.mb--149 {
		margin-bottom: -149px;
	}
	.mb--150 {
		margin-bottom: -150px;
	}
	.mb--151 {
		margin-bottom: -151px;
	}
	.mb--152 {
		margin-bottom: -152px;
	}
	.mb--153 {
		margin-bottom: -153px;
	}
	.mb--154 {
		margin-bottom: -154px;
	}
	.mb--155 {
		margin-bottom: -155px;
	}
	.mb--156 {
		margin-bottom: -156px;
	}
	.mb--157 {
		margin-bottom: -157px;
	}
	.mb--158 {
		margin-bottom: -158px;
	}
	.mb--159 {
		margin-bottom: -159px;
	}
	.mb--160 {
		margin-bottom: -160px;
	}
	.mb--161 {
		margin-bottom: -161px;
	}
	.mb--162 {
		margin-bottom: -162px;
	}
	.mb--163 {
		margin-bottom: -163px;
	}
	.mb--164 {
		margin-bottom: -164px;
	}
	.mb--165 {
		margin-bottom: -165px;
	}
	.mb--166 {
		margin-bottom: -166px;
	}
	.mb--167 {
		margin-bottom: -167px;
	}
	.mb--168 {
		margin-bottom: -168px;
	}
	.mb--169 {
		margin-bottom: -169px;
	}
	.mb--170 {
		margin-bottom: -170px;
	}
	.mb--171 {
		margin-bottom: -171px;
	}
	.mb--172 {
		margin-bottom: -172px;
	}
	.mb--173 {
		margin-bottom: -173px;
	}
	.mb--174 {
		margin-bottom: -174px;
	}
	.mb--175 {
		margin-bottom: -175px;
	}
	.mb--176 {
		margin-bottom: -176px;
	}
	.mb--177 {
		margin-bottom: -177px;
	}
	.mb--178 {
		margin-bottom: -178px;
	}
	.mb--179 {
		margin-bottom: -179px;
	}
	.mb--180 {
		margin-bottom: -180px;
	}
	.mb--181 {
		margin-bottom: -181px;
	}
	.mb--182 {
		margin-bottom: -182px;
	}
	.mb--183 {
		margin-bottom: -183px;
	}
	.mb--184 {
		margin-bottom: -184px;
	}
	.mb--185 {
		margin-bottom: -185px;
	}
	.mb--186 {
		margin-bottom: -186px;
	}
	.mb--187 {
		margin-bottom: -187px;
	}
	.mb--188 {
		margin-bottom: -188px;
	}
	.mb--189 {
		margin-bottom: -189px;
	}
	.mb--190 {
		margin-bottom: -190px;
	}
	.mb--191 {
		margin-bottom: -191px;
	}
	.mb--192 {
		margin-bottom: -192px;
	}
	.mb--193 {
		margin-bottom: -193px;
	}
	.mb--194 {
		margin-bottom: -194px;
	}
	.mb--195 {
		margin-bottom: -195px;
	}
	.mb--196 {
		margin-bottom: -196px;
	}
	.mb--197 {
		margin-bottom: -197px;
	}
	.mb--198 {
		margin-bottom: -198px;
	}
	.mb--199 {
		margin-bottom: -199px;
	}
	.mb--200 {
		margin-bottom: -200px;
	}
/*}*/

/*------------------------------------------
	11. MARGIN LEFT REVERSE
-------------------------------------------*/
/*.ml- {*/
	.ml--1 {
		margin-left: -1px;
	}
	.ml--2 {
		margin-left: -2px;
	}
	.ml--3 {
		margin-left: -3px;
	}
	.ml--4 {
		margin-left: -4px;
	}
	.ml--5 {
		margin-left: -5px;
	}
	.ml--6 {
		margin-left: -6px;
	}
	.ml--7 {
		margin-left: -7px;
	}
	.ml--8 {
		margin-left: -8px;
	}
	.ml--9 {
		margin-left: -9px;
	}
	.ml--10 {
		margin-left: -10px;
	}
	.ml--11 {
		margin-left: -11px;
	}
	.ml--12 {
		margin-left: -12px;
	}
	.ml--13 {
		margin-left: -13px;
	}
	.ml--14 {
		margin-left: -14px;
	}
	.ml--15 {
		margin-left: -15px;
	}
	.ml--16 {
		margin-left: -16px;
	}
	.ml--17 {
		margin-left: -17px;
	}
	.ml--18 {
		margin-left: -18px;
	}
	.ml--19 {
		margin-left: -19px;
	}
	.ml--20 {
		margin-left: -20px;
	}
	.ml--21 {
		margin-left: -21px;
	}
	.ml--22 {
		margin-left: -22px;
	}
	.ml--23 {
		margin-left: -23px;
	}
	.ml--24 {
		margin-left: -24px;
	}
	.ml--25 {
		margin-left: -25px;
	}
	.ml--26 {
		margin-left: -26px;
	}
	.ml--27 {
		margin-left: -27px;
	}
	.ml--28 {
		margin-left: -28px;
	}
	.ml--29 {
		margin-left: -29px;
	}
	.ml--30 {
		margin-left: -30px;
	}
	.ml--31 {
		margin-left: -31px;
	}
	.ml--32 {
		margin-left: -32px;
	}
	.ml--33 {
		margin-left: -33px;
	}
	.ml--34 {
		margin-left: -34px;
	}
	.ml--35 {
		margin-left: -35px;
	}
	.ml--36 {
		margin-left: -36px;
	}
	.ml--37 {
		margin-left: -37px;
	}
	.ml--38 {
		margin-left: -38px;
	}
	.ml--39 {
		margin-left: -39px;
	}
	.ml--40 {
		margin-left: -40px;
	}
	.ml--41 {
		margin-left: -41px;
	}
	.ml--42 {
		margin-left: -42px;
	}
	.ml--43 {
		margin-left: -43px;
	}
	.ml--44 {
		margin-left: -44px;
	}
	.ml--45 {
		margin-left: -45px;
	}
	.ml--46 {
		margin-left: -46px;
	}
	.ml--47 {
		margin-left: -47px;
	}
	.ml--48 {
		margin-left: -48px;
	}
	.ml--49 {
		margin-left: -49px;
	}
	.ml--50 {
		margin-left: -50px;
	}
	.ml--51 {
		margin-left: -51px;
	}
	.ml--52 {
		margin-left: -52px;
	}
	.ml--53 {
		margin-left: -53px;
	}
	.ml--54 {
		margin-left: -54px;
	}
	.ml--55 {
		margin-left: -55px;
	}
	.ml--56 {
		margin-left: -56px;
	}
	.ml--57 {
		margin-left: -57px;
	}
	.ml--58 {
		margin-left: -58px;
	}
	.ml--59 {
		margin-left: -59px;
	}
	.ml--60 {
		margin-left: -60px;
	}
	.ml--61 {
		margin-left: -61px;
	}
	.ml--62 {
		margin-left: -62px;
	}
	.ml--63 {
		margin-left: -63px;
	}
	.ml--64 {
		margin-left: -64px;
	}
	.ml--65 {
		margin-left: -65px;
	}
	.ml--66 {
		margin-left: -66px;
	}
	.ml--67 {
		margin-left: -67px;
	}
	.ml--68 {
		margin-left: -68px;
	}
	.ml--69 {
		margin-left: -69px;
	}
	.ml--70 {
		margin-left: -70px;
	}
	.ml--71 {
		margin-left: -71px;
	}
	.ml--72 {
		margin-left: -72px;
	}
	.ml--73 {
		margin-left: -73px;
	}
	.ml--74 {
		margin-left: -74px;
	}
	.ml--75 {
		margin-left: -75px;
	}
	.ml--76 {
		margin-left: -76px;
	}
	.ml--77 {
		margin-left: -77px;
	}
	.ml--78 {
		margin-left: -78px;
	}
	.ml--79 {
		margin-left: -79px;
	}
	.ml--80 {
		margin-left: -80px;
	}
	.ml--81 {
		margin-left: -81px;
	}
	.ml--82 {
		margin-left: -82px;
	}
	.ml--83 {
		margin-left: -83px;
	}
	.ml--84 {
		margin-left: -84px;
	}
	.ml--85 {
		margin-left: -85px;
	}
	.ml--86 {
		margin-left: -86px;
	}
	.ml--87 {
		margin-left: -87px;
	}
	.ml--88 {
		margin-left: -88px;
	}
	.ml--89 {
		margin-left: -89px;
	}
	.ml--90 {
		margin-left: -90px;
	}
	.ml--91 {
		margin-left: -91px;
	}
	.ml--92 {
		margin-left: -92px;
	}
	.ml--93 {
		margin-left: -93px;
	}
	.ml--94 {
		margin-left: -94px;
	}
	.ml--95 {
		margin-left: -95px;
	}
	.ml--96 {
		margin-left: -96px;
	}
	.ml--97 {
		margin-left: -97px;
	}
	.ml--98 {
		margin-left: -98px;
	}
	.ml--99 {
		margin-left: -99px;
	}
	.ml--100 {
		margin-left: -100px;
	}
	.ml--101 {
		margin-left: -101px;
	}
	.ml--102 {
		margin-left: -102px;
	}
	.ml--103 {
		margin-left: -103px;
	}
	.ml--104 {
		margin-left: -104px;
	}
	.ml--105 {
		margin-left: -105px;
	}
	.ml--106 {
		margin-left: -106px;
	}
	.ml--107 {
		margin-left: -107px;
	}
	.ml--108 {
		margin-left: -108px;
	}
	.ml--109 {
		margin-left: -109px;
	}
	.ml--110 {
		margin-left: -110px;
	}
	.ml--111 {
		margin-left: -111px;
	}
	.ml--112 {
		margin-left: -112px;
	}
	.ml--113 {
		margin-left: -113px;
	}
	.ml--114 {
		margin-left: -114px;
	}
	.ml--115 {
		margin-left: -115px;
	}
	.ml--116 {
		margin-left: -116px;
	}
	.ml--117 {
		margin-left: -117px;
	}
	.ml--118 {
		margin-left: -118px;
	}
	.ml--119 {
		margin-left: -119px;
	}
	.ml--120 {
		margin-left: -120px;
	}
	.ml--121 {
		margin-left: -121px;
	}
	.ml--122 {
		margin-left: -122px;
	}
	.ml--123 {
		margin-left: -123px;
	}
	.ml--124 {
		margin-left: -124px;
	}
	.ml--125 {
		margin-left: -125px;
	}
	.ml--126 {
		margin-left: -126px;
	}
	.ml--127 {
		margin-left: -127px;
	}
	.ml--128 {
		margin-left: -128px;
	}
	.ml--129 {
		margin-left: -129px;
	}
	.ml--130 {
		margin-left: -130px;
	}
	.ml--131 {
		margin-left: -131px;
	}
	.ml--132 {
		margin-left: -132px;
	}
	.ml--133 {
		margin-left: -133px;
	}
	.ml--134 {
		margin-left: -134px;
	}
	.ml--135 {
		margin-left: -135px;
	}
	.ml--136 {
		margin-left: -136px;
	}
	.ml--137 {
		margin-left: -137px;
	}
	.ml--138 {
		margin-left: -138px;
	}
	.ml--139 {
		margin-left: -139px;
	}
	.ml--140 {
		margin-left: -140px;
	}
	.ml--141 {
		margin-left: -141px;
	}
	.ml--142 {
		margin-left: -142px;
	}
	.ml--143 {
		margin-left: -143px;
	}
	.ml--144 {
		margin-left: -144px;
	}
	.ml--145 {
		margin-left: -145px;
	}
	.ml--146 {
		margin-left: -146px;
	}
	.ml--147 {
		margin-left: -147px;
	}
	.ml--148 {
		margin-left: -148px;
	}
	.ml--149 {
		margin-left: -149px;
	}
	.ml--150 {
		margin-left: -150px;
	}
	.ml--151 {
		margin-left: -151px;
	}
	.ml--152 {
		margin-left: -152px;
	}
	.ml--153 {
		margin-left: -153px;
	}
	.ml--154 {
		margin-left: -154px;
	}
	.ml--155 {
		margin-left: -155px;
	}
	.ml--156 {
		margin-left: -156px;
	}
	.ml--157 {
		margin-left: -157px;
	}
	.ml--158 {
		margin-left: -158px;
	}
	.ml--159 {
		margin-left: -159px;
	}
	.ml--160 {
		margin-left: -160px;
	}
	.ml--161 {
		margin-left: -161px;
	}
	.ml--162 {
		margin-left: -162px;
	}
	.ml--163 {
		margin-left: -163px;
	}
	.ml--164 {
		margin-left: -164px;
	}
	.ml--165 {
		margin-left: -165px;
	}
	.ml--166 {
		margin-left: -166px;
	}
	.ml--167 {
		margin-left: -167px;
	}
	.ml--168 {
		margin-left: -168px;
	}
	.ml--169 {
		margin-left: -169px;
	}
	.ml--170 {
		margin-left: -170px;
	}
	.ml--171 {
		margin-left: -171px;
	}
	.ml--172 {
		margin-left: -172px;
	}
	.ml--173 {
		margin-left: -173px;
	}
	.ml--174 {
		margin-left: -174px;
	}
	.ml--175 {
		margin-left: -175px;
	}
	.ml--176 {
		margin-left: -176px;
	}
	.ml--177 {
		margin-left: -177px;
	}
	.ml--178 {
		margin-left: -178px;
	}
	.ml--179 {
		margin-left: -179px;
	}
	.ml--180 {
		margin-left: -180px;
	}
	.ml--181 {
		margin-left: -181px;
	}
	.ml--182 {
		margin-left: -182px;
	}
	.ml--183 {
		margin-left: -183px;
	}
	.ml--184 {
		margin-left: -184px;
	}
	.ml--185 {
		margin-left: -185px;
	}
	.ml--186 {
		margin-left: -186px;
	}
	.ml--187 {
		margin-left: -187px;
	}
	.ml--188 {
		margin-left: -188px;
	}
	.ml--189 {
		margin-left: -189px;
	}
	.ml--190 {
		margin-left: -190px;
	}
	.ml--191 {
		margin-left: -191px;
	}
	.ml--192 {
		margin-left: -192px;
	}
	.ml--193 {
		margin-left: -193px;
	}
	.ml--194 {
		margin-left: -194px;
	}
	.ml--195 {
		margin-left: -195px;
	}
	.ml--196 {
		margin-left: -196px;
	}
	.ml--197 {
		margin-left: -197px;
	}
	.ml--198 {
		margin-left: -198px;
	}
	.ml--199 {
		margin-left: -199px;
	}
	.ml--200 {
		margin-left: -200px;
	}
/*}*/

/*------------------------------------------
	12. MARGIN RIGHT REVERSE
-------------------------------------------*/
/*.mr- {*/
	.mr--1 {
		margin-right: -1px;
	}
	.mr--2 {
		margin-right: -2px;
	}
	.mr--3 {
		margin-right: -3px;
	}
	.mr--4 {
		margin-right: -4px;
	}
	.mr--5 {
		margin-right: -5px;
	}
	.mr--6 {
		margin-right: -6px;
	}
	.mr--7 {
		margin-right: -7px;
	}
	.mr--8 {
		margin-right: -8px;
	}
	.mr--9 {
		margin-right: -9px;
	}
	.mr--10 {
		margin-right: -10px;
	}
	.mr--11 {
		margin-right: -11px;
	}
	.mr--12 {
		margin-right: -12px;
	}
	.mr--13 {
		margin-right: -13px;
	}
	.mr--14 {
		margin-right: -14px;
	}
	.mr--15 {
		margin-right: -15px;
	}
	.mr--16 {
		margin-right: -16px;
	}
	.mr--17 {
		margin-right: -17px;
	}
	.mr--18 {
		margin-right: -18px;
	}
	.mr--19 {
		margin-right: -19px;
	}
	.mr--20 {
		margin-right: -20px;
	}
	.mr--21 {
		margin-right: -21px;
	}
	.mr--22 {
		margin-right: -22px;
	}
	.mr--23 {
		margin-right: -23px;
	}
	.mr--24 {
		margin-right: -24px;
	}
	.mr--25 {
		margin-right: -25px;
	}
	.mr--26 {
		margin-right: -26px;
	}
	.mr--27 {
		margin-right: -27px;
	}
	.mr--28 {
		margin-right: -28px;
	}
	.mr--29 {
		margin-right: -29px;
	}
	.mr--30 {
		margin-right: -30px;
	}
	.mr--31 {
		margin-right: -31px;
	}
	.mr--32 {
		margin-right: -32px;
	}
	.mr--33 {
		margin-right: -33px;
	}
	.mr--34 {
		margin-right: -34px;
	}
	.mr--35 {
		margin-right: -35px;
	}
	.mr--36 {
		margin-right: -36px;
	}
	.mr--37 {
		margin-right: -37px;
	}
	.mr--38 {
		margin-right: -38px;
	}
	.mr--39 {
		margin-right: -39px;
	}
	.mr--40 {
		margin-right: -40px;
	}
	.mr--41 {
		margin-right: -41px;
	}
	.mr--42 {
		margin-right: -42px;
	}
	.mr--43 {
		margin-right: -43px;
	}
	.mr--44 {
		margin-right: -44px;
	}
	.mr--45 {
		margin-right: -45px;
	}
	.mr--46 {
		margin-right: -46px;
	}
	.mr--47 {
		margin-right: -47px;
	}
	.mr--48 {
		margin-right: -48px;
	}
	.mr--49 {
		margin-right: -49px;
	}
	.mr--50 {
		margin-right: -50px;
	}
	.mr--51 {
		margin-right: -51px;
	}
	.mr--52 {
		margin-right: -52px;
	}
	.mr--53 {
		margin-right: -53px;
	}
	.mr--54 {
		margin-right: -54px;
	}
	.mr--55 {
		margin-right: -55px;
	}
	.mr--56 {
		margin-right: -56px;
	}
	.mr--57 {
		margin-right: -57px;
	}
	.mr--58 {
		margin-right: -58px;
	}
	.mr--59 {
		margin-right: -59px;
	}
	.mr--60 {
		margin-right: -60px;
	}
	.mr--61 {
		margin-right: -61px;
	}
	.mr--62 {
		margin-right: -62px;
	}
	.mr--63 {
		margin-right: -63px;
	}
	.mr--64 {
		margin-right: -64px;
	}
	.mr--65 {
		margin-right: -65px;
	}
	.mr--66 {
		margin-right: -66px;
	}
	.mr--67 {
		margin-right: -67px;
	}
	.mr--68 {
		margin-right: -68px;
	}
	.mr--69 {
		margin-right: -69px;
	}
	.mr--70 {
		margin-right: -70px;
	}
	.mr--71 {
		margin-right: -71px;
	}
	.mr--72 {
		margin-right: -72px;
	}
	.mr--73 {
		margin-right: -73px;
	}
	.mr--74 {
		margin-right: -74px;
	}
	.mr--75 {
		margin-right: -75px;
	}
	.mr--76 {
		margin-right: -76px;
	}
	.mr--77 {
		margin-right: -77px;
	}
	.mr--78 {
		margin-right: -78px;
	}
	.mr--79 {
		margin-right: -79px;
	}
	.mr--80 {
		margin-right: -80px;
	}
	.mr--81 {
		margin-right: -81px;
	}
	.mr--82 {
		margin-right: -82px;
	}
	.mr--83 {
		margin-right: -83px;
	}
	.mr--84 {
		margin-right: -84px;
	}
	.mr--85 {
		margin-right: -85px;
	}
	.mr--86 {
		margin-right: -86px;
	}
	.mr--87 {
		margin-right: -87px;
	}
	.mr--88 {
		margin-right: -88px;
	}
	.mr--89 {
		margin-right: -89px;
	}
	.mr--90 {
		margin-right: -90px;
	}
	.mr--91 {
		margin-right: -91px;
	}
	.mr--92 {
		margin-right: -92px;
	}
	.mr--93 {
		margin-right: -93px;
	}
	.mr--94 {
		margin-right: -94px;
	}
	.mr--95 {
		margin-right: -95px;
	}
	.mr--96 {
		margin-right: -96px;
	}
	.mr--97 {
		margin-right: -97px;
	}
	.mr--98 {
		margin-right: -98px;
	}
	.mr--99 {
		margin-right: -99px;
	}
	.mr--100 {
		margin-right: -100px;
	}
	.mr--101 {
		margin-right: -101px;
	}
	.mr--102 {
		margin-right: -102px;
	}
	.mr--103 {
		margin-right: -103px;
	}
	.mr--104 {
		margin-right: -104px;
	}
	.mr--105 {
		margin-right: -105px;
	}
	.mr--106 {
		margin-right: -106px;
	}
	.mr--107 {
		margin-right: -107px;
	}
	.mr--108 {
		margin-right: -108px;
	}
	.mr--109 {
		margin-right: -109px;
	}
	.mr--110 {
		margin-right: -110px;
	}
	.mr--111 {
		margin-right: -111px;
	}
	.mr--112 {
		margin-right: -112px;
	}
	.mr--113 {
		margin-right: -113px;
	}
	.mr--114 {
		margin-right: -114px;
	}
	.mr--115 {
		margin-right: -115px;
	}
	.mr--116 {
		margin-right: -116px;
	}
	.mr--117 {
		margin-right: -117px;
	}
	.mr--118 {
		margin-right: -118px;
	}
	.mr--119 {
		margin-right: -119px;
	}
	.mr--120 {
		margin-right: -120px;
	}
	.mr--121 {
		margin-right: -121px;
	}
	.mr--122 {
		margin-right: -122px;
	}
	.mr--123 {
		margin-right: -123px;
	}
	.mr--124 {
		margin-right: -124px;
	}
	.mr--125 {
		margin-right: -125px;
	}
	.mr--126 {
		margin-right: -126px;
	}
	.mr--127 {
		margin-right: -127px;
	}
	.mr--128 {
		margin-right: -128px;
	}
	.mr--129 {
		margin-right: -129px;
	}
	.mr--130 {
		margin-right: -130px;
	}
	.mr--131 {
		margin-right: -131px;
	}
	.mr--132 {
		margin-right: -132px;
	}
	.mr--133 {
		margin-right: -133px;
	}
	.mr--134 {
		margin-right: -134px;
	}
	.mr--135 {
		margin-right: -135px;
	}
	.mr--136 {
		margin-right: -136px;
	}
	.mr--137 {
		margin-right: -137px;
	}
	.mr--138 {
		margin-right: -138px;
	}
	.mr--139 {
		margin-right: -139px;
	}
	.mr--140 {
		margin-right: -140px;
	}
	.mr--141 {
		margin-right: -141px;
	}
	.mr--142 {
		margin-right: -142px;
	}
	.mr--143 {
		margin-right: -143px;
	}
	.mr--144 {
		margin-right: -144px;
	}
	.mr--145 {
		margin-right: -145px;
	}
	.mr--146 {
		margin-right: -146px;
	}
	.mr--147 {
		margin-right: -147px;
	}
	.mr--148 {
		margin-right: -148px;
	}
	.mr--149 {
		margin-right: -149px;
	}
	.mr--150 {
		margin-right: -150px;
	}
	.mr--151 {
		margin-right: -151px;
	}
	.mr--152 {
		margin-right: -152px;
	}
	.mr--153 {
		margin-right: -153px;
	}
	.mr--154 {
		margin-right: -154px;
	}
	.mr--155 {
		margin-right: -155px;
	}
	.mr--156 {
		margin-right: -156px;
	}
	.mr--157 {
		margin-right: -157px;
	}
	.mr--158 {
		margin-right: -158px;
	}
	.mr--159 {
		margin-right: -159px;
	}
	.mr--160 {
		margin-right: -160px;
	}
	.mr--161 {
		margin-right: -161px;
	}
	.mr--162 {
		margin-right: -162px;
	}
	.mr--163 {
		margin-right: -163px;
	}
	.mr--164 {
		margin-right: -164px;
	}
	.mr--165 {
		margin-right: -165px;
	}
	.mr--166 {
		margin-right: -166px;
	}
	.mr--167 {
		margin-right: -167px;
	}
	.mr--168 {
		margin-right: -168px;
	}
	.mr--169 {
		margin-right: -169px;
	}
	.mr--170 {
		margin-right: -170px;
	}
	.mr--171 {
		margin-right: -171px;
	}
	.mr--172 {
		margin-right: -172px;
	}
	.mr--173 {
		margin-right: -173px;
	}
	.mr--174 {
		margin-right: -174px;
	}
	.mr--175 {
		margin-right: -175px;
	}
	.mr--176 {
		margin-right: -176px;
	}
	.mr--177 {
		margin-right: -177px;
	}
	.mr--178 {
		margin-right: -178px;
	}
	.mr--179 {
		margin-right: -179px;
	}
	.mr--180 {
		margin-right: -180px;
	}
	.mr--181 {
		margin-right: -181px;
	}
	.mr--182 {
		margin-right: -182px;
	}
	.mr--183 {
		margin-right: -183px;
	}
	.mr--184 {
		margin-right: -184px;
	}
	.mr--185 {
		margin-right: -185px;
	}
	.mr--186 {
		margin-right: -186px;
	}
	.mr--187 {
		margin-right: -187px;
	}
	.mr--188 {
		margin-right: -188px;
	}
	.mr--189 {
		margin-right: -189px;
	}
	.mr--190 {
		margin-right: -190px;
	}
	.mr--191 {
		margin-right: -191px;
	}
	.mr--192 {
		margin-right: -192px;
	}
	.mr--193 {
		margin-right: -193px;
	}
	.mr--194 {
		margin-right: -194px;
	}
	.mr--195 {
		margin-right: -195px;
	}
	.mr--196 {
		margin-right: -196px;
	}
	.mr--197 {
		margin-right: -197px;
	}
	.mr--198 {
		margin-right: -198px;
	}
	.mr--199 {
		margin-right: -199px;
	}
	.mr--200 {
		margin-right: -200px;
	}
/*}*/

@media only screen and (max-width: 1199px){

	.lg-padding-0 {
		padding: 0;
	}
	.lg-margin-0 {
		margin: 0;
	}

	/*------------------------------------------
		13. LG PADDING TOP
	-------------------------------------------*/
	/*.lg-pt {*/
		.lg-pt-0 {
			padding-top: 0px;
		}
		.lg-pt-1 {
			padding-top: 1px;
		}
		.lg-pt-2 {
			padding-top: 2px;
		}
		.lg-pt-3 {
			padding-top: 3px;
		}
		.lg-pt-4 {
			padding-top: 4px;
		}
		.lg-pt-5 {
			padding-top: 5px;
		}
		.lg-pt-6 {
			padding-top: 6px;
		}
		.lg-pt-7 {
			padding-top: 7px;
		}
		.lg-pt-8 {
			padding-top: 8px;
		}
		.lg-pt-9 {
			padding-top: 9px;
		}
		.lg-pt-10 {
			padding-top: 10px;
		}
		.lg-pt-11 {
			padding-top: 11px;
		}
		.lg-pt-12 {
			padding-top: 12px;
		}
		.lg-pt-13 {
			padding-top: 13px;
		}
		.lg-pt-14 {
			padding-top: 14px;
		}
		.lg-pt-15 {
			padding-top: 15px;
		}
		.lg-pt-16 {
			padding-top: 16px;
		}
		.lg-pt-17 {
			padding-top: 17px;
		}
		.lg-pt-18 {
			padding-top: 18px;
		}
		.lg-pt-19 {
			padding-top: 19px;
		}
		.lg-pt-20 {
			padding-top: 20px;
		}
		.lg-pt-21 {
			padding-top: 21px;
		}
		.lg-pt-22 {
			padding-top: 22px;
		}
		.lg-pt-23 {
			padding-top: 23px;
		}
		.lg-pt-24 {
			padding-top: 24px;
		}
		.lg-pt-25 {
			padding-top: 25px;
		}
		.lg-pt-26 {
			padding-top: 26px;
		}
		.lg-pt-27 {
			padding-top: 27px;
		}
		.lg-pt-28 {
			padding-top: 28px;
		}
		.lg-pt-29 {
			padding-top: 29px;
		}
		.lg-pt-30 {
			padding-top: 30px;
		}
		.lg-pt-31 {
			padding-top: 31px;
		}
		.lg-pt-32 {
			padding-top: 32px;
		}
		.lg-pt-33 {
			padding-top: 33px;
		}
		.lg-pt-34 {
			padding-top: 34px;
		}
		.lg-pt-35 {
			padding-top: 35px;
		}
		.lg-pt-36 {
			padding-top: 36px;
		}
		.lg-pt-37 {
			padding-top: 37px;
		}
		.lg-pt-38 {
			padding-top: 38px;
		}
		.lg-pt-39 {
			padding-top: 39px;
		}
		.lg-pt-40 {
			padding-top: 40px;
		}
		.lg-pt-41 {
			padding-top: 41px;
		}
		.lg-pt-42 {
			padding-top: 42px;
		}
		.lg-pt-43 {
			padding-top: 43px;
		}
		.lg-pt-44 {
			padding-top: 44px;
		}
		.lg-pt-45 {
			padding-top: 45px;
		}
		.lg-pt-46 {
			padding-top: 46px;
		}
		.lg-pt-47 {
			padding-top: 47px;
		}
		.lg-pt-48 {
			padding-top: 48px;
		}
		.lg-pt-49 {
			padding-top: 49px;
		}
		.lg-pt-50 {
			padding-top: 50px;
		}
		.lg-pt-51 {
			padding-top: 51px;
		}
		.lg-pt-52 {
			padding-top: 52px;
		}
		.lg-pt-53 {
			padding-top: 53px;
		}
		.lg-pt-54 {
			padding-top: 54px;
		}
		.lg-pt-55 {
			padding-top: 55px;
		}
		.lg-pt-56 {
			padding-top: 56px;
		}
		.lg-pt-57 {
			padding-top: 57px;
		}
		.lg-pt-58 {
			padding-top: 58px;
		}
		.lg-pt-59 {
			padding-top: 59px;
		}
		.lg-pt-60 {
			padding-top: 60px;
		}
		.lg-pt-61 {
			padding-top: 61px;
		}
		.lg-pt-62 {
			padding-top: 62px;
		}
		.lg-pt-63 {
			padding-top: 63px;
		}
		.lg-pt-64 {
			padding-top: 64px;
		}
		.lg-pt-65 {
			padding-top: 65px;
		}
		.lg-pt-66 {
			padding-top: 66px;
		}
		.lg-pt-67 {
			padding-top: 67px;
		}
		.lg-pt-68 {
			padding-top: 68px;
		}
		.lg-pt-69 {
			padding-top: 69px;
		}
		.lg-pt-70 {
			padding-top: 70px;
		}
		.lg-pt-71 {
			padding-top: 71px;
		}
		.lg-pt-72 {
			padding-top: 72px;
		}
		.lg-pt-73 {
			padding-top: 73px;
		}
		.lg-pt-74 {
			padding-top: 74px;
		}
		.lg-pt-75 {
			padding-top: 75px;
		}
		.lg-pt-76 {
			padding-top: 76px;
		}
		.lg-pt-77 {
			padding-top: 77px;
		}
		.lg-pt-78 {
			padding-top: 78px;
		}
		.lg-pt-79 {
			padding-top: 79px;
		}
		.lg-pt-80 {
			padding-top: 80px;
		}
		.lg-pt-81 {
			padding-top: 81px;
		}
		.lg-pt-82 {
			padding-top: 82px;
		}
		.lg-pt-83 {
			padding-top: 83px;
		}
		.lg-pt-84 {
			padding-top: 84px;
		}
		.lg-pt-85 {
			padding-top: 85px;
		}
		.lg-pt-86 {
			padding-top: 86px;
		}
		.lg-pt-87 {
			padding-top: 87px;
		}
		.lg-pt-88 {
			padding-top: 88px;
		}
		.lg-pt-89 {
			padding-top: 89px;
		}
		.lg-pt-90 {
			padding-top: 90px;
		}
		.lg-pt-91 {
			padding-top: 91px;
		}
		.lg-pt-92 {
			padding-top: 92px;
		}
		.lg-pt-93 {
			padding-top: 93px;
		}
		.lg-pt-94 {
			padding-top: 94px;
		}
		.lg-pt-95 {
			padding-top: 95px;
		}
		.lg-pt-96 {
			padding-top: 96px;
		}
		.lg-pt-97 {
			padding-top: 97px;
		}
		.lg-pt-98 {
			padding-top: 98px;
		}
		.lg-pt-99 {
			padding-top: 99px;
		}
		.lg-pt-100 {
			padding-top: 100px;
		}

		.lg-pt-101 {
			padding-top: 101px;
		}
		.lg-pt-102 {
			padding-top: 102px;
		}
		.lg-pt-103 {
			padding-top: 103px;
		}
		.lg-pt-104 {
			padding-top: 104px;
		}
		.lg-pt-105 {
			padding-top: 105px;
		}
		.lg-pt-106 {
			padding-top: 106px;
		}
		.lg-pt-107 {
			padding-top: 107px;
		}
		.lg-pt-108 {
			padding-top: 108px;
		}
		.lg-pt-109 {
			padding-top: 109px;
		}
		.lg-pt-110 {
			padding-top: 110px;
		}
		.lg-pt-111 {
			padding-top: 111px;
		}
		.lg-pt-112 {
			padding-top: 112px;
		}
		.lg-pt-113 {
			padding-top: 113px;
		}
		.lg-pt-114 {
			padding-top: 114px;
		}
		.lg-pt-115 {
			padding-top: 115px;
		}
		.lg-pt-116 {
			padding-top: 116px;
		}
		.lg-pt-117 {
			padding-top: 117px;
		}
		.lg-pt-118 {
			padding-top: 118px;
		}
		.lg-pt-119 {
			padding-top: 119px;
		}
		.lg-pt-120 {
			padding-top: 120px;
		}
		.lg-pt-121 {
			padding-top: 121px;
		}
		.lg-pt-122 {
			padding-top: 122px;
		}
		.lg-pt-123 {
			padding-top: 123px;
		}
		.lg-pt-124 {
			padding-top: 124px;
		}
		.lg-pt-125 {
			padding-top: 125px;
		}
		.lg-pt-126 {
			padding-top: 126px;
		}
		.lg-pt-127 {
			padding-top: 127px;
		}
		.lg-pt-128 {
			padding-top: 128px;
		}
		.lg-pt-129 {
			padding-top: 129px;
		}
		.lg-pt-130 {
			padding-top: 130px;
		}
		.lg-pt-131 {
			padding-top: 131px;
		}
		.lg-pt-132 {
			padding-top: 132px;
		}
		.lg-pt-133 {
			padding-top: 133px;
		}
		.lg-pt-134 {
			padding-top: 134px;
		}
		.lg-pt-135 {
			padding-top: 135px;
		}
		.lg-pt-136 {
			padding-top: 136px;
		}
		.lg-pt-137 {
			padding-top: 137px;
		}
		.lg-pt-138 {
			padding-top: 138px;
		}
		.lg-pt-139 {
			padding-top: 139px;
		}
		.lg-pt-140 {
			padding-top: 140px;
		}
		.lg-pt-141 {
			padding-top: 141px;
		}
		.lg-pt-142 {
			padding-top: 142px;
		}
		.lg-pt-143 {
			padding-top: 143px;
		}
		.lg-pt-144 {
			padding-top: 144px;
		}
		.lg-pt-145 {
			padding-top: 145px;
		}
		.lg-pt-146 {
			padding-top: 146px;
		}
		.lg-pt-147 {
			padding-top: 147px;
		}
		.lg-pt-148 {
			padding-top: 148px;
		}
		.lg-pt-149 {
			padding-top: 149px;
		}
		.lg-pt-150 {
			padding-top: 150px;
		}
		.lg-pt-151 {
			padding-top: 151px;
		}
		.lg-pt-152 {
			padding-top: 152px;
		}
		.lg-pt-153 {
			padding-top: 153px;
		}
		.lg-pt-154 {
			padding-top: 154px;
		}
		.lg-pt-155 {
			padding-top: 155px;
		}
		.lg-pt-156 {
			padding-top: 156px;
		}
		.lg-pt-157 {
			padding-top: 157px;
		}
		.lg-pt-158 {
			padding-top: 158px;
		}
		.lg-pt-159 {
			padding-top: 159px;
		}
		.lg-pt-160 {
			padding-top: 160px;
		}
		.lg-pt-161 {
			padding-top: 161px;
		}
		.lg-pt-162 {
			padding-top: 162px;
		}
		.lg-pt-163 {
			padding-top: 163px;
		}
		.lg-pt-164 {
			padding-top: 164px;
		}
		.lg-pt-165 {
			padding-top: 165px;
		}
		.lg-pt-166 {
			padding-top: 166px;
		}
		.lg-pt-167 {
			padding-top: 167px;
		}
		.lg-pt-168 {
			padding-top: 168px;
		}
		.lg-pt-169 {
			padding-top: 169px;
		}
		.lg-pt-170 {
			padding-top: 170px;
		}
		.lg-pt-171 {
			padding-top: 171px;
		}
		.lg-pt-172 {
			padding-top: 172px;
		}
		.lg-pt-173 {
			padding-top: 173px;
		}
		.lg-pt-174 {
			padding-top: 174px;
		}
		.lg-pt-175 {
			padding-top: 175px;
		}
		.lg-pt-176 {
			padding-top: 176px;
		}
		.lg-pt-177 {
			padding-top: 177px;
		}
		.lg-pt-178 {
			padding-top: 178px;
		}
		.lg-pt-179 {
			padding-top: 179px;
		}
		.lg-pt-180 {
			padding-top: 180px;
		}
		.lg-pt-181 {
			padding-top: 181px;
		}
		.lg-pt-182 {
			padding-top: 182px;
		}
		.lg-pt-183 {
			padding-top: 183px;
		}
		.lg-pt-184 {
			padding-top: 184px;
		}
		.lg-pt-185 {
			padding-top: 185px;
		}
		.lg-pt-186 {
			padding-top: 186px;
		}
		.lg-pt-187 {
			padding-top: 187px;
		}
		.lg-pt-188 {
			padding-top: 188px;
		}
		.lg-pt-189 {
			padding-top: 189px;
		}
		.lg-pt-190 {
			padding-top: 190px;
		}
		.lg-pt-191 {
			padding-top: 191px;
		}
		.lg-pt-192 {
			padding-top: 192px;
		}
		.lg-pt-193 {
			padding-top: 193px;
		}
		.lg-pt-194 {
			padding-top: 194px;
		}
		.lg-pt-195 {
			padding-top: 195px;
		}
		.lg-pt-196 {
			padding-top: 196px;
		}
		.lg-pt-197 {
			padding-top: 197px;
		}
		.lg-pt-198 {
			padding-top: 198px;
		}
		.lg-pt-199 {
			padding-top: 199px;
		}
		.lg-pt-200 {
			padding-top: 200px;
		}
	/*}*/

	/*------------------------------------------
		14. LG PADDING BOTTOM
	-------------------------------------------*/
	/*.lg-pb {*/
		.lg-pb-0 {
			padding-bottom: 0px;
		}
		.lg-pb-1 {
			padding-bottom: 1px;
		}
		.lg-pb-2 {
			padding-bottom: 2px;
		}
		.lg-pb-3 {
			padding-bottom: 3px;
		}
		.lg-pb-4 {
			padding-bottom: 4px;
		}
		.lg-pb-5 {
			padding-bottom: 5px;
		}
		.lg-pb-6 {
			padding-bottom: 6px;
		}
		.lg-pb-7 {
			padding-bottom: 7px;
		}
		.lg-pb-8 {
			padding-bottom: 8px;
		}
		.lg-pb-9 {
			padding-bottom: 9px;
		}
		.lg-pb-10 {
			padding-bottom: 10px;
		}
		.lg-pb-11 {
			padding-bottom: 11px;
		}
		.lg-pb-12 {
			padding-bottom: 12px;
		}
		.lg-pb-13 {
			padding-bottom: 13px;
		}
		.lg-pb-14 {
			padding-bottom: 14px;
		}
		.lg-pb-15 {
			padding-bottom: 15px;
		}
		.lg-pb-16 {
			padding-bottom: 16px;
		}
		.lg-pb-17 {
			padding-bottom: 17px;
		}
		.lg-pb-18 {
			padding-bottom: 18px;
		}
		.lg-pb-19 {
			padding-bottom: 19px;
		}
		.lg-pb-20 {
			padding-bottom: 20px;
		}
		.lg-pb-21 {
			padding-bottom: 21px;
		}
		.lg-pb-22 {
			padding-bottom: 22px;
		}
		.lg-pb-23 {
			padding-bottom: 23px;
		}
		.lg-pb-24 {
			padding-bottom: 24px;
		}
		.lg-pb-25 {
			padding-bottom: 25px;
		}
		.lg-pb-26 {
			padding-bottom: 26px;
		}
		.lg-pb-27 {
			padding-bottom: 27px;
		}
		.lg-pb-28 {
			padding-bottom: 28px;
		}
		.lg-pb-29 {
			padding-bottom: 29px;
		}
		.lg-pb-30 {
			padding-bottom: 30px;
		}
		.lg-pb-31 {
			padding-bottom: 31px;
		}
		.lg-pb-32 {
			padding-bottom: 32px;
		}
		.lg-pb-33 {
			padding-bottom: 33px;
		}
		.lg-pb-34 {
			padding-bottom: 34px;
		}
		.lg-pb-35 {
			padding-bottom: 35px;
		}
		.lg-pb-36 {
			padding-bottom: 36px;
		}
		.lg-pb-37 {
			padding-bottom: 37px;
		}
		.lg-pb-38 {
			padding-bottom: 38px;
		}
		.lg-pb-39 {
			padding-bottom: 39px;
		}
		.lg-pb-40 {
			padding-bottom: 40px;
		}
		.lg-pb-41 {
			padding-bottom: 41px;
		}
		.lg-pb-42 {
			padding-bottom: 42px;
		}
		.lg-pb-43 {
			padding-bottom: 43px;
		}
		.lg-pb-44 {
			padding-bottom: 44px;
		}
		.lg-pb-45 {
			padding-bottom: 45px;
		}
		.lg-pb-46 {
			padding-bottom: 46px;
		}
		.lg-pb-47 {
			padding-bottom: 47px;
		}
		.lg-pb-48 {
			padding-bottom: 48px;
		}
		.lg-pb-49 {
			padding-bottom: 49px;
		}
		.lg-pb-50 {
			padding-bottom: 50px;
		}
		.lg-pb-51 {
			padding-bottom: 51px;
		}
		.lg-pb-52 {
			padding-bottom: 52px;
		}
		.lg-pb-53 {
			padding-bottom: 53px;
		}
		.lg-pb-54 {
			padding-bottom: 54px;
		}
		.lg-pb-55 {
			padding-bottom: 55px;
		}
		.lg-pb-56 {
			padding-bottom: 56px;
		}
		.lg-pb-57 {
			padding-bottom: 57px;
		}
		.lg-pb-58 {
			padding-bottom: 58px;
		}
		.lg-pb-59 {
			padding-bottom: 59px;
		}
		.lg-pb-60 {
			padding-bottom: 60px;
		}
		.lg-pb-61 {
			padding-bottom: 61px;
		}
		.lg-pb-62 {
			padding-bottom: 62px;
		}
		.lg-pb-63 {
			padding-bottom: 63px;
		}
		.lg-pb-64 {
			padding-bottom: 64px;
		}
		.lg-pb-65 {
			padding-bottom: 65px;
		}
		.lg-pb-66 {
			padding-bottom: 66px;
		}
		.lg-pb-67 {
			padding-bottom: 67px;
		}
		.lg-pb-68 {
			padding-bottom: 68px;
		}
		.lg-pb-69 {
			padding-bottom: 69px;
		}
		.lg-pb-70 {
			padding-bottom: 70px;
		}
		.lg-pb-71 {
			padding-bottom: 71px;
		}
		.lg-pb-72 {
			padding-bottom: 72px;
		}
		.lg-pb-73 {
			padding-bottom: 73px;
		}
		.lg-pb-74 {
			padding-bottom: 74px;
		}
		.lg-pb-75 {
			padding-bottom: 75px;
		}
		.lg-pb-76 {
			padding-bottom: 76px;
		}
		.lg-pb-77 {
			padding-bottom: 77px;
		}
		.lg-pb-78 {
			padding-bottom: 78px;
		}
		.lg-pb-79 {
			padding-bottom: 79px;
		}
		.lg-pb-80 {
			padding-bottom: 80px;
		}
		.lg-pb-81 {
			padding-bottom: 81px;
		}
		.lg-pb-82 {
			padding-bottom: 82px;
		}
		.lg-pb-83 {
			padding-bottom: 83px;
		}
		.lg-pb-84 {
			padding-bottom: 84px;
		}
		.lg-pb-85 {
			padding-bottom: 85px;
		}
		.lg-pb-86 {
			padding-bottom: 86px;
		}
		.lg-pb-87 {
			padding-bottom: 87px;
		}
		.lg-pb-88 {
			padding-bottom: 88px;
		}
		.lg-pb-89 {
			padding-bottom: 89px;
		}
		.lg-pb-90 {
			padding-bottom: 90px;
		}
		.lg-pb-91 {
			padding-bottom: 91px;
		}
		.lg-pb-92 {
			padding-bottom: 92px;
		}
		.lg-pb-93 {
			padding-bottom: 93px;
		}
		.lg-pb-94 {
			padding-bottom: 94px;
		}
		.lg-pb-95 {
			padding-bottom: 95px;
		}
		.lg-pb-96 {
			padding-bottom: 96px;
		}
		.lg-pb-97 {
			padding-bottom: 97px;
		}
		.lg-pb-98 {
			padding-bottom: 98px;
		}
		.lg-pb-99 {
			padding-bottom: 99px;
		}
		.lg-pb-100 {
			padding-bottom: 100px;
		}

		.lg-pb-101 {
			padding-bottom: 101px;
		}
		.lg-pb-102 {
			padding-bottom: 102px;
		}
		.lg-pb-103 {
			padding-bottom: 103px;
		}
		.lg-pb-104 {
			padding-bottom: 104px;
		}
		.lg-pb-105 {
			padding-bottom: 105px;
		}
		.lg-pb-106 {
			padding-bottom: 106px;
		}
		.lg-pb-107 {
			padding-bottom: 107px;
		}
		.lg-pb-108 {
			padding-bottom: 108px;
		}
		.lg-pb-109 {
			padding-bottom: 109px;
		}
		.lg-pb-110 {
			padding-bottom: 110px;
		}
		.lg-pb-111 {
			padding-bottom: 111px;
		}
		.lg-pb-112 {
			padding-bottom: 112px;
		}
		.lg-pb-113 {
			padding-bottom: 113px;
		}
		.lg-pb-114 {
			padding-bottom: 114px;
		}
		.lg-pb-115 {
			padding-bottom: 115px;
		}
		.lg-pb-116 {
			padding-bottom: 116px;
		}
		.lg-pb-117 {
			padding-bottom: 117px;
		}
		.lg-pb-118 {
			padding-bottom: 118px;
		}
		.lg-pb-119 {
			padding-bottom: 119px;
		}
		.lg-pb-120 {
			padding-bottom: 120px;
		}
		.lg-pb-121 {
			padding-bottom: 121px;
		}
		.lg-pb-122 {
			padding-bottom: 122px;
		}
		.lg-pb-123 {
			padding-bottom: 123px;
		}
		.lg-pb-124 {
			padding-bottom: 124px;
		}
		.lg-pb-125 {
			padding-bottom: 125px;
		}
		.lg-pb-126 {
			padding-bottom: 126px;
		}
		.lg-pb-127 {
			padding-bottom: 127px;
		}
		.lg-pb-128 {
			padding-bottom: 128px;
		}
		.lg-pb-129 {
			padding-bottom: 129px;
		}
		.lg-pb-130 {
			padding-bottom: 130px;
		}
		.lg-pb-131 {
			padding-bottom: 131px;
		}
		.lg-pb-132 {
			padding-bottom: 132px;
		}
		.lg-pb-133 {
			padding-bottom: 133px;
		}
		.lg-pb-134 {
			padding-bottom: 134px;
		}
		.lg-pb-135 {
			padding-bottom: 135px;
		}
		.lg-pb-136 {
			padding-bottom: 136px;
		}
		.lg-pb-137 {
			padding-bottom: 137px;
		}
		.lg-pb-138 {
			padding-bottom: 138px;
		}
		.lg-pb-139 {
			padding-bottom: 139px;
		}
		.lg-pb-140 {
			padding-bottom: 140px;
		}
		.lg-pb-141 {
			padding-bottom: 141px;
		}
		.lg-pb-142 {
			padding-bottom: 142px;
		}
		.lg-pb-143 {
			padding-bottom: 143px;
		}
		.lg-pb-144 {
			padding-bottom: 144px;
		}
		.lg-pb-145 {
			padding-bottom: 145px;
		}
		.lg-pb-146 {
			padding-bottom: 146px;
		}
		.lg-pb-147 {
			padding-bottom: 147px;
		}
		.lg-pb-148 {
			padding-bottom: 148px;
		}
		.lg-pb-149 {
			padding-bottom: 149px;
		}
		.lg-pb-150 {
			padding-bottom: 150px;
		}
		.lg-pb-151 {
			padding-bottom: 151px;
		}
		.lg-pb-152 {
			padding-bottom: 152px;
		}
		.lg-pb-153 {
			padding-bottom: 153px;
		}
		.lg-pb-154 {
			padding-bottom: 154px;
		}
		.lg-pb-155 {
			padding-bottom: 155px;
		}
		.lg-pb-156 {
			padding-bottom: 156px;
		}
		.lg-pb-157 {
			padding-bottom: 157px;
		}
		.lg-pb-158 {
			padding-bottom: 158px;
		}
		.lg-pb-159 {
			padding-bottom: 159px;
		}
		.lg-pb-160 {
			padding-bottom: 160px;
		}
		.lg-pb-161 {
			padding-bottom: 161px;
		}
		.lg-pb-162 {
			padding-bottom: 162px;
		}
		.lg-pb-163 {
			padding-bottom: 163px;
		}
		.lg-pb-164 {
			padding-bottom: 164px;
		}
		.lg-pb-165 {
			padding-bottom: 165px;
		}
		.lg-pb-166 {
			padding-bottom: 166px;
		}
		.lg-pb-167 {
			padding-bottom: 167px;
		}
		.lg-pb-168 {
			padding-bottom: 168px;
		}
		.lg-pb-169 {
			padding-bottom: 169px;
		}
		.lg-pb-170 {
			padding-bottom: 170px;
		}
		.lg-pb-171 {
			padding-bottom: 171px;
		}
		.lg-pb-172 {
			padding-bottom: 172px;
		}
		.lg-pb-173 {
			padding-bottom: 173px;
		}
		.lg-pb-174 {
			padding-bottom: 174px;
		}
		.lg-pb-175 {
			padding-bottom: 175px;
		}
		.lg-pb-176 {
			padding-bottom: 176px;
		}
		.lg-pb-177 {
			padding-bottom: 177px;
		}
		.lg-pb-178 {
			padding-bottom: 178px;
		}
		.lg-pb-179 {
			padding-bottom: 179px;
		}
		.lg-pb-180 {
			padding-bottom: 180px;
		}
		.lg-pb-181 {
			padding-bottom: 181px;
		}
		.lg-pb-182 {
			padding-bottom: 182px;
		}
		.lg-pb-183 {
			padding-bottom: 183px;
		}
		.lg-pb-184 {
			padding-bottom: 184px;
		}
		.lg-pb-185 {
			padding-bottom: 185px;
		}
		.lg-pb-186 {
			padding-bottom: 186px;
		}
		.lg-pb-187 {
			padding-bottom: 187px;
		}
		.lg-pb-188 {
			padding-bottom: 188px;
		}
		.lg-pb-189 {
			padding-bottom: 189px;
		}
		.lg-pb-190 {
			padding-bottom: 190px;
		}
		.lg-pb-191 {
			padding-bottom: 191px;
		}
		.lg-pb-192 {
			padding-bottom: 192px;
		}
		.lg-pb-193 {
			padding-bottom: 193px;
		}
		.lg-pb-194 {
			padding-bottom: 194px;
		}
		.lg-pb-195 {
			padding-bottom: 195px;
		}
		.lg-pb-196 {
			padding-bottom: 196px;
		}
		.lg-pb-197 {
			padding-bottom: 197px;
		}
		.lg-pb-198 {
			padding-bottom: 198px;
		}
		.lg-pb-199 {
			padding-bottom: 199px;
		}
		.lg-pb-200 {
			padding-bottom: 200px;
		}
	/*}*/

	/*------------------------------------------
		15. LG PADDING RIGHT
	-------------------------------------------*/
	/*.lg-pr {*/
		.lg-pr-0 {
			padding-right: 0px;
		}
		.lg-pr-1 {
			padding-right: 1px;
		}
		.lg-pr-2 {
			padding-right: 2px;
		}
		.pb-3 {
			padding-bottom: 3px;
		}
		.lg-pr-4 {
			padding-right: 4px;
		}
		.lg-pr-5 {
			padding-right: 5px;
		}
		.lg-pr-6 {
			padding-right: 6px;
		}
		.lg-pr-7 {
			padding-right: 7px;
		}
		.lg-pr-8 {
			padding-right: 8px;
		}
		.lg-pr-9 {
			padding-right: 9px;
		}
		.lg-pr-10 {
			padding-right: 10px;
		}
		.lg-pr-11 {
			padding-right: 11px;
		}
		.lg-pr-12 {
			padding-right: 12px;
		}
		.lg-pr-13 {
			padding-right: 13px;
		}
		.lg-pr-14 {
			padding-right: 14px;
		}
		.lg-pr-15 {
			padding-right: 15px;
		}
		.lg-pr-16 {
			padding-right: 16px;
		}
		.lg-pr-17 {
			padding-right: 17px;
		}
		.lg-pr-18 {
			padding-right: 18px;
		}
		.lg-pr-19 {
			padding-right: 19px;
		}
		.lg-pr-20 {
			padding-right: 20px;
		}
		.lg-pr-21 {
			padding-right: 21px;
		}
		.lg-pr-22 {
			padding-right: 22px;
		}
		.lg-pr-23 {
			padding-right: 23px;
		}
		.lg-pr-24 {
			padding-right: 24px;
		}
		.lg-pr-25 {
			padding-right: 25px;
		}
		.lg-pr-26 {
			padding-right: 26px;
		}
		.lg-pr-27 {
			padding-right: 27px;
		}
		.lg-pr-28 {
			padding-right: 28px;
		}
		.lg-pr-29 {
			padding-right: 29px;
		}
		.lg-pr-30 {
			padding-right: 30px;
		}
		.lg-pr-31 {
			padding-right: 31px;
		}
		.lg-pr-32 {
			padding-right: 32px;
		}
		.lg-pr-33 {
			padding-right: 33px;
		}
		.lg-pr-34 {
			padding-right: 34px;
		}
		.lg-pr-35 {
			padding-right: 35px;
		}
		.lg-pr-36 {
			padding-right: 36px;
		}
		.lg-pr-37 {
			padding-right: 37px;
		}
		.lg-pr-38 {
			padding-right: 38px;
		}
		.lg-pr-39 {
			padding-right: 39px;
		}
		.lg-pr-40 {
			padding-right: 40px;
		}
		.lg-pr-41 {
			padding-right: 41px;
		}
		.lg-pr-42 {
			padding-right: 42px;
		}
		.lg-pr-43 {
			padding-right: 43px;
		}
		.lg-pr-44 {
			padding-right: 44px;
		}
		.lg-pr-45 {
			padding-right: 45px;
		}
		.lg-pr-46 {
			padding-right: 46px;
		}
		.lg-pr-47 {
			padding-right: 47px;
		}
		.lg-pr-48 {
			padding-right: 48px;
		}
		.lg-pr-49 {
			padding-right: 49px;
		}
		.lg-pr-50 {
			padding-right: 50px;
		}
		.lg-pr-51 {
			padding-right: 51px;
		}
		.lg-pr-52 {
			padding-right: 52px;
		}
		.lg-pr-53 {
			padding-right: 53px;
		}
		.lg-pr-54 {
			padding-right: 54px;
		}
		.lg-pr-55 {
			padding-right: 55px;
		}
		.lg-pr-56 {
			padding-right: 56px;
		}
		.lg-pr-57 {
			padding-right: 57px;
		}
		.lg-pr-58 {
			padding-right: 58px;
		}
		.lg-pr-59 {
			padding-right: 59px;
		}
		.lg-pr-60 {
			padding-right: 60px;
		}
		.lg-pr-61 {
			padding-right: 61px;
		}
		.lg-pr-62 {
			padding-right: 62px;
		}
		.lg-pr-63 {
			padding-right: 63px;
		}
		.lg-pr-64 {
			padding-right: 64px;
		}
		.lg-pr-65 {
			padding-right: 65px;
		}
		.lg-pr-66 {
			padding-right: 66px;
		}
		.lg-pr-67 {
			padding-right: 67px;
		}
		.lg-pr-68 {
			padding-right: 68px;
		}
		.lg-pr-69 {
			padding-right: 69px;
		}
		.lg-pr-70 {
			padding-right: 70px;
		}
		.lg-pr-71 {
			padding-right: 71px;
		}
		.lg-pr-72 {
			padding-right: 72px;
		}
		.lg-pr-73 {
			padding-right: 73px;
		}
		.lg-pr-74 {
			padding-right: 74px;
		}
		.lg-pr-75 {
			padding-right: 75px;
		}
		.lg-pr-76 {
			padding-right: 76px;
		}
		.lg-pr-77 {
			padding-right: 77px;
		}
		.lg-pr-78 {
			padding-right: 78px;
		}
		.lg-pr-79 {
			padding-right: 79px;
		}
		.lg-pr-80 {
			padding-right: 80px;
		}
		.lg-pr-81 {
			padding-right: 81px;
		}
		.lg-pr-82 {
			padding-right: 82px;
		}
		.lg-pr-83 {
			padding-right: 83px;
		}
		.lg-pr-84 {
			padding-right: 84px;
		}
		.lg-pr-85 {
			padding-right: 85px;
		}
		.lg-pr-86 {
			padding-right: 86px;
		}
		.lg-pr-87 {
			padding-right: 87px;
		}
		.lg-pr-88 {
			padding-right: 88px;
		}
		.lg-pr-89 {
			padding-right: 89px;
		}
		.lg-pr-90 {
			padding-right: 90px;
		}
		.lg-pr-91 {
			padding-right: 91px;
		}
		.lg-pr-92 {
			padding-right: 92px;
		}
		.lg-pr-93 {
			padding-right: 93px;
		}
		.lg-pr-94 {
			padding-right: 94px;
		}
		.lg-pr-95 {
			padding-right: 95px;
		}
		.lg-pr-96 {
			padding-right: 96px;
		}
		.lg-pr-97 {
			padding-right: 97px;
		}
		.lg-pr-98 {
			padding-right: 98px;
		}
		.lg-pr-99 {
			padding-right: 99px;
		}
		.lg-pr-100 {
			padding-right: 100px;
		}
		.lg-pr-101 {
			padding-right: 101px;
		}
		.lg-pr-102 {
			padding-right: 102px;
		}
		.lg-pb-103 {
			padding-bottom: 103px;
		}
		.lg-pr-104 {
			padding-right: 104px;
		}
		.lg-pr-105 {
			padding-right: 105px;
		}
		.lg-pr-106 {
			padding-right: 106px;
		}
		.lg-pr-107 {
			padding-right: 107px;
		}
		.lg-pr-108 {
			padding-right: 108px;
		}
		.lg-pr-109 {
			padding-right: 109px;
		}
		.lg-pr-110 {
			padding-right: 110px;
		}
		.lg-pr-111 {
			padding-right: 111px;
		}
		.lg-pr-112 {
			padding-right: 112px;
		}
		.lg-pr-113 {
			padding-right: 113px;
		}
		.lg-pr-114 {
			padding-right: 114px;
		}
		.lg-pr-115 {
			padding-right: 115px;
		}
		.lg-pr-116 {
			padding-right: 116px;
		}
		.lg-pr-117 {
			padding-right: 117px;
		}
		.lg-pr-118 {
			padding-right: 118px;
		}
		.lg-pr-119 {
			padding-right: 119px;
		}
		.lg-pr-120 {
			padding-right: 120px;
		}
		.lg-pr-121 {
			padding-right: 121px;
		}
		.lg-pr-122 {
			padding-right: 122px;
		}
		.lg-pr-123 {
			padding-right: 123px;
		}
		.lg-pr-124 {
			padding-right: 124px;
		}
		.lg-pr-125 {
			padding-right: 125px;
		}
		.lg-pr-126 {
			padding-right: 126px;
		}
		.lg-pr-127 {
			padding-right: 127px;
		}
		.lg-pr-128 {
			padding-right: 128px;
		}
		.lg-pr-129 {
			padding-right: 129px;
		}
		.lg-pr-130 {
			padding-right: 130px;
		}
		.lg-pr-131 {
			padding-right: 131px;
		}
		.lg-pr-132 {
			padding-right: 132px;
		}
		.lg-pr-133 {
			padding-right: 133px;
		}
		.lg-pr-134 {
			padding-right: 134px;
		}
		.lg-pr-135 {
			padding-right: 135px;
		}
		.lg-pr-136 {
			padding-right: 136px;
		}
		.lg-pr-137 {
			padding-right: 137px;
		}
		.lg-pr-138 {
			padding-right: 138px;
		}
		.lg-pr-139 {
			padding-right: 139px;
		}
		.lg-pr-140 {
			padding-right: 140px;
		}
		.lg-pr-141 {
			padding-right: 141px;
		}
		.lg-pr-142 {
			padding-right: 142px;
		}
		.lg-pr-143 {
			padding-right: 143px;
		}
		.lg-pr-144 {
			padding-right: 144px;
		}
		.lg-pr-145 {
			padding-right: 145px;
		}
		.lg-pr-146 {
			padding-right: 146px;
		}
		.lg-pr-147 {
			padding-right: 147px;
		}
		.lg-pr-148 {
			padding-right: 148px;
		}
		.lg-pr-149 {
			padding-right: 149px;
		}
		.lg-pr-150 {
			padding-right: 150px;
		}
		.lg-pr-151 {
			padding-right: 151px;
		}
		.lg-pr-152 {
			padding-right: 152px;
		}
		.lg-pr-153 {
			padding-right: 153px;
		}
		.lg-pr-154 {
			padding-right: 154px;
		}
		.lg-pr-155 {
			padding-right: 155px;
		}
		.lg-pr-156 {
			padding-right: 156px;
		}
		.lg-pr-157 {
			padding-right: 157px;
		}
		.lg-pr-158 {
			padding-right: 158px;
		}
		.lg-pr-159 {
			padding-right: 159px;
		}
		.lg-pr-160 {
			padding-right: 160px;
		}
		.lg-pr-161 {
			padding-right: 161px;
		}
		.lg-pr-162 {
			padding-right: 162px;
		}
		.lg-pr-163 {
			padding-right: 163px;
		}
		.lg-pr-164 {
			padding-right: 164px;
		}
		.lg-pr-165 {
			padding-right: 165px;
		}
		.lg-pr-166 {
			padding-right: 166px;
		}
		.lg-pr-167 {
			padding-right: 167px;
		}
		.lg-pr-168 {
			padding-right: 168px;
		}
		.lg-pr-169 {
			padding-right: 169px;
		}
		.lg-pr-170 {
			padding-right: 170px;
		}
		.lg-pr-171 {
			padding-right: 171px;
		}
		.lg-pr-172 {
			padding-right: 172px;
		}
		.lg-pr-173 {
			padding-right: 173px;
		}
		.lg-pr-174 {
			padding-right: 174px;
		}
		.lg-pr-175 {
			padding-right: 175px;
		}
		.lg-pr-176 {
			padding-right: 176px;
		}
		.lg-pr-177 {
			padding-right: 177px;
		}
		.lg-pr-178 {
			padding-right: 178px;
		}
		.lg-pr-179 {
			padding-right: 179px;
		}
		.lg-pr-180 {
			padding-right: 180px;
		}
		.lg-pr-181 {
			padding-right: 181px;
		}
		.lg-pr-182 {
			padding-right: 182px;
		}
		.lg-pr-183 {
			padding-right: 183px;
		}
		.lg-pr-184 {
			padding-right: 184px;
		}
		.lg-pr-185 {
			padding-right: 185px;
		}
		.lg-pr-186 {
			padding-right: 186px;
		}
		.lg-pr-187 {
			padding-right: 187px;
		}
		.lg-pr-188 {
			padding-right: 188px;
		}
		.lg-pr-189 {
			padding-right: 189px;
		}
		.lg-pr-190 {
			padding-right: 190px;
		}
		.lg-pr-191 {
			padding-right: 191px;
		}
		.lg-pr-192 {
			padding-right: 192px;
		}
		.lg-pr-193 {
			padding-right: 193px;
		}
		.lg-pr-194 {
			padding-right: 194px;
		}
		.lg-pr-195 {
			padding-right: 195px;
		}
		.lg-pr-196 {
			padding-right: 196px;
		}
		.lg-pr-197 {
			padding-right: 197px;
		}
		.lg-pr-198 {
			padding-right: 198px;
		}
		.lg-pr-199 {
			padding-right: 199px;
		}
		.lg-pr-200 {
			padding-right: 200px;
		}
	/*}*/

	/*------------------------------------------
		16. LG PADDING LEFT
	-------------------------------------------*/
	/*.lg-pl {*/
		.lg-pl-0 {
			padding-left: 0px;
		}
		.lg-pl-1 {
			padding-left: 1px;
		}
		.lg-pl-2 {
			padding-left: 2px;
		}
		.pb-3 {
			padding-bottom: 3px;
		}
		.lg-pl-4 {
			padding-left: 4px;
		}
		.lg-pl-5 {
			padding-left: 5px;
		}
		.lg-pl-6 {
			padding-left: 6px;
		}
		.lg-pl-7 {
			padding-left: 7px;
		}
		.lg-pl-8 {
			padding-left: 8px;
		}
		.lg-pl-9 {
			padding-left: 9px;
		}
		.lg-pl-10 {
			padding-left: 10px;
		}
		.lg-pl-11 {
			padding-left: 11px;
		}
		.lg-pl-12 {
			padding-left: 12px;
		}
		.lg-pl-13 {
			padding-left: 13px;
		}
		.lg-pl-14 {
			padding-left: 14px;
		}
		.lg-pl-15 {
			padding-left: 15px;
		}
		.lg-pl-16 {
			padding-left: 16px;
		}
		.lg-pl-17 {
			padding-left: 17px;
		}
		.lg-pl-18 {
			padding-left: 18px;
		}
		.lg-pl-19 {
			padding-left: 19px;
		}
		.lg-pl-20 {
			padding-left: 20px;
		}
		.lg-pl-21 {
			padding-left: 21px;
		}
		.lg-pl-22 {
			padding-left: 22px;
		}
		.lg-pl-23 {
			padding-left: 23px;
		}
		.lg-pl-24 {
			padding-left: 24px;
		}
		.lg-pl-25 {
			padding-left: 25px;
		}
		.lg-pl-26 {
			padding-left: 26px;
		}
		.lg-pl-27 {
			padding-left: 27px;
		}
		.lg-pl-28 {
			padding-left: 28px;
		}
		.lg-pl-29 {
			padding-left: 29px;
		}
		.lg-pl-30 {
			padding-left: 30px;
		}
		.lg-pl-31 {
			padding-left: 31px;
		}
		.lg-pl-32 {
			padding-left: 32px;
		}
		.lg-pl-33 {
			padding-left: 33px;
		}
		.lg-pl-34 {
			padding-left: 34px;
		}
		.lg-pl-35 {
			padding-left: 35px;
		}
		.lg-pl-36 {
			padding-left: 36px;
		}
		.lg-pl-37 {
			padding-left: 37px;
		}
		.lg-pl-38 {
			padding-left: 38px;
		}
		.lg-pl-39 {
			padding-left: 39px;
		}
		.lg-pl-40 {
			padding-left: 40px;
		}
		.lg-pl-41 {
			padding-left: 41px;
		}
		.lg-pl-42 {
			padding-left: 42px;
		}
		.lg-pl-43 {
			padding-left: 43px;
		}
		.lg-pl-44 {
			padding-left: 44px;
		}
		.lg-pl-45 {
			padding-left: 45px;
		}
		.lg-pl-46 {
			padding-left: 46px;
		}
		.lg-pl-47 {
			padding-left: 47px;
		}
		.lg-pl-48 {
			padding-left: 48px;
		}
		.lg-pl-49 {
			padding-left: 49px;
		}
		.lg-pl-50 {
			padding-left: 50px;
		}
		.lg-pl-51 {
			padding-left: 51px;
		}
		.lg-pl-52 {
			padding-left: 52px;
		}
		.lg-pl-53 {
			padding-left: 53px;
		}
		.lg-pl-54 {
			padding-left: 54px;
		}
		.lg-pl-55 {
			padding-left: 55px;
		}
		.lg-pl-56 {
			padding-left: 56px;
		}
		.lg-pl-57 {
			padding-left: 57px;
		}
		.lg-pl-58 {
			padding-left: 58px;
		}
		.lg-pl-59 {
			padding-left: 59px;
		}
		.lg-pl-60 {
			padding-left: 60px;
		}
		.lg-pl-61 {
			padding-left: 61px;
		}
		.lg-pl-62 {
			padding-left: 62px;
		}
		.lg-pl-63 {
			padding-left: 63px;
		}
		.lg-pl-64 {
			padding-left: 64px;
		}
		.lg-pl-65 {
			padding-left: 65px;
		}
		.lg-pl-66 {
			padding-left: 66px;
		}
		.lg-pl-67 {
			padding-left: 67px;
		}
		.lg-pl-68 {
			padding-left: 68px;
		}
		.lg-pl-69 {
			padding-left: 69px;
		}
		.lg-pl-70 {
			padding-left: 70px;
		}
		.lg-pl-71 {
			padding-left: 71px;
		}
		.lg-pl-72 {
			padding-left: 72px;
		}
		.lg-pl-73 {
			padding-left: 73px;
		}
		.lg-pl-74 {
			padding-left: 74px;
		}
		.lg-pl-75 {
			padding-left: 75px;
		}
		.lg-pl-76 {
			padding-left: 76px;
		}
		.lg-pl-77 {
			padding-left: 77px;
		}
		.lg-pl-78 {
			padding-left: 78px;
		}
		.lg-pl-79 {
			padding-left: 79px;
		}
		.lg-pl-80 {
			padding-left: 80px;
		}
		.lg-pl-81 {
			padding-left: 81px;
		}
		.lg-pl-82 {
			padding-left: 82px;
		}
		.lg-pl-83 {
			padding-left: 83px;
		}
		.lg-pl-84 {
			padding-left: 84px;
		}
		.lg-pl-85 {
			padding-left: 85px;
		}
		.lg-pl-86 {
			padding-left: 86px;
		}
		.lg-pl-87 {
			padding-left: 87px;
		}
		.lg-pl-88 {
			padding-left: 88px;
		}
		.lg-pl-89 {
			padding-left: 89px;
		}
		.lg-pl-90 {
			padding-left: 90px;
		}
		.lg-pl-91 {
			padding-left: 91px;
		}
		.lg-pl-92 {
			padding-left: 92px;
		}
		.lg-pl-93 {
			padding-left: 93px;
		}
		.lg-pl-94 {
			padding-left: 94px;
		}
		.lg-pl-95 {
			padding-left: 95px;
		}
		.lg-pl-96 {
			padding-left: 96px;
		}
		.lg-pl-97 {
			padding-left: 97px;
		}
		.lg-pl-98 {
			padding-left: 98px;
		}
		.lg-pl-99 {
			padding-left: 99px;
		}
		.lg-pl-100 {
			padding-left: 100px;
		}
		.lg-pl-101 {
			padding-left: 101px;
		}
		.lg-pl-102 {
			padding-left: 102px;
		}
		.lg-pb-103 {
			padding-bottom: 103px;
		}
		.lg-pl-104 {
			padding-left: 104px;
		}
		.lg-pl-105 {
			padding-left: 105px;
		}
		.lg-pl-106 {
			padding-left: 106px;
		}
		.lg-pl-107 {
			padding-left: 107px;
		}
		.lg-pl-108 {
			padding-left: 108px;
		}
		.lg-pl-109 {
			padding-left: 109px;
		}
		.lg-pl-110 {
			padding-left: 110px;
		}
		.lg-pl-111 {
			padding-left: 111px;
		}
		.lg-pl-112 {
			padding-left: 112px;
		}
		.lg-pl-113 {
			padding-left: 113px;
		}
		.lg-pl-114 {
			padding-left: 114px;
		}
		.lg-pl-115 {
			padding-left: 115px;
		}
		.lg-pl-116 {
			padding-left: 116px;
		}
		.lg-pl-117 {
			padding-left: 117px;
		}
		.lg-pl-118 {
			padding-left: 118px;
		}
		.lg-pl-119 {
			padding-left: 119px;
		}
		.lg-pl-120 {
			padding-left: 120px;
		}
		.lg-pl-121 {
			padding-left: 121px;
		}
		.lg-pl-122 {
			padding-left: 122px;
		}
		.lg-pl-123 {
			padding-left: 123px;
		}
		.lg-pl-124 {
			padding-left: 124px;
		}
		.lg-pl-125 {
			padding-left: 125px;
		}
		.lg-pl-126 {
			padding-left: 126px;
		}
		.lg-pl-127 {
			padding-left: 127px;
		}
		.lg-pl-128 {
			padding-left: 128px;
		}
		.lg-pl-129 {
			padding-left: 129px;
		}
		.lg-pl-130 {
			padding-left: 130px;
		}
		.lg-pl-131 {
			padding-left: 131px;
		}
		.lg-pl-132 {
			padding-left: 132px;
		}
		.lg-pl-133 {
			padding-left: 133px;
		}
		.lg-pl-134 {
			padding-left: 134px;
		}
		.lg-pl-135 {
			padding-left: 135px;
		}
		.lg-pl-136 {
			padding-left: 136px;
		}
		.lg-pl-137 {
			padding-left: 137px;
		}
		.lg-pl-138 {
			padding-left: 138px;
		}
		.lg-pl-139 {
			padding-left: 139px;
		}
		.lg-pl-140 {
			padding-left: 140px;
		}
		.lg-pl-141 {
			padding-left: 141px;
		}
		.lg-pl-142 {
			padding-left: 142px;
		}
		.lg-pl-143 {
			padding-left: 143px;
		}
		.lg-pl-144 {
			padding-left: 144px;
		}
		.lg-pl-145 {
			padding-left: 145px;
		}
		.lg-pl-146 {
			padding-left: 146px;
		}
		.lg-pl-147 {
			padding-left: 147px;
		}
		.lg-pl-148 {
			padding-left: 148px;
		}
		.lg-pl-149 {
			padding-left: 149px;
		}
		.lg-pl-150 {
			padding-left: 150px;
		}
		.lg-pl-151 {
			padding-left: 151px;
		}
		.lg-pl-152 {
			padding-left: 152px;
		}
		.lg-pl-153 {
			padding-left: 153px;
		}
		.lg-pl-154 {
			padding-left: 154px;
		}
		.lg-pl-155 {
			padding-left: 155px;
		}
		.lg-pl-156 {
			padding-left: 156px;
		}
		.lg-pl-157 {
			padding-left: 157px;
		}
		.lg-pl-158 {
			padding-left: 158px;
		}
		.lg-pl-159 {
			padding-left: 159px;
		}
		.lg-pl-160 {
			padding-left: 160px;
		}
		.lg-pl-161 {
			padding-left: 161px;
		}
		.lg-pl-162 {
			padding-left: 162px;
		}
		.lg-pl-163 {
			padding-left: 163px;
		}
		.lg-pl-164 {
			padding-left: 164px;
		}
		.lg-pl-165 {
			padding-left: 165px;
		}
		.lg-pl-166 {
			padding-left: 166px;
		}
		.lg-pl-167 {
			padding-left: 167px;
		}
		.lg-pl-168 {
			padding-left: 168px;
		}
		.lg-pl-169 {
			padding-left: 169px;
		}
		.lg-pl-170 {
			padding-left: 170px;
		}
		.lg-pl-171 {
			padding-left: 171px;
		}
		.lg-pl-172 {
			padding-left: 172px;
		}
		.lg-pl-173 {
			padding-left: 173px;
		}
		.lg-pl-174 {
			padding-left: 174px;
		}
		.lg-pl-175 {
			padding-left: 175px;
		}
		.lg-pl-176 {
			padding-left: 176px;
		}
		.lg-pl-177 {
			padding-left: 177px;
		}
		.lg-pl-178 {
			padding-left: 178px;
		}
		.lg-pl-179 {
			padding-left: 179px;
		}
		.lg-pl-180 {
			padding-left: 180px;
		}
		.lg-pl-181 {
			padding-left: 181px;
		}
		.lg-pl-182 {
			padding-left: 182px;
		}
		.lg-pl-183 {
			padding-left: 183px;
		}
		.lg-pl-184 {
			padding-left: 184px;
		}
		.lg-pl-185 {
			padding-left: 185px;
		}
		.lg-pl-186 {
			padding-left: 186px;
		}
		.lg-pl-187 {
			padding-left: 187px;
		}
		.lg-pl-188 {
			padding-left: 188px;
		}
		.lg-pl-189 {
			padding-left: 189px;
		}
		.lg-pl-190 {
			padding-left: 190px;
		}
		.lg-pl-191 {
			padding-left: 191px;
		}
		.lg-pl-192 {
			padding-left: 192px;
		}
		.lg-pl-193 {
			padding-left: 193px;
		}
		.lg-pl-194 {
			padding-left: 194px;
		}
		.lg-pl-195 {
			padding-left: 195px;
		}
		.lg-pl-196 {
			padding-left: 196px;
		}
		.lg-pl-197 {
			padding-left: 197px;
		}
		.lg-pl-198 {
			padding-left: 198px;
		}
		.lg-pl-199 {
			padding-left: 199px;
		}
		.lg-pl-200 {
			padding-left: 200px;
		}
	/*}*/

	/*------------------------------------------
		17. LG MARGIN TOP
	-------------------------------------------*/
	/*.lg-mt {*/
		.lg-mt-0 {
			margin-top: 0px;
		}
		.lg-mt-1 {
			margin-top: 1px;
		}
		.lg-mt-2 {
			margin-top: 2px;
		}
		.lg-mt-3 {
			margin-top: 3px;
		}
		.lg-mt-4 {
			margin-top: 4px;
		}
		.lg-mt-5 {
			margin-top: 5px;
		}
		.lg-mt-6 {
			margin-top: 6px;
		}
		.lg-mt-7 {
			margin-top: 7px;
		}
		.lg-mt-8 {
			margin-top: 8px;
		}
		.lg-mt-9 {
			margin-top: 9px;
		}
		.lg-mt-10 {
			margin-top: 10px;
		}
		.lg-mt-11 {
			margin-top: 11px;
		}
		.lg-mt-12 {
			margin-top: 12px;
		}
		.lg-mt-13 {
			margin-top: 13px;
		}
		.lg-mt-14 {
			margin-top: 14px;
		}
		.lg-mt-15 {
			margin-top: 15px;
		}
		.lg-mt-16 {
			margin-top: 16px;
		}
		.lg-mt-17 {
			margin-top: 17px;
		}
		.lg-mt-18 {
			margin-top: 18px;
		}
		.lg-mt-19 {
			margin-top: 19px;
		}
		.lg-mt-20 {
			margin-top: 20px;
		}
		.lg-mt-21 {
			margin-top: 21px;
		}
		.lg-mt-22 {
			margin-top: 22px;
		}
		.lg-mt-23 {
			margin-top: 23px;
		}
		.lg-mt-24 {
			margin-top: 24px;
		}
		.lg-mt-25 {
			margin-top: 25px;
		}
		.lg-mt-26 {
			margin-top: 26px;
		}
		.lg-mt-27 {
			margin-top: 27px;
		}
		.lg-mt-28 {
			margin-top: 28px;
		}
		.lg-mt-29 {
			margin-top: 29px;
		}
		.lg-mt-30 {
			margin-top: 30px;
		}
		.lg-mt-31 {
			margin-top: 31px;
		}
		.lg-mt-32 {
			margin-top: 32px;
		}
		.lg-mt-33 {
			margin-top: 33px;
		}
		.lg-mt-34 {
			margin-top: 34px;
		}
		.lg-mt-35 {
			margin-top: 35px;
		}
		.lg-mt-36 {
			margin-top: 36px;
		}
		.lg-mt-37 {
			margin-top: 37px;
		}
		.lg-mt-38 {
			margin-top: 38px;
		}
		.lg-mt-39 {
			margin-top: 39px;
		}
		.lg-mt-40 {
			margin-top: 40px;
		}
		.lg-mt-41 {
			margin-top: 41px;
		}
		.lg-mt-42 {
			margin-top: 42px;
		}
		.lg-mt-43 {
			margin-top: 43px;
		}
		.lg-mt-44 {
			margin-top: 44px;
		}
		.lg-mt-45 {
			margin-top: 45px;
		}
		.lg-mt-46 {
			margin-top: 46px;
		}
		.lg-mt-47 {
			margin-top: 47px;
		}
		.lg-mt-48 {
			margin-top: 48px;
		}
		.lg-mt-49 {
			margin-top: 49px;
		}
		.lg-mt-50 {
			margin-top: 50px;
		}
		.lg-mt-51 {
			margin-top: 51px;
		}
		.lg-mt-52 {
			margin-top: 52px;
		}
		.lg-mt-53 {
			margin-top: 53px;
		}
		.lg-mt-54 {
			margin-top: 54px;
		}
		.lg-mt-55 {
			margin-top: 55px;
		}
		.lg-mt-56 {
			margin-top: 56px;
		}
		.lg-mt-57 {
			margin-top: 57px;
		}
		.lg-mt-58 {
			margin-top: 58px;
		}
		.lg-mt-59 {
			margin-top: 59px;
		}
		.lg-mt-60 {
			margin-top: 60px;
		}
		.lg-mt-61 {
			margin-top: 61px;
		}
		.lg-mt-62 {
			margin-top: 62px;
		}
		.lg-mt-63 {
			margin-top: 63px;
		}
		.lg-mt-64 {
			margin-top: 64px;
		}
		.lg-mt-65 {
			margin-top: 65px;
		}
		.lg-mt-66 {
			margin-top: 66px;
		}
		.lg-mt-67 {
			margin-top: 67px;
		}
		.lg-mt-68 {
			margin-top: 68px;
		}
		.lg-mt-69 {
			margin-top: 69px;
		}
		.lg-mt-70 {
			margin-top: 70px;
		}
		.lg-mt-71 {
			margin-top: 71px;
		}
		.lg-mt-72 {
			margin-top: 72px;
		}
		.lg-mt-73 {
			margin-top: 73px;
		}
		.lg-mt-74 {
			margin-top: 74px;
		}
		.lg-mt-75 {
			margin-top: 75px;
		}
		.lg-mt-76 {
			margin-top: 76px;
		}
		.lg-mt-77 {
			margin-top: 77px;
		}
		.lg-mt-78 {
			margin-top: 78px;
		}
		.lg-mt-79 {
			margin-top: 79px;
		}
		.lg-mt-80 {
			margin-top: 80px;
		}
		.lg-mt-81 {
			margin-top: 81px;
		}
		.lg-mt-82 {
			margin-top: 82px;
		}
		.lg-mt-83 {
			margin-top: 83px;
		}
		.lg-mt-84 {
			margin-top: 84px;
		}
		.lg-mt-85 {
			margin-top: 85px;
		}
		.lg-mt-86 {
			margin-top: 86px;
		}
		.lg-mt-87 {
			margin-top: 87px;
		}
		.lg-mt-88 {
			margin-top: 88px;
		}
		.lg-mt-89 {
			margin-top: 89px;
		}
		.lg-mt-90 {
			margin-top: 90px;
		}
		.lg-mt-91 {
			margin-top: 91px;
		}
		.lg-mt-92 {
			margin-top: 92px;
		}
		.lg-mt-93 {
			margin-top: 93px;
		}
		.lg-mt-94 {
			margin-top: 94px;
		}
		.lg-mt-95 {
			margin-top: 95px;
		}
		.lg-mt-96 {
			margin-top: 96px;
		}
		.lg-mt-97 {
			margin-top: 97px;
		}
		.lg-mt-98 {
			margin-top: 98px;
		}
		.lg-mt-99 {
			margin-top: 99px;
		}
		.lg-mt-100 {
			margin-top: 100px;
		}

		.lg-mt-101 {
			margin-top: 101px;
		}
		.lg-mt-102 {
			margin-top: 102px;
		}
		.lg-mt-103 {
			margin-top: 103px;
		}
		.lg-mt-104 {
			margin-top: 104px;
		}
		.lg-mt-105 {
			margin-top: 105px;
		}
		.lg-mt-106 {
			margin-top: 106px;
		}
		.lg-mt-107 {
			margin-top: 107px;
		}
		.lg-mt-108 {
			margin-top: 108px;
		}
		.lg-mt-109 {
			margin-top: 109px;
		}
		.lg-mt-110 {
			margin-top: 110px;
		}
		.lg-mt-111 {
			margin-top: 111px;
		}
		.lg-mt-112 {
			margin-top: 112px;
		}
		.lg-mt-113 {
			margin-top: 113px;
		}
		.lg-mt-114 {
			margin-top: 114px;
		}
		.lg-mt-115 {
			margin-top: 115px;
		}
		.lg-mt-116 {
			margin-top: 116px;
		}
		.lg-mt-117 {
			margin-top: 117px;
		}
		.lg-mt-118 {
			margin-top: 118px;
		}
		.lg-mt-119 {
			margin-top: 119px;
		}
		.lg-mt-120 {
			margin-top: 120px;
		}
		.lg-mt-121 {
			margin-top: 121px;
		}
		.lg-mt-122 {
			margin-top: 122px;
		}
		.lg-mt-123 {
			margin-top: 123px;
		}
		.lg-mt-124 {
			margin-top: 124px;
		}
		.lg-mt-125 {
			margin-top: 125px;
		}
		.lg-mt-126 {
			margin-top: 126px;
		}
		.lg-mt-127 {
			margin-top: 127px;
		}
		.lg-mt-128 {
			margin-top: 128px;
		}
		.lg-mt-129 {
			margin-top: 129px;
		}
		.lg-mt-130 {
			margin-top: 130px;
		}
		.lg-mt-131 {
			margin-top: 131px;
		}
		.lg-mt-132 {
			margin-top: 132px;
		}
		.lg-mt-133 {
			margin-top: 133px;
		}
		.lg-mt-134 {
			margin-top: 134px;
		}
		.lg-mt-135 {
			margin-top: 135px;
		}
		.lg-mt-136 {
			margin-top: 136px;
		}
		.lg-mt-137 {
			margin-top: 137px;
		}
		.lg-mt-138 {
			margin-top: 138px;
		}
		.lg-mt-139 {
			margin-top: 139px;
		}
		.lg-mt-140 {
			margin-top: 140px;
		}
		.lg-mt-141 {
			margin-top: 141px;
		}
		.lg-mt-142 {
			margin-top: 142px;
		}
		.lg-mt-143 {
			margin-top: 143px;
		}
		.lg-mt-144 {
			margin-top: 144px;
		}
		.lg-mt-145 {
			margin-top: 145px;
		}
		.lg-mt-146 {
			margin-top: 146px;
		}
		.lg-mt-147 {
			margin-top: 147px;
		}
		.lg-mt-148 {
			margin-top: 148px;
		}
		.lg-mt-149 {
			margin-top: 149px;
		}
		.lg-mt-150 {
			margin-top: 150px;
		}
		.lg-mt-151 {
			margin-top: 151px;
		}
		.lg-mt-152 {
			margin-top: 152px;
		}
		.lg-mt-153 {
			margin-top: 153px;
		}
		.lg-mt-154 {
			margin-top: 154px;
		}
		.lg-mt-155 {
			margin-top: 155px;
		}
		.lg-mt-156 {
			margin-top: 156px;
		}
		.lg-mt-157 {
			margin-top: 157px;
		}
		.lg-mt-158 {
			margin-top: 158px;
		}
		.lg-mt-159 {
			margin-top: 159px;
		}
		.lg-mt-160 {
			margin-top: 160px;
		}
		.lg-mt-161 {
			margin-top: 161px;
		}
		.lg-mt-162 {
			margin-top: 162px;
		}
		.lg-mt-163 {
			margin-top: 163px;
		}
		.lg-mt-164 {
			margin-top: 164px;
		}
		.lg-mt-165 {
			margin-top: 165px;
		}
		.lg-mt-166 {
			margin-top: 166px;
		}
		.lg-mt-167 {
			margin-top: 167px;
		}
		.lg-mt-168 {
			margin-top: 168px;
		}
		.lg-mt-169 {
			margin-top: 169px;
		}
		.lg-mt-170 {
			margin-top: 170px;
		}
		.lg-mt-171 {
			margin-top: 171px;
		}
		.lg-mt-172 {
			margin-top: 172px;
		}
		.lg-mt-173 {
			margin-top: 173px;
		}
		.lg-mt-174 {
			margin-top: 174px;
		}
		.lg-mt-175 {
			margin-top: 175px;
		}
		.lg-mt-176 {
			margin-top: 176px;
		}
		.lg-mt-177 {
			margin-top: 177px;
		}
		.lg-mt-178 {
			margin-top: 178px;
		}
		.lg-mt-179 {
			margin-top: 179px;
		}
		.lg-mt-180 {
			margin-top: 180px;
		}
		.lg-mt-181 {
			margin-top: 181px;
		}
		.lg-mt-182 {
			margin-top: 182px;
		}
		.lg-mt-183 {
			margin-top: 183px;
		}
		.lg-mt-184 {
			margin-top: 184px;
		}
		.lg-mt-185 {
			margin-top: 185px;
		}
		.lg-mt-186 {
			margin-top: 186px;
		}
		.lg-mt-187 {
			margin-top: 187px;
		}
		.lg-mt-188 {
			margin-top: 188px;
		}
		.lg-mt-189 {
			margin-top: 189px;
		}
		.lg-mt-190 {
			margin-top: 190px;
		}
		.lg-mt-191 {
			margin-top: 191px;
		}
		.lg-mt-192 {
			margin-top: 192px;
		}
		.lg-mt-193 {
			margin-top: 193px;
		}
		.lg-mt-194 {
			margin-top: 194px;
		}
		.lg-mt-195 {
			margin-top: 195px;
		}
		.lg-mt-196 {
			margin-top: 196px;
		}
		.lg-mt-197 {
			margin-top: 197px;
		}
		.lg-mt-198 {
			margin-top: 198px;
		}
		.lg-mt-199 {
			margin-top: 199px;
		}
		.lg-mt-200 {
			margin-top: 200px;
		}
	/*}*/

	/*------------------------------------------
		18. LG MARGIN BOTTOM
	-------------------------------------------*/
	/*.lg-mb {*/
		.lg-mb-0 {
			margin-bottom: 0px;
		}
		.lg-mb-1 {
			margin-bottom: 1px;
		}
		.lg-mb-2 {
			margin-bottom: 2px;
		}
		.lg-mb-3 {
			margin-bottom: 3px;
		}
		.lg-mb-4 {
			margin-bottom: 4px;
		}
		.lg-mb-5 {
			margin-bottom: 5px;
		}
		.lg-mb-6 {
			margin-bottom: 6px;
		}
		.lg-mb-7 {
			margin-bottom: 7px;
		}
		.lg-mb-8 {
			margin-bottom: 8px;
		}
		.lg-mb-9 {
			margin-bottom: 9px;
		}
		.lg-mb-10 {
			margin-bottom: 10px;
		}
		.lg-mb-11 {
			margin-bottom: 11px;
		}
		.lg-mb-12 {
			margin-bottom: 12px;
		}
		.lg-mb-13 {
			margin-bottom: 13px;
		}
		.lg-mb-14 {
			margin-bottom: 14px;
		}
		.lg-mb-15 {
			margin-bottom: 15px;
		}
		.lg-mb-16 {
			margin-bottom: 16px;
		}
		.lg-mb-17 {
			margin-bottom: 17px;
		}
		.lg-mb-18 {
			margin-bottom: 18px;
		}
		.lg-mb-19 {
			margin-bottom: 19px;
		}
		.lg-mb-20 {
			margin-bottom: 20px;
		}
		.lg-mb-21 {
			margin-bottom: 21px;
		}
		.lg-mb-22 {
			margin-bottom: 22px;
		}
		.lg-mb-23 {
			margin-bottom: 23px;
		}
		.lg-mb-24 {
			margin-bottom: 24px;
		}
		.lg-mb-25 {
			margin-bottom: 25px!important;
		}
		.lg-mb-26 {
			margin-bottom: 26px;
		}
		.lg-mb-27 {
			margin-bottom: 27px;
		}
		.lg-mb-28 {
			margin-bottom: 28px;
		}
		.lg-mb-29 {
			margin-bottom: 29px;
		}
		.lg-mb-30 {
			margin-bottom: 30px;
		}
		.lg-mb-31 {
			margin-bottom: 31px;
		}
		.lg-mb-32 {
			margin-bottom: 32px;
		}
		.lg-mb-33 {
			margin-bottom: 33px;
		}
		.lg-mb-34 {
			margin-bottom: 34px;
		}
		.lg-mb-35 {
			margin-bottom: 35px;
		}
		.lg-mb-36 {
			margin-bottom: 36px;
		}
		.lg-mb-37 {
			margin-bottom: 37px;
		}
		.lg-mb-38 {
			margin-bottom: 38px;
		}
		.lg-mb-39 {
			margin-bottom: 39px;
		}
		.lg-mb-40 {
			margin-bottom: 40px!important;
		}
		.lg-mb-41 {
			margin-bottom: 41px;
		}
		.lg-mb-42 {
			margin-bottom: 42px;
		}
		.lg-mb-43 {
			margin-bottom: 43px;
		}
		.lg-mb-44 {
			margin-bottom: 44px;
		}
		.lg-mb-45 {
			margin-bottom: 45px;
		}
		.lg-mb-46 {
			margin-bottom: 46px;
		}
		.lg-mb-47 {
			margin-bottom: 47px;
		}
		.lg-mb-48 {
			margin-bottom: 48px;
		}
		.lg-mb-49 {
			margin-bottom: 49px;
		}
		.lg-mb-50 {
			margin-bottom: 50px;
		}
		.lg-mb-51 {
			margin-bottom: 51px;
		}
		.lg-mb-52 {
			margin-bottom: 52px;
		}
		.lg-mb-53 {
			margin-bottom: 53px;
		}
		.lg-mb-54 {
			margin-bottom: 54px;
		}
		.lg-mb-55 {
			margin-bottom: 55px;
		}
		.lg-mb-56 {
			margin-bottom: 56px;
		}
		.lg-mb-57 {
			margin-bottom: 57px;
		}
		.lg-mb-58 {
			margin-bottom: 58px;
		}
		.lg-mb-59 {
			margin-bottom: 59px;
		}
		.lg-mb-60 {
			margin-bottom: 60px;
		}
		.lg-mb-61 {
			margin-bottom: 61px;
		}
		.lg-mb-62 {
			margin-bottom: 62px;
		}
		.lg-mb-63 {
			margin-bottom: 63px;
		}
		.lg-mb-64 {
			margin-bottom: 64px;
		}
		.lg-mb-65 {
			margin-bottom: 65px;
		}
		.lg-mb-66 {
			margin-bottom: 66px;
		}
		.lg-mb-67 {
			margin-bottom: 67px;
		}
		.lg-mb-68 {
			margin-bottom: 68px;
		}
		.lg-mb-69 {
			margin-bottom: 69px;
		}
		.lg-mb-70 {
			margin-bottom: 70px;
		}
		.lg-mb-71 {
			margin-bottom: 71px;
		}
		.lg-mb-72 {
			margin-bottom: 72px;
		}
		.lg-mb-73 {
			margin-bottom: 73px;
		}
		.lg-mb-74 {
			margin-bottom: 74px;
		}
		.lg-mb-75 {
			margin-bottom: 75px;
		}
		.lg-mb-76 {
			margin-bottom: 76px;
		}
		.lg-mb-77 {
			margin-bottom: 77px;
		}
		.lg-mb-78 {
			margin-bottom: 78px;
		}
		.lg-mb-79 {
			margin-bottom: 79px;
		}
		.lg-mb-80 {
			margin-bottom: 80px;
		}
		.lg-mb-81 {
			margin-bottom: 81px;
		}
		.lg-mb-82 {
			margin-bottom: 82px;
		}
		.lg-mb-83 {
			margin-bottom: 83px;
		}
		.lg-mb-84 {
			margin-bottom: 84px;
		}
		.lg-mb-85 {
			margin-bottom: 85px;
		}
		.lg-mb-86 {
			margin-bottom: 86px;
		}
		.lg-mb-87 {
			margin-bottom: 87px;
		}
		.lg-mb-88 {
			margin-bottom: 88px;
		}
		.lg-mb-89 {
			margin-bottom: 89px;
		}
		.lg-mb-90 {
			margin-bottom: 90px;
		}
		.lg-mb-91 {
			margin-bottom: 91px;
		}
		.lg-mb-92 {
			margin-bottom: 92px;
		}
		.lg-mb-93 {
			margin-bottom: 93px;
		}
		.lg-mb-94 {
			margin-bottom: 94px;
		}
		.lg-mb-95 {
			margin-bottom: 95px;
		}
		.lg-mb-96 {
			margin-bottom: 96px;
		}
		.lg-mb-97 {
			margin-bottom: 97px;
		}
		.lg-mb-98 {
			margin-bottom: 98px;
		}
		.lg-mb-99 {
			margin-bottom: 99px;
		}
		.lg-mb-100 {
			margin-bottom: 100px;
		}

		.lg-mb-101 {
			margin-bottom: 101px;
		}
		.lg-mb-102 {
			margin-bottom: 102px;
		}
		.lg-mb-103 {
			margin-bottom: 103px;
		}
		.lg-mb-104 {
			margin-bottom: 104px;
		}
		.lg-mb-105 {
			margin-bottom: 105px;
		}
		.lg-mb-106 {
			margin-bottom: 106px;
		}
		.lg-mb-107 {
			margin-bottom: 107px;
		}
		.lg-mb-108 {
			margin-bottom: 108px;
		}
		.lg-mb-109 {
			margin-bottom: 109px;
		}
		.lg-mb-110 {
			margin-bottom: 110px;
		}
		.lg-mb-111 {
			margin-bottom: 111px;
		}
		.lg-mb-112 {
			margin-bottom: 112px;
		}
		.lg-mb-113 {
			margin-bottom: 113px;
		}
		.lg-mb-114 {
			margin-bottom: 114px;
		}
		.lg-mb-115 {
			margin-bottom: 115px;
		}
		.lg-mb-116 {
			margin-bottom: 116px;
		}
		.lg-mb-117 {
			margin-bottom: 117px;
		}
		.lg-mb-118 {
			margin-bottom: 118px;
		}
		.lg-mb-119 {
			margin-bottom: 119px;
		}
		.lg-mb-120 {
			margin-bottom: 120px;
		}
		.lg-mb-121 {
			margin-bottom: 121px;
		}
		.lg-mb-122 {
			margin-bottom: 122px;
		}
		.lg-mb-123 {
			margin-bottom: 123px;
		}
		.lg-mb-124 {
			margin-bottom: 124px;
		}
		.lg-mb-125 {
			margin-bottom: 125px;
		}
		.lg-mb-126 {
			margin-bottom: 126px;
		}
		.lg-mb-127 {
			margin-bottom: 127px;
		}
		.lg-mb-128 {
			margin-bottom: 128px;
		}
		.lg-mb-129 {
			margin-bottom: 129px;
		}
		.lg-mb-130 {
			margin-bottom: 130px;
		}
		.lg-mb-131 {
			margin-bottom: 131px;
		}
		.lg-mb-132 {
			margin-bottom: 132px;
		}
		.lg-mb-133 {
			margin-bottom: 133px;
		}
		.lg-mb-134 {
			margin-bottom: 134px;
		}
		.lg-mb-135 {
			margin-bottom: 135px;
		}
		.lg-mb-136 {
			margin-bottom: 136px;
		}
		.lg-mb-137 {
			margin-bottom: 137px;
		}
		.lg-mb-138 {
			margin-bottom: 138px;
		}
		.lg-mb-139 {
			margin-bottom: 139px;
		}
		.lg-mb-140 {
			margin-bottom: 140px;
		}
		.lg-mb-141 {
			margin-bottom: 141px;
		}
		.lg-mb-142 {
			margin-bottom: 142px;
		}
		.lg-mb-143 {
			margin-bottom: 143px;
		}
		.lg-mb-144 {
			margin-bottom: 144px;
		}
		.lg-mb-145 {
			margin-bottom: 145px;
		}
		.lg-mb-146 {
			margin-bottom: 146px;
		}
		.lg-mb-147 {
			margin-bottom: 147px;
		}
		.lg-mb-148 {
			margin-bottom: 148px;
		}
		.lg-mb-149 {
			margin-bottom: 149px;
		}
		.lg-mb-150 {
			margin-bottom: 150px;
		}
		.lg-mb-151 {
			margin-bottom: 151px;
		}
		.lg-mb-152 {
			margin-bottom: 152px;
		}
		.lg-mb-153 {
			margin-bottom: 153px;
		}
		.lg-mb-154 {
			margin-bottom: 154px;
		}
		.lg-mb-155 {
			margin-bottom: 155px;
		}
		.lg-mb-156 {
			margin-bottom: 156px;
		}
		.lg-mb-157 {
			margin-bottom: 157px;
		}
		.lg-mb-158 {
			margin-bottom: 158px;
		}
		.lg-mb-159 {
			margin-bottom: 159px;
		}
		.lg-mb-160 {
			margin-bottom: 160px;
		}
		.lg-mb-161 {
			margin-bottom: 161px;
		}
		.lg-mb-162 {
			margin-bottom: 162px;
		}
		.lg-mb-163 {
			margin-bottom: 163px;
		}
		.lg-mb-164 {
			margin-bottom: 164px;
		}
		.lg-mb-165 {
			margin-bottom: 165px;
		}
		.lg-mb-166 {
			margin-bottom: 166px;
		}
		.lg-mb-167 {
			margin-bottom: 167px;
		}
		.lg-mb-168 {
			margin-bottom: 168px;
		}
		.lg-mb-169 {
			margin-bottom: 169px;
		}
		.lg-mb-170 {
			margin-bottom: 170px;
		}
		.lg-mb-171 {
			margin-bottom: 171px;
		}
		.lg-mb-172 {
			margin-bottom: 172px;
		}
		.lg-mb-173 {
			margin-bottom: 173px;
		}
		.lg-mb-174 {
			margin-bottom: 174px;
		}
		.lg-mb-175 {
			margin-bottom: 175px;
		}
		.lg-mb-176 {
			margin-bottom: 176px;
		}
		.lg-mb-177 {
			margin-bottom: 177px;
		}
		.lg-mb-178 {
			margin-bottom: 178px;
		}
		.lg-mb-179 {
			margin-bottom: 179px;
		}
		.lg-mb-180 {
			margin-bottom: 180px;
		}
		.lg-mb-181 {
			margin-bottom: 181px;
		}
		.lg-mb-182 {
			margin-bottom: 182px;
		}
		.lg-mb-183 {
			margin-bottom: 183px;
		}
		.lg-mb-184 {
			margin-bottom: 184px;
		}
		.lg-mb-185 {
			margin-bottom: 185px;
		}
		.lg-mb-186 {
			margin-bottom: 186px;
		}
		.lg-mb-187 {
			margin-bottom: 187px;
		}
		.lg-mb-188 {
			margin-bottom: 188px;
		}
		.lg-mb-189 {
			margin-bottom: 189px;
		}
		.lg-mb-190 {
			margin-bottom: 190px;
		}
		.lg-mb-191 {
			margin-bottom: 191px;
		}
		.lg-mb-192 {
			margin-bottom: 192px;
		}
		.lg-mb-193 {
			margin-bottom: 193px;
		}
		.lg-mb-194 {
			margin-bottom: 194px;
		}
		.lg-mb-195 {
			margin-bottom: 195px;
		}
		.lg-mb-196 {
			margin-bottom: 196px;
		}
		.lg-mb-197 {
			margin-bottom: 197px;
		}
		.lg-mb-198 {
			margin-bottom: 198px;
		}
		.lg-mb-199 {
			margin-bottom: 199px;
		}
		.lg-mb-200 {
			margin-bottom: 200px;
		}
	/*}*/

	/*------------------------------------------
		19. LG MARGIN RIGHT
	-------------------------------------------*/
	/*.lg-mr {*/
		.lg-mr-0 {
			margin-right: 0px;
		}
		.lg-mr-1 {
			margin-right: 1px;
		}
		.lg-mr-2 {
			margin-right: 2px;
		}
		.lg-mr-3 {
			margin-right: 3px;
		}
		.lg-mr-4 {
			margin-right: 4px;
		}
		.lg-mr-5 {
			margin-right: 5px;
		}
		.lg-mr-6 {
			margin-right: 6px;
		}
		.lg-mr-7 {
			margin-right: 7px;
		}
		.lg-mr-8 {
			margin-right: 8px;
		}
		.lg-mr-9 {
			margin-right: 9px;
		}
		.lg-mr-10 {
			margin-right: 10px;
		}
		.lg-mr-11 {
			margin-right: 11px;
		}
		.lg-mr-12 {
			margin-right: 12px;
		}
		.lg-mr-13 {
			margin-right: 13px;
		}
		.lg-mr-14 {
			margin-right: 14px;
		}
		.lg-mr-15 {
			margin-right: 15px;
		}
		.lg-mr-16 {
			margin-right: 16px;
		}
		.lg-mr-17 {
			margin-right: 17px;
		}
		.lg-mr-18 {
			margin-right: 18px;
		}
		.lg-mr-19 {
			margin-right: 19px;
		}
		.lg-mr-20 {
			margin-right: 20px;
		}
		.lg-mr-21 {
			margin-right: 21px;
		}
		.lg-mr-22 {
			margin-right: 22px;
		}
		.lg-mr-23 {
			margin-right: 23px;
		}
		.lg-mr-24 {
			margin-right: 24px;
		}
		.lg-mr-25 {
			margin-right: 25px;
		}
		.lg-mr-26 {
			margin-right: 26px;
		}
		.lg-mr-27 {
			margin-right: 27px;
		}
		.lg-mr-28 {
			margin-right: 28px;
		}
		.lg-mr-29 {
			margin-right: 29px;
		}
		.lg-mr-30 {
			margin-right: 30px;
		}
		.lg-mr-31 {
			margin-right: 31px;
		}
		.lg-mr-32 {
			margin-right: 32px;
		}
		.lg-mr-33 {
			margin-right: 33px;
		}
		.lg-mr-34 {
			margin-right: 34px;
		}
		.lg-mr-35 {
			margin-right: 35px;
		}
		.lg-mr-36 {
			margin-right: 36px;
		}
		.lg-mr-37 {
			margin-right: 37px;
		}
		.lg-mr-38 {
			margin-right: 38px;
		}
		.lg-mr-39 {
			margin-right: 39px;
		}
		.lg-mr-40 {
			margin-right: 40px;
		}
		.lg-mr-41 {
			margin-right: 41px;
		}
		.lg-mr-42 {
			margin-right: 42px;
		}
		.lg-mr-43 {
			margin-right: 43px;
		}
		.lg-mr-44 {
			margin-right: 44px;
		}
		.lg-mr-45 {
			margin-right: 45px;
		}
		.lg-mr-46 {
			margin-right: 46px;
		}
		.lg-mr-47 {
			margin-right: 47px;
		}
		.lg-mr-48 {
			margin-right: 48px;
		}
		.lg-mr-49 {
			margin-right: 49px;
		}
		.lg-mr-50 {
			margin-right: 50px;
		}
		.lg-mr-51 {
			margin-right: 51px;
		}
		.lg-mr-52 {
			margin-right: 52px;
		}
		.lg-mr-53 {
			margin-right: 53px;
		}
		.lg-mr-54 {
			margin-right: 54px;
		}
		.lg-mr-55 {
			margin-right: 55px;
		}
		.lg-mr-56 {
			margin-right: 56px;
		}
		.lg-mr-57 {
			margin-right: 57px;
		}
		.lg-mr-58 {
			margin-right: 58px;
		}
		.lg-mr-59 {
			margin-right: 59px;
		}
		.lg-mr-60 {
			margin-right: 60px;
		}
		.lg-mr-61 {
			margin-right: 61px;
		}
		.lg-mr-62 {
			margin-right: 62px;
		}
		.lg-mr-63 {
			margin-right: 63px;
		}
		.lg-mr-64 {
			margin-right: 64px;
		}
		.lg-mr-65 {
			margin-right: 65px;
		}
		.lg-mr-66 {
			margin-right: 66px;
		}
		.lg-mr-67 {
			margin-right: 67px;
		}
		.lg-mr-68 {
			margin-right: 68px;
		}
		.lg-mr-69 {
			margin-right: 69px;
		}
		.lg-mr-70 {
			margin-right: 70px;
		}
		.lg-mr-71 {
			margin-right: 71px;
		}
		.lg-mr-72 {
			margin-right: 72px;
		}
		.lg-mr-73 {
			margin-right: 73px;
		}
		.lg-mr-74 {
			margin-right: 74px;
		}
		.lg-mr-75 {
			margin-right: 75px;
		}
		.lg-mr-76 {
			margin-right: 76px;
		}
		.lg-mr-77 {
			margin-right: 77px;
		}
		.lg-mr-78 {
			margin-right: 78px;
		}
		.lg-mr-79 {
			margin-right: 79px;
		}
		.lg-mr-80 {
			margin-right: 80px;
		}
		.lg-mr-81 {
			margin-right: 81px;
		}
		.lg-mr-82 {
			margin-right: 82px;
		}
		.lg-mr-83 {
			margin-right: 83px;
		}
		.lg-mr-84 {
			margin-right: 84px;
		}
		.lg-mr-85 {
			margin-right: 85px;
		}
		.lg-mr-86 {
			margin-right: 86px;
		}
		.lg-mr-87 {
			margin-right: 87px;
		}
		.lg-mr-88 {
			margin-right: 88px;
		}
		.lg-mr-89 {
			margin-right: 89px;
		}
		.lg-mr-90 {
			margin-right: 90px;
		}
		.lg-mr-91 {
			margin-right: 91px;
		}
		.lg-mr-92 {
			margin-right: 92px;
		}
		.lg-mr-93 {
			margin-right: 93px;
		}
		.lg-mr-94 {
			margin-right: 94px;
		}
		.lg-mr-95 {
			margin-right: 95px;
		}
		.lg-mr-96 {
			margin-right: 96px;
		}
		.lg-mr-97 {
			margin-right: 97px;
		}
		.lg-mr-98 {
			margin-right: 98px;
		}
		.lg-mr-99 {
			margin-right: 99px;
		}
		.lg-mr-100 {
			margin-right: 100px;
		}
	/*}*/

	/*------------------------------------------
		20. LG MARGIN LEFT
	-------------------------------------------*/
	/*.lg-ml {*/
		.lg-ml-0 {
			margin-left: 0px;
		}
		.lg-ml-1 {
			margin-left: 1px;
		}
		.lg-ml-2 {
			margin-left: 2px;
		}
		.lg-ml-3 {
			margin-left: 3px;
		}
		.lg-ml-4 {
			margin-left: 4px;
		}
		.lg-ml-5 {
			margin-left: 5px;
		}
		.lg-ml-6 {
			margin-left: 6px;
		}
		.lg-ml-7 {
			margin-left: 7px;
		}
		.lg-ml-8 {
			margin-left: 8px;
		}
		.lg-ml-9 {
			margin-left: 9px;
		}
		.lg-ml-10 {
			margin-left: 10px;
		}
		.lg-ml-11 {
			margin-left: 11px;
		}
		.lg-ml-12 {
			margin-left: 12px;
		}
		.lg-ml-13 {
			margin-left: 13px;
		}
		.lg-ml-14 {
			margin-left: 14px;
		}
		.lg-ml-15 {
			margin-left: 15px;
		}
		.lg-ml-16 {
			margin-left: 16px;
		}
		.lg-ml-17 {
			margin-left: 17px;
		}
		.lg-ml-18 {
			margin-left: 18px;
		}
		.lg-ml-19 {
			margin-left: 19px;
		}
		.lg-ml-20 {
			margin-left: 20px;
		}
		.lg-ml-21 {
			margin-left: 21px;
		}
		.lg-ml-22 {
			margin-left: 22px;
		}
		.lg-ml-23 {
			margin-left: 23px;
		}
		.lg-ml-24 {
			margin-left: 24px;
		}
		.lg-ml-25 {
			margin-left: 25px;
		}
		.lg-ml-26 {
			margin-left: 26px;
		}
		.lg-ml-27 {
			margin-left: 27px;
		}
		.lg-ml-28 {
			margin-left: 28px;
		}
		.lg-ml-29 {
			margin-left: 29px;
		}
		.lg-ml-30 {
			margin-left: 30px;
		}
		.lg-ml-31 {
			margin-left: 31px;
		}
		.lg-ml-32 {
			margin-left: 32px;
		}
		.lg-ml-33 {
			margin-left: 33px;
		}
		.lg-ml-34 {
			margin-left: 34px;
		}
		.lg-ml-35 {
			margin-left: 35px;
		}
		.lg-ml-36 {
			margin-left: 36px;
		}
		.lg-ml-37 {
			margin-left: 37px;
		}
		.lg-ml-38 {
			margin-left: 38px;
		}
		.lg-ml-39 {
			margin-left: 39px;
		}
		.lg-ml-40 {
			margin-left: 40px;
		}
		.lg-ml-41 {
			margin-left: 41px;
		}
		.lg-ml-42 {
			margin-left: 42px;
		}
		.lg-ml-43 {
			margin-left: 43px;
		}
		.lg-ml-44 {
			margin-left: 44px;
		}
		.lg-ml-45 {
			margin-left: 45px;
		}
		.lg-ml-46 {
			margin-left: 46px;
		}
		.lg-ml-47 {
			margin-left: 47px;
		}
		.lg-ml-48 {
			margin-left: 48px;
		}
		.lg-ml-49 {
			margin-left: 49px;
		}
		.lg-ml-50 {
			margin-left: 50px;
		}
		.lg-ml-51 {
			margin-left: 51px;
		}
		.lg-ml-52 {
			margin-left: 52px;
		}
		.lg-ml-53 {
			margin-left: 53px;
		}
		.lg-ml-54 {
			margin-left: 54px;
		}
		.lg-ml-55 {
			margin-left: 55px;
		}
		.lg-ml-56 {
			margin-left: 56px;
		}
		.lg-ml-57 {
			margin-left: 57px;
		}
		.lg-ml-58 {
			margin-left: 58px;
		}
		.lg-ml-59 {
			margin-left: 59px;
		}
		.lg-ml-60 {
			margin-left: 60px;
		}
		.lg-ml-61 {
			margin-left: 61px;
		}
		.lg-ml-62 {
			margin-left: 62px;
		}
		.lg-ml-63 {
			margin-left: 63px;
		}
		.lg-ml-64 {
			margin-left: 64px;
		}
		.lg-ml-65 {
			margin-left: 65px;
		}
		.lg-ml-66 {
			margin-left: 66px;
		}
		.lg-ml-67 {
			margin-left: 67px;
		}
		.lg-ml-68 {
			margin-left: 68px;
		}
		.lg-ml-69 {
			margin-left: 69px;
		}
		.lg-ml-70 {
			margin-left: 70px;
		}
		.lg-ml-71 {
			margin-left: 71px;
		}
		.lg-ml-72 {
			margin-left: 72px;
		}
		.lg-ml-73 {
			margin-left: 73px;
		}
		.lg-ml-74 {
			margin-left: 74px;
		}
		.lg-ml-75 {
			margin-left: 75px;
		}
		.lg-ml-76 {
			margin-left: 76px;
		}
		.lg-ml-77 {
			margin-left: 77px;
		}
		.lg-ml-78 {
			margin-left: 78px;
		}
		.lg-ml-79 {
			margin-left: 79px;
		}
		.lg-ml-80 {
			margin-left: 80px;
		}
		.lg-ml-81 {
			margin-left: 81px;
		}
		.lg-ml-82 {
			margin-left: 82px;
		}
		.lg-ml-83 {
			margin-left: 83px;
		}
		.lg-ml-84 {
			margin-left: 84px;
		}
		.lg-ml-85 {
			margin-left: 85px;
		}
		.lg-ml-86 {
			margin-left: 86px;
		}
		.lg-ml-87 {
			margin-left: 87px;
		}
		.lg-ml-88 {
			margin-left: 88px;
		}
		.lg-ml-89 {
			margin-left: 89px;
		}
		.lg-ml-90 {
			margin-left: 90px;
		}
		.lg-ml-91 {
			margin-left: 91px;
		}
		.lg-ml-92 {
			margin-left: 92px;
		}
		.lg-ml-93 {
			margin-left: 93px;
		}
		.lg-ml-94 {
			margin-left: 94px;
		}
		.lg-ml-95 {
			margin-left: 95px;
		}
		.lg-ml-96 {
			margin-left: 96px;
		}
		.lg-ml-97 {
			margin-left: 97px;
		}
		.lg-ml-98 {
			margin-left: 98px;
		}
		.lg-ml-99 {
			margin-left: 99px;
		}
		.lg-ml-100 {
			margin-left: 100px;
		}
	/*}*/

	/*------------------------------------------
		21. LG MARGIN TOP REVERSE
	-------------------------------------------*/
	/*.lg-mt- {*/
		.lg-mt--1 {
			margin-top: -1px;
		}
		.lg-mt--2 {
			margin-top: -2px;
		}
		.lg-mt--3 {
			margin-top: -3px;
		}
		.lg-mt--4 {
			margin-top: -4px;
		}
		.lg-mt--5 {
			margin-top: -5px;
		}
		.lg-mt--6 {
			margin-top: -6px;
		}
		.lg-mt--7 {
			margin-top: -7px;
		}
		.lg-mt--8 {
			margin-top: -8px;
		}
		.lg-mt--9 {
			margin-top: -9px;
		}
		.lg-mt--10 {
			margin-top: -10px;
		}
		.lg-mt--11 {
			margin-top: -11px;
		}
		.lg-mt--12 {
			margin-top: -12px;
		}
		.lg-mt--13 {
			margin-top: -13px;
		}
		.lg-mt--14 {
			margin-top: -14px;
		}
		.lg-mt--15 {
			margin-top: -15px;
		}
		.lg-mt--16 {
			margin-top: -16px;
		}
		.lg-mt--17 {
			margin-top: -17px;
		}
		.lg-mt--18 {
			margin-top: -18px;
		}
		.lg-mt--19 {
			margin-top: -19px;
		}
		.lg-mt--20 {
			margin-top: -20px;
		}
		.lg-mt--21 {
			margin-top: -21px;
		}
		.lg-mt--22 {
			margin-top: -22px;
		}
		.lg-mt--23 {
			margin-top: -23px;
		}
		.lg-mt--24 {
			margin-top: -24px;
		}
		.lg-mt--25 {
			margin-top: -25px;
		}
		.lg-mt--26 {
			margin-top: -26px;
		}
		.lg-mt--27 {
			margin-top: -27px;
		}
		.lg-mt--28 {
			margin-top: -28px;
		}
		.lg-mt--29 {
			margin-top: -29px;
		}
		.lg-mt--30 {
			margin-top: -30px;
		}
		.lg-mt--31 {
			margin-top: -31px;
		}
		.lg-mt--32 {
			margin-top: -32px;
		}
		.lg-mt--33 {
			margin-top: -33px;
		}
		.lg-mt--34 {
			margin-top: -34px;
		}
		.lg-mt--35 {
			margin-top: -35px;
		}
		.lg-mt--36 {
			margin-top: -36px;
		}
		.lg-mt--37 {
			margin-top: -37px;
		}
		.lg-mt--38 {
			margin-top: -38px;
		}
		.lg-mt--39 {
			margin-top: -39px;
		}
		.lg-mt--40 {
			margin-top: -40px;
		}
		.lg-mt--41 {
			margin-top: -41px;
		}
		.lg-mt--42 {
			margin-top: -42px;
		}
		.lg-mt--43 {
			margin-top: -43px;
		}
		.lg-mt--44 {
			margin-top: -44px;
		}
		.lg-mt--45 {
			margin-top: -45px;
		}
		.lg-mt--46 {
			margin-top: -46px;
		}
		.lg-mt--47 {
			margin-top: -47px;
		}
		.lg-mt--48 {
			margin-top: -48px;
		}
		.lg-mt--49 {
			margin-top: -49px;
		}
		.lg-mt--50 {
			margin-top: -50px;
		}
		.lg-mt--51 {
			margin-top: -51px;
		}
		.lg-mt--52 {
			margin-top: -52px;
		}
		.lg-mt--53 {
			margin-top: -53px;
		}
		.lg-mt--54 {
			margin-top: -54px;
		}
		.lg-mt--55 {
			margin-top: -55px;
		}
		.lg-mt--56 {
			margin-top: -56px;
		}
		.lg-mt--57 {
			margin-top: -57px;
		}
		.lg-mt--58 {
			margin-top: -58px;
		}
		.lg-mt--59 {
			margin-top: -59px;
		}
		.lg-mt--60 {
			margin-top: -60px;
		}
		.lg-mt--61 {
			margin-top: -61px;
		}
		.lg-mt--62 {
			margin-top: -62px;
		}
		.lg-mt--63 {
			margin-top: -63px;
		}
		.lg-mt--64 {
			margin-top: -64px;
		}
		.lg-mt--65 {
			margin-top: -65px;
		}
		.lg-mt--66 {
			margin-top: -66px;
		}
		.lg-mt--67 {
			margin-top: -67px;
		}
		.lg-mt--68 {
			margin-top: -68px;
		}
		.lg-mt--69 {
			margin-top: -69px;
		}
		.lg-mt--70 {
			margin-top: -70px;
		}
		.lg-mt--71 {
			margin-top: -71px;
		}
		.lg-mt--72 {
			margin-top: -72px;
		}
		.lg-mt--73 {
			margin-top: -73px;
		}
		.lg-mt--74 {
			margin-top: -74px;
		}
		.lg-mt--75 {
			margin-top: -75px;
		}
		.lg-mt--76 {
			margin-top: -76px;
		}
		.lg-mt--77 {
			margin-top: -77px;
		}
		.lg-mt--78 {
			margin-top: -78px;
		}
		.lg-mt--79 {
			margin-top: -79px;
		}
		.lg-mt--80 {
			margin-top: -80px;
		}
		.lg-mt--81 {
			margin-top: -81px;
		}
		.lg-mt--82 {
			margin-top: -82px;
		}
		.lg-mt--83 {
			margin-top: -83px;
		}
		.lg-mt--84 {
			margin-top: -84px;
		}
		.lg-mt--85 {
			margin-top: -85px;
		}
		.lg-mt--86 {
			margin-top: -86px;
		}
		.lg-mt--87 {
			margin-top: -87px;
		}
		.lg-mt--88 {
			margin-top: -88px;
		}
		.lg-mt--89 {
			margin-top: -89px;
		}
		.lg-mt--90 {
			margin-top: -90px;
		}
		.lg-mt--91 {
			margin-top: -91px;
		}
		.lg-mt--92 {
			margin-top: -92px;
		}
		.lg-mt--93 {
			margin-top: -93px;
		}
		.lg-mt--94 {
			margin-top: -94px;
		}
		.lg-mt--95 {
			margin-top: -95px;
		}
		.lg-mt--96 {
			margin-top: -96px;
		}
		.lg-mt--97 {
			margin-top: -97px;
		}
		.lg-mt--98 {
			margin-top: -98px;
		}
		.lg-mt--99 {
			margin-top: -99px;
		}
		.lg-mt--100 {
			margin-top: -100px;
		}
		.lg-mt--101 {
			margin-top: -101px;
		}
		.lg-mt--102 {
			margin-top: -102px;
		}
		.lg-mt--103 {
			margin-top: -103px;
		}
		.lg-mt--104 {
			margin-top: -104px;
		}
		.lg-mt--105 {
			margin-top: -105px;
		}
		.lg-mt--106 {
			margin-top: -106px;
		}
		.lg-mt--107 {
			margin-top: -107px;
		}
		.lg-mt--108 {
			margin-top: -108px;
		}
		.lg-mt--109 {
			margin-top: -109px;
		}
		.lg-mt--110 {
			margin-top: -110px;
		}
		.lg-mt--111 {
			margin-top: -111px;
		}
		.lg-mt--112 {
			margin-top: -112px;
		}
		.lg-mt--113 {
			margin-top: -113px;
		}
		.lg-mt--114 {
			margin-top: -114px;
		}
		.lg-mt--115 {
			margin-top: -115px;
		}
		.lg-mt--116 {
			margin-top: -116px;
		}
		.lg-mt--117 {
			margin-top: -117px;
		}
		.lg-mt--118 {
			margin-top: -118px;
		}
		.lg-mt--119 {
			margin-top: -119px;
		}
		.lg-mt--120 {
			margin-top: -120px;
		}
		.lg-mt--121 {
			margin-top: -121px;
		}
		.lg-mt--122 {
			margin-top: -122px;
		}
		.lg-mt--123 {
			margin-top: -123px;
		}
		.lg-mt--124 {
			margin-top: -124px;
		}
		.lg-mt--125 {
			margin-top: -125px;
		}
		.lg-mt--126 {
			margin-top: -126px;
		}
		.lg-mt--127 {
			margin-top: -127px;
		}
		.lg-mt--128 {
			margin-top: -128px;
		}
		.lg-mt--129 {
			margin-top: -129px;
		}
		.lg-mt--130 {
			margin-top: -130px;
		}
		.lg-mt--131 {
			margin-top: -131px;
		}
		.lg-mt--132 {
			margin-top: -132px;
		}
		.lg-mt--133 {
			margin-top: -133px;
		}
		.lg-mt--134 {
			margin-top: -134px;
		}
		.lg-mt--135 {
			margin-top: -135px;
		}
		.lg-mt--136 {
			margin-top: -136px;
		}
		.lg-mt--137 {
			margin-top: -137px;
		}
		.lg-mt--138 {
			margin-top: -138px;
		}
		.lg-mt--139 {
			margin-top: -139px;
		}
		.lg-mt--140 {
			margin-top: -140px;
		}
		.lg-mt--141 {
			margin-top: -141px;
		}
		.lg-mt--142 {
			margin-top: -142px;
		}
		.lg-mt--143 {
			margin-top: -143px;
		}
		.lg-mt--144 {
			margin-top: -144px;
		}
		.lg-mt--145 {
			margin-top: -145px;
		}
		.lg-mt--146 {
			margin-top: -146px;
		}
		.lg-mt--147 {
			margin-top: -147px;
		}
		.lg-mt--148 {
			margin-top: -148px;
		}
		.lg-mt--149 {
			margin-top: -149px;
		}
		.lg-mt--150 {
			margin-top: -150px;
		}
		.lg-mt--151 {
			margin-top: -151px;
		}
		.lg-mt--152 {
			margin-top: -152px;
		}
		.lg-mt--153 {
			margin-top: -153px;
		}
		.lg-mt--154 {
			margin-top: -154px;
		}
		.lg-mt--155 {
			margin-top: -155px;
		}
		.lg-mt--156 {
			margin-top: -156px;
		}
		.lg-mt--157 {
			margin-top: -157px;
		}
		.lg-mt--158 {
			margin-top: -158px;
		}
		.lg-mt--159 {
			margin-top: -159px;
		}
		.lg-mt--160 {
			margin-top: -160px;
		}
		.lg-mt--161 {
			margin-top: -161px;
		}
		.lg-mt--162 {
			margin-top: -162px;
		}
		.lg-mt--163 {
			margin-top: -163px;
		}
		.lg-mt--164 {
			margin-top: -164px;
		}
		.lg-mt--165 {
			margin-top: -165px;
		}
		.lg-mt--166 {
			margin-top: -166px;
		}
		.lg-mt--167 {
			margin-top: -167px;
		}
		.lg-mt--168 {
			margin-top: -168px;
		}
		.lg-mt--169 {
			margin-top: -169px;
		}
		.lg-mt--170 {
			margin-top: -170px;
		}
		.lg-mt--171 {
			margin-top: -171px;
		}
		.lg-mt--172 {
			margin-top: -172px;
		}
		.lg-mt--173 {
			margin-top: -173px;
		}
		.lg-mt--174 {
			margin-top: -174px;
		}
		.lg-mt--175 {
			margin-top: -175px;
		}
		.lg-mt--176 {
			margin-top: -176px;
		}
		.lg-mt--177 {
			margin-top: -177px;
		}
		.lg-mt--178 {
			margin-top: -178px;
		}
		.lg-mt--179 {
			margin-top: -179px;
		}
		.lg-mt--180 {
			margin-top: -180px;
		}
		.lg-mt--181 {
			margin-top: -181px;
		}
		.lg-mt--182 {
			margin-top: -182px;
		}
		.lg-mt--183 {
			margin-top: -183px;
		}
		.lg-mt--184 {
			margin-top: -184px;
		}
		.lg-mt--185 {
			margin-top: -185px;
		}
		.lg-mt--186 {
			margin-top: -186px;
		}
		.lg-mt--187 {
			margin-top: -187px;
		}
		.lg-mt--188 {
			margin-top: -188px;
		}
		.lg-mt--189 {
			margin-top: -189px;
		}
		.lg-mt--190 {
			margin-top: -190px;
		}
		.lg-mt--191 {
			margin-top: -191px;
		}
		.lg-mt--192 {
			margin-top: -192px;
		}
		.lg-mt--193 {
			margin-top: -193px;
		}
		.lg-mt--194 {
			margin-top: -194px;
		}
		.lg-mt--195 {
			margin-top: -195px;
		}
		.lg-mt--196 {
			margin-top: -196px;
		}
		.lg-mt--197 {
			margin-top: -197px;
		}
		.lg-mt--198 {
			margin-top: -198px;
		}
		.lg-mt--199 {
			margin-top: -199px;
		}
		.lg-mt--200 {
			margin-top: -200px;
		}
	/*}*/

	/*------------------------------------------
		22. LG MARGIN BOTTOM REVERSE
	-------------------------------------------*/
	/*.lg-mb- {*/
		.lg-mb--1 {
			margin-bottom: -1px;
		}
		.lg-mb--2 {
			margin-bottom: -2px;
		}
		.lg-mb--3 {
			margin-bottom: -3px;
		}
		.lg-mb--4 {
			margin-bottom: -4px;
		}
		.lg-mb--5 {
			margin-bottom: -5px;
		}
		.lg-mb--6 {
			margin-bottom: -6px;
		}
		.lg-mb--7 {
			margin-bottom: -7px;
		}
		.lg-mb--8 {
			margin-bottom: -8px;
		}
		.lg-mb--9 {
			margin-bottom: -9px;
		}
		.lg-mb--10 {
			margin-bottom: -10px;
		}
		.lg-mb--11 {
			margin-bottom: -11px;
		}
		.lg-mb--12 {
			margin-bottom: -12px;
		}
		.lg-mb--13 {
			margin-bottom: -13px;
		}
		.lg-mb--14 {
			margin-bottom: -14px;
		}
		.lg-mb--15 {
			margin-bottom: -15px;
		}
		.lg-mb--16 {
			margin-bottom: -16px;
		}
		.lg-mb--17 {
			margin-bottom: -17px;
		}
		.lg-mb--18 {
			margin-bottom: -18px;
		}
		.lg-mb--19 {
			margin-bottom: -19px;
		}
		.lg-mb--20 {
			margin-bottom: -20px;
		}
		.lg-mb--21 {
			margin-bottom: -21px;
		}
		.lg-mb--22 {
			margin-bottom: -22px;
		}
		.lg-mb--23 {
			margin-bottom: -23px;
		}
		.lg-mb--24 {
			margin-bottom: -24px;
		}
		.lg-mb--25 {
			margin-bottom: -25px;
		}
		.lg-mb--26 {
			margin-bottom: -26px;
		}
		.lg-mb--27 {
			margin-bottom: -27px;
		}
		.lg-mb--28 {
			margin-bottom: -28px;
		}
		.lg-mb--29 {
			margin-bottom: -29px;
		}
		.lg-mb--30 {
			margin-bottom: -30px;
		}
		.lg-mb--31 {
			margin-bottom: -31px;
		}
		.lg-mb--32 {
			margin-bottom: -32px;
		}
		.lg-mb--33 {
			margin-bottom: -33px;
		}
		.lg-mb--34 {
			margin-bottom: -34px;
		}
		.lg-mb--35 {
			margin-bottom: -35px;
		}
		.lg-mb--36 {
			margin-bottom: -36px;
		}
		.lg-mb--37 {
			margin-bottom: -37px;
		}
		.lg-mb--38 {
			margin-bottom: -38px;
		}
		.lg-mb--39 {
			margin-bottom: -39px;
		}
		.lg-mb--40 {
			margin-bottom: -40px;
		}
		.lg-mb--41 {
			margin-bottom: -41px;
		}
		.lg-mb--42 {
			margin-bottom: -42px;
		}
		.lg-mb--43 {
			margin-bottom: -43px;
		}
		.lg-mb--44 {
			margin-bottom: -44px;
		}
		.lg-mb--45 {
			margin-bottom: -45px;
		}
		.lg-mb--46 {
			margin-bottom: -46px;
		}
		.lg-mb--47 {
			margin-bottom: -47px;
		}
		.lg-mb--48 {
			margin-bottom: -48px;
		}
		.lg-mb--49 {
			margin-bottom: -49px;
		}
		.lg-mb--50 {
			margin-bottom: -50px;
		}
		.lg-mb--51 {
			margin-bottom: -51px;
		}
		.lg-mb--52 {
			margin-bottom: -52px;
		}
		.lg-mb--53 {
			margin-bottom: -53px;
		}
		.lg-mb--54 {
			margin-bottom: -54px;
		}
		.lg-mb--55 {
			margin-bottom: -55px;
		}
		.lg-mb--56 {
			margin-bottom: -56px;
		}
		.lg-mb--57 {
			margin-bottom: -57px;
		}
		.lg-mb--58 {
			margin-bottom: -58px;
		}
		.lg-mb--59 {
			margin-bottom: -59px;
		}
		.lg-mb--60 {
			margin-bottom: -60px;
		}
		.lg-mb--61 {
			margin-bottom: -61px;
		}
		.lg-mb--62 {
			margin-bottom: -62px;
		}
		.lg-mb--63 {
			margin-bottom: -63px;
		}
		.lg-mb--64 {
			margin-bottom: -64px;
		}
		.lg-mb--65 {
			margin-bottom: -65px;
		}
		.lg-mb--66 {
			margin-bottom: -66px;
		}
		.lg-mb--67 {
			margin-bottom: -67px;
		}
		.lg-mb--68 {
			margin-bottom: -68px;
		}
		.lg-mb--69 {
			margin-bottom: -69px;
		}
		.lg-mb--70 {
			margin-bottom: -70px;
		}
		.lg-mb--71 {
			margin-bottom: -71px;
		}
		.lg-mb--72 {
			margin-bottom: -72px;
		}
		.lg-mb--73 {
			margin-bottom: -73px;
		}
		.lg-mb--74 {
			margin-bottom: -74px;
		}
		.lg-mb--75 {
			margin-bottom: -75px;
		}
		.lg-mb--76 {
			margin-bottom: -76px;
		}
		.lg-mb--77 {
			margin-bottom: -77px;
		}
		.lg-mb--78 {
			margin-bottom: -78px;
		}
		.lg-mb--79 {
			margin-bottom: -79px;
		}
		.lg-mb--80 {
			margin-bottom: -80px;
		}
		.lg-mb--81 {
			margin-bottom: -81px;
		}
		.lg-mb--82 {
			margin-bottom: -82px;
		}
		.lg-mb--83 {
			margin-bottom: -83px;
		}
		.lg-mb--84 {
			margin-bottom: -84px;
		}
		.lg-mb--85 {
			margin-bottom: -85px;
		}
		.lg-mb--86 {
			margin-bottom: -86px;
		}
		.lg-mb--87 {
			margin-bottom: -87px;
		}
		.lg-mb--88 {
			margin-bottom: -88px;
		}
		.lg-mb--89 {
			margin-bottom: -89px;
		}
		.lg-mb--90 {
			margin-bottom: -90px;
		}
		.lg-mb--91 {
			margin-bottom: -91px;
		}
		.lg-mb--92 {
			margin-bottom: -92px;
		}
		.lg-mb--93 {
			margin-bottom: -93px;
		}
		.lg-mb--94 {
			margin-bottom: -94px;
		}
		.lg-mb--95 {
			margin-bottom: -95px;
		}
		.lg-mb--96 {
			margin-bottom: -96px;
		}
		.lg-mb--97 {
			margin-bottom: -97px;
		}
		.lg-mb--98 {
			margin-bottom: -98px;
		}
		.lg-mb--99 {
			margin-bottom: -99px;
		}
		.lg-mb--100 {
			margin-bottom: -100px;
		}
		.lg-mb--101 {
			margin-bottom: -101px;
		}
		.lg-mb--102 {
			margin-bottom: -102px;
		}
		.lg-mb--103 {
			margin-bottom: -103px;
		}
		.lg-mb--104 {
			margin-bottom: -104px;
		}
		.lg-mb--105 {
			margin-bottom: -105px;
		}
		.lg-mb--106 {
			margin-bottom: -106px;
		}
		.lg-mb--107 {
			margin-bottom: -107px;
		}
		.lg-mb--108 {
			margin-bottom: -108px;
		}
		.lg-mb--109 {
			margin-bottom: -109px;
		}
		.lg-mb--110 {
			margin-bottom: -110px;
		}
		.lg-mb--111 {
			margin-bottom: -111px;
		}
		.lg-mb--112 {
			margin-bottom: -112px;
		}
		.lg-mb--113 {
			margin-bottom: -113px;
		}
		.lg-mb--114 {
			margin-bottom: -114px;
		}
		.lg-mb--115 {
			margin-bottom: -115px;
		}
		.lg-mb--116 {
			margin-bottom: -116px;
		}
		.lg-mb--117 {
			margin-bottom: -117px;
		}
		.lg-mb--118 {
			margin-bottom: -118px;
		}
		.lg-mb--119 {
			margin-bottom: -119px;
		}
		.lg-mb--120 {
			margin-bottom: -120px;
		}
		.lg-mb--121 {
			margin-bottom: -121px;
		}
		.lg-mb--122 {
			margin-bottom: -122px;
		}
		.lg-mb--123 {
			margin-bottom: -123px;
		}
		.lg-mb--124 {
			margin-bottom: -124px;
		}
		.lg-mb--125 {
			margin-bottom: -125px;
		}
		.lg-mb--126 {
			margin-bottom: -126px;
		}
		.lg-mb--127 {
			margin-bottom: -127px;
		}
		.lg-mb--128 {
			margin-bottom: -128px;
		}
		.lg-mb--129 {
			margin-bottom: -129px;
		}
		.lg-mb--130 {
			margin-bottom: -130px;
		}
		.lg-mb--131 {
			margin-bottom: -131px;
		}
		.lg-mb--132 {
			margin-bottom: -132px;
		}
		.lg-mb--133 {
			margin-bottom: -133px;
		}
		.lg-mb--134 {
			margin-bottom: -134px;
		}
		.lg-mb--135 {
			margin-bottom: -135px;
		}
		.lg-mb--136 {
			margin-bottom: -136px;
		}
		.lg-mb--137 {
			margin-bottom: -137px;
		}
		.lg-mb--138 {
			margin-bottom: -138px;
		}
		.lg-mb--139 {
			margin-bottom: -139px;
		}
		.lg-mb--140 {
			margin-bottom: -140px;
		}
		.lg-mb--141 {
			margin-bottom: -141px;
		}
		.lg-mb--142 {
			margin-bottom: -142px;
		}
		.lg-mb--143 {
			margin-bottom: -143px;
		}
		.lg-mb--144 {
			margin-bottom: -144px;
		}
		.lg-mb--145 {
			margin-bottom: -145px;
		}
		.lg-mb--146 {
			margin-bottom: -146px;
		}
		.lg-mb--147 {
			margin-bottom: -147px;
		}
		.lg-mb--148 {
			margin-bottom: -148px;
		}
		.lg-mb--149 {
			margin-bottom: -149px;
		}
		.lg-mb--150 {
			margin-bottom: -150px;
		}
		.lg-mb--151 {
			margin-bottom: -151px;
		}
		.lg-mb--152 {
			margin-bottom: -152px;
		}
		.lg-mb--153 {
			margin-bottom: -153px;
		}
		.lg-mb--154 {
			margin-bottom: -154px;
		}
		.lg-mb--155 {
			margin-bottom: -155px;
		}
		.lg-mb--156 {
			margin-bottom: -156px;
		}
		.lg-mb--157 {
			margin-bottom: -157px;
		}
		.lg-mb--158 {
			margin-bottom: -158px;
		}
		.lg-mb--159 {
			margin-bottom: -159px;
		}
		.lg-mb--160 {
			margin-bottom: -160px;
		}
		.lg-mb--161 {
			margin-bottom: -161px;
		}
		.lg-mb--162 {
			margin-bottom: -162px;
		}
		.lg-mb--163 {
			margin-bottom: -163px;
		}
		.lg-mb--164 {
			margin-bottom: -164px;
		}
		.lg-mb--165 {
			margin-bottom: -165px;
		}
		.lg-mb--166 {
			margin-bottom: -166px;
		}
		.lg-mb--167 {
			margin-bottom: -167px;
		}
		.lg-mb--168 {
			margin-bottom: -168px;
		}
		.lg-mb--169 {
			margin-bottom: -169px;
		}
		.lg-mb--170 {
			margin-bottom: -170px;
		}
		.lg-mb--171 {
			margin-bottom: -171px;
		}
		.lg-mb--172 {
			margin-bottom: -172px;
		}
		.lg-mb--173 {
			margin-bottom: -173px;
		}
		.lg-mb--174 {
			margin-bottom: -174px;
		}
		.lg-mb--175 {
			margin-bottom: -175px;
		}
		.lg-mb--176 {
			margin-bottom: -176px;
		}
		.lg-mb--177 {
			margin-bottom: -177px;
		}
		.lg-mb--178 {
			margin-bottom: -178px;
		}
		.lg-mb--179 {
			margin-bottom: -179px;
		}
		.lg-mb--180 {
			margin-bottom: -180px;
		}
		.lg-mb--181 {
			margin-bottom: -181px;
		}
		.lg-mb--182 {
			margin-bottom: -182px;
		}
		.lg-mb--183 {
			margin-bottom: -183px;
		}
		.lg-mb--184 {
			margin-bottom: -184px;
		}
		.lg-mb--185 {
			margin-bottom: -185px;
		}
		.lg-mb--186 {
			margin-bottom: -186px;
		}
		.lg-mb--187 {
			margin-bottom: -187px;
		}
		.lg-mb--188 {
			margin-bottom: -188px;
		}
		.lg-mb--189 {
			margin-bottom: -189px;
		}
		.lg-mb--190 {
			margin-bottom: -190px;
		}
		.lg-mb--191 {
			margin-bottom: -191px;
		}
		.lg-mb--192 {
			margin-bottom: -192px;
		}
		.lg-mb--193 {
			margin-bottom: -193px;
		}
		.lg-mb--194 {
			margin-bottom: -194px;
		}
		.lg-mb--195 {
			margin-bottom: -195px;
		}
		.lg-mb--196 {
			margin-bottom: -196px;
		}
		.lg-mb--197 {
			margin-bottom: -197px;
		}
		.lg-mb--198 {
			margin-bottom: -198px;
		}
		.lg-mb--199 {
			margin-bottom: -199px;
		}
		.lg-mb--200 {
			margin-bottom: -200px;
		}
	/*}*/

	/*------------------------------------------
		23. LG MARGIN LEFT REVERSE
	-------------------------------------------*/
	/*.lg-ml- {*/
		.lg-ml--1 {
			margin-left: -1px;
		}
		.lg-ml--2 {
			margin-left: -2px;
		}
		.lg-ml--3 {
			margin-left: -3px;
		}
		.lg-ml--4 {
			margin-left: -4px;
		}
		.lg-ml--5 {
			margin-left: -5px;
		}
		.lg-ml--6 {
			margin-left: -6px;
		}
		.lg-ml--7 {
			margin-left: -7px;
		}
		.lg-ml--8 {
			margin-left: -8px;
		}
		.lg-ml--9 {
			margin-left: -9px;
		}
		.lg-ml--10 {
			margin-left: -10px;
		}
		.lg-ml--11 {
			margin-left: -11px;
		}
		.lg-ml--12 {
			margin-left: -12px;
		}
		.lg-ml--13 {
			margin-left: -13px;
		}
		.lg-ml--14 {
			margin-left: -14px;
		}
		.lg-ml--15 {
			margin-left: -15px;
		}
		.lg-ml--16 {
			margin-left: -16px;
		}
		.lg-ml--17 {
			margin-left: -17px;
		}
		.lg-ml--18 {
			margin-left: -18px;
		}
		.lg-ml--19 {
			margin-left: -19px;
		}
		.lg-ml--20 {
			margin-left: -20px;
		}
		.lg-ml--21 {
			margin-left: -21px;
		}
		.lg-ml--22 {
			margin-left: -22px;
		}
		.lg-ml--23 {
			margin-left: -23px;
		}
		.lg-ml--24 {
			margin-left: -24px;
		}
		.lg-ml--25 {
			margin-left: -25px;
		}
		.lg-ml--26 {
			margin-left: -26px;
		}
		.lg-ml--27 {
			margin-left: -27px;
		}
		.lg-ml--28 {
			margin-left: -28px;
		}
		.lg-ml--29 {
			margin-left: -29px;
		}
		.lg-ml--30 {
			margin-left: -30px;
		}
		.lg-ml--31 {
			margin-left: -31px;
		}
		.lg-ml--32 {
			margin-left: -32px;
		}
		.lg-ml--33 {
			margin-left: -33px;
		}
		.lg-ml--34 {
			margin-left: -34px;
		}
		.lg-ml--35 {
			margin-left: -35px;
		}
		.lg-ml--36 {
			margin-left: -36px;
		}
		.lg-ml--37 {
			margin-left: -37px;
		}
		.lg-ml--38 {
			margin-left: -38px;
		}
		.lg-ml--39 {
			margin-left: -39px;
		}
		.lg-ml--40 {
			margin-left: -40px;
		}
		.lg-ml--41 {
			margin-left: -41px;
		}
		.lg-ml--42 {
			margin-left: -42px;
		}
		.lg-ml--43 {
			margin-left: -43px;
		}
		.lg-ml--44 {
			margin-left: -44px;
		}
		.lg-ml--45 {
			margin-left: -45px;
		}
		.lg-ml--46 {
			margin-left: -46px;
		}
		.lg-ml--47 {
			margin-left: -47px;
		}
		.lg-ml--48 {
			margin-left: -48px;
		}
		.lg-ml--49 {
			margin-left: -49px;
		}
		.lg-ml--50 {
			margin-left: -50px;
		}
		.lg-ml--51 {
			margin-left: -51px;
		}
		.lg-ml--52 {
			margin-left: -52px;
		}
		.lg-ml--53 {
			margin-left: -53px;
		}
		.lg-ml--54 {
			margin-left: -54px;
		}
		.lg-ml--55 {
			margin-left: -55px;
		}
		.lg-ml--56 {
			margin-left: -56px;
		}
		.lg-ml--57 {
			margin-left: -57px;
		}
		.lg-ml--58 {
			margin-left: -58px;
		}
		.lg-ml--59 {
			margin-left: -59px;
		}
		.lg-ml--60 {
			margin-left: -60px;
		}
		.lg-ml--61 {
			margin-left: -61px;
		}
		.lg-ml--62 {
			margin-left: -62px;
		}
		.lg-ml--63 {
			margin-left: -63px;
		}
		.lg-ml--64 {
			margin-left: -64px;
		}
		.lg-ml--65 {
			margin-left: -65px;
		}
		.lg-ml--66 {
			margin-left: -66px;
		}
		.lg-ml--67 {
			margin-left: -67px;
		}
		.lg-ml--68 {
			margin-left: -68px;
		}
		.lg-ml--69 {
			margin-left: -69px;
		}
		.lg-ml--70 {
			margin-left: -70px;
		}
		.lg-ml--71 {
			margin-left: -71px;
		}
		.lg-ml--72 {
			margin-left: -72px;
		}
		.lg-ml--73 {
			margin-left: -73px;
		}
		.lg-ml--74 {
			margin-left: -74px;
		}
		.lg-ml--75 {
			margin-left: -75px;
		}
		.lg-ml--76 {
			margin-left: -76px;
		}
		.lg-ml--77 {
			margin-left: -77px;
		}
		.lg-ml--78 {
			margin-left: -78px;
		}
		.lg-ml--79 {
			margin-left: -79px;
		}
		.lg-ml--80 {
			margin-left: -80px;
		}
		.lg-ml--81 {
			margin-left: -81px;
		}
		.lg-ml--82 {
			margin-left: -82px;
		}
		.lg-ml--83 {
			margin-left: -83px;
		}
		.lg-ml--84 {
			margin-left: -84px;
		}
		.lg-ml--85 {
			margin-left: -85px;
		}
		.lg-ml--86 {
			margin-left: -86px;
		}
		.lg-ml--87 {
			margin-left: -87px;
		}
		.lg-ml--88 {
			margin-left: -88px;
		}
		.lg-ml--89 {
			margin-left: -89px;
		}
		.lg-ml--90 {
			margin-left: -90px;
		}
		.lg-ml--91 {
			margin-left: -91px;
		}
		.lg-ml--92 {
			margin-left: -92px;
		}
		.lg-ml--93 {
			margin-left: -93px;
		}
		.lg-ml--94 {
			margin-left: -94px;
		}
		.lg-ml--95 {
			margin-left: -95px;
		}
		.lg-ml--96 {
			margin-left: -96px;
		}
		.lg-ml--97 {
			margin-left: -97px;
		}
		.lg-ml--98 {
			margin-left: -98px;
		}
		.lg-ml--99 {
			margin-left: -99px;
		}
		.lg-ml--100 {
			margin-left: -100px;
		}
		.lg-ml--101 {
			margin-left: -101px;
		}
		.lg-ml--102 {
			margin-left: -102px;
		}
		.lg-ml--103 {
			margin-left: -103px;
		}
		.lg-ml--104 {
			margin-left: -104px;
		}
		.lg-ml--105 {
			margin-left: -105px;
		}
		.lg-ml--106 {
			margin-left: -106px;
		}
		.lg-ml--107 {
			margin-left: -107px;
		}
		.lg-ml--108 {
			margin-left: -108px;
		}
		.lg-ml--109 {
			margin-left: -109px;
		}
		.lg-ml--110 {
			margin-left: -110px;
		}
		.lg-ml--111 {
			margin-left: -111px;
		}
		.lg-ml--112 {
			margin-left: -112px;
		}
		.lg-ml--113 {
			margin-left: -113px;
		}
		.lg-ml--114 {
			margin-left: -114px;
		}
		.lg-ml--115 {
			margin-left: -115px;
		}
		.lg-ml--116 {
			margin-left: -116px;
		}
		.lg-ml--117 {
			margin-left: -117px;
		}
		.lg-ml--118 {
			margin-left: -118px;
		}
		.lg-ml--119 {
			margin-left: -119px;
		}
		.lg-ml--120 {
			margin-left: -120px;
		}
		.lg-ml--121 {
			margin-left: -121px;
		}
		.lg-ml--122 {
			margin-left: -122px;
		}
		.lg-ml--123 {
			margin-left: -123px;
		}
		.lg-ml--124 {
			margin-left: -124px;
		}
		.lg-ml--125 {
			margin-left: -125px;
		}
		.lg-ml--126 {
			margin-left: -126px;
		}
		.lg-ml--127 {
			margin-left: -127px;
		}
		.lg-ml--128 {
			margin-left: -128px;
		}
		.lg-ml--129 {
			margin-left: -129px;
		}
		.lg-ml--130 {
			margin-left: -130px;
		}
		.lg-ml--131 {
			margin-left: -131px;
		}
		.lg-ml--132 {
			margin-left: -132px;
		}
		.lg-ml--133 {
			margin-left: -133px;
		}
		.lg-ml--134 {
			margin-left: -134px;
		}
		.lg-ml--135 {
			margin-left: -135px;
		}
		.lg-ml--136 {
			margin-left: -136px;
		}
		.lg-ml--137 {
			margin-left: -137px;
		}
		.lg-ml--138 {
			margin-left: -138px;
		}
		.lg-ml--139 {
			margin-left: -139px;
		}
		.lg-ml--140 {
			margin-left: -140px;
		}
		.lg-ml--141 {
			margin-left: -141px;
		}
		.lg-ml--142 {
			margin-left: -142px;
		}
		.lg-ml--143 {
			margin-left: -143px;
		}
		.lg-ml--144 {
			margin-left: -144px;
		}
		.lg-ml--145 {
			margin-left: -145px;
		}
		.lg-ml--146 {
			margin-left: -146px;
		}
		.lg-ml--147 {
			margin-left: -147px;
		}
		.lg-ml--148 {
			margin-left: -148px;
		}
		.lg-ml--149 {
			margin-left: -149px;
		}
		.lg-ml--150 {
			margin-left: -150px;
		}
		.lg-ml--151 {
			margin-left: -151px;
		}
		.lg-ml--152 {
			margin-left: -152px;
		}
		.lg-ml--153 {
			margin-left: -153px;
		}
		.lg-ml--154 {
			margin-left: -154px;
		}
		.lg-ml--155 {
			margin-left: -155px;
		}
		.lg-ml--156 {
			margin-left: -156px;
		}
		.lg-ml--157 {
			margin-left: -157px;
		}
		.lg-ml--158 {
			margin-left: -158px;
		}
		.lg-ml--159 {
			margin-left: -159px;
		}
		.lg-ml--160 {
			margin-left: -160px;
		}
		.lg-ml--161 {
			margin-left: -161px;
		}
		.lg-ml--162 {
			margin-left: -162px;
		}
		.lg-ml--163 {
			margin-left: -163px;
		}
		.lg-ml--164 {
			margin-left: -164px;
		}
		.lg-ml--165 {
			margin-left: -165px;
		}
		.lg-ml--166 {
			margin-left: -166px;
		}
		.lg-ml--167 {
			margin-left: -167px;
		}
		.lg-ml--168 {
			margin-left: -168px;
		}
		.lg-ml--169 {
			margin-left: -169px;
		}
		.lg-ml--170 {
			margin-left: -170px;
		}
		.lg-ml--171 {
			margin-left: -171px;
		}
		.lg-ml--172 {
			margin-left: -172px;
		}
		.lg-ml--173 {
			margin-left: -173px;
		}
		.lg-ml--174 {
			margin-left: -174px;
		}
		.lg-ml--175 {
			margin-left: -175px;
		}
		.lg-ml--176 {
			margin-left: -176px;
		}
		.lg-ml--177 {
			margin-left: -177px;
		}
		.lg-ml--178 {
			margin-left: -178px;
		}
		.lg-ml--179 {
			margin-left: -179px;
		}
		.lg-ml--180 {
			margin-left: -180px;
		}
		.lg-ml--181 {
			margin-left: -181px;
		}
		.lg-ml--182 {
			margin-left: -182px;
		}
		.lg-ml--183 {
			margin-left: -183px;
		}
		.lg-ml--184 {
			margin-left: -184px;
		}
		.lg-ml--185 {
			margin-left: -185px;
		}
		.lg-ml--186 {
			margin-left: -186px;
		}
		.lg-ml--187 {
			margin-left: -187px;
		}
		.lg-ml--188 {
			margin-left: -188px;
		}
		.lg-ml--189 {
			margin-left: -189px;
		}
		.lg-ml--190 {
			margin-left: -190px;
		}
		.lg-ml--191 {
			margin-left: -191px;
		}
		.lg-ml--192 {
			margin-left: -192px;
		}
		.lg-ml--193 {
			margin-left: -193px;
		}
		.lg-ml--194 {
			margin-left: -194px;
		}
		.lg-ml--195 {
			margin-left: -195px;
		}
		.lg-ml--196 {
			margin-left: -196px;
		}
		.lg-ml--197 {
			margin-left: -197px;
		}
		.lg-ml--198 {
			margin-left: -198px;
		}
		.lg-ml--199 {
			margin-left: -199px;
		}
		.lg-ml--200 {
			margin-left: -200px;
		}
	/*}*/

	/*------------------------------------------
		24. LG MARGIN RIGHT REVERSE
	-------------------------------------------*/
	/*.lg-mr- {*/
		.lg-mr--1 {
			margin-right: -1px;
		}
		.lg-mr--2 {
			margin-right: -2px;
		}
		.lg-mr--3 {
			margin-right: -3px;
		}
		.lg-mr--4 {
			margin-right: -4px;
		}
		.lg-mr--5 {
			margin-right: -5px;
		}
		.lg-mr--6 {
			margin-right: -6px;
		}
		.lg-mr--7 {
			margin-right: -7px;
		}
		.lg-mr--8 {
			margin-right: -8px;
		}
		.lg-mr--9 {
			margin-right: -9px;
		}
		.lg-mr--10 {
			margin-right: -10px;
		}
		.lg-mr--11 {
			margin-right: -11px;
		}
		.lg-mr--12 {
			margin-right: -12px;
		}
		.lg-mr--13 {
			margin-right: -13px;
		}
		.lg-mr--14 {
			margin-right: -14px;
		}
		.lg-mr--15 {
			margin-right: -15px;
		}
		.lg-mr--16 {
			margin-right: -16px;
		}
		.lg-mr--17 {
			margin-right: -17px;
		}
		.lg-mr--18 {
			margin-right: -18px;
		}
		.lg-mr--19 {
			margin-right: -19px;
		}
		.lg-mr--20 {
			margin-right: -20px;
		}
		.lg-mr--21 {
			margin-right: -21px;
		}
		.lg-mr--22 {
			margin-right: -22px;
		}
		.lg-mr--23 {
			margin-right: -23px;
		}
		.lg-mr--24 {
			margin-right: -24px;
		}
		.lg-mr--25 {
			margin-right: -25px;
		}
		.lg-mr--26 {
			margin-right: -26px;
		}
		.lg-mr--27 {
			margin-right: -27px;
		}
		.lg-mr--28 {
			margin-right: -28px;
		}
		.lg-mr--29 {
			margin-right: -29px;
		}
		.lg-mr--30 {
			margin-right: -30px;
		}
		.lg-mr--31 {
			margin-right: -31px;
		}
		.lg-mr--32 {
			margin-right: -32px;
		}
		.lg-mr--33 {
			margin-right: -33px;
		}
		.lg-mr--34 {
			margin-right: -34px;
		}
		.lg-mr--35 {
			margin-right: -35px;
		}
		.lg-mr--36 {
			margin-right: -36px;
		}
		.lg-mr--37 {
			margin-right: -37px;
		}
		.lg-mr--38 {
			margin-right: -38px;
		}
		.lg-mr--39 {
			margin-right: -39px;
		}
		.lg-mr--40 {
			margin-right: -40px;
		}
		.lg-mr--41 {
			margin-right: -41px;
		}
		.lg-mr--42 {
			margin-right: -42px;
		}
		.lg-mr--43 {
			margin-right: -43px;
		}
		.lg-mr--44 {
			margin-right: -44px;
		}
		.lg-mr--45 {
			margin-right: -45px;
		}
		.lg-mr--46 {
			margin-right: -46px;
		}
		.lg-mr--47 {
			margin-right: -47px;
		}
		.lg-mr--48 {
			margin-right: -48px;
		}
		.lg-mr--49 {
			margin-right: -49px;
		}
		.lg-mr--50 {
			margin-right: -50px;
		}
		.lg-mr--51 {
			margin-right: -51px;
		}
		.lg-mr--52 {
			margin-right: -52px;
		}
		.lg-mr--53 {
			margin-right: -53px;
		}
		.lg-mr--54 {
			margin-right: -54px;
		}
		.lg-mr--55 {
			margin-right: -55px;
		}
		.lg-mr--56 {
			margin-right: -56px;
		}
		.lg-mr--57 {
			margin-right: -57px;
		}
		.lg-mr--58 {
			margin-right: -58px;
		}
		.lg-mr--59 {
			margin-right: -59px;
		}
		.lg-mr--60 {
			margin-right: -60px;
		}
		.lg-mr--61 {
			margin-right: -61px;
		}
		.lg-mr--62 {
			margin-right: -62px;
		}
		.lg-mr--63 {
			margin-right: -63px;
		}
		.lg-mr--64 {
			margin-right: -64px;
		}
		.lg-mr--65 {
			margin-right: -65px;
		}
		.lg-mr--66 {
			margin-right: -66px;
		}
		.lg-mr--67 {
			margin-right: -67px;
		}
		.lg-mr--68 {
			margin-right: -68px;
		}
		.lg-mr--69 {
			margin-right: -69px;
		}
		.lg-mr--70 {
			margin-right: -70px;
		}
		.lg-mr--71 {
			margin-right: -71px;
		}
		.lg-mr--72 {
			margin-right: -72px;
		}
		.lg-mr--73 {
			margin-right: -73px;
		}
		.lg-mr--74 {
			margin-right: -74px;
		}
		.lg-mr--75 {
			margin-right: -75px;
		}
		.lg-mr--76 {
			margin-right: -76px;
		}
		.lg-mr--77 {
			margin-right: -77px;
		}
		.lg-mr--78 {
			margin-right: -78px;
		}
		.lg-mr--79 {
			margin-right: -79px;
		}
		.lg-mr--80 {
			margin-right: -80px;
		}
		.lg-mr--81 {
			margin-right: -81px;
		}
		.lg-mr--82 {
			margin-right: -82px;
		}
		.lg-mr--83 {
			margin-right: -83px;
		}
		.lg-mr--84 {
			margin-right: -84px;
		}
		.lg-mr--85 {
			margin-right: -85px;
		}
		.lg-mr--86 {
			margin-right: -86px;
		}
		.lg-mr--87 {
			margin-right: -87px;
		}
		.lg-mr--88 {
			margin-right: -88px;
		}
		.lg-mr--89 {
			margin-right: -89px;
		}
		.lg-mr--90 {
			margin-right: -90px;
		}
		.lg-mr--91 {
			margin-right: -91px;
		}
		.lg-mr--92 {
			margin-right: -92px;
		}
		.lg-mr--93 {
			margin-right: -93px;
		}
		.lg-mr--94 {
			margin-right: -94px;
		}
		.lg-mr--95 {
			margin-right: -95px;
		}
		.lg-mr--96 {
			margin-right: -96px;
		}
		.lg-mr--97 {
			margin-right: -97px;
		}
		.lg-mr--98 {
			margin-right: -98px;
		}
		.lg-mr--99 {
			margin-right: -99px;
		}
		.lg-mr--100 {
			margin-right: -100px;
		}
		.lg-mr--101 {
			margin-right: -101px;
		}
		.lg-mr--102 {
			margin-right: -102px;
		}
		.lg-mr--103 {
			margin-right: -103px;
		}
		.lg-mr--104 {
			margin-right: -104px;
		}
		.lg-mr--105 {
			margin-right: -105px;
		}
		.lg-mr--106 {
			margin-right: -106px;
		}
		.lg-mr--107 {
			margin-right: -107px;
		}
		.lg-mr--108 {
			margin-right: -108px;
		}
		.lg-mr--109 {
			margin-right: -109px;
		}
		.lg-mr--110 {
			margin-right: -110px;
		}
		.lg-mr--111 {
			margin-right: -111px;
		}
		.lg-mr--112 {
			margin-right: -112px;
		}
		.lg-mr--113 {
			margin-right: -113px;
		}
		.lg-mr--114 {
			margin-right: -114px;
		}
		.lg-mr--115 {
			margin-right: -115px;
		}
		.lg-mr--116 {
			margin-right: -116px;
		}
		.lg-mr--117 {
			margin-right: -117px;
		}
		.lg-mr--118 {
			margin-right: -118px;
		}
		.lg-mr--119 {
			margin-right: -119px;
		}
		.lg-mr--120 {
			margin-right: -120px;
		}
		.lg-mr--121 {
			margin-right: -121px;
		}
		.lg-mr--122 {
			margin-right: -122px;
		}
		.lg-mr--123 {
			margin-right: -123px;
		}
		.lg-mr--124 {
			margin-right: -124px;
		}
		.lg-mr--125 {
			margin-right: -125px;
		}
		.lg-mr--126 {
			margin-right: -126px;
		}
		.lg-mr--127 {
			margin-right: -127px;
		}
		.lg-mr--128 {
			margin-right: -128px;
		}
		.lg-mr--129 {
			margin-right: -129px;
		}
		.lg-mr--130 {
			margin-right: -130px;
		}
		.lg-mr--131 {
			margin-right: -131px;
		}
		.lg-mr--132 {
			margin-right: -132px;
		}
		.lg-mr--133 {
			margin-right: -133px;
		}
		.lg-mr--134 {
			margin-right: -134px;
		}
		.lg-mr--135 {
			margin-right: -135px;
		}
		.lg-mr--136 {
			margin-right: -136px;
		}
		.lg-mr--137 {
			margin-right: -137px;
		}
		.lg-mr--138 {
			margin-right: -138px;
		}
		.lg-mr--139 {
			margin-right: -139px;
		}
		.lg-mr--140 {
			margin-right: -140px;
		}
		.lg-mr--141 {
			margin-right: -141px;
		}
		.lg-mr--142 {
			margin-right: -142px;
		}
		.lg-mr--143 {
			margin-right: -143px;
		}
		.lg-mr--144 {
			margin-right: -144px;
		}
		.lg-mr--145 {
			margin-right: -145px;
		}
		.lg-mr--146 {
			margin-right: -146px;
		}
		.lg-mr--147 {
			margin-right: -147px;
		}
		.lg-mr--148 {
			margin-right: -148px;
		}
		.lg-mr--149 {
			margin-right: -149px;
		}
		.lg-mr--150 {
			margin-right: -150px;
		}
		.lg-mr--151 {
			margin-right: -151px;
		}
		.lg-mr--152 {
			margin-right: -152px;
		}
		.lg-mr--153 {
			margin-right: -153px;
		}
		.lg-mr--154 {
			margin-right: -154px;
		}
		.lg-mr--155 {
			margin-right: -155px;
		}
		.lg-mr--156 {
			margin-right: -156px;
		}
		.lg-mr--157 {
			margin-right: -157px;
		}
		.lg-mr--158 {
			margin-right: -158px;
		}
		.lg-mr--159 {
			margin-right: -159px;
		}
		.lg-mr--160 {
			margin-right: -160px;
		}
		.lg-mr--161 {
			margin-right: -161px;
		}
		.lg-mr--162 {
			margin-right: -162px;
		}
		.lg-mr--163 {
			margin-right: -163px;
		}
		.lg-mr--164 {
			margin-right: -164px;
		}
		.lg-mr--165 {
			margin-right: -165px;
		}
		.lg-mr--166 {
			margin-right: -166px;
		}
		.lg-mr--167 {
			margin-right: -167px;
		}
		.lg-mr--168 {
			margin-right: -168px;
		}
		.lg-mr--169 {
			margin-right: -169px;
		}
		.lg-mr--170 {
			margin-right: -170px;
		}
		.lg-mr--171 {
			margin-right: -171px;
		}
		.lg-mr--172 {
			margin-right: -172px;
		}
		.lg-mr--173 {
			margin-right: -173px;
		}
		.lg-mr--174 {
			margin-right: -174px;
		}
		.lg-mr--175 {
			margin-right: -175px;
		}
		.lg-mr--176 {
			margin-right: -176px;
		}
		.lg-mr--177 {
			margin-right: -177px;
		}
		.lg-mr--178 {
			margin-right: -178px;
		}
		.lg-mr--179 {
			margin-right: -179px;
		}
		.lg-mr--180 {
			margin-right: -180px;
		}
		.lg-mr--181 {
			margin-right: -181px;
		}
		.lg-mr--182 {
			margin-right: -182px;
		}
		.lg-mr--183 {
			margin-right: -183px;
		}
		.lg-mr--184 {
			margin-right: -184px;
		}
		.lg-mr--185 {
			margin-right: -185px;
		}
		.lg-mr--186 {
			margin-right: -186px;
		}
		.lg-mr--187 {
			margin-right: -187px;
		}
		.lg-mr--188 {
			margin-right: -188px;
		}
		.lg-mr--189 {
			margin-right: -189px;
		}
		.lg-mr--190 {
			margin-right: -190px;
		}
		.lg-mr--191 {
			margin-right: -191px;
		}
		.lg-mr--192 {
			margin-right: -192px;
		}
		.lg-mr--193 {
			margin-right: -193px;
		}
		.lg-mr--194 {
			margin-right: -194px;
		}
		.lg-mr--195 {
			margin-right: -195px;
		}
		.lg-mr--196 {
			margin-right: -196px;
		}
		.lg-mr--197 {
			margin-right: -197px;
		}
		.lg-mr--198 {
			margin-right: -198px;
		}
		.lg-mr--199 {
			margin-right: -199px;
		}
		.lg-mr--200 {
			margin-right: -200px;
		}
	/*}*/
}

@media only screen and (max-width: 991px){

	.md-padding-0 {
		padding: 0;
	}
	.md-margin-0 {
		margin: 0;
	}

	/*------------------------------------------
		25. MD PADDING TOP
	-------------------------------------------*/
	/*.md-pt {*/
		.md-pt-0 {
			padding-top: 0px;
		}
		.md-pt-1 {
			padding-top: 1px;
		}
		.md-pt-2 {
			padding-top: 2px;
		}
		.md-pt-3 {
			padding-top: 3px;
		}
		.md-pt-4 {
			padding-top: 4px;
		}
		.md-pt-5 {
			padding-top: 5px;
		}
		.md-pt-6 {
			padding-top: 6px!important;
		}
		.md-pt-7 {
			padding-top: 7px;
		}
		.md-pt-8 {
			padding-top: 8px;
		}
		.md-pt-9 {
			padding-top: 9px;
		}
		.md-pt-10 {
			padding-top: 10px;
		}
		.md-pt-11 {
			padding-top: 11px;
		}
		.md-pt-12 {
			padding-top: 12px;
		}
		.md-pt-13 {
			padding-top: 13px;
		}
		.md-pt-14 {
			padding-top: 14px;
		}
		.md-pt-15 {
			padding-top: 15px;
		}
		.md-pt-16 {
			padding-top: 16px;
		}
		.md-pt-17 {
			padding-top: 17px;
		}
		.md-pt-18 {
			padding-top: 18px;
		}
		.md-pt-19 {
			padding-top: 19px;
		}
		.md-pt-20 {
			padding-top: 20px!important;
		}
		.md-pt-21 {
			padding-top: 21px;
		}
		.md-pt-22 {
			padding-top: 22px;
		}
		.md-pt-23 {
			padding-top: 23px;
		}
		.md-pt-24 {
			padding-top: 24px;
		}
		.md-pt-25 {
			padding-top: 25px;
		}
		.md-pt-26 {
			padding-top: 26px;
		}
		.md-pt-27 {
			padding-top: 27px;
		}
		.md-pt-28 {
			padding-top: 28px;
		}
		.md-pt-29 {
			padding-top: 29px;
		}
		.md-pt-30 {
			padding-top: 30px!important;
		}
		.md-pt-31 {
			padding-top: 31px;
		}
		.md-pt-32 {
			padding-top: 32px;
		}
		.md-pt-33 {
			padding-top: 33px;
		}
		.md-pt-34 {
			padding-top: 34px;
		}
		.md-pt-35 {
			padding-top: 35px;
		}
		.md-pt-36 {
			padding-top: 36px;
		}
		.md-pt-37 {
			padding-top: 37px;
		}
		.md-pt-38 {
			padding-top: 38px;
		}
		.md-pt-39 {
			padding-top: 39px;
		}
		.md-pt-40 {
			padding-top: 40px;
		}
		.md-pt-41 {
			padding-top: 41px;
		}
		.md-pt-42 {
			padding-top: 42px;
		}
		.md-pt-43 {
			padding-top: 43px;
		}
		.md-pt-44 {
			padding-top: 44px;
		}
		.md-pt-45 {
			padding-top: 45px;
		}
		.md-pt-46 {
			padding-top: 46px;
		}
		.md-pt-47 {
			padding-top: 47px;
		}
		.md-pt-48 {
			padding-top: 48px;
		}
		.md-pt-49 {
			padding-top: 49px;
		}
		.md-pt-50 {
			padding-top: 50px;
		}
		.md-pt-51 {
			padding-top: 51px;
		}
		.md-pt-52 {
			padding-top: 52px;
		}
		.md-pt-53 {
			padding-top: 53px;
		}
		.md-pt-54 {
			padding-top: 54px;
		}
		.md-pt-55 {
			padding-top: 55px;
		}
		.md-pt-56 {
			padding-top: 56px;
		}
		.md-pt-57 {
			padding-top: 57px;
		}
		.md-pt-58 {
			padding-top: 58px;
		}
		.md-pt-59 {
			padding-top: 59px;
		}
		.md-pt-60 {
			padding-top: 60px;
		}
		.md-pt-61 {
			padding-top: 61px;
		}
		.md-pt-62 {
			padding-top: 62px;
		}
		.md-pt-63 {
			padding-top: 63px;
		}
		.md-pt-64 {
			padding-top: 64px;
		}
		.md-pt-65 {
			padding-top: 65px;
		}
		.md-pt-66 {
			padding-top: 66px;
		}
		.md-pt-67 {
			padding-top: 67px;
		}
		.md-pt-68 {
			padding-top: 68px;
		}
		.md-pt-69 {
			padding-top: 69px;
		}
		.md-pt-70 {
			padding-top: 70px;
		}
		.md-pt-71 {
			padding-top: 71px;
		}
		.md-pt-72 {
			padding-top: 72px;
		}
		.md-pt-73 {
			padding-top: 73px;
		}
		.md-pt-74 {
			padding-top: 74px;
		}
		.md-pt-75 {
			padding-top: 75px;
		}
		.md-pt-76 {
			padding-top: 76px;
		}
		.md-pt-77 {
			padding-top: 77px;
		}
		.md-pt-78 {
			padding-top: 78px;
		}
		.md-pt-79 {
			padding-top: 79px;
		}
		.md-pt-80 {
			padding-top: 80px;
		}
		.md-pt-81 {
			padding-top: 81px;
		}
		.md-pt-82 {
			padding-top: 82px;
		}
		.md-pt-83 {
			padding-top: 83px;
		}
		.md-pt-84 {
			padding-top: 84px;
		}
		.md-pt-85 {
			padding-top: 85px;
		}
		.md-pt-86 {
			padding-top: 86px;
		}
		.md-pt-87 {
			padding-top: 87px;
		}
		.md-pt-88 {
			padding-top: 88px;
		}
		.md-pt-89 {
			padding-top: 89px;
		}
		.md-pt-90 {
			padding-top: 90px;
		}
		.md-pt-91 {
			padding-top: 91px;
		}
		.md-pt-92 {
			padding-top: 92px;
		}
		.md-pt-93 {
			padding-top: 93px;
		}
		.md-pt-94 {
			padding-top: 94px;
		}
		.md-pt-95 {
			padding-top: 95px;
		}
		.md-pt-96 {
			padding-top: 96px;
		}
		.md-pt-97 {
			padding-top: 97px;
		}
		.md-pt-98 {
			padding-top: 98px;
		}
		.md-pt-99 {
			padding-top: 99px;
		}
		.md-pt-100 {
			padding-top: 100px;
		}

		.md-pt-101 {
			padding-top: 101px;
		}
		.md-pt-102 {
			padding-top: 102px;
		}
		.md-pt-103 {
			padding-top: 103px;
		}
		.md-pt-104 {
			padding-top: 104px;
		}
		.md-pt-105 {
			padding-top: 105px;
		}
		.md-pt-106 {
			padding-top: 106px;
		}
		.md-pt-107 {
			padding-top: 107px;
		}
		.md-pt-108 {
			padding-top: 108px;
		}
		.md-pt-109 {
			padding-top: 109px;
		}
		.md-pt-110 {
			padding-top: 110px;
		}
		.md-pt-111 {
			padding-top: 111px;
		}
		.md-pt-112 {
			padding-top: 112px;
		}
		.md-pt-113 {
			padding-top: 113px;
		}
		.md-pt-114 {
			padding-top: 114px;
		}
		.md-pt-115 {
			padding-top: 115px;
		}
		.md-pt-116 {
			padding-top: 116px;
		}
		.md-pt-117 {
			padding-top: 117px;
		}
		.md-pt-118 {
			padding-top: 118px;
		}
		.md-pt-119 {
			padding-top: 119px;
		}
		.md-pt-120 {
			padding-top: 120px;
		}
		.md-pt-121 {
			padding-top: 121px;
		}
		.md-pt-122 {
			padding-top: 122px;
		}
		.md-pt-123 {
			padding-top: 123px;
		}
		.md-pt-124 {
			padding-top: 124px;
		}
		.md-pt-125 {
			padding-top: 125px;
		}
		.md-pt-126 {
			padding-top: 126px;
		}
		.md-pt-127 {
			padding-top: 127px;
		}
		.md-pt-128 {
			padding-top: 128px;
		}
		.md-pt-129 {
			padding-top: 129px;
		}
		.md-pt-130 {
			padding-top: 130px;
		}
		.md-pt-131 {
			padding-top: 131px;
		}
		.md-pt-132 {
			padding-top: 132px;
		}
		.md-pt-133 {
			padding-top: 133px;
		}
		.md-pt-134 {
			padding-top: 134px;
		}
		.md-pt-135 {
			padding-top: 135px;
		}
		.md-pt-136 {
			padding-top: 136px;
		}
		.md-pt-137 {
			padding-top: 137px;
		}
		.md-pt-138 {
			padding-top: 138px;
		}
		.md-pt-139 {
			padding-top: 139px;
		}
		.md-pt-140 {
			padding-top: 140px;
		}
		.md-pt-141 {
			padding-top: 141px;
		}
		.md-pt-142 {
			padding-top: 142px;
		}
		.md-pt-143 {
			padding-top: 143px;
		}
		.md-pt-144 {
			padding-top: 144px;
		}
		.md-pt-145 {
			padding-top: 145px;
		}
		.md-pt-146 {
			padding-top: 146px;
		}
		.md-pt-147 {
			padding-top: 147px;
		}
		.md-pt-148 {
			padding-top: 148px;
		}
		.md-pt-149 {
			padding-top: 149px;
		}
		.md-pt-150 {
			padding-top: 150px;
		}
		.md-pt-151 {
			padding-top: 151px;
		}
		.md-pt-152 {
			padding-top: 152px;
		}
		.md-pt-153 {
			padding-top: 153px;
		}
		.md-pt-154 {
			padding-top: 154px;
		}
		.md-pt-155 {
			padding-top: 155px;
		}
		.md-pt-156 {
			padding-top: 156px;
		}
		.md-pt-157 {
			padding-top: 157px;
		}
		.md-pt-158 {
			padding-top: 158px;
		}
		.md-pt-159 {
			padding-top: 159px;
		}
		.md-pt-160 {
			padding-top: 160px;
		}
		.md-pt-161 {
			padding-top: 161px;
		}
		.md-pt-162 {
			padding-top: 162px;
		}
		.md-pt-163 {
			padding-top: 163px;
		}
		.md-pt-164 {
			padding-top: 164px;
		}
		.md-pt-165 {
			padding-top: 165px;
		}
		.md-pt-166 {
			padding-top: 166px;
		}
		.md-pt-167 {
			padding-top: 167px;
		}
		.md-pt-168 {
			padding-top: 168px;
		}
		.md-pt-169 {
			padding-top: 169px;
		}
		.md-pt-170 {
			padding-top: 170px;
		}
		.md-pt-171 {
			padding-top: 171px;
		}
		.md-pt-172 {
			padding-top: 172px;
		}
		.md-pt-173 {
			padding-top: 173px;
		}
		.md-pt-174 {
			padding-top: 174px;
		}
		.md-pt-175 {
			padding-top: 175px;
		}
		.md-pt-176 {
			padding-top: 176px;
		}
		.md-pt-177 {
			padding-top: 177px;
		}
		.md-pt-178 {
			padding-top: 178px;
		}
		.md-pt-179 {
			padding-top: 179px;
		}
		.md-pt-180 {
			padding-top: 180px;
		}
		.md-pt-181 {
			padding-top: 181px;
		}
		.md-pt-182 {
			padding-top: 182px;
		}
		.md-pt-183 {
			padding-top: 183px;
		}
		.md-pt-184 {
			padding-top: 184px;
		}
		.md-pt-185 {
			padding-top: 185px;
		}
		.md-pt-186 {
			padding-top: 186px;
		}
		.md-pt-187 {
			padding-top: 187px;
		}
		.md-pt-188 {
			padding-top: 188px;
		}
		.md-pt-189 {
			padding-top: 189px;
		}
		.md-pt-190 {
			padding-top: 190px;
		}
		.md-pt-191 {
			padding-top: 191px;
		}
		.md-pt-192 {
			padding-top: 192px;
		}
		.md-pt-193 {
			padding-top: 193px;
		}
		.md-pt-194 {
			padding-top: 194px;
		}
		.md-pt-195 {
			padding-top: 195px;
		}
		.md-pt-196 {
			padding-top: 196px;
		}
		.md-pt-197 {
			padding-top: 197px;
		}
		.md-pt-198 {
			padding-top: 198px;
		}
		.md-pt-199 {
			padding-top: 199px;
		}
		.md-pt-200 {
			padding-top: 200px;
		}
	/*}*/

	/*------------------------------------------
		26. MD PADDING BOTTOM
	-------------------------------------------*/
	/*.md-pb {*/
		.md-pb-0 {
			padding-bottom: 0px;
		}
		.md-pb-1 {
			padding-bottom: 1px;
		}
		.md-pb-2 {
			padding-bottom: 2px;
		}
		.md-pb-3 {
			padding-bottom: 3px;
		}
		.md-pb-4 {
			padding-bottom: 4px;
		}
		.md-pb-5 {
			padding-bottom: 5px;
		}
		.md-pb-6 {
			padding-bottom: 6px;
		}
		.md-pb-7 {
			padding-bottom: 7px;
		}
		.md-pb-8 {
			padding-bottom: 8px;
		}
		.md-pb-9 {
			padding-bottom: 9px;
		}
		.md-pb-10 {
			padding-bottom: 10px;
		}
		.md-pb-11 {
			padding-bottom: 11px;
		}
		.md-pb-12 {
			padding-bottom: 12px;
		}
		.md-pb-13 {
			padding-bottom: 13px;
		}
		.md-pb-14 {
			padding-bottom: 14px;
		}
		.md-pb-15 {
			padding-bottom: 15px;
		}
		.md-pb-16 {
			padding-bottom: 16px;
		}
		.md-pb-17 {
			padding-bottom: 17px;
		}
		.md-pb-18 {
			padding-bottom: 18px;
		}
		.md-pb-19 {
			padding-bottom: 19px;
		}
		.md-pb-20 {
			padding-bottom: 20px!important;
		}
		.md-pb-21 {
			padding-bottom: 21px;
		}
		.md-pb-22 {
			padding-bottom: 22px;
		}
		.md-pb-23 {
			padding-bottom: 23px;
		}
		.md-pb-24 {
			padding-bottom: 24px;
		}
		.md-pb-25 {
			padding-bottom: 25px;
		}
		.md-pb-26 {
			padding-bottom: 26px;
		}
		.md-pb-27 {
			padding-bottom: 27px;
		}
		.md-pb-28 {
			padding-bottom: 28px;
		}
		.md-pb-29 {
			padding-bottom: 29px;
		}
		.md-pb-30 {
			padding-bottom: 30px;
		}
		.md-pb-31 {
			padding-bottom: 31px;
		}
		.md-pb-32 {
			padding-bottom: 32px;
		}
		.md-pb-33 {
			padding-bottom: 33px;
		}
		.md-pb-34 {
			padding-bottom: 34px;
		}
		.md-pb-35 {
			padding-bottom: 35px;
		}
		.md-pb-36 {
			padding-bottom: 36px;
		}
		.md-pb-37 {
			padding-bottom: 37px;
		}
		.md-pb-38 {
			padding-bottom: 38px;
		}
		.md-pb-39 {
			padding-bottom: 39px;
		}
		.md-pb-40 {
			padding-bottom: 40px;
		}
		.md-pb-41 {
			padding-bottom: 41px;
		}
		.md-pb-42 {
			padding-bottom: 42px;
		}
		.md-pb-43 {
			padding-bottom: 43px;
		}
		.md-pb-44 {
			padding-bottom: 44px;
		}
		.md-pb-45 {
			padding-bottom: 45px;
		}
		.md-pb-46 {
			padding-bottom: 46px;
		}
		.md-pb-47 {
			padding-bottom: 47px;
		}
		.md-pb-48 {
			padding-bottom: 48px;
		}
		.md-pb-49 {
			padding-bottom: 49px;
		}
		.md-pb-50 {
			padding-bottom: 50px;
		}
		.md-pb-51 {
			padding-bottom: 51px;
		}
		.md-pb-52 {
			padding-bottom: 52px;
		}
		.md-pb-53 {
			padding-bottom: 53px;
		}
		.md-pb-54 {
			padding-bottom: 54px;
		}
		.md-pb-55 {
			padding-bottom: 55px;
		}
		.md-pb-56 {
			padding-bottom: 56px;
		}
		.md-pb-57 {
			padding-bottom: 57px;
		}
		.md-pb-58 {
			padding-bottom: 58px;
		}
		.md-pb-59 {
			padding-bottom: 59px;
		}
		.md-pb-60 {
			padding-bottom: 60px;
		}
		.md-pb-61 {
			padding-bottom: 61px;
		}
		.md-pb-62 {
			padding-bottom: 62px;
		}
		.md-pb-63 {
			padding-bottom: 63px;
		}
		.md-pb-64 {
			padding-bottom: 64px;
		}
		.md-pb-65 {
			padding-bottom: 65px;
		}
		.md-pb-66 {
			padding-bottom: 66px;
		}
		.md-pb-67 {
			padding-bottom: 67px;
		}
		.md-pb-68 {
			padding-bottom: 68px;
		}
		.md-pb-69 {
			padding-bottom: 69px;
		}
		.md-pb-70 {
			padding-bottom: 70px;
		}
		.md-pb-71 {
			padding-bottom: 71px;
		}
		.md-pb-72 {
			padding-bottom: 72px;
		}
		.md-pb-73 {
			padding-bottom: 73px;
		}
		.md-pb-74 {
			padding-bottom: 74px;
		}
		.md-pb-75 {
			padding-bottom: 75px;
		}
		.md-pb-76 {
			padding-bottom: 76px;
		}
		.md-pb-77 {
			padding-bottom: 77px;
		}
		.md-pb-78 {
			padding-bottom: 78px;
		}
		.md-pb-79 {
			padding-bottom: 79px;
		}
		.md-pb-80 {
			padding-bottom: 80px;
		}
		.md-pb-81 {
			padding-bottom: 81px;
		}
		.md-pb-82 {
			padding-bottom: 82px;
		}
		.md-pb-83 {
			padding-bottom: 83px;
		}
		.md-pb-84 {
			padding-bottom: 84px;
		}
		.md-pb-85 {
			padding-bottom: 85px;
		}
		.md-pb-86 {
			padding-bottom: 86px;
		}
		.md-pb-87 {
			padding-bottom: 87px;
		}
		.md-pb-88 {
			padding-bottom: 88px;
		}
		.md-pb-89 {
			padding-bottom: 89px;
		}
		.md-pb-90 {
			padding-bottom: 90px;
		}
		.md-pb-91 {
			padding-bottom: 91px;
		}
		.md-pb-92 {
			padding-bottom: 92px;
		}
		.md-pb-93 {
			padding-bottom: 93px;
		}
		.md-pb-94 {
			padding-bottom: 94px;
		}
		.md-pb-95 {
			padding-bottom: 95px;
		}
		.md-pb-96 {
			padding-bottom: 96px;
		}
		.md-pb-97 {
			padding-bottom: 97px;
		}
		.md-pb-98 {
			padding-bottom: 98px;
		}
		.md-pb-99 {
			padding-bottom: 99px;
		}
		.md-pb-100 {
			padding-bottom: 100px;
		}

		.md-pb-101 {
			padding-bottom: 101px;
		}
		.md-pb-102 {
			padding-bottom: 102px;
		}
		.md-pb-103 {
			padding-bottom: 103px;
		}
		.md-pb-104 {
			padding-bottom: 104px;
		}
		.md-pb-105 {
			padding-bottom: 105px;
		}
		.md-pb-106 {
			padding-bottom: 106px;
		}
		.md-pb-107 {
			padding-bottom: 107px;
		}
		.md-pb-108 {
			padding-bottom: 108px;
		}
		.md-pb-109 {
			padding-bottom: 109px;
		}
		.md-pb-110 {
			padding-bottom: 110px;
		}
		.md-pb-111 {
			padding-bottom: 111px;
		}
		.md-pb-112 {
			padding-bottom: 112px;
		}
		.md-pb-113 {
			padding-bottom: 113px;
		}
		.md-pb-114 {
			padding-bottom: 114px;
		}
		.md-pb-115 {
			padding-bottom: 115px;
		}
		.md-pb-116 {
			padding-bottom: 116px;
		}
		.md-pb-117 {
			padding-bottom: 117px;
		}
		.md-pb-118 {
			padding-bottom: 118px;
		}
		.md-pb-119 {
			padding-bottom: 119px;
		}
		.md-pb-120 {
			padding-bottom: 120px;
		}
		.md-pb-121 {
			padding-bottom: 121px;
		}
		.md-pb-122 {
			padding-bottom: 122px;
		}
		.md-pb-123 {
			padding-bottom: 123px;
		}
		.md-pb-124 {
			padding-bottom: 124px;
		}
		.md-pb-125 {
			padding-bottom: 125px;
		}
		.md-pb-126 {
			padding-bottom: 126px;
		}
		.md-pb-127 {
			padding-bottom: 127px;
		}
		.md-pb-128 {
			padding-bottom: 128px;
		}
		.md-pb-129 {
			padding-bottom: 129px;
		}
		.md-pb-130 {
			padding-bottom: 130px;
		}
		.md-pb-131 {
			padding-bottom: 131px;
		}
		.md-pb-132 {
			padding-bottom: 132px;
		}
		.md-pb-133 {
			padding-bottom: 133px;
		}
		.md-pb-134 {
			padding-bottom: 134px;
		}
		.md-pb-135 {
			padding-bottom: 135px;
		}
		.md-pb-136 {
			padding-bottom: 136px;
		}
		.md-pb-137 {
			padding-bottom: 137px;
		}
		.md-pb-138 {
			padding-bottom: 138px;
		}
		.md-pb-139 {
			padding-bottom: 139px;
		}
		.md-pb-140 {
			padding-bottom: 140px;
		}
		.md-pb-141 {
			padding-bottom: 141px;
		}
		.md-pb-142 {
			padding-bottom: 142px;
		}
		.md-pb-143 {
			padding-bottom: 143px;
		}
		.md-pb-144 {
			padding-bottom: 144px;
		}
		.md-pb-145 {
			padding-bottom: 145px;
		}
		.md-pb-146 {
			padding-bottom: 146px;
		}
		.md-pb-147 {
			padding-bottom: 147px;
		}
		.md-pb-148 {
			padding-bottom: 148px;
		}
		.md-pb-149 {
			padding-bottom: 149px;
		}
		.md-pb-150 {
			padding-bottom: 150px;
		}
		.md-pb-151 {
			padding-bottom: 151px;
		}
		.md-pb-152 {
			padding-bottom: 152px;
		}
		.md-pb-153 {
			padding-bottom: 153px;
		}
		.md-pb-154 {
			padding-bottom: 154px;
		}
		.md-pb-155 {
			padding-bottom: 155px;
		}
		.md-pb-156 {
			padding-bottom: 156px;
		}
		.md-pb-157 {
			padding-bottom: 157px;
		}
		.md-pb-158 {
			padding-bottom: 158px;
		}
		.md-pb-159 {
			padding-bottom: 159px;
		}
		.md-pb-160 {
			padding-bottom: 160px;
		}
		.md-pb-161 {
			padding-bottom: 161px;
		}
		.md-pb-162 {
			padding-bottom: 162px;
		}
		.md-pb-163 {
			padding-bottom: 163px;
		}
		.md-pb-164 {
			padding-bottom: 164px;
		}
		.md-pb-165 {
			padding-bottom: 165px;
		}
		.md-pb-166 {
			padding-bottom: 166px;
		}
		.md-pb-167 {
			padding-bottom: 167px;
		}
		.md-pb-168 {
			padding-bottom: 168px;
		}
		.md-pb-169 {
			padding-bottom: 169px;
		}
		.md-pb-170 {
			padding-bottom: 170px;
		}
		.md-pb-171 {
			padding-bottom: 171px;
		}
		.md-pb-172 {
			padding-bottom: 172px;
		}
		.md-pb-173 {
			padding-bottom: 173px;
		}
		.md-pb-174 {
			padding-bottom: 174px;
		}
		.md-pb-175 {
			padding-bottom: 175px;
		}
		.md-pb-176 {
			padding-bottom: 176px;
		}
		.md-pb-177 {
			padding-bottom: 177px;
		}
		.md-pb-178 {
			padding-bottom: 178px;
		}
		.md-pb-179 {
			padding-bottom: 179px;
		}
		.md-pb-180 {
			padding-bottom: 180px;
		}
		.md-pb-181 {
			padding-bottom: 181px;
		}
		.md-pb-182 {
			padding-bottom: 182px;
		}
		.md-pb-183 {
			padding-bottom: 183px;
		}
		.md-pb-184 {
			padding-bottom: 184px;
		}
		.md-pb-185 {
			padding-bottom: 185px;
		}
		.md-pb-186 {
			padding-bottom: 186px;
		}
		.md-pb-187 {
			padding-bottom: 187px;
		}
		.md-pb-188 {
			padding-bottom: 188px;
		}
		.md-pb-189 {
			padding-bottom: 189px;
		}
		.md-pb-190 {
			padding-bottom: 190px;
		}
		.md-pb-191 {
			padding-bottom: 191px;
		}
		.md-pb-192 {
			padding-bottom: 192px;
		}
		.md-pb-193 {
			padding-bottom: 193px;
		}
		.md-pb-194 {
			padding-bottom: 194px;
		}
		.md-pb-195 {
			padding-bottom: 195px;
		}
		.md-pb-196 {
			padding-bottom: 196px;
		}
		.md-pb-197 {
			padding-bottom: 197px;
		}
		.md-pb-198 {
			padding-bottom: 198px;
		}
		.md-pb-199 {
			padding-bottom: 199px;
		}
		.md-pb-200 {
			padding-bottom: 200px;
		}
	/*}*/

	/*------------------------------------------
		27. MD PADDING RIGHT
	-------------------------------------------*/
	/*.md-pr {*/
		.md-pr-0 {
			padding-right: 0px;
		}
		.md-pr-1 {
			padding-right: 1px;
		}
		.md-pr-2 {
			padding-right: 2px;
		}
		.pb-3 {
			padding-bottom: 3px;
		}
		.md-pr-4 {
			padding-right: 4px;
		}
		.md-pr-5 {
			padding-right: 5px;
		}
		.md-pr-6 {
			padding-right: 6px;
		}
		.md-pr-7 {
			padding-right: 7px;
		}
		.md-pr-8 {
			padding-right: 8px;
		}
		.md-pr-9 {
			padding-right: 9px;
		}
		.md-pr-10 {
			padding-right: 10px;
		}
		.md-pr-11 {
			padding-right: 11px;
		}
		.md-pr-12 {
			padding-right: 12px;
		}
		.md-pr-13 {
			padding-right: 13px;
		}
		.md-pr-14 {
			padding-right: 14px;
		}
		.md-pr-15 {
			padding-right: 15px;
		}
		.md-pr-16 {
			padding-right: 16px;
		}
		.md-pr-17 {
			padding-right: 17px;
		}
		.md-pr-18 {
			padding-right: 18px;
		}
		.md-pr-19 {
			padding-right: 19px;
		}
		.md-pr-20 {
			padding-right: 20px;
		}
		.md-pr-21 {
			padding-right: 21px;
		}
		.md-pr-22 {
			padding-right: 22px;
		}
		.md-pr-23 {
			padding-right: 23px;
		}
		.md-pr-24 {
			padding-right: 24px;
		}
		.md-pr-25 {
			padding-right: 25px;
		}
		.md-pr-26 {
			padding-right: 26px;
		}
		.md-pr-27 {
			padding-right: 27px;
		}
		.md-pr-28 {
			padding-right: 28px;
		}
		.md-pr-29 {
			padding-right: 29px;
		}
		.md-pr-30 {
			padding-right: 30px;
		}
		.md-pr-31 {
			padding-right: 31px;
		}
		.md-pr-32 {
			padding-right: 32px;
		}
		.md-pr-33 {
			padding-right: 33px;
		}
		.md-pr-34 {
			padding-right: 34px;
		}
		.md-pr-35 {
			padding-right: 35px;
		}
		.md-pr-36 {
			padding-right: 36px;
		}
		.md-pr-37 {
			padding-right: 37px;
		}
		.md-pr-38 {
			padding-right: 38px;
		}
		.md-pr-39 {
			padding-right: 39px;
		}
		.md-pr-40 {
			padding-right: 40px;
		}
		.md-pr-41 {
			padding-right: 41px;
		}
		.md-pr-42 {
			padding-right: 42px;
		}
		.md-pr-43 {
			padding-right: 43px;
		}
		.md-pr-44 {
			padding-right: 44px;
		}
		.md-pr-45 {
			padding-right: 45px;
		}
		.md-pr-46 {
			padding-right: 46px;
		}
		.md-pr-47 {
			padding-right: 47px;
		}
		.md-pr-48 {
			padding-right: 48px;
		}
		.md-pr-49 {
			padding-right: 49px;
		}
		.md-pr-50 {
			padding-right: 50px;
		}
		.md-pr-51 {
			padding-right: 51px;
		}
		.md-pr-52 {
			padding-right: 52px;
		}
		.md-pr-53 {
			padding-right: 53px;
		}
		.md-pr-54 {
			padding-right: 54px;
		}
		.md-pr-55 {
			padding-right: 55px;
		}
		.md-pr-56 {
			padding-right: 56px;
		}
		.md-pr-57 {
			padding-right: 57px;
		}
		.md-pr-58 {
			padding-right: 58px;
		}
		.md-pr-59 {
			padding-right: 59px;
		}
		.md-pr-60 {
			padding-right: 60px;
		}
		.md-pr-61 {
			padding-right: 61px;
		}
		.md-pr-62 {
			padding-right: 62px;
		}
		.md-pr-63 {
			padding-right: 63px;
		}
		.md-pr-64 {
			padding-right: 64px;
		}
		.md-pr-65 {
			padding-right: 65px;
		}
		.md-pr-66 {
			padding-right: 66px;
		}
		.md-pr-67 {
			padding-right: 67px;
		}
		.md-pr-68 {
			padding-right: 68px;
		}
		.md-pr-69 {
			padding-right: 69px;
		}
		.md-pr-70 {
			padding-right: 70px;
		}
		.md-pr-71 {
			padding-right: 71px;
		}
		.md-pr-72 {
			padding-right: 72px;
		}
		.md-pr-73 {
			padding-right: 73px;
		}
		.md-pr-74 {
			padding-right: 74px;
		}
		.md-pr-75 {
			padding-right: 75px;
		}
		.md-pr-76 {
			padding-right: 76px;
		}
		.md-pr-77 {
			padding-right: 77px;
		}
		.md-pr-78 {
			padding-right: 78px;
		}
		.md-pr-79 {
			padding-right: 79px;
		}
		.md-pr-80 {
			padding-right: 80px;
		}
		.md-pr-81 {
			padding-right: 81px;
		}
		.md-pr-82 {
			padding-right: 82px;
		}
		.md-pr-83 {
			padding-right: 83px;
		}
		.md-pr-84 {
			padding-right: 84px;
		}
		.md-pr-85 {
			padding-right: 85px;
		}
		.md-pr-86 {
			padding-right: 86px;
		}
		.md-pr-87 {
			padding-right: 87px;
		}
		.md-pr-88 {
			padding-right: 88px;
		}
		.md-pr-89 {
			padding-right: 89px;
		}
		.md-pr-90 {
			padding-right: 90px;
		}
		.md-pr-91 {
			padding-right: 91px;
		}
		.md-pr-92 {
			padding-right: 92px;
		}
		.md-pr-93 {
			padding-right: 93px;
		}
		.md-pr-94 {
			padding-right: 94px;
		}
		.md-pr-95 {
			padding-right: 95px;
		}
		.md-pr-96 {
			padding-right: 96px;
		}
		.md-pr-97 {
			padding-right: 97px;
		}
		.md-pr-98 {
			padding-right: 98px;
		}
		.md-pr-99 {
			padding-right: 99px;
		}
		.md-pr-100 {
			padding-right: 100px;
		}
		.md-pr-101 {
			padding-right: 101px;
		}
		.md-pr-102 {
			padding-right: 102px;
		}
		.md-pb-103 {
			padding-bottom: 103px;
		}
		.md-pr-104 {
			padding-right: 104px;
		}
		.md-pr-105 {
			padding-right: 105px;
		}
		.md-pr-106 {
			padding-right: 106px;
		}
		.md-pr-107 {
			padding-right: 107px;
		}
		.md-pr-108 {
			padding-right: 108px;
		}
		.md-pr-109 {
			padding-right: 109px;
		}
		.md-pr-110 {
			padding-right: 110px;
		}
		.md-pr-111 {
			padding-right: 111px;
		}
		.md-pr-112 {
			padding-right: 112px;
		}
		.md-pr-113 {
			padding-right: 113px;
		}
		.md-pr-114 {
			padding-right: 114px;
		}
		.md-pr-115 {
			padding-right: 115px;
		}
		.md-pr-116 {
			padding-right: 116px;
		}
		.md-pr-117 {
			padding-right: 117px;
		}
		.md-pr-118 {
			padding-right: 118px;
		}
		.md-pr-119 {
			padding-right: 119px;
		}
		.md-pr-120 {
			padding-right: 120px;
		}
		.md-pr-121 {
			padding-right: 121px;
		}
		.md-pr-122 {
			padding-right: 122px;
		}
		.md-pr-123 {
			padding-right: 123px;
		}
		.md-pr-124 {
			padding-right: 124px;
		}
		.md-pr-125 {
			padding-right: 125px;
		}
		.md-pr-126 {
			padding-right: 126px;
		}
		.md-pr-127 {
			padding-right: 127px;
		}
		.md-pr-128 {
			padding-right: 128px;
		}
		.md-pr-129 {
			padding-right: 129px;
		}
		.md-pr-130 {
			padding-right: 130px;
		}
		.md-pr-131 {
			padding-right: 131px;
		}
		.md-pr-132 {
			padding-right: 132px;
		}
		.md-pr-133 {
			padding-right: 133px;
		}
		.md-pr-134 {
			padding-right: 134px;
		}
		.md-pr-135 {
			padding-right: 135px;
		}
		.md-pr-136 {
			padding-right: 136px;
		}
		.md-pr-137 {
			padding-right: 137px;
		}
		.md-pr-138 {
			padding-right: 138px;
		}
		.md-pr-139 {
			padding-right: 139px;
		}
		.md-pr-140 {
			padding-right: 140px;
		}
		.md-pr-141 {
			padding-right: 141px;
		}
		.md-pr-142 {
			padding-right: 142px;
		}
		.md-pr-143 {
			padding-right: 143px;
		}
		.md-pr-144 {
			padding-right: 144px;
		}
		.md-pr-145 {
			padding-right: 145px;
		}
		.md-pr-146 {
			padding-right: 146px;
		}
		.md-pr-147 {
			padding-right: 147px;
		}
		.md-pr-148 {
			padding-right: 148px;
		}
		.md-pr-149 {
			padding-right: 149px;
		}
		.md-pr-150 {
			padding-right: 150px;
		}
		.md-pr-151 {
			padding-right: 151px;
		}
		.md-pr-152 {
			padding-right: 152px;
		}
		.md-pr-153 {
			padding-right: 153px;
		}
		.md-pr-154 {
			padding-right: 154px;
		}
		.md-pr-155 {
			padding-right: 155px;
		}
		.md-pr-156 {
			padding-right: 156px;
		}
		.md-pr-157 {
			padding-right: 157px;
		}
		.md-pr-158 {
			padding-right: 158px;
		}
		.md-pr-159 {
			padding-right: 159px;
		}
		.md-pr-160 {
			padding-right: 160px;
		}
		.md-pr-161 {
			padding-right: 161px;
		}
		.md-pr-162 {
			padding-right: 162px;
		}
		.md-pr-163 {
			padding-right: 163px;
		}
		.md-pr-164 {
			padding-right: 164px;
		}
		.md-pr-165 {
			padding-right: 165px;
		}
		.md-pr-166 {
			padding-right: 166px;
		}
		.md-pr-167 {
			padding-right: 167px;
		}
		.md-pr-168 {
			padding-right: 168px;
		}
		.md-pr-169 {
			padding-right: 169px;
		}
		.md-pr-170 {
			padding-right: 170px;
		}
		.md-pr-171 {
			padding-right: 171px;
		}
		.md-pr-172 {
			padding-right: 172px;
		}
		.md-pr-173 {
			padding-right: 173px;
		}
		.md-pr-174 {
			padding-right: 174px;
		}
		.md-pr-175 {
			padding-right: 175px;
		}
		.md-pr-176 {
			padding-right: 176px;
		}
		.md-pr-177 {
			padding-right: 177px;
		}
		.md-pr-178 {
			padding-right: 178px;
		}
		.md-pr-179 {
			padding-right: 179px;
		}
		.md-pr-180 {
			padding-right: 180px;
		}
		.md-pr-181 {
			padding-right: 181px;
		}
		.md-pr-182 {
			padding-right: 182px;
		}
		.md-pr-183 {
			padding-right: 183px;
		}
		.md-pr-184 {
			padding-right: 184px;
		}
		.md-pr-185 {
			padding-right: 185px;
		}
		.md-pr-186 {
			padding-right: 186px;
		}
		.md-pr-187 {
			padding-right: 187px;
		}
		.md-pr-188 {
			padding-right: 188px;
		}
		.md-pr-189 {
			padding-right: 189px;
		}
		.md-pr-190 {
			padding-right: 190px;
		}
		.md-pr-191 {
			padding-right: 191px;
		}
		.md-pr-192 {
			padding-right: 192px;
		}
		.md-pr-193 {
			padding-right: 193px;
		}
		.md-pr-194 {
			padding-right: 194px;
		}
		.md-pr-195 {
			padding-right: 195px;
		}
		.md-pr-196 {
			padding-right: 196px;
		}
		.md-pr-197 {
			padding-right: 197px;
		}
		.md-pr-198 {
			padding-right: 198px;
		}
		.md-pr-199 {
			padding-right: 199px;
		}
		.md-pr-200 {
			padding-right: 200px;
		}
	/*}*/

	/*------------------------------------------
		28. MD PADDING LEFT
	-------------------------------------------*/
	/*.md-pl {*/
		.md-pl-0 {
			padding-left: 0px;
		}
		.md-pl-1 {
			padding-left: 1px;
		}
		.md-pl-2 {
			padding-left: 2px;
		}
		.pb-3 {
			padding-bottom: 3px;
		}
		.md-pl-4 {
			padding-left: 4px;
		}
		.md-pl-5 {
			padding-left: 5px;
		}
		.md-pl-6 {
			padding-left: 6px;
		}
		.md-pl-7 {
			padding-left: 7px;
		}
		.md-pl-8 {
			padding-left: 8px;
		}
		.md-pl-9 {
			padding-left: 9px;
		}
		.md-pl-10 {
			padding-left: 10px;
		}
		.md-pl-11 {
			padding-left: 11px;
		}
		.md-pl-12 {
			padding-left: 12px;
		}
		.md-pl-13 {
			padding-left: 13px;
		}
		.md-pl-14 {
			padding-left: 14px;
		}
		.md-pl-15 {
			padding-left: 15px;
		}
		.md-pl-16 {
			padding-left: 16px;
		}
		.md-pl-17 {
			padding-left: 17px;
		}
		.md-pl-18 {
			padding-left: 18px;
		}
		.md-pl-19 {
			padding-left: 19px;
		}
		.md-pl-20 {
			padding-left: 20px;
		}
		.md-pl-21 {
			padding-left: 21px;
		}
		.md-pl-22 {
			padding-left: 22px;
		}
		.md-pl-23 {
			padding-left: 23px;
		}
		.md-pl-24 {
			padding-left: 24px;
		}
		.md-pl-25 {
			padding-left: 25px;
		}
		.md-pl-26 {
			padding-left: 26px;
		}
		.md-pl-27 {
			padding-left: 27px;
		}
		.md-pl-28 {
			padding-left: 28px;
		}
		.md-pl-29 {
			padding-left: 29px;
		}
		.md-pl-30 {
			padding-left: 30px;
		}
		.md-pl-31 {
			padding-left: 31px;
		}
		.md-pl-32 {
			padding-left: 32px;
		}
		.md-pl-33 {
			padding-left: 33px;
		}
		.md-pl-34 {
			padding-left: 34px;
		}
		.md-pl-35 {
			padding-left: 35px;
		}
		.md-pl-36 {
			padding-left: 36px;
		}
		.md-pl-37 {
			padding-left: 37px;
		}
		.md-pl-38 {
			padding-left: 38px;
		}
		.md-pl-39 {
			padding-left: 39px;
		}
		.md-pl-40 {
			padding-left: 40px;
		}
		.md-pl-41 {
			padding-left: 41px;
		}
		.md-pl-42 {
			padding-left: 42px;
		}
		.md-pl-43 {
			padding-left: 43px;
		}
		.md-pl-44 {
			padding-left: 44px;
		}
		.md-pl-45 {
			padding-left: 45px;
		}
		.md-pl-46 {
			padding-left: 46px;
		}
		.md-pl-47 {
			padding-left: 47px;
		}
		.md-pl-48 {
			padding-left: 48px;
		}
		.md-pl-49 {
			padding-left: 49px;
		}
		.md-pl-50 {
			padding-left: 50px;
		}
		.md-pl-51 {
			padding-left: 51px;
		}
		.md-pl-52 {
			padding-left: 52px;
		}
		.md-pl-53 {
			padding-left: 53px;
		}
		.md-pl-54 {
			padding-left: 54px;
		}
		.md-pl-55 {
			padding-left: 55px;
		}
		.md-pl-56 {
			padding-left: 56px;
		}
		.md-pl-57 {
			padding-left: 57px;
		}
		.md-pl-58 {
			padding-left: 58px;
		}
		.md-pl-59 {
			padding-left: 59px;
		}
		.md-pl-60 {
			padding-left: 60px;
		}
		.md-pl-61 {
			padding-left: 61px;
		}
		.md-pl-62 {
			padding-left: 62px;
		}
		.md-pl-63 {
			padding-left: 63px;
		}
		.md-pl-64 {
			padding-left: 64px;
		}
		.md-pl-65 {
			padding-left: 65px;
		}
		.md-pl-66 {
			padding-left: 66px;
		}
		.md-pl-67 {
			padding-left: 67px;
		}
		.md-pl-68 {
			padding-left: 68px;
		}
		.md-pl-69 {
			padding-left: 69px;
		}
		.md-pl-70 {
			padding-left: 70px;
		}
		.md-pl-71 {
			padding-left: 71px;
		}
		.md-pl-72 {
			padding-left: 72px;
		}
		.md-pl-73 {
			padding-left: 73px;
		}
		.md-pl-74 {
			padding-left: 74px;
		}
		.md-pl-75 {
			padding-left: 75px;
		}
		.md-pl-76 {
			padding-left: 76px;
		}
		.md-pl-77 {
			padding-left: 77px;
		}
		.md-pl-78 {
			padding-left: 78px;
		}
		.md-pl-79 {
			padding-left: 79px;
		}
		.md-pl-80 {
			padding-left: 80px;
		}
		.md-pl-81 {
			padding-left: 81px;
		}
		.md-pl-82 {
			padding-left: 82px;
		}
		.md-pl-83 {
			padding-left: 83px;
		}
		.md-pl-84 {
			padding-left: 84px;
		}
		.md-pl-85 {
			padding-left: 85px;
		}
		.md-pl-86 {
			padding-left: 86px;
		}
		.md-pl-87 {
			padding-left: 87px;
		}
		.md-pl-88 {
			padding-left: 88px;
		}
		.md-pl-89 {
			padding-left: 89px;
		}
		.md-pl-90 {
			padding-left: 90px;
		}
		.md-pl-91 {
			padding-left: 91px;
		}
		.md-pl-92 {
			padding-left: 92px;
		}
		.md-pl-93 {
			padding-left: 93px;
		}
		.md-pl-94 {
			padding-left: 94px;
		}
		.md-pl-95 {
			padding-left: 95px;
		}
		.md-pl-96 {
			padding-left: 96px;
		}
		.md-pl-97 {
			padding-left: 97px;
		}
		.md-pl-98 {
			padding-left: 98px;
		}
		.md-pl-99 {
			padding-left: 99px;
		}
		.md-pl-100 {
			padding-left: 100px;
		}
		.md-pl-101 {
			padding-left: 101px;
		}
		.md-pl-102 {
			padding-left: 102px;
		}
		.md-pb-103 {
			padding-bottom: 103px;
		}
		.md-pl-104 {
			padding-left: 104px;
		}
		.md-pl-105 {
			padding-left: 105px;
		}
		.md-pl-106 {
			padding-left: 106px;
		}
		.md-pl-107 {
			padding-left: 107px;
		}
		.md-pl-108 {
			padding-left: 108px;
		}
		.md-pl-109 {
			padding-left: 109px;
		}
		.md-pl-110 {
			padding-left: 110px;
		}
		.md-pl-111 {
			padding-left: 111px;
		}
		.md-pl-112 {
			padding-left: 112px;
		}
		.md-pl-113 {
			padding-left: 113px;
		}
		.md-pl-114 {
			padding-left: 114px;
		}
		.md-pl-115 {
			padding-left: 115px;
		}
		.md-pl-116 {
			padding-left: 116px;
		}
		.md-pl-117 {
			padding-left: 117px;
		}
		.md-pl-118 {
			padding-left: 118px;
		}
		.md-pl-119 {
			padding-left: 119px;
		}
		.md-pl-120 {
			padding-left: 120px;
		}
		.md-pl-121 {
			padding-left: 121px;
		}
		.md-pl-122 {
			padding-left: 122px;
		}
		.md-pl-123 {
			padding-left: 123px;
		}
		.md-pl-124 {
			padding-left: 124px;
		}
		.md-pl-125 {
			padding-left: 125px;
		}
		.md-pl-126 {
			padding-left: 126px;
		}
		.md-pl-127 {
			padding-left: 127px;
		}
		.md-pl-128 {
			padding-left: 128px;
		}
		.md-pl-129 {
			padding-left: 129px;
		}
		.md-pl-130 {
			padding-left: 130px;
		}
		.md-pl-131 {
			padding-left: 131px;
		}
		.md-pl-132 {
			padding-left: 132px;
		}
		.md-pl-133 {
			padding-left: 133px;
		}
		.md-pl-134 {
			padding-left: 134px;
		}
		.md-pl-135 {
			padding-left: 135px;
		}
		.md-pl-136 {
			padding-left: 136px;
		}
		.md-pl-137 {
			padding-left: 137px;
		}
		.md-pl-138 {
			padding-left: 138px;
		}
		.md-pl-139 {
			padding-left: 139px;
		}
		.md-pl-140 {
			padding-left: 140px;
		}
		.md-pl-141 {
			padding-left: 141px;
		}
		.md-pl-142 {
			padding-left: 142px;
		}
		.md-pl-143 {
			padding-left: 143px;
		}
		.md-pl-144 {
			padding-left: 144px;
		}
		.md-pl-145 {
			padding-left: 145px;
		}
		.md-pl-146 {
			padding-left: 146px;
		}
		.md-pl-147 {
			padding-left: 147px;
		}
		.md-pl-148 {
			padding-left: 148px;
		}
		.md-pl-149 {
			padding-left: 149px;
		}
		.md-pl-150 {
			padding-left: 150px;
		}
		.md-pl-151 {
			padding-left: 151px;
		}
		.md-pl-152 {
			padding-left: 152px;
		}
		.md-pl-153 {
			padding-left: 153px;
		}
		.md-pl-154 {
			padding-left: 154px;
		}
		.md-pl-155 {
			padding-left: 155px;
		}
		.md-pl-156 {
			padding-left: 156px;
		}
		.md-pl-157 {
			padding-left: 157px;
		}
		.md-pl-158 {
			padding-left: 158px;
		}
		.md-pl-159 {
			padding-left: 159px;
		}
		.md-pl-160 {
			padding-left: 160px;
		}
		.md-pl-161 {
			padding-left: 161px;
		}
		.md-pl-162 {
			padding-left: 162px;
		}
		.md-pl-163 {
			padding-left: 163px;
		}
		.md-pl-164 {
			padding-left: 164px;
		}
		.md-pl-165 {
			padding-left: 165px;
		}
		.md-pl-166 {
			padding-left: 166px;
		}
		.md-pl-167 {
			padding-left: 167px;
		}
		.md-pl-168 {
			padding-left: 168px;
		}
		.md-pl-169 {
			padding-left: 169px;
		}
		.md-pl-170 {
			padding-left: 170px;
		}
		.md-pl-171 {
			padding-left: 171px;
		}
		.md-pl-172 {
			padding-left: 172px;
		}
		.md-pl-173 {
			padding-left: 173px;
		}
		.md-pl-174 {
			padding-left: 174px;
		}
		.md-pl-175 {
			padding-left: 175px;
		}
		.md-pl-176 {
			padding-left: 176px;
		}
		.md-pl-177 {
			padding-left: 177px;
		}
		.md-pl-178 {
			padding-left: 178px;
		}
		.md-pl-179 {
			padding-left: 179px;
		}
		.md-pl-180 {
			padding-left: 180px;
		}
		.md-pl-181 {
			padding-left: 181px;
		}
		.md-pl-182 {
			padding-left: 182px;
		}
		.md-pl-183 {
			padding-left: 183px;
		}
		.md-pl-184 {
			padding-left: 184px;
		}
		.md-pl-185 {
			padding-left: 185px;
		}
		.md-pl-186 {
			padding-left: 186px;
		}
		.md-pl-187 {
			padding-left: 187px;
		}
		.md-pl-188 {
			padding-left: 188px;
		}
		.md-pl-189 {
			padding-left: 189px;
		}
		.md-pl-190 {
			padding-left: 190px;
		}
		.md-pl-191 {
			padding-left: 191px;
		}
		.md-pl-192 {
			padding-left: 192px;
		}
		.md-pl-193 {
			padding-left: 193px;
		}
		.md-pl-194 {
			padding-left: 194px;
		}
		.md-pl-195 {
			padding-left: 195px;
		}
		.md-pl-196 {
			padding-left: 196px;
		}
		.md-pl-197 {
			padding-left: 197px;
		}
		.md-pl-198 {
			padding-left: 198px;
		}
		.md-pl-199 {
			padding-left: 199px;
		}
		.md-pl-200 {
			padding-left: 200px;
		}
	/*}*/

	/*------------------------------------------
		29. MD MARGIN TOP
	-------------------------------------------*/
	/*.md-mt {*/
		.md-mt-0 {
			margin-top: 0px;
		}
		.md-mt-1 {
			margin-top: 1px;
		}
		.md-mt-2 {
			margin-top: 2px;
		}
		.md-mt-3 {
			margin-top: 3px;
		}
		.md-mt-4 {
			margin-top: 4px;
		}
		.md-mt-5 {
			margin-top: 5px;
		}
		.md-mt-6 {
			margin-top: 6px;
		}
		.md-mt-7 {
			margin-top: 7px;
		}
		.md-mt-8 {
			margin-top: 8px;
		}
		.md-mt-9 {
			margin-top: 9px;
		}
		.md-mt-10 {
			margin-top: 10px;
		}
		.md-mt-11 {
			margin-top: 11px;
		}
		.md-mt-12 {
			margin-top: 12px;
		}
		.md-mt-13 {
			margin-top: 13px;
		}
		.md-mt-14 {
			margin-top: 14px;
		}
		.md-mt-15 {
			margin-top: 15px;
		}
		.md-mt-16 {
			margin-top: 16px;
		}
		.md-mt-17 {
			margin-top: 17px;
		}
		.md-mt-18 {
			margin-top: 18px;
		}
		.md-mt-19 {
			margin-top: 19px;
		}
		.md-mt-20 {
			margin-top: 20px;
		}
		.md-mt-21 {
			margin-top: 21px;
		}
		.md-mt-22 {
			margin-top: 22px;
		}
		.md-mt-23 {
			margin-top: 23px;
		}
		.md-mt-24 {
			margin-top: 24px;
		}
		.md-mt-25 {
			margin-top: 25px;
		}
		.md-mt-26 {
			margin-top: 26px;
		}
		.md-mt-27 {
			margin-top: 27px;
		}
		.md-mt-28 {
			margin-top: 28px;
		}
		.md-mt-29 {
			margin-top: 29px;
		}
		.md-mt-30 {
			margin-top: 30px;
		}
		.md-mt-31 {
			margin-top: 31px;
		}
		.md-mt-32 {
			margin-top: 32px;
		}
		.md-mt-33 {
			margin-top: 33px;
		}
		.md-mt-34 {
			margin-top: 34px;
		}
		.md-mt-35 {
			margin-top: 35px;
		}
		.md-mt-36 {
			margin-top: 36px;
		}
		.md-mt-37 {
			margin-top: 37px;
		}
		.md-mt-38 {
			margin-top: 38px;
		}
		.md-mt-39 {
			margin-top: 39px;
		}
		.md-mt-40 {
			margin-top: 40px;
		}
		.md-mt-41 {
			margin-top: 41px;
		}
		.md-mt-42 {
			margin-top: 42px;
		}
		.md-mt-43 {
			margin-top: 43px;
		}
		.md-mt-44 {
			margin-top: 44px;
		}
		.md-mt-45 {
			margin-top: 45px;
		}
		.md-mt-46 {
			margin-top: 46px;
		}
		.md-mt-47 {
			margin-top: 47px;
		}
		.md-mt-48 {
			margin-top: 48px;
		}
		.md-mt-49 {
			margin-top: 49px;
		}
		.md-mt-50 {
			margin-top: 50px;
		}
		.md-mt-51 {
			margin-top: 51px;
		}
		.md-mt-52 {
			margin-top: 52px;
		}
		.md-mt-53 {
			margin-top: 53px;
		}
		.md-mt-54 {
			margin-top: 54px;
		}
		.md-mt-55 {
			margin-top: 55px;
		}
		.md-mt-56 {
			margin-top: 56px;
		}
		.md-mt-57 {
			margin-top: 57px;
		}
		.md-mt-58 {
			margin-top: 58px;
		}
		.md-mt-59 {
			margin-top: 59px;
		}
		.md-mt-60 {
			margin-top: 60px;
		}
		.md-mt-61 {
			margin-top: 61px;
		}
		.md-mt-62 {
			margin-top: 62px;
		}
		.md-mt-63 {
			margin-top: 63px;
		}
		.md-mt-64 {
			margin-top: 64px;
		}
		.md-mt-65 {
			margin-top: 65px;
		}
		.md-mt-66 {
			margin-top: 66px;
		}
		.md-mt-67 {
			margin-top: 67px;
		}
		.md-mt-68 {
			margin-top: 68px;
		}
		.md-mt-69 {
			margin-top: 69px;
		}
		.md-mt-70 {
			margin-top: 70px;
		}
		.md-mt-71 {
			margin-top: 71px;
		}
		.md-mt-72 {
			margin-top: 72px;
		}
		.md-mt-73 {
			margin-top: 73px;
		}
		.md-mt-74 {
			margin-top: 74px;
		}
		.md-mt-75 {
			margin-top: 75px;
		}
		.md-mt-76 {
			margin-top: 76px;
		}
		.md-mt-77 {
			margin-top: 77px;
		}
		.md-mt-78 {
			margin-top: 78px;
		}
		.md-mt-79 {
			margin-top: 79px;
		}
		.md-mt-80 {
			margin-top: 80px;
		}
		.md-mt-81 {
			margin-top: 81px;
		}
		.md-mt-82 {
			margin-top: 82px;
		}
		.md-mt-83 {
			margin-top: 83px;
		}
		.md-mt-84 {
			margin-top: 84px;
		}
		.md-mt-85 {
			margin-top: 85px;
		}
		.md-mt-86 {
			margin-top: 86px;
		}
		.md-mt-87 {
			margin-top: 87px;
		}
		.md-mt-88 {
			margin-top: 88px;
		}
		.md-mt-89 {
			margin-top: 89px;
		}
		.md-mt-90 {
			margin-top: 90px;
		}
		.md-mt-91 {
			margin-top: 91px;
		}
		.md-mt-92 {
			margin-top: 92px;
		}
		.md-mt-93 {
			margin-top: 93px;
		}
		.md-mt-94 {
			margin-top: 94px;
		}
		.md-mt-95 {
			margin-top: 95px;
		}
		.md-mt-96 {
			margin-top: 96px;
		}
		.md-mt-97 {
			margin-top: 97px;
		}
		.md-mt-98 {
			margin-top: 98px;
		}
		.md-mt-99 {
			margin-top: 99px;
		}
		.md-mt-100 {
			margin-top: 100px;
		}

		.md-mt-101 {
			margin-top: 101px;
		}
		.md-mt-102 {
			margin-top: 102px;
		}
		.md-mt-103 {
			margin-top: 103px;
		}
		.md-mt-104 {
			margin-top: 104px;
		}
		.md-mt-105 {
			margin-top: 105px;
		}
		.md-mt-106 {
			margin-top: 106px;
		}
		.md-mt-107 {
			margin-top: 107px;
		}
		.md-mt-108 {
			margin-top: 108px;
		}
		.md-mt-109 {
			margin-top: 109px;
		}
		.md-mt-110 {
			margin-top: 110px;
		}
		.md-mt-111 {
			margin-top: 111px;
		}
		.md-mt-112 {
			margin-top: 112px;
		}
		.md-mt-113 {
			margin-top: 113px;
		}
		.md-mt-114 {
			margin-top: 114px;
		}
		.md-mt-115 {
			margin-top: 115px;
		}
		.md-mt-116 {
			margin-top: 116px;
		}
		.md-mt-117 {
			margin-top: 117px;
		}
		.md-mt-118 {
			margin-top: 118px;
		}
		.md-mt-119 {
			margin-top: 119px;
		}
		.md-mt-120 {
			margin-top: 120px;
		}
		.md-mt-121 {
			margin-top: 121px;
		}
		.md-mt-122 {
			margin-top: 122px;
		}
		.md-mt-123 {
			margin-top: 123px;
		}
		.md-mt-124 {
			margin-top: 124px;
		}
		.md-mt-125 {
			margin-top: 125px;
		}
		.md-mt-126 {
			margin-top: 126px;
		}
		.md-mt-127 {
			margin-top: 127px;
		}
		.md-mt-128 {
			margin-top: 128px;
		}
		.md-mt-129 {
			margin-top: 129px;
		}
		.md-mt-130 {
			margin-top: 130px;
		}
		.md-mt-131 {
			margin-top: 131px;
		}
		.md-mt-132 {
			margin-top: 132px;
		}
		.md-mt-133 {
			margin-top: 133px;
		}
		.md-mt-134 {
			margin-top: 134px;
		}
		.md-mt-135 {
			margin-top: 135px;
		}
		.md-mt-136 {
			margin-top: 136px;
		}
		.md-mt-137 {
			margin-top: 137px;
		}
		.md-mt-138 {
			margin-top: 138px;
		}
		.md-mt-139 {
			margin-top: 139px;
		}
		.md-mt-140 {
			margin-top: 140px;
		}
		.md-mt-141 {
			margin-top: 141px;
		}
		.md-mt-142 {
			margin-top: 142px;
		}
		.md-mt-143 {
			margin-top: 143px;
		}
		.md-mt-144 {
			margin-top: 144px;
		}
		.md-mt-145 {
			margin-top: 145px;
		}
		.md-mt-146 {
			margin-top: 146px;
		}
		.md-mt-147 {
			margin-top: 147px;
		}
		.md-mt-148 {
			margin-top: 148px;
		}
		.md-mt-149 {
			margin-top: 149px;
		}
		.md-mt-150 {
			margin-top: 150px;
		}
		.md-mt-151 {
			margin-top: 151px;
		}
		.md-mt-152 {
			margin-top: 152px;
		}
		.md-mt-153 {
			margin-top: 153px;
		}
		.md-mt-154 {
			margin-top: 154px;
		}
		.md-mt-155 {
			margin-top: 155px;
		}
		.md-mt-156 {
			margin-top: 156px;
		}
		.md-mt-157 {
			margin-top: 157px;
		}
		.md-mt-158 {
			margin-top: 158px;
		}
		.md-mt-159 {
			margin-top: 159px;
		}
		.md-mt-160 {
			margin-top: 160px;
		}
		.md-mt-161 {
			margin-top: 161px;
		}
		.md-mt-162 {
			margin-top: 162px;
		}
		.md-mt-163 {
			margin-top: 163px;
		}
		.md-mt-164 {
			margin-top: 164px;
		}
		.md-mt-165 {
			margin-top: 165px;
		}
		.md-mt-166 {
			margin-top: 166px;
		}
		.md-mt-167 {
			margin-top: 167px;
		}
		.md-mt-168 {
			margin-top: 168px;
		}
		.md-mt-169 {
			margin-top: 169px;
		}
		.md-mt-170 {
			margin-top: 170px;
		}
		.md-mt-171 {
			margin-top: 171px;
		}
		.md-mt-172 {
			margin-top: 172px;
		}
		.md-mt-173 {
			margin-top: 173px;
		}
		.md-mt-174 {
			margin-top: 174px;
		}
		.md-mt-175 {
			margin-top: 175px;
		}
		.md-mt-176 {
			margin-top: 176px;
		}
		.md-mt-177 {
			margin-top: 177px;
		}
		.md-mt-178 {
			margin-top: 178px;
		}
		.md-mt-179 {
			margin-top: 179px;
		}
		.md-mt-180 {
			margin-top: 180px;
		}
		.md-mt-181 {
			margin-top: 181px;
		}
		.md-mt-182 {
			margin-top: 182px;
		}
		.md-mt-183 {
			margin-top: 183px;
		}
		.md-mt-184 {
			margin-top: 184px;
		}
		.md-mt-185 {
			margin-top: 185px;
		}
		.md-mt-186 {
			margin-top: 186px;
		}
		.md-mt-187 {
			margin-top: 187px;
		}
		.md-mt-188 {
			margin-top: 188px;
		}
		.md-mt-189 {
			margin-top: 189px;
		}
		.md-mt-190 {
			margin-top: 190px;
		}
		.md-mt-191 {
			margin-top: 191px;
		}
		.md-mt-192 {
			margin-top: 192px;
		}
		.md-mt-193 {
			margin-top: 193px;
		}
		.md-mt-194 {
			margin-top: 194px;
		}
		.md-mt-195 {
			margin-top: 195px;
		}
		.md-mt-196 {
			margin-top: 196px;
		}
		.md-mt-197 {
			margin-top: 197px;
		}
		.md-mt-198 {
			margin-top: 198px;
		}
		.md-mt-199 {
			margin-top: 199px;
		}
		.md-mt-200 {
			margin-top: 200px;
		}
	/*}*/

	/*------------------------------------------
		30. MD MARGIN BOTTOM
	-------------------------------------------*/
	/*.md-mb {*/
		.md-mb-0 {
			margin-bottom: 0px;
		}
		.md-mb-1 {
			margin-bottom: 1px;
		}
		.md-mb-2 {
			margin-bottom: 2px;
		}
		.md-mb-3 {
			margin-bottom: 3px;
		}
		.md-mb-4 {
			margin-bottom: 4px;
		}
		.md-mb-5 {
			margin-bottom: 5px;
		}
		.md-mb-6 {
			margin-bottom: 6px;
		}
		.md-mb-7 {
			margin-bottom: 7px;
		}
		.md-mb-8 {
			margin-bottom: 8px;
		}
		.md-mb-9 {
			margin-bottom: 9px;
		}
		.md-mb-10 {
			margin-bottom: 10px;
		}
		.md-mb-11 {
			margin-bottom: 11px;
		}
		.md-mb-12 {
			margin-bottom: 12px;
		}
		.md-mb-13 {
			margin-bottom: 13px;
		}
		.md-mb-14 {
			margin-bottom: 14px;
		}
		.md-mb-15 {
			margin-bottom: 15px;
		}
		.md-mb-16 {
			margin-bottom: 16px;
		}
		.md-mb-17 {
			margin-bottom: 17px;
		}
		.md-mb-18 {
			margin-bottom: 18px;
		}
		.md-mb-19 {
			margin-bottom: 19px;
		}
		.md-mb-20 {
			margin-bottom: 20px;
		}
		.md-mb-21 {
			margin-bottom: 21px;
		}
		.md-mb-22 {
			margin-bottom: 22px;
		}
		.md-mb-23 {
			margin-bottom: 23px;
		}
		.md-mb-24 {
			margin-bottom: 24px;
		}
		.md-mb-25 {
			margin-bottom: 25px;
		}
		.md-mb-26 {
			margin-bottom: 26px;
		}
		.md-mb-27 {
			margin-bottom: 27px;
		}
		.md-mb-28 {
			margin-bottom: 28px;
		}
		.md-mb-29 {
			margin-bottom: 29px;
		}
		.md-mb-30 {
			margin-bottom: 30px;
		}
		.md-mb-31 {
			margin-bottom: 31px;
		}
		.md-mb-32 {
			margin-bottom: 32px;
		}
		.md-mb-33 {
			margin-bottom: 33px;
		}
		.md-mb-34 {
			margin-bottom: 34px;
		}
		.md-mb-35 {
			margin-bottom: 35px;
		}
		.md-mb-36 {
			margin-bottom: 36px;
		}
		.md-mb-37 {
			margin-bottom: 37px;
		}
		.md-mb-38 {
			margin-bottom: 38px;
		}
		.md-mb-39 {
			margin-bottom: 39px;
		}
		.md-mb-40 {
			margin-bottom: 40px;
		}
		.md-mb-41 {
			margin-bottom: 41px;
		}
		.md-mb-42 {
			margin-bottom: 42px;
		}
		.md-mb-43 {
			margin-bottom: 43px;
		}
		.md-mb-44 {
			margin-bottom: 44px;
		}
		.md-mb-45 {
			margin-bottom: 45px;
		}
		.md-mb-46 {
			margin-bottom: 46px;
		}
		.md-mb-47 {
			margin-bottom: 47px;
		}
		.md-mb-48 {
			margin-bottom: 48px;
		}
		.md-mb-49 {
			margin-bottom: 49px;
		}
		.md-mb-50 {
			margin-bottom: 50px;
		}
		.md-mb-51 {
			margin-bottom: 51px;
		}
		.md-mb-52 {
			margin-bottom: 52px;
		}
		.md-mb-53 {
			margin-bottom: 53px;
		}
		.md-mb-54 {
			margin-bottom: 54px;
		}
		.md-mb-55 {
			margin-bottom: 55px;
		}
		.md-mb-56 {
			margin-bottom: 56px;
		}
		.md-mb-57 {
			margin-bottom: 57px;
		}
		.md-mb-58 {
			margin-bottom: 58px;
		}
		.md-mb-59 {
			margin-bottom: 59px;
		}
		.md-mb-60 {
			margin-bottom: 60px;
		}
		.md-mb-61 {
			margin-bottom: 61px;
		}
		.md-mb-62 {
			margin-bottom: 62px;
		}
		.md-mb-63 {
			margin-bottom: 63px;
		}
		.md-mb-64 {
			margin-bottom: 64px;
		}
		.md-mb-65 {
			margin-bottom: 65px;
		}
		.md-mb-66 {
			margin-bottom: 66px;
		}
		.md-mb-67 {
			margin-bottom: 67px;
		}
		.md-mb-68 {
			margin-bottom: 68px;
		}
		.md-mb-69 {
			margin-bottom: 69px;
		}
		.md-mb-70 {
			margin-bottom: 70px;
		}
		.md-mb-71 {
			margin-bottom: 71px;
		}
		.md-mb-72 {
			margin-bottom: 72px;
		}
		.md-mb-73 {
			margin-bottom: 73px;
		}
		.md-mb-74 {
			margin-bottom: 74px;
		}
		.md-mb-75 {
			margin-bottom: 75px;
		}
		.md-mb-76 {
			margin-bottom: 76px;
		}
		.md-mb-77 {
			margin-bottom: 77px;
		}
		.md-mb-78 {
			margin-bottom: 78px;
		}
		.md-mb-79 {
			margin-bottom: 79px;
		}
		.md-mb-80 {
			margin-bottom: 80px;
		}
		.md-mb-81 {
			margin-bottom: 81px;
		}
		.md-mb-82 {
			margin-bottom: 82px;
		}
		.md-mb-83 {
			margin-bottom: 83px;
		}
		.md-mb-84 {
			margin-bottom: 84px;
		}
		.md-mb-85 {
			margin-bottom: 85px;
		}
		.md-mb-86 {
			margin-bottom: 86px;
		}
		.md-mb-87 {
			margin-bottom: 87px;
		}
		.md-mb-88 {
			margin-bottom: 88px;
		}
		.md-mb-89 {
			margin-bottom: 89px;
		}
		.md-mb-90 {
			margin-bottom: 90px;
		}
		.md-mb-91 {
			margin-bottom: 91px;
		}
		.md-mb-92 {
			margin-bottom: 92px;
		}
		.md-mb-93 {
			margin-bottom: 93px;
		}
		.md-mb-94 {
			margin-bottom: 94px;
		}
		.md-mb-95 {
			margin-bottom: 95px;
		}
		.md-mb-96 {
			margin-bottom: 96px;
		}
		.md-mb-97 {
			margin-bottom: 97px;
		}
		.md-mb-98 {
			margin-bottom: 98px;
		}
		.md-mb-99 {
			margin-bottom: 99px;
		}
		.md-mb-100 {
			margin-bottom: 100px;
		}

		.md-mb-101 {
			margin-bottom: 101px;
		}
		.md-mb-102 {
			margin-bottom: 102px;
		}
		.md-mb-103 {
			margin-bottom: 103px;
		}
		.md-mb-104 {
			margin-bottom: 104px;
		}
		.md-mb-105 {
			margin-bottom: 105px;
		}
		.md-mb-106 {
			margin-bottom: 106px;
		}
		.md-mb-107 {
			margin-bottom: 107px;
		}
		.md-mb-108 {
			margin-bottom: 108px;
		}
		.md-mb-109 {
			margin-bottom: 109px;
		}
		.md-mb-110 {
			margin-bottom: 110px;
		}
		.md-mb-111 {
			margin-bottom: 111px;
		}
		.md-mb-112 {
			margin-bottom: 112px;
		}
		.md-mb-113 {
			margin-bottom: 113px;
		}
		.md-mb-114 {
			margin-bottom: 114px;
		}
		.md-mb-115 {
			margin-bottom: 115px;
		}
		.md-mb-116 {
			margin-bottom: 116px;
		}
		.md-mb-117 {
			margin-bottom: 117px;
		}
		.md-mb-118 {
			margin-bottom: 118px;
		}
		.md-mb-119 {
			margin-bottom: 119px;
		}
		.md-mb-120 {
			margin-bottom: 120px;
		}
		.md-mb-121 {
			margin-bottom: 121px;
		}
		.md-mb-122 {
			margin-bottom: 122px;
		}
		.md-mb-123 {
			margin-bottom: 123px;
		}
		.md-mb-124 {
			margin-bottom: 124px;
		}
		.md-mb-125 {
			margin-bottom: 125px;
		}
		.md-mb-126 {
			margin-bottom: 126px;
		}
		.md-mb-127 {
			margin-bottom: 127px;
		}
		.md-mb-128 {
			margin-bottom: 128px;
		}
		.md-mb-129 {
			margin-bottom: 129px;
		}
		.md-mb-130 {
			margin-bottom: 130px;
		}
		.md-mb-131 {
			margin-bottom: 131px;
		}
		.md-mb-132 {
			margin-bottom: 132px;
		}
		.md-mb-133 {
			margin-bottom: 133px;
		}
		.md-mb-134 {
			margin-bottom: 134px;
		}
		.md-mb-135 {
			margin-bottom: 135px;
		}
		.md-mb-136 {
			margin-bottom: 136px;
		}
		.md-mb-137 {
			margin-bottom: 137px;
		}
		.md-mb-138 {
			margin-bottom: 138px;
		}
		.md-mb-139 {
			margin-bottom: 139px;
		}
		.md-mb-140 {
			margin-bottom: 140px;
		}
		.md-mb-141 {
			margin-bottom: 141px;
		}
		.md-mb-142 {
			margin-bottom: 142px;
		}
		.md-mb-143 {
			margin-bottom: 143px;
		}
		.md-mb-144 {
			margin-bottom: 144px;
		}
		.md-mb-145 {
			margin-bottom: 145px;
		}
		.md-mb-146 {
			margin-bottom: 146px;
		}
		.md-mb-147 {
			margin-bottom: 147px;
		}
		.md-mb-148 {
			margin-bottom: 148px;
		}
		.md-mb-149 {
			margin-bottom: 149px;
		}
		.md-mb-150 {
			margin-bottom: 150px;
		}
		.md-mb-151 {
			margin-bottom: 151px;
		}
		.md-mb-152 {
			margin-bottom: 152px;
		}
		.md-mb-153 {
			margin-bottom: 153px;
		}
		.md-mb-154 {
			margin-bottom: 154px;
		}
		.md-mb-155 {
			margin-bottom: 155px;
		}
		.md-mb-156 {
			margin-bottom: 156px;
		}
		.md-mb-157 {
			margin-bottom: 157px;
		}
		.md-mb-158 {
			margin-bottom: 158px;
		}
		.md-mb-159 {
			margin-bottom: 159px;
		}
		.md-mb-160 {
			margin-bottom: 160px;
		}
		.md-mb-161 {
			margin-bottom: 161px;
		}
		.md-mb-162 {
			margin-bottom: 162px;
		}
		.md-mb-163 {
			margin-bottom: 163px;
		}
		.md-mb-164 {
			margin-bottom: 164px;
		}
		.md-mb-165 {
			margin-bottom: 165px;
		}
		.md-mb-166 {
			margin-bottom: 166px;
		}
		.md-mb-167 {
			margin-bottom: 167px;
		}
		.md-mb-168 {
			margin-bottom: 168px;
		}
		.md-mb-169 {
			margin-bottom: 169px;
		}
		.md-mb-170 {
			margin-bottom: 170px;
		}
		.md-mb-171 {
			margin-bottom: 171px;
		}
		.md-mb-172 {
			margin-bottom: 172px;
		}
		.md-mb-173 {
			margin-bottom: 173px;
		}
		.md-mb-174 {
			margin-bottom: 174px;
		}
		.md-mb-175 {
			margin-bottom: 175px;
		}
		.md-mb-176 {
			margin-bottom: 176px;
		}
		.md-mb-177 {
			margin-bottom: 177px;
		}
		.md-mb-178 {
			margin-bottom: 178px;
		}
		.md-mb-179 {
			margin-bottom: 179px;
		}
		.md-mb-180 {
			margin-bottom: 180px;
		}
		.md-mb-181 {
			margin-bottom: 181px;
		}
		.md-mb-182 {
			margin-bottom: 182px;
		}
		.md-mb-183 {
			margin-bottom: 183px;
		}
		.md-mb-184 {
			margin-bottom: 184px;
		}
		.md-mb-185 {
			margin-bottom: 185px;
		}
		.md-mb-186 {
			margin-bottom: 186px;
		}
		.md-mb-187 {
			margin-bottom: 187px;
		}
		.md-mb-188 {
			margin-bottom: 188px;
		}
		.md-mb-189 {
			margin-bottom: 189px;
		}
		.md-mb-190 {
			margin-bottom: 190px;
		}
		.md-mb-191 {
			margin-bottom: 191px;
		}
		.md-mb-192 {
			margin-bottom: 192px;
		}
		.md-mb-193 {
			margin-bottom: 193px;
		}
		.md-mb-194 {
			margin-bottom: 194px;
		}
		.md-mb-195 {
			margin-bottom: 195px;
		}
		.md-mb-196 {
			margin-bottom: 196px;
		}
		.md-mb-197 {
			margin-bottom: 197px;
		}
		.md-mb-198 {
			margin-bottom: 198px;
		}
		.md-mb-199 {
			margin-bottom: 199px;
		}
		.md-mb-200 {
			margin-bottom: 200px;
		}
	/*}*/

	/*------------------------------------------
		31. MD MARGIN RIGHT
	-------------------------------------------*/
	/*.md-mr {*/
		.md-mr-0 {
			margin-right: 0px;
		}
		.md-mr-1 {
			margin-right: 1px;
		}
		.md-mr-2 {
			margin-right: 2px;
		}
		.md-mr-3 {
			margin-right: 3px;
		}
		.md-mr-4 {
			margin-right: 4px;
		}
		.md-mr-5 {
			margin-right: 5px;
		}
		.md-mr-6 {
			margin-right: 6px;
		}
		.md-mr-7 {
			margin-right: 7px;
		}
		.md-mr-8 {
			margin-right: 8px;
		}
		.md-mr-9 {
			margin-right: 9px;
		}
		.md-mr-10 {
			margin-right: 10px;
		}
		.md-mr-11 {
			margin-right: 11px;
		}
		.md-mr-12 {
			margin-right: 12px;
		}
		.md-mr-13 {
			margin-right: 13px;
		}
		.md-mr-14 {
			margin-right: 14px;
		}
		.md-mr-15 {
			margin-right: 15px;
		}
		.md-mr-16 {
			margin-right: 16px;
		}
		.md-mr-17 {
			margin-right: 17px;
		}
		.md-mr-18 {
			margin-right: 18px;
		}
		.md-mr-19 {
			margin-right: 19px;
		}
		.md-mr-20 {
			margin-right: 20px;
		}
		.md-mr-21 {
			margin-right: 21px;
		}
		.md-mr-22 {
			margin-right: 22px;
		}
		.md-mr-23 {
			margin-right: 23px;
		}
		.md-mr-24 {
			margin-right: 24px;
		}
		.md-mr-25 {
			margin-right: 25px;
		}
		.md-mr-26 {
			margin-right: 26px;
		}
		.md-mr-27 {
			margin-right: 27px;
		}
		.md-mr-28 {
			margin-right: 28px;
		}
		.md-mr-29 {
			margin-right: 29px;
		}
		.md-mr-30 {
			margin-right: 30px;
		}
		.md-mr-31 {
			margin-right: 31px;
		}
		.md-mr-32 {
			margin-right: 32px;
		}
		.md-mr-33 {
			margin-right: 33px;
		}
		.md-mr-34 {
			margin-right: 34px;
		}
		.md-mr-35 {
			margin-right: 35px;
		}
		.md-mr-36 {
			margin-right: 36px;
		}
		.md-mr-37 {
			margin-right: 37px;
		}
		.md-mr-38 {
			margin-right: 38px;
		}
		.md-mr-39 {
			margin-right: 39px;
		}
		.md-mr-40 {
			margin-right: 40px;
		}
		.md-mr-41 {
			margin-right: 41px;
		}
		.md-mr-42 {
			margin-right: 42px;
		}
		.md-mr-43 {
			margin-right: 43px;
		}
		.md-mr-44 {
			margin-right: 44px;
		}
		.md-mr-45 {
			margin-right: 45px;
		}
		.md-mr-46 {
			margin-right: 46px;
		}
		.md-mr-47 {
			margin-right: 47px;
		}
		.md-mr-48 {
			margin-right: 48px;
		}
		.md-mr-49 {
			margin-right: 49px;
		}
		.md-mr-50 {
			margin-right: 50px;
		}
		.md-mr-51 {
			margin-right: 51px;
		}
		.md-mr-52 {
			margin-right: 52px;
		}
		.md-mr-53 {
			margin-right: 53px;
		}
		.md-mr-54 {
			margin-right: 54px;
		}
		.md-mr-55 {
			margin-right: 55px;
		}
		.md-mr-56 {
			margin-right: 56px;
		}
		.md-mr-57 {
			margin-right: 57px;
		}
		.md-mr-58 {
			margin-right: 58px;
		}
		.md-mr-59 {
			margin-right: 59px;
		}
		.md-mr-60 {
			margin-right: 60px;
		}
		.md-mr-61 {
			margin-right: 61px;
		}
		.md-mr-62 {
			margin-right: 62px;
		}
		.md-mr-63 {
			margin-right: 63px;
		}
		.md-mr-64 {
			margin-right: 64px;
		}
		.md-mr-65 {
			margin-right: 65px;
		}
		.md-mr-66 {
			margin-right: 66px;
		}
		.md-mr-67 {
			margin-right: 67px;
		}
		.md-mr-68 {
			margin-right: 68px;
		}
		.md-mr-69 {
			margin-right: 69px;
		}
		.md-mr-70 {
			margin-right: 70px;
		}
		.md-mr-71 {
			margin-right: 71px;
		}
		.md-mr-72 {
			margin-right: 72px;
		}
		.md-mr-73 {
			margin-right: 73px;
		}
		.md-mr-74 {
			margin-right: 74px;
		}
		.md-mr-75 {
			margin-right: 75px;
		}
		.md-mr-76 {
			margin-right: 76px;
		}
		.md-mr-77 {
			margin-right: 77px;
		}
		.md-mr-78 {
			margin-right: 78px;
		}
		.md-mr-79 {
			margin-right: 79px;
		}
		.md-mr-80 {
			margin-right: 80px;
		}
		.md-mr-81 {
			margin-right: 81px;
		}
		.md-mr-82 {
			margin-right: 82px;
		}
		.md-mr-83 {
			margin-right: 83px;
		}
		.md-mr-84 {
			margin-right: 84px;
		}
		.md-mr-85 {
			margin-right: 85px;
		}
		.md-mr-86 {
			margin-right: 86px;
		}
		.md-mr-87 {
			margin-right: 87px;
		}
		.md-mr-88 {
			margin-right: 88px;
		}
		.md-mr-89 {
			margin-right: 89px;
		}
		.md-mr-90 {
			margin-right: 90px;
		}
		.md-mr-91 {
			margin-right: 91px;
		}
		.md-mr-92 {
			margin-right: 92px;
		}
		.md-mr-93 {
			margin-right: 93px;
		}
		.md-mr-94 {
			margin-right: 94px;
		}
		.md-mr-95 {
			margin-right: 95px;
		}
		.md-mr-96 {
			margin-right: 96px;
		}
		.md-mr-97 {
			margin-right: 97px;
		}
		.md-mr-98 {
			margin-right: 98px;
		}
		.md-mr-99 {
			margin-right: 99px;
		}
		.md-mr-100 {
			margin-right: 100px;
		}
	/*}*/

	/*------------------------------------------
		32. MD MARGIN LEFT
	-------------------------------------------*/
	/*.md-ml {*/
		.md-ml-0 {
			margin-left: 0px;
		}
		.md-ml-1 {
			margin-left: 1px;
		}
		.md-ml-2 {
			margin-left: 2px;
		}
		.md-ml-3 {
			margin-left: 3px;
		}
		.md-ml-4 {
			margin-left: 4px;
		}
		.md-ml-5 {
			margin-left: 5px;
		}
		.md-ml-6 {
			margin-left: 6px;
		}
		.md-ml-7 {
			margin-left: 7px;
		}
		.md-ml-8 {
			margin-left: 8px;
		}
		.md-ml-9 {
			margin-left: 9px;
		}
		.md-ml-10 {
			margin-left: 10px;
		}
		.md-ml-11 {
			margin-left: 11px;
		}
		.md-ml-12 {
			margin-left: 12px;
		}
		.md-ml-13 {
			margin-left: 13px;
		}
		.md-ml-14 {
			margin-left: 14px;
		}
		.md-ml-15 {
			margin-left: 15px;
		}
		.md-ml-16 {
			margin-left: 16px;
		}
		.md-ml-17 {
			margin-left: 17px;
		}
		.md-ml-18 {
			margin-left: 18px;
		}
		.md-ml-19 {
			margin-left: 19px;
		}
		.md-ml-20 {
			margin-left: 20px;
		}
		.md-ml-21 {
			margin-left: 21px;
		}
		.md-ml-22 {
			margin-left: 22px;
		}
		.md-ml-23 {
			margin-left: 23px;
		}
		.md-ml-24 {
			margin-left: 24px;
		}
		.md-ml-25 {
			margin-left: 25px;
		}
		.md-ml-26 {
			margin-left: 26px;
		}
		.md-ml-27 {
			margin-left: 27px;
		}
		.md-ml-28 {
			margin-left: 28px;
		}
		.md-ml-29 {
			margin-left: 29px;
		}
		.md-ml-30 {
			margin-left: 30px;
		}
		.md-ml-31 {
			margin-left: 31px;
		}
		.md-ml-32 {
			margin-left: 32px;
		}
		.md-ml-33 {
			margin-left: 33px;
		}
		.md-ml-34 {
			margin-left: 34px;
		}
		.md-ml-35 {
			margin-left: 35px;
		}
		.md-ml-36 {
			margin-left: 36px;
		}
		.md-ml-37 {
			margin-left: 37px;
		}
		.md-ml-38 {
			margin-left: 38px;
		}
		.md-ml-39 {
			margin-left: 39px;
		}
		.md-ml-40 {
			margin-left: 40px;
		}
		.md-ml-41 {
			margin-left: 41px;
		}
		.md-ml-42 {
			margin-left: 42px;
		}
		.md-ml-43 {
			margin-left: 43px;
		}
		.md-ml-44 {
			margin-left: 44px;
		}
		.md-ml-45 {
			margin-left: 45px;
		}
		.md-ml-46 {
			margin-left: 46px;
		}
		.md-ml-47 {
			margin-left: 47px;
		}
		.md-ml-48 {
			margin-left: 48px;
		}
		.md-ml-49 {
			margin-left: 49px;
		}
		.md-ml-50 {
			margin-left: 50px;
		}
		.md-ml-51 {
			margin-left: 51px;
		}
		.md-ml-52 {
			margin-left: 52px;
		}
		.md-ml-53 {
			margin-left: 53px;
		}
		.md-ml-54 {
			margin-left: 54px;
		}
		.md-ml-55 {
			margin-left: 55px;
		}
		.md-ml-56 {
			margin-left: 56px;
		}
		.md-ml-57 {
			margin-left: 57px;
		}
		.md-ml-58 {
			margin-left: 58px;
		}
		.md-ml-59 {
			margin-left: 59px;
		}
		.md-ml-60 {
			margin-left: 60px;
		}
		.md-ml-61 {
			margin-left: 61px;
		}
		.md-ml-62 {
			margin-left: 62px;
		}
		.md-ml-63 {
			margin-left: 63px;
		}
		.md-ml-64 {
			margin-left: 64px;
		}
		.md-ml-65 {
			margin-left: 65px;
		}
		.md-ml-66 {
			margin-left: 66px;
		}
		.md-ml-67 {
			margin-left: 67px;
		}
		.md-ml-68 {
			margin-left: 68px;
		}
		.md-ml-69 {
			margin-left: 69px;
		}
		.md-ml-70 {
			margin-left: 70px;
		}
		.md-ml-71 {
			margin-left: 71px;
		}
		.md-ml-72 {
			margin-left: 72px;
		}
		.md-ml-73 {
			margin-left: 73px;
		}
		.md-ml-74 {
			margin-left: 74px;
		}
		.md-ml-75 {
			margin-left: 75px;
		}
		.md-ml-76 {
			margin-left: 76px;
		}
		.md-ml-77 {
			margin-left: 77px;
		}
		.md-ml-78 {
			margin-left: 78px;
		}
		.md-ml-79 {
			margin-left: 79px;
		}
		.md-ml-80 {
			margin-left: 80px;
		}
		.md-ml-81 {
			margin-left: 81px;
		}
		.md-ml-82 {
			margin-left: 82px;
		}
		.md-ml-83 {
			margin-left: 83px;
		}
		.md-ml-84 {
			margin-left: 84px;
		}
		.md-ml-85 {
			margin-left: 85px;
		}
		.md-ml-86 {
			margin-left: 86px;
		}
		.md-ml-87 {
			margin-left: 87px;
		}
		.md-ml-88 {
			margin-left: 88px;
		}
		.md-ml-89 {
			margin-left: 89px;
		}
		.md-ml-90 {
			margin-left: 90px;
		}
		.md-ml-91 {
			margin-left: 91px;
		}
		.md-ml-92 {
			margin-left: 92px;
		}
		.md-ml-93 {
			margin-left: 93px;
		}
		.md-ml-94 {
			margin-left: 94px;
		}
		.md-ml-95 {
			margin-left: 95px;
		}
		.md-ml-96 {
			margin-left: 96px;
		}
		.md-ml-97 {
			margin-left: 97px;
		}
		.md-ml-98 {
			margin-left: 98px;
		}
		.md-ml-99 {
			margin-left: 99px;
		}
		.md-ml-100 {
			margin-left: 100px;
		}
	/*}*/

	/*------------------------------------------
		33. MD MARGIN TOP REVERSE
	-------------------------------------------*/
	/*.md-mt- {*/
		.md-mt--1 {
			margin-top: -1px;
		}
		.md-mt--2 {
			margin-top: -2px;
		}
		.md-mt--3 {
			margin-top: -3px;
		}
		.md-mt--4 {
			margin-top: -4px;
		}
		.md-mt--5 {
			margin-top: -5px;
		}
		.md-mt--6 {
			margin-top: -6px;
		}
		.md-mt--7 {
			margin-top: -7px;
		}
		.md-mt--8 {
			margin-top: -8px;
		}
		.md-mt--9 {
			margin-top: -9px;
		}
		.md-mt--10 {
			margin-top: -10px;
		}
		.md-mt--11 {
			margin-top: -11px;
		}
		.md-mt--12 {
			margin-top: -12px;
		}
		.md-mt--13 {
			margin-top: -13px;
		}
		.md-mt--14 {
			margin-top: -14px;
		}
		.md-mt--15 {
			margin-top: -15px;
		}
		.md-mt--16 {
			margin-top: -16px;
		}
		.md-mt--17 {
			margin-top: -17px;
		}
		.md-mt--18 {
			margin-top: -18px;
		}
		.md-mt--19 {
			margin-top: -19px;
		}
		.md-mt--20 {
			margin-top: -20px;
		}
		.md-mt--21 {
			margin-top: -21px;
		}
		.md-mt--22 {
			margin-top: -22px;
		}
		.md-mt--23 {
			margin-top: -23px;
		}
		.md-mt--24 {
			margin-top: -24px;
		}
		.md-mt--25 {
			margin-top: -25px;
		}
		.md-mt--26 {
			margin-top: -26px;
		}
		.md-mt--27 {
			margin-top: -27px;
		}
		.md-mt--28 {
			margin-top: -28px;
		}
		.md-mt--29 {
			margin-top: -29px;
		}
		.md-mt--30 {
			margin-top: -30px;
		}
		.md-mt--31 {
			margin-top: -31px;
		}
		.md-mt--32 {
			margin-top: -32px;
		}
		.md-mt--33 {
			margin-top: -33px;
		}
		.md-mt--34 {
			margin-top: -34px;
		}
		.md-mt--35 {
			margin-top: -35px;
		}
		.md-mt--36 {
			margin-top: -36px;
		}
		.md-mt--37 {
			margin-top: -37px;
		}
		.md-mt--38 {
			margin-top: -38px;
		}
		.md-mt--39 {
			margin-top: -39px;
		}
		.md-mt--40 {
			margin-top: -40px;
		}
		.md-mt--41 {
			margin-top: -41px;
		}
		.md-mt--42 {
			margin-top: -42px;
		}
		.md-mt--43 {
			margin-top: -43px;
		}
		.md-mt--44 {
			margin-top: -44px;
		}
		.md-mt--45 {
			margin-top: -45px;
		}
		.md-mt--46 {
			margin-top: -46px;
		}
		.md-mt--47 {
			margin-top: -47px;
		}
		.md-mt--48 {
			margin-top: -48px;
		}
		.md-mt--49 {
			margin-top: -49px;
		}
		.md-mt--50 {
			margin-top: -50px;
		}
		.md-mt--51 {
			margin-top: -51px;
		}
		.md-mt--52 {
			margin-top: -52px;
		}
		.md-mt--53 {
			margin-top: -53px;
		}
		.md-mt--54 {
			margin-top: -54px;
		}
		.md-mt--55 {
			margin-top: -55px;
		}
		.md-mt--56 {
			margin-top: -56px;
		}
		.md-mt--57 {
			margin-top: -57px;
		}
		.md-mt--58 {
			margin-top: -58px;
		}
		.md-mt--59 {
			margin-top: -59px;
		}
		.md-mt--60 {
			margin-top: -60px;
		}
		.md-mt--61 {
			margin-top: -61px;
		}
		.md-mt--62 {
			margin-top: -62px;
		}
		.md-mt--63 {
			margin-top: -63px;
		}
		.md-mt--64 {
			margin-top: -64px;
		}
		.md-mt--65 {
			margin-top: -65px;
		}
		.md-mt--66 {
			margin-top: -66px;
		}
		.md-mt--67 {
			margin-top: -67px;
		}
		.md-mt--68 {
			margin-top: -68px;
		}
		.md-mt--69 {
			margin-top: -69px;
		}
		.md-mt--70 {
			margin-top: -70px;
		}
		.md-mt--71 {
			margin-top: -71px;
		}
		.md-mt--72 {
			margin-top: -72px;
		}
		.md-mt--73 {
			margin-top: -73px;
		}
		.md-mt--74 {
			margin-top: -74px;
		}
		.md-mt--75 {
			margin-top: -75px;
		}
		.md-mt--76 {
			margin-top: -76px;
		}
		.md-mt--77 {
			margin-top: -77px;
		}
		.md-mt--78 {
			margin-top: -78px;
		}
		.md-mt--79 {
			margin-top: -79px;
		}
		.md-mt--80 {
			margin-top: -80px;
		}
		.md-mt--81 {
			margin-top: -81px;
		}
		.md-mt--82 {
			margin-top: -82px;
		}
		.md-mt--83 {
			margin-top: -83px;
		}
		.md-mt--84 {
			margin-top: -84px;
		}
		.md-mt--85 {
			margin-top: -85px;
		}
		.md-mt--86 {
			margin-top: -86px;
		}
		.md-mt--87 {
			margin-top: -87px;
		}
		.md-mt--88 {
			margin-top: -88px;
		}
		.md-mt--89 {
			margin-top: -89px;
		}
		.md-mt--90 {
			margin-top: -90px;
		}
		.md-mt--91 {
			margin-top: -91px;
		}
		.md-mt--92 {
			margin-top: -92px;
		}
		.md-mt--93 {
			margin-top: -93px;
		}
		.md-mt--94 {
			margin-top: -94px;
		}
		.md-mt--95 {
			margin-top: -95px;
		}
		.md-mt--96 {
			margin-top: -96px;
		}
		.md-mt--97 {
			margin-top: -97px;
		}
		.md-mt--98 {
			margin-top: -98px;
		}
		.md-mt--99 {
			margin-top: -99px;
		}
		.md-mt--100 {
			margin-top: -100px;
		}
		.md-mt--101 {
			margin-top: -101px;
		}
		.md-mt--102 {
			margin-top: -102px;
		}
		.md-mt--103 {
			margin-top: -103px;
		}
		.md-mt--104 {
			margin-top: -104px;
		}
		.md-mt--105 {
			margin-top: -105px;
		}
		.md-mt--106 {
			margin-top: -106px;
		}
		.md-mt--107 {
			margin-top: -107px;
		}
		.md-mt--108 {
			margin-top: -108px;
		}
		.md-mt--109 {
			margin-top: -109px;
		}
		.md-mt--110 {
			margin-top: -110px;
		}
		.md-mt--111 {
			margin-top: -111px;
		}
		.md-mt--112 {
			margin-top: -112px;
		}
		.md-mt--113 {
			margin-top: -113px;
		}
		.md-mt--114 {
			margin-top: -114px;
		}
		.md-mt--115 {
			margin-top: -115px;
		}
		.md-mt--116 {
			margin-top: -116px;
		}
		.md-mt--117 {
			margin-top: -117px;
		}
		.md-mt--118 {
			margin-top: -118px;
		}
		.md-mt--119 {
			margin-top: -119px;
		}
		.md-mt--120 {
			margin-top: -120px;
		}
		.md-mt--121 {
			margin-top: -121px;
		}
		.md-mt--122 {
			margin-top: -122px;
		}
		.md-mt--123 {
			margin-top: -123px;
		}
		.md-mt--124 {
			margin-top: -124px;
		}
		.md-mt--125 {
			margin-top: -125px;
		}
		.md-mt--126 {
			margin-top: -126px;
		}
		.md-mt--127 {
			margin-top: -127px;
		}
		.md-mt--128 {
			margin-top: -128px;
		}
		.md-mt--129 {
			margin-top: -129px;
		}
		.md-mt--130 {
			margin-top: -130px;
		}
		.md-mt--131 {
			margin-top: -131px;
		}
		.md-mt--132 {
			margin-top: -132px;
		}
		.md-mt--133 {
			margin-top: -133px;
		}
		.md-mt--134 {
			margin-top: -134px;
		}
		.md-mt--135 {
			margin-top: -135px;
		}
		.md-mt--136 {
			margin-top: -136px;
		}
		.md-mt--137 {
			margin-top: -137px;
		}
		.md-mt--138 {
			margin-top: -138px;
		}
		.md-mt--139 {
			margin-top: -139px;
		}
		.md-mt--140 {
			margin-top: -140px;
		}
		.md-mt--141 {
			margin-top: -141px;
		}
		.md-mt--142 {
			margin-top: -142px;
		}
		.md-mt--143 {
			margin-top: -143px;
		}
		.md-mt--144 {
			margin-top: -144px;
		}
		.md-mt--145 {
			margin-top: -145px;
		}
		.md-mt--146 {
			margin-top: -146px;
		}
		.md-mt--147 {
			margin-top: -147px;
		}
		.md-mt--148 {
			margin-top: -148px;
		}
		.md-mt--149 {
			margin-top: -149px;
		}
		.md-mt--150 {
			margin-top: -150px;
		}
		.md-mt--151 {
			margin-top: -151px;
		}
		.md-mt--152 {
			margin-top: -152px;
		}
		.md-mt--153 {
			margin-top: -153px;
		}
		.md-mt--154 {
			margin-top: -154px;
		}
		.md-mt--155 {
			margin-top: -155px;
		}
		.md-mt--156 {
			margin-top: -156px;
		}
		.md-mt--157 {
			margin-top: -157px;
		}
		.md-mt--158 {
			margin-top: -158px;
		}
		.md-mt--159 {
			margin-top: -159px;
		}
		.md-mt--160 {
			margin-top: -160px;
		}
		.md-mt--161 {
			margin-top: -161px;
		}
		.md-mt--162 {
			margin-top: -162px;
		}
		.md-mt--163 {
			margin-top: -163px;
		}
		.md-mt--164 {
			margin-top: -164px;
		}
		.md-mt--165 {
			margin-top: -165px;
		}
		.md-mt--166 {
			margin-top: -166px;
		}
		.md-mt--167 {
			margin-top: -167px;
		}
		.md-mt--168 {
			margin-top: -168px;
		}
		.md-mt--169 {
			margin-top: -169px;
		}
		.md-mt--170 {
			margin-top: -170px;
		}
		.md-mt--171 {
			margin-top: -171px;
		}
		.md-mt--172 {
			margin-top: -172px;
		}
		.md-mt--173 {
			margin-top: -173px;
		}
		.md-mt--174 {
			margin-top: -174px;
		}
		.md-mt--175 {
			margin-top: -175px;
		}
		.md-mt--176 {
			margin-top: -176px;
		}
		.md-mt--177 {
			margin-top: -177px;
		}
		.md-mt--178 {
			margin-top: -178px;
		}
		.md-mt--179 {
			margin-top: -179px;
		}
		.md-mt--180 {
			margin-top: -180px;
		}
		.md-mt--181 {
			margin-top: -181px;
		}
		.md-mt--182 {
			margin-top: -182px;
		}
		.md-mt--183 {
			margin-top: -183px;
		}
		.md-mt--184 {
			margin-top: -184px;
		}
		.md-mt--185 {
			margin-top: -185px;
		}
		.md-mt--186 {
			margin-top: -186px;
		}
		.md-mt--187 {
			margin-top: -187px;
		}
		.md-mt--188 {
			margin-top: -188px;
		}
		.md-mt--189 {
			margin-top: -189px;
		}
		.md-mt--190 {
			margin-top: -190px;
		}
		.md-mt--191 {
			margin-top: -191px;
		}
		.md-mt--192 {
			margin-top: -192px;
		}
		.md-mt--193 {
			margin-top: -193px;
		}
		.md-mt--194 {
			margin-top: -194px;
		}
		.md-mt--195 {
			margin-top: -195px;
		}
		.md-mt--196 {
			margin-top: -196px;
		}
		.md-mt--197 {
			margin-top: -197px;
		}
		.md-mt--198 {
			margin-top: -198px;
		}
		.md-mt--199 {
			margin-top: -199px;
		}
		.md-mt--200 {
			margin-top: -200px;
		}
	/*}*/

	/*------------------------------------------
		34. MD MARGIN BOTTOM REVERSE
	-------------------------------------------*/
	/*.md-mb- {*/
		.md-mb--1 {
			margin-bottom: -1px;
		}
		.md-mb--2 {
			margin-bottom: -2px;
		}
		.md-mb--3 {
			margin-bottom: -3px;
		}
		.md-mb--4 {
			margin-bottom: -4px;
		}
		.md-mb--5 {
			margin-bottom: -5px;
		}
		.md-mb--6 {
			margin-bottom: -6px;
		}
		.md-mb--7 {
			margin-bottom: -7px;
		}
		.md-mb--8 {
			margin-bottom: -8px;
		}
		.md-mb--9 {
			margin-bottom: -9px;
		}
		.md-mb--10 {
			margin-bottom: -10px;
		}
		.md-mb--11 {
			margin-bottom: -11px;
		}
		.md-mb--12 {
			margin-bottom: -12px;
		}
		.md-mb--13 {
			margin-bottom: -13px;
		}
		.md-mb--14 {
			margin-bottom: -14px;
		}
		.md-mb--15 {
			margin-bottom: -15px;
		}
		.md-mb--16 {
			margin-bottom: -16px;
		}
		.md-mb--17 {
			margin-bottom: -17px;
		}
		.md-mb--18 {
			margin-bottom: -18px;
		}
		.md-mb--19 {
			margin-bottom: -19px;
		}
		.md-mb--20 {
			margin-bottom: -20px;
		}
		.md-mb--21 {
			margin-bottom: -21px;
		}
		.md-mb--22 {
			margin-bottom: -22px;
		}
		.md-mb--23 {
			margin-bottom: -23px;
		}
		.md-mb--24 {
			margin-bottom: -24px;
		}
		.md-mb--25 {
			margin-bottom: -25px;
		}
		.md-mb--26 {
			margin-bottom: -26px;
		}
		.md-mb--27 {
			margin-bottom: -27px;
		}
		.md-mb--28 {
			margin-bottom: -28px;
		}
		.md-mb--29 {
			margin-bottom: -29px;
		}
		.md-mb--30 {
			margin-bottom: -30px;
		}
		.md-mb--31 {
			margin-bottom: -31px;
		}
		.md-mb--32 {
			margin-bottom: -32px;
		}
		.md-mb--33 {
			margin-bottom: -33px;
		}
		.md-mb--34 {
			margin-bottom: -34px;
		}
		.md-mb--35 {
			margin-bottom: -35px;
		}
		.md-mb--36 {
			margin-bottom: -36px;
		}
		.md-mb--37 {
			margin-bottom: -37px;
		}
		.md-mb--38 {
			margin-bottom: -38px;
		}
		.md-mb--39 {
			margin-bottom: -39px;
		}
		.md-mb--40 {
			margin-bottom: -40px;
		}
		.md-mb--41 {
			margin-bottom: -41px;
		}
		.md-mb--42 {
			margin-bottom: -42px;
		}
		.md-mb--43 {
			margin-bottom: -43px;
		}
		.md-mb--44 {
			margin-bottom: -44px;
		}
		.md-mb--45 {
			margin-bottom: -45px;
		}
		.md-mb--46 {
			margin-bottom: -46px;
		}
		.md-mb--47 {
			margin-bottom: -47px;
		}
		.md-mb--48 {
			margin-bottom: -48px;
		}
		.md-mb--49 {
			margin-bottom: -49px;
		}
		.md-mb--50 {
			margin-bottom: -50px;
		}
		.md-mb--51 {
			margin-bottom: -51px;
		}
		.md-mb--52 {
			margin-bottom: -52px;
		}
		.md-mb--53 {
			margin-bottom: -53px;
		}
		.md-mb--54 {
			margin-bottom: -54px;
		}
		.md-mb--55 {
			margin-bottom: -55px;
		}
		.md-mb--56 {
			margin-bottom: -56px;
		}
		.md-mb--57 {
			margin-bottom: -57px;
		}
		.md-mb--58 {
			margin-bottom: -58px;
		}
		.md-mb--59 {
			margin-bottom: -59px;
		}
		.md-mb--60 {
			margin-bottom: -60px;
		}
		.md-mb--61 {
			margin-bottom: -61px;
		}
		.md-mb--62 {
			margin-bottom: -62px;
		}
		.md-mb--63 {
			margin-bottom: -63px;
		}
		.md-mb--64 {
			margin-bottom: -64px;
		}
		.md-mb--65 {
			margin-bottom: -65px;
		}
		.md-mb--66 {
			margin-bottom: -66px;
		}
		.md-mb--67 {
			margin-bottom: -67px;
		}
		.md-mb--68 {
			margin-bottom: -68px;
		}
		.md-mb--69 {
			margin-bottom: -69px;
		}
		.md-mb--70 {
			margin-bottom: -70px;
		}
		.md-mb--71 {
			margin-bottom: -71px;
		}
		.md-mb--72 {
			margin-bottom: -72px;
		}
		.md-mb--73 {
			margin-bottom: -73px;
		}
		.md-mb--74 {
			margin-bottom: -74px;
		}
		.md-mb--75 {
			margin-bottom: -75px;
		}
		.md-mb--76 {
			margin-bottom: -76px;
		}
		.md-mb--77 {
			margin-bottom: -77px;
		}
		.md-mb--78 {
			margin-bottom: -78px;
		}
		.md-mb--79 {
			margin-bottom: -79px;
		}
		.md-mb--80 {
			margin-bottom: -80px;
		}
		.md-mb--81 {
			margin-bottom: -81px;
		}
		.md-mb--82 {
			margin-bottom: -82px;
		}
		.md-mb--83 {
			margin-bottom: -83px;
		}
		.md-mb--84 {
			margin-bottom: -84px;
		}
		.md-mb--85 {
			margin-bottom: -85px;
		}
		.md-mb--86 {
			margin-bottom: -86px;
		}
		.md-mb--87 {
			margin-bottom: -87px;
		}
		.md-mb--88 {
			margin-bottom: -88px;
		}
		.md-mb--89 {
			margin-bottom: -89px;
		}
		.md-mb--90 {
			margin-bottom: -90px;
		}
		.md-mb--91 {
			margin-bottom: -91px;
		}
		.md-mb--92 {
			margin-bottom: -92px;
		}
		.md-mb--93 {
			margin-bottom: -93px;
		}
		.md-mb--94 {
			margin-bottom: -94px;
		}
		.md-mb--95 {
			margin-bottom: -95px;
		}
		.md-mb--96 {
			margin-bottom: -96px;
		}
		.md-mb--97 {
			margin-bottom: -97px;
		}
		.md-mb--98 {
			margin-bottom: -98px;
		}
		.md-mb--99 {
			margin-bottom: -99px;
		}
		.md-mb--100 {
			margin-bottom: -100px;
		}
		.md-mb--101 {
			margin-bottom: -101px;
		}
		.md-mb--102 {
			margin-bottom: -102px;
		}
		.md-mb--103 {
			margin-bottom: -103px;
		}
		.md-mb--104 {
			margin-bottom: -104px;
		}
		.md-mb--105 {
			margin-bottom: -105px;
		}
		.md-mb--106 {
			margin-bottom: -106px;
		}
		.md-mb--107 {
			margin-bottom: -107px;
		}
		.md-mb--108 {
			margin-bottom: -108px;
		}
		.md-mb--109 {
			margin-bottom: -109px;
		}
		.md-mb--110 {
			margin-bottom: -110px;
		}
		.md-mb--111 {
			margin-bottom: -111px;
		}
		.md-mb--112 {
			margin-bottom: -112px;
		}
		.md-mb--113 {
			margin-bottom: -113px;
		}
		.md-mb--114 {
			margin-bottom: -114px;
		}
		.md-mb--115 {
			margin-bottom: -115px;
		}
		.md-mb--116 {
			margin-bottom: -116px;
		}
		.md-mb--117 {
			margin-bottom: -117px;
		}
		.md-mb--118 {
			margin-bottom: -118px;
		}
		.md-mb--119 {
			margin-bottom: -119px;
		}
		.md-mb--120 {
			margin-bottom: -120px;
		}
		.md-mb--121 {
			margin-bottom: -121px;
		}
		.md-mb--122 {
			margin-bottom: -122px;
		}
		.md-mb--123 {
			margin-bottom: -123px;
		}
		.md-mb--124 {
			margin-bottom: -124px;
		}
		.md-mb--125 {
			margin-bottom: -125px;
		}
		.md-mb--126 {
			margin-bottom: -126px;
		}
		.md-mb--127 {
			margin-bottom: -127px;
		}
		.md-mb--128 {
			margin-bottom: -128px;
		}
		.md-mb--129 {
			margin-bottom: -129px;
		}
		.md-mb--130 {
			margin-bottom: -130px;
		}
		.md-mb--131 {
			margin-bottom: -131px;
		}
		.md-mb--132 {
			margin-bottom: -132px;
		}
		.md-mb--133 {
			margin-bottom: -133px;
		}
		.md-mb--134 {
			margin-bottom: -134px;
		}
		.md-mb--135 {
			margin-bottom: -135px;
		}
		.md-mb--136 {
			margin-bottom: -136px;
		}
		.md-mb--137 {
			margin-bottom: -137px;
		}
		.md-mb--138 {
			margin-bottom: -138px;
		}
		.md-mb--139 {
			margin-bottom: -139px;
		}
		.md-mb--140 {
			margin-bottom: -140px;
		}
		.md-mb--141 {
			margin-bottom: -141px;
		}
		.md-mb--142 {
			margin-bottom: -142px;
		}
		.md-mb--143 {
			margin-bottom: -143px;
		}
		.md-mb--144 {
			margin-bottom: -144px;
		}
		.md-mb--145 {
			margin-bottom: -145px;
		}
		.md-mb--146 {
			margin-bottom: -146px;
		}
		.md-mb--147 {
			margin-bottom: -147px;
		}
		.md-mb--148 {
			margin-bottom: -148px;
		}
		.md-mb--149 {
			margin-bottom: -149px;
		}
		.md-mb--150 {
			margin-bottom: -150px;
		}
		.md-mb--151 {
			margin-bottom: -151px;
		}
		.md-mb--152 {
			margin-bottom: -152px;
		}
		.md-mb--153 {
			margin-bottom: -153px;
		}
		.md-mb--154 {
			margin-bottom: -154px;
		}
		.md-mb--155 {
			margin-bottom: -155px;
		}
		.md-mb--156 {
			margin-bottom: -156px;
		}
		.md-mb--157 {
			margin-bottom: -157px;
		}
		.md-mb--158 {
			margin-bottom: -158px;
		}
		.md-mb--159 {
			margin-bottom: -159px;
		}
		.md-mb--160 {
			margin-bottom: -160px;
		}
		.md-mb--161 {
			margin-bottom: -161px;
		}
		.md-mb--162 {
			margin-bottom: -162px;
		}
		.md-mb--163 {
			margin-bottom: -163px;
		}
		.md-mb--164 {
			margin-bottom: -164px;
		}
		.md-mb--165 {
			margin-bottom: -165px;
		}
		.md-mb--166 {
			margin-bottom: -166px;
		}
		.md-mb--167 {
			margin-bottom: -167px;
		}
		.md-mb--168 {
			margin-bottom: -168px;
		}
		.md-mb--169 {
			margin-bottom: -169px;
		}
		.md-mb--170 {
			margin-bottom: -170px;
		}
		.md-mb--171 {
			margin-bottom: -171px;
		}
		.md-mb--172 {
			margin-bottom: -172px;
		}
		.md-mb--173 {
			margin-bottom: -173px;
		}
		.md-mb--174 {
			margin-bottom: -174px;
		}
		.md-mb--175 {
			margin-bottom: -175px;
		}
		.md-mb--176 {
			margin-bottom: -176px;
		}
		.md-mb--177 {
			margin-bottom: -177px;
		}
		.md-mb--178 {
			margin-bottom: -178px;
		}
		.md-mb--179 {
			margin-bottom: -179px;
		}
		.md-mb--180 {
			margin-bottom: -180px;
		}
		.md-mb--181 {
			margin-bottom: -181px;
		}
		.md-mb--182 {
			margin-bottom: -182px;
		}
		.md-mb--183 {
			margin-bottom: -183px;
		}
		.md-mb--184 {
			margin-bottom: -184px;
		}
		.md-mb--185 {
			margin-bottom: -185px;
		}
		.md-mb--186 {
			margin-bottom: -186px;
		}
		.md-mb--187 {
			margin-bottom: -187px;
		}
		.md-mb--188 {
			margin-bottom: -188px;
		}
		.md-mb--189 {
			margin-bottom: -189px;
		}
		.md-mb--190 {
			margin-bottom: -190px;
		}
		.md-mb--191 {
			margin-bottom: -191px;
		}
		.md-mb--192 {
			margin-bottom: -192px;
		}
		.md-mb--193 {
			margin-bottom: -193px;
		}
		.md-mb--194 {
			margin-bottom: -194px;
		}
		.md-mb--195 {
			margin-bottom: -195px;
		}
		.md-mb--196 {
			margin-bottom: -196px;
		}
		.md-mb--197 {
			margin-bottom: -197px;
		}
		.md-mb--198 {
			margin-bottom: -198px;
		}
		.md-mb--199 {
			margin-bottom: -199px;
		}
		.md-mb--200 {
			margin-bottom: -200px;
		}
	/*}*/

	/*------------------------------------------
		35. MD MARGIN LEFT REVERSE
	-------------------------------------------*/
	/*.md-ml- {*/
		.md-ml--1 {
			margin-left: -1px;
		}
		.md-ml--2 {
			margin-left: -2px;
		}
		.md-ml--3 {
			margin-left: -3px;
		}
		.md-ml--4 {
			margin-left: -4px;
		}
		.md-ml--5 {
			margin-left: -5px;
		}
		.md-ml--6 {
			margin-left: -6px;
		}
		.md-ml--7 {
			margin-left: -7px;
		}
		.md-ml--8 {
			margin-left: -8px;
		}
		.md-ml--9 {
			margin-left: -9px;
		}
		.md-ml--10 {
			margin-left: -10px;
		}
		.md-ml--11 {
			margin-left: -11px;
		}
		.md-ml--12 {
			margin-left: -12px;
		}
		.md-ml--13 {
			margin-left: -13px;
		}
		.md-ml--14 {
			margin-left: -14px;
		}
		.md-ml--15 {
			margin-left: -15px;
		}
		.md-ml--16 {
			margin-left: -16px;
		}
		.md-ml--17 {
			margin-left: -17px;
		}
		.md-ml--18 {
			margin-left: -18px;
		}
		.md-ml--19 {
			margin-left: -19px;
		}
		.md-ml--20 {
			margin-left: -20px;
		}
		.md-ml--21 {
			margin-left: -21px;
		}
		.md-ml--22 {
			margin-left: -22px;
		}
		.md-ml--23 {
			margin-left: -23px;
		}
		.md-ml--24 {
			margin-left: -24px;
		}
		.md-ml--25 {
			margin-left: -25px;
		}
		.md-ml--26 {
			margin-left: -26px;
		}
		.md-ml--27 {
			margin-left: -27px;
		}
		.md-ml--28 {
			margin-left: -28px;
		}
		.md-ml--29 {
			margin-left: -29px;
		}
		.md-ml--30 {
			margin-left: -30px;
		}
		.md-ml--31 {
			margin-left: -31px;
		}
		.md-ml--32 {
			margin-left: -32px;
		}
		.md-ml--33 {
			margin-left: -33px;
		}
		.md-ml--34 {
			margin-left: -34px;
		}
		.md-ml--35 {
			margin-left: -35px;
		}
		.md-ml--36 {
			margin-left: -36px;
		}
		.md-ml--37 {
			margin-left: -37px;
		}
		.md-ml--38 {
			margin-left: -38px;
		}
		.md-ml--39 {
			margin-left: -39px;
		}
		.md-ml--40 {
			margin-left: -40px;
		}
		.md-ml--41 {
			margin-left: -41px;
		}
		.md-ml--42 {
			margin-left: -42px;
		}
		.md-ml--43 {
			margin-left: -43px;
		}
		.md-ml--44 {
			margin-left: -44px;
		}
		.md-ml--45 {
			margin-left: -45px;
		}
		.md-ml--46 {
			margin-left: -46px;
		}
		.md-ml--47 {
			margin-left: -47px;
		}
		.md-ml--48 {
			margin-left: -48px;
		}
		.md-ml--49 {
			margin-left: -49px;
		}
		.md-ml--50 {
			margin-left: -50px;
		}
		.md-ml--51 {
			margin-left: -51px;
		}
		.md-ml--52 {
			margin-left: -52px;
		}
		.md-ml--53 {
			margin-left: -53px;
		}
		.md-ml--54 {
			margin-left: -54px;
		}
		.md-ml--55 {
			margin-left: -55px;
		}
		.md-ml--56 {
			margin-left: -56px;
		}
		.md-ml--57 {
			margin-left: -57px;
		}
		.md-ml--58 {
			margin-left: -58px;
		}
		.md-ml--59 {
			margin-left: -59px;
		}
		.md-ml--60 {
			margin-left: -60px;
		}
		.md-ml--61 {
			margin-left: -61px;
		}
		.md-ml--62 {
			margin-left: -62px;
		}
		.md-ml--63 {
			margin-left: -63px;
		}
		.md-ml--64 {
			margin-left: -64px;
		}
		.md-ml--65 {
			margin-left: -65px;
		}
		.md-ml--66 {
			margin-left: -66px;
		}
		.md-ml--67 {
			margin-left: -67px;
		}
		.md-ml--68 {
			margin-left: -68px;
		}
		.md-ml--69 {
			margin-left: -69px;
		}
		.md-ml--70 {
			margin-left: -70px;
		}
		.md-ml--71 {
			margin-left: -71px;
		}
		.md-ml--72 {
			margin-left: -72px;
		}
		.md-ml--73 {
			margin-left: -73px;
		}
		.md-ml--74 {
			margin-left: -74px;
		}
		.md-ml--75 {
			margin-left: -75px;
		}
		.md-ml--76 {
			margin-left: -76px;
		}
		.md-ml--77 {
			margin-left: -77px;
		}
		.md-ml--78 {
			margin-left: -78px;
		}
		.md-ml--79 {
			margin-left: -79px;
		}
		.md-ml--80 {
			margin-left: -80px;
		}
		.md-ml--81 {
			margin-left: -81px;
		}
		.md-ml--82 {
			margin-left: -82px;
		}
		.md-ml--83 {
			margin-left: -83px;
		}
		.md-ml--84 {
			margin-left: -84px;
		}
		.md-ml--85 {
			margin-left: -85px;
		}
		.md-ml--86 {
			margin-left: -86px;
		}
		.md-ml--87 {
			margin-left: -87px;
		}
		.md-ml--88 {
			margin-left: -88px;
		}
		.md-ml--89 {
			margin-left: -89px;
		}
		.md-ml--90 {
			margin-left: -90px;
		}
		.md-ml--91 {
			margin-left: -91px;
		}
		.md-ml--92 {
			margin-left: -92px;
		}
		.md-ml--93 {
			margin-left: -93px;
		}
		.md-ml--94 {
			margin-left: -94px;
		}
		.md-ml--95 {
			margin-left: -95px;
		}
		.md-ml--96 {
			margin-left: -96px;
		}
		.md-ml--97 {
			margin-left: -97px;
		}
		.md-ml--98 {
			margin-left: -98px;
		}
		.md-ml--99 {
			margin-left: -99px;
		}
		.md-ml--100 {
			margin-left: -100px;
		}
		.md-ml--101 {
			margin-left: -101px;
		}
		.md-ml--102 {
			margin-left: -102px;
		}
		.md-ml--103 {
			margin-left: -103px;
		}
		.md-ml--104 {
			margin-left: -104px;
		}
		.md-ml--105 {
			margin-left: -105px;
		}
		.md-ml--106 {
			margin-left: -106px;
		}
		.md-ml--107 {
			margin-left: -107px;
		}
		.md-ml--108 {
			margin-left: -108px;
		}
		.md-ml--109 {
			margin-left: -109px;
		}
		.md-ml--110 {
			margin-left: -110px;
		}
		.md-ml--111 {
			margin-left: -111px;
		}
		.md-ml--112 {
			margin-left: -112px;
		}
		.md-ml--113 {
			margin-left: -113px;
		}
		.md-ml--114 {
			margin-left: -114px;
		}
		.md-ml--115 {
			margin-left: -115px;
		}
		.md-ml--116 {
			margin-left: -116px;
		}
		.md-ml--117 {
			margin-left: -117px;
		}
		.md-ml--118 {
			margin-left: -118px;
		}
		.md-ml--119 {
			margin-left: -119px;
		}
		.md-ml--120 {
			margin-left: -120px;
		}
		.md-ml--121 {
			margin-left: -121px;
		}
		.md-ml--122 {
			margin-left: -122px;
		}
		.md-ml--123 {
			margin-left: -123px;
		}
		.md-ml--124 {
			margin-left: -124px;
		}
		.md-ml--125 {
			margin-left: -125px;
		}
		.md-ml--126 {
			margin-left: -126px;
		}
		.md-ml--127 {
			margin-left: -127px;
		}
		.md-ml--128 {
			margin-left: -128px;
		}
		.md-ml--129 {
			margin-left: -129px;
		}
		.md-ml--130 {
			margin-left: -130px;
		}
		.md-ml--131 {
			margin-left: -131px;
		}
		.md-ml--132 {
			margin-left: -132px;
		}
		.md-ml--133 {
			margin-left: -133px;
		}
		.md-ml--134 {
			margin-left: -134px;
		}
		.md-ml--135 {
			margin-left: -135px;
		}
		.md-ml--136 {
			margin-left: -136px;
		}
		.md-ml--137 {
			margin-left: -137px;
		}
		.md-ml--138 {
			margin-left: -138px;
		}
		.md-ml--139 {
			margin-left: -139px;
		}
		.md-ml--140 {
			margin-left: -140px;
		}
		.md-ml--141 {
			margin-left: -141px;
		}
		.md-ml--142 {
			margin-left: -142px;
		}
		.md-ml--143 {
			margin-left: -143px;
		}
		.md-ml--144 {
			margin-left: -144px;
		}
		.md-ml--145 {
			margin-left: -145px;
		}
		.md-ml--146 {
			margin-left: -146px;
		}
		.md-ml--147 {
			margin-left: -147px;
		}
		.md-ml--148 {
			margin-left: -148px;
		}
		.md-ml--149 {
			margin-left: -149px;
		}
		.md-ml--150 {
			margin-left: -150px;
		}
		.md-ml--151 {
			margin-left: -151px;
		}
		.md-ml--152 {
			margin-left: -152px;
		}
		.md-ml--153 {
			margin-left: -153px;
		}
		.md-ml--154 {
			margin-left: -154px;
		}
		.md-ml--155 {
			margin-left: -155px;
		}
		.md-ml--156 {
			margin-left: -156px;
		}
		.md-ml--157 {
			margin-left: -157px;
		}
		.md-ml--158 {
			margin-left: -158px;
		}
		.md-ml--159 {
			margin-left: -159px;
		}
		.md-ml--160 {
			margin-left: -160px;
		}
		.md-ml--161 {
			margin-left: -161px;
		}
		.md-ml--162 {
			margin-left: -162px;
		}
		.md-ml--163 {
			margin-left: -163px;
		}
		.md-ml--164 {
			margin-left: -164px;
		}
		.md-ml--165 {
			margin-left: -165px;
		}
		.md-ml--166 {
			margin-left: -166px;
		}
		.md-ml--167 {
			margin-left: -167px;
		}
		.md-ml--168 {
			margin-left: -168px;
		}
		.md-ml--169 {
			margin-left: -169px;
		}
		.md-ml--170 {
			margin-left: -170px;
		}
		.md-ml--171 {
			margin-left: -171px;
		}
		.md-ml--172 {
			margin-left: -172px;
		}
		.md-ml--173 {
			margin-left: -173px;
		}
		.md-ml--174 {
			margin-left: -174px;
		}
		.md-ml--175 {
			margin-left: -175px;
		}
		.md-ml--176 {
			margin-left: -176px;
		}
		.md-ml--177 {
			margin-left: -177px;
		}
		.md-ml--178 {
			margin-left: -178px;
		}
		.md-ml--179 {
			margin-left: -179px;
		}
		.md-ml--180 {
			margin-left: -180px;
		}
		.md-ml--181 {
			margin-left: -181px;
		}
		.md-ml--182 {
			margin-left: -182px;
		}
		.md-ml--183 {
			margin-left: -183px;
		}
		.md-ml--184 {
			margin-left: -184px;
		}
		.md-ml--185 {
			margin-left: -185px;
		}
		.md-ml--186 {
			margin-left: -186px;
		}
		.md-ml--187 {
			margin-left: -187px;
		}
		.md-ml--188 {
			margin-left: -188px;
		}
		.md-ml--189 {
			margin-left: -189px;
		}
		.md-ml--190 {
			margin-left: -190px;
		}
		.md-ml--191 {
			margin-left: -191px;
		}
		.md-ml--192 {
			margin-left: -192px;
		}
		.md-ml--193 {
			margin-left: -193px;
		}
		.md-ml--194 {
			margin-left: -194px;
		}
		.md-ml--195 {
			margin-left: -195px;
		}
		.md-ml--196 {
			margin-left: -196px;
		}
		.md-ml--197 {
			margin-left: -197px;
		}
		.md-ml--198 {
			margin-left: -198px;
		}
		.md-ml--199 {
			margin-left: -199px;
		}
		.md-ml--200 {
			margin-left: -200px;
		}
	/*}*/

	/*------------------------------------------
		36. MD MARGIN RIGHT REVERSE
	-------------------------------------------*/
	/*.md-mr- {*/
		.md-mr--1 {
			margin-right: -1px;
		}
		.md-mr--2 {
			margin-right: -2px;
		}
		.md-mr--3 {
			margin-right: -3px;
		}
		.md-mr--4 {
			margin-right: -4px;
		}
		.md-mr--5 {
			margin-right: -5px;
		}
		.md-mr--6 {
			margin-right: -6px;
		}
		.md-mr--7 {
			margin-right: -7px;
		}
		.md-mr--8 {
			margin-right: -8px;
		}
		.md-mr--9 {
			margin-right: -9px;
		}
		.md-mr--10 {
			margin-right: -10px;
		}
		.md-mr--11 {
			margin-right: -11px;
		}
		.md-mr--12 {
			margin-right: -12px;
		}
		.md-mr--13 {
			margin-right: -13px;
		}
		.md-mr--14 {
			margin-right: -14px;
		}
		.md-mr--15 {
			margin-right: -15px;
		}
		.md-mr--16 {
			margin-right: -16px;
		}
		.md-mr--17 {
			margin-right: -17px;
		}
		.md-mr--18 {
			margin-right: -18px;
		}
		.md-mr--19 {
			margin-right: -19px;
		}
		.md-mr--20 {
			margin-right: -20px;
		}
		.md-mr--21 {
			margin-right: -21px;
		}
		.md-mr--22 {
			margin-right: -22px;
		}
		.md-mr--23 {
			margin-right: -23px;
		}
		.md-mr--24 {
			margin-right: -24px;
		}
		.md-mr--25 {
			margin-right: -25px;
		}
		.md-mr--26 {
			margin-right: -26px;
		}
		.md-mr--27 {
			margin-right: -27px;
		}
		.md-mr--28 {
			margin-right: -28px;
		}
		.md-mr--29 {
			margin-right: -29px;
		}
		.md-mr--30 {
			margin-right: -30px;
		}
		.md-mr--31 {
			margin-right: -31px;
		}
		.md-mr--32 {
			margin-right: -32px;
		}
		.md-mr--33 {
			margin-right: -33px;
		}
		.md-mr--34 {
			margin-right: -34px;
		}
		.md-mr--35 {
			margin-right: -35px;
		}
		.md-mr--36 {
			margin-right: -36px;
		}
		.md-mr--37 {
			margin-right: -37px;
		}
		.md-mr--38 {
			margin-right: -38px;
		}
		.md-mr--39 {
			margin-right: -39px;
		}
		.md-mr--40 {
			margin-right: -40px;
		}
		.md-mr--41 {
			margin-right: -41px;
		}
		.md-mr--42 {
			margin-right: -42px;
		}
		.md-mr--43 {
			margin-right: -43px;
		}
		.md-mr--44 {
			margin-right: -44px;
		}
		.md-mr--45 {
			margin-right: -45px;
		}
		.md-mr--46 {
			margin-right: -46px;
		}
		.md-mr--47 {
			margin-right: -47px;
		}
		.md-mr--48 {
			margin-right: -48px;
		}
		.md-mr--49 {
			margin-right: -49px;
		}
		.md-mr--50 {
			margin-right: -50px;
		}
		.md-mr--51 {
			margin-right: -51px;
		}
		.md-mr--52 {
			margin-right: -52px;
		}
		.md-mr--53 {
			margin-right: -53px;
		}
		.md-mr--54 {
			margin-right: -54px;
		}
		.md-mr--55 {
			margin-right: -55px;
		}
		.md-mr--56 {
			margin-right: -56px;
		}
		.md-mr--57 {
			margin-right: -57px;
		}
		.md-mr--58 {
			margin-right: -58px;
		}
		.md-mr--59 {
			margin-right: -59px;
		}
		.md-mr--60 {
			margin-right: -60px;
		}
		.md-mr--61 {
			margin-right: -61px;
		}
		.md-mr--62 {
			margin-right: -62px;
		}
		.md-mr--63 {
			margin-right: -63px;
		}
		.md-mr--64 {
			margin-right: -64px;
		}
		.md-mr--65 {
			margin-right: -65px;
		}
		.md-mr--66 {
			margin-right: -66px;
		}
		.md-mr--67 {
			margin-right: -67px;
		}
		.md-mr--68 {
			margin-right: -68px;
		}
		.md-mr--69 {
			margin-right: -69px;
		}
		.md-mr--70 {
			margin-right: -70px;
		}
		.md-mr--71 {
			margin-right: -71px;
		}
		.md-mr--72 {
			margin-right: -72px;
		}
		.md-mr--73 {
			margin-right: -73px;
		}
		.md-mr--74 {
			margin-right: -74px;
		}
		.md-mr--75 {
			margin-right: -75px;
		}
		.md-mr--76 {
			margin-right: -76px;
		}
		.md-mr--77 {
			margin-right: -77px;
		}
		.md-mr--78 {
			margin-right: -78px;
		}
		.md-mr--79 {
			margin-right: -79px;
		}
		.md-mr--80 {
			margin-right: -80px;
		}
		.md-mr--81 {
			margin-right: -81px;
		}
		.md-mr--82 {
			margin-right: -82px;
		}
		.md-mr--83 {
			margin-right: -83px;
		}
		.md-mr--84 {
			margin-right: -84px;
		}
		.md-mr--85 {
			margin-right: -85px;
		}
		.md-mr--86 {
			margin-right: -86px;
		}
		.md-mr--87 {
			margin-right: -87px;
		}
		.md-mr--88 {
			margin-right: -88px;
		}
		.md-mr--89 {
			margin-right: -89px;
		}
		.md-mr--90 {
			margin-right: -90px;
		}
		.md-mr--91 {
			margin-right: -91px;
		}
		.md-mr--92 {
			margin-right: -92px;
		}
		.md-mr--93 {
			margin-right: -93px;
		}
		.md-mr--94 {
			margin-right: -94px;
		}
		.md-mr--95 {
			margin-right: -95px;
		}
		.md-mr--96 {
			margin-right: -96px;
		}
		.md-mr--97 {
			margin-right: -97px;
		}
		.md-mr--98 {
			margin-right: -98px;
		}
		.md-mr--99 {
			margin-right: -99px;
		}
		.md-mr--100 {
			margin-right: -100px;
		}
		.md-mr--101 {
			margin-right: -101px;
		}
		.md-mr--102 {
			margin-right: -102px;
		}
		.md-mr--103 {
			margin-right: -103px;
		}
		.md-mr--104 {
			margin-right: -104px;
		}
		.md-mr--105 {
			margin-right: -105px;
		}
		.md-mr--106 {
			margin-right: -106px;
		}
		.md-mr--107 {
			margin-right: -107px;
		}
		.md-mr--108 {
			margin-right: -108px;
		}
		.md-mr--109 {
			margin-right: -109px;
		}
		.md-mr--110 {
			margin-right: -110px;
		}
		.md-mr--111 {
			margin-right: -111px;
		}
		.md-mr--112 {
			margin-right: -112px;
		}
		.md-mr--113 {
			margin-right: -113px;
		}
		.md-mr--114 {
			margin-right: -114px;
		}
		.md-mr--115 {
			margin-right: -115px;
		}
		.md-mr--116 {
			margin-right: -116px;
		}
		.md-mr--117 {
			margin-right: -117px;
		}
		.md-mr--118 {
			margin-right: -118px;
		}
		.md-mr--119 {
			margin-right: -119px;
		}
		.md-mr--120 {
			margin-right: -120px;
		}
		.md-mr--121 {
			margin-right: -121px;
		}
		.md-mr--122 {
			margin-right: -122px;
		}
		.md-mr--123 {
			margin-right: -123px;
		}
		.md-mr--124 {
			margin-right: -124px;
		}
		.md-mr--125 {
			margin-right: -125px;
		}
		.md-mr--126 {
			margin-right: -126px;
		}
		.md-mr--127 {
			margin-right: -127px;
		}
		.md-mr--128 {
			margin-right: -128px;
		}
		.md-mr--129 {
			margin-right: -129px;
		}
		.md-mr--130 {
			margin-right: -130px;
		}
		.md-mr--131 {
			margin-right: -131px;
		}
		.md-mr--132 {
			margin-right: -132px;
		}
		.md-mr--133 {
			margin-right: -133px;
		}
		.md-mr--134 {
			margin-right: -134px;
		}
		.md-mr--135 {
			margin-right: -135px;
		}
		.md-mr--136 {
			margin-right: -136px;
		}
		.md-mr--137 {
			margin-right: -137px;
		}
		.md-mr--138 {
			margin-right: -138px;
		}
		.md-mr--139 {
			margin-right: -139px;
		}
		.md-mr--140 {
			margin-right: -140px;
		}
		.md-mr--141 {
			margin-right: -141px;
		}
		.md-mr--142 {
			margin-right: -142px;
		}
		.md-mr--143 {
			margin-right: -143px;
		}
		.md-mr--144 {
			margin-right: -144px;
		}
		.md-mr--145 {
			margin-right: -145px;
		}
		.md-mr--146 {
			margin-right: -146px;
		}
		.md-mr--147 {
			margin-right: -147px;
		}
		.md-mr--148 {
			margin-right: -148px;
		}
		.md-mr--149 {
			margin-right: -149px;
		}
		.md-mr--150 {
			margin-right: -150px;
		}
		.md-mr--151 {
			margin-right: -151px;
		}
		.md-mr--152 {
			margin-right: -152px;
		}
		.md-mr--153 {
			margin-right: -153px;
		}
		.md-mr--154 {
			margin-right: -154px;
		}
		.md-mr--155 {
			margin-right: -155px;
		}
		.md-mr--156 {
			margin-right: -156px;
		}
		.md-mr--157 {
			margin-right: -157px;
		}
		.md-mr--158 {
			margin-right: -158px;
		}
		.md-mr--159 {
			margin-right: -159px;
		}
		.md-mr--160 {
			margin-right: -160px;
		}
		.md-mr--161 {
			margin-right: -161px;
		}
		.md-mr--162 {
			margin-right: -162px;
		}
		.md-mr--163 {
			margin-right: -163px;
		}
		.md-mr--164 {
			margin-right: -164px;
		}
		.md-mr--165 {
			margin-right: -165px;
		}
		.md-mr--166 {
			margin-right: -166px;
		}
		.md-mr--167 {
			margin-right: -167px;
		}
		.md-mr--168 {
			margin-right: -168px;
		}
		.md-mr--169 {
			margin-right: -169px;
		}
		.md-mr--170 {
			margin-right: -170px;
		}
		.md-mr--171 {
			margin-right: -171px;
		}
		.md-mr--172 {
			margin-right: -172px;
		}
		.md-mr--173 {
			margin-right: -173px;
		}
		.md-mr--174 {
			margin-right: -174px;
		}
		.md-mr--175 {
			margin-right: -175px;
		}
		.md-mr--176 {
			margin-right: -176px;
		}
		.md-mr--177 {
			margin-right: -177px;
		}
		.md-mr--178 {
			margin-right: -178px;
		}
		.md-mr--179 {
			margin-right: -179px;
		}
		.md-mr--180 {
			margin-right: -180px;
		}
		.md-mr--181 {
			margin-right: -181px;
		}
		.md-mr--182 {
			margin-right: -182px;
		}
		.md-mr--183 {
			margin-right: -183px;
		}
		.md-mr--184 {
			margin-right: -184px;
		}
		.md-mr--185 {
			margin-right: -185px;
		}
		.md-mr--186 {
			margin-right: -186px;
		}
		.md-mr--187 {
			margin-right: -187px;
		}
		.md-mr--188 {
			margin-right: -188px;
		}
		.md-mr--189 {
			margin-right: -189px;
		}
		.md-mr--190 {
			margin-right: -190px;
		}
		.md-mr--191 {
			margin-right: -191px;
		}
		.md-mr--192 {
			margin-right: -192px;
		}
		.md-mr--193 {
			margin-right: -193px;
		}
		.md-mr--194 {
			margin-right: -194px;
		}
		.md-mr--195 {
			margin-right: -195px;
		}
		.md-mr--196 {
			margin-right: -196px;
		}
		.md-mr--197 {
			margin-right: -197px;
		}
		.md-mr--198 {
			margin-right: -198px;
		}
		.md-mr--199 {
			margin-right: -199px;
		}
		.md-mr--200 {
			margin-right: -200px;
		}
	/*}*/
}

@media only screen and (max-width: 767px){

	.sm-padding-0 {
		padding: 0;
	}
	.sm-margin-0 {
		margin: 0;
	}

	/*------------------------------------------
		37. SM PADDING TOP
	-------------------------------------------*/
	/*.sm-pt {*/
		.sm-pt-0 {
			padding-top: 0px;
		}
		.sm-pt-1 {
			padding-top: 1px;
		}
		.sm-pt-2 {
			padding-top: 2px;
		}
		.sm-pt-3 {
			padding-top: 3px;
		}
		.sm-pt-4 {
			padding-top: 4px;
		}
		.sm-pt-5 {
			padding-top: 5px;
		}
		.sm-pt-6 {
			padding-top: 6px!important;
		}
		.sm-pt-7 {
			padding-top: 7px;
		}
		.sm-pt-8 {
			padding-top: 8px;
		}
		.sm-pt-9 {
			padding-top: 9px;
		}
		.sm-pt-10 {
			padding-top: 10px;
		}
		.sm-pt-11 {
			padding-top: 11px;
		}
		.sm-pt-12 {
			padding-top: 12px;
		}
		.sm-pt-13 {
			padding-top: 13px;
		}
		.sm-pt-14 {
			padding-top: 14px;
		}
		.sm-pt-15 {
			padding-top: 15px;
		}
		.sm-pt-16 {
			padding-top: 16px;
		}
		.sm-pt-17 {
			padding-top: 17px;
		}
		.sm-pt-18 {
			padding-top: 18px;
		}
		.sm-pt-19 {
			padding-top: 19px;
		}
		.sm-pt-20 {
			padding-top: 20px;
		}
		.sm-pt-21 {
			padding-top: 21px;
		}
		.sm-pt-22 {
			padding-top: 22px;
		}
		.sm-pt-23 {
			padding-top: 23px;
		}
		.sm-pt-24 {
			padding-top: 24px;
		}
		.sm-pt-25 {
			padding-top: 25px;
		}
		.sm-pt-26 {
			padding-top: 26px;
		}
		.sm-pt-27 {
			padding-top: 27px;
		}
		.sm-pt-28 {
			padding-top: 28px;
		}
		.sm-pt-29 {
			padding-top: 29px;
		}
		.sm-pt-30 {
			padding-top: 30px;
		}
		.sm-pt-31 {
			padding-top: 31px;
		}
		.sm-pt-32 {
			padding-top: 32px;
		}
		.sm-pt-33 {
			padding-top: 33px;
		}
		.sm-pt-34 {
			padding-top: 34px;
		}
		.sm-pt-35 {
			padding-top: 35px;
		}
		.sm-pt-36 {
			padding-top: 36px;
		}
		.sm-pt-37 {
			padding-top: 37px;
		}
		.sm-pt-38 {
			padding-top: 38px;
		}
		.sm-pt-39 {
			padding-top: 39px;
		}
		.sm-pt-40 {
			padding-top: 40px;
		}
		.sm-pt-41 {
			padding-top: 41px;
		}
		.sm-pt-42 {
			padding-top: 42px;
		}
		.sm-pt-43 {
			padding-top: 43px;
		}
		.sm-pt-44 {
			padding-top: 44px;
		}
		.sm-pt-45 {
			padding-top: 45px;
		}
		.sm-pt-46 {
			padding-top: 46px;
		}
		.sm-pt-47 {
			padding-top: 47px;
		}
		.sm-pt-48 {
			padding-top: 48px;
		}
		.sm-pt-49 {
			padding-top: 49px;
		}
		.sm-pt-50 {
			padding-top: 50px;
		}
		.sm-pt-51 {
			padding-top: 51px;
		}
		.sm-pt-52 {
			padding-top: 52px;
		}
		.sm-pt-53 {
			padding-top: 53px;
		}
		.sm-pt-54 {
			padding-top: 54px;
		}
		.sm-pt-55 {
			padding-top: 55px;
		}
		.sm-pt-56 {
			padding-top: 56px;
		}
		.sm-pt-57 {
			padding-top: 57px;
		}
		.sm-pt-58 {
			padding-top: 58px;
		}
		.sm-pt-59 {
			padding-top: 59px;
		}
		.sm-pt-60 {
			padding-top: 60px;
		}
		.sm-pt-61 {
			padding-top: 61px;
		}
		.sm-pt-62 {
			padding-top: 62px;
		}
		.sm-pt-63 {
			padding-top: 63px;
		}
		.sm-pt-64 {
			padding-top: 64px;
		}
		.sm-pt-65 {
			padding-top: 65px;
		}
		.sm-pt-66 {
			padding-top: 66px;
		}
		.sm-pt-67 {
			padding-top: 67px;
		}
		.sm-pt-68 {
			padding-top: 68px;
		}
		.sm-pt-69 {
			padding-top: 69px;
		}
		.sm-pt-70 {
			padding-top: 70px;
		}
		.sm-pt-71 {
			padding-top: 71px;
		}
		.sm-pt-72 {
			padding-top: 72px;
		}
		.sm-pt-73 {
			padding-top: 73px;
		}
		.sm-pt-74 {
			padding-top: 74px;
		}
		.sm-pt-75 {
			padding-top: 75px;
		}
		.sm-pt-76 {
			padding-top: 76px;
		}
		.sm-pt-77 {
			padding-top: 77px;
		}
		.sm-pt-78 {
			padding-top: 78px;
		}
		.sm-pt-79 {
			padding-top: 79px;
		}
		.sm-pt-80 {
			padding-top: 80px;
		}
		.sm-pt-81 {
			padding-top: 81px;
		}
		.sm-pt-82 {
			padding-top: 82px;
		}
		.sm-pt-83 {
			padding-top: 83px;
		}
		.sm-pt-84 {
			padding-top: 84px;
		}
		.sm-pt-85 {
			padding-top: 85px;
		}
		.sm-pt-86 {
			padding-top: 86px;
		}
		.sm-pt-87 {
			padding-top: 87px;
		}
		.sm-pt-88 {
			padding-top: 88px;
		}
		.sm-pt-89 {
			padding-top: 89px;
		}
		.sm-pt-90 {
			padding-top: 90px;
		}
		.sm-pt-91 {
			padding-top: 91px;
		}
		.sm-pt-92 {
			padding-top: 92px;
		}
		.sm-pt-93 {
			padding-top: 93px;
		}
		.sm-pt-94 {
			padding-top: 94px;
		}
		.sm-pt-95 {
			padding-top: 95px;
		}
		.sm-pt-96 {
			padding-top: 96px;
		}
		.sm-pt-97 {
			padding-top: 97px;
		}
		.sm-pt-98 {
			padding-top: 98px;
		}
		.sm-pt-99 {
			padding-top: 99px;
		}
		.sm-pt-100 {
			padding-top: 100px;
		}

		.sm-pt-101 {
			padding-top: 101px;
		}
		.sm-pt-102 {
			padding-top: 102px;
		}
		.sm-pt-103 {
			padding-top: 103px;
		}
		.sm-pt-104 {
			padding-top: 104px;
		}
		.sm-pt-105 {
			padding-top: 105px;
		}
		.sm-pt-106 {
			padding-top: 106px;
		}
		.sm-pt-107 {
			padding-top: 107px;
		}
		.sm-pt-108 {
			padding-top: 108px;
		}
		.sm-pt-109 {
			padding-top: 109px;
		}
		.sm-pt-110 {
			padding-top: 110px;
		}
		.sm-pt-111 {
			padding-top: 111px;
		}
		.sm-pt-112 {
			padding-top: 112px;
		}
		.sm-pt-113 {
			padding-top: 113px;
		}
		.sm-pt-114 {
			padding-top: 114px;
		}
		.sm-pt-115 {
			padding-top: 115px;
		}
		.sm-pt-116 {
			padding-top: 116px;
		}
		.sm-pt-117 {
			padding-top: 117px;
		}
		.sm-pt-118 {
			padding-top: 118px;
		}
		.sm-pt-119 {
			padding-top: 119px;
		}
		.sm-pt-120 {
			padding-top: 120px;
		}
		.sm-pt-121 {
			padding-top: 121px;
		}
		.sm-pt-122 {
			padding-top: 122px;
		}
		.sm-pt-123 {
			padding-top: 123px;
		}
		.sm-pt-124 {
			padding-top: 124px;
		}
		.sm-pt-125 {
			padding-top: 125px;
		}
		.sm-pt-126 {
			padding-top: 126px;
		}
		.sm-pt-127 {
			padding-top: 127px;
		}
		.sm-pt-128 {
			padding-top: 128px;
		}
		.sm-pt-129 {
			padding-top: 129px;
		}
		.sm-pt-130 {
			padding-top: 130px;
		}
		.sm-pt-131 {
			padding-top: 131px;
		}
		.sm-pt-132 {
			padding-top: 132px;
		}
		.sm-pt-133 {
			padding-top: 133px;
		}
		.sm-pt-134 {
			padding-top: 134px;
		}
		.sm-pt-135 {
			padding-top: 135px;
		}
		.sm-pt-136 {
			padding-top: 136px;
		}
		.sm-pt-137 {
			padding-top: 137px;
		}
		.sm-pt-138 {
			padding-top: 138px;
		}
		.sm-pt-139 {
			padding-top: 139px;
		}
		.sm-pt-140 {
			padding-top: 140px;
		}
		.sm-pt-141 {
			padding-top: 141px;
		}
		.sm-pt-142 {
			padding-top: 142px;
		}
		.sm-pt-143 {
			padding-top: 143px;
		}
		.sm-pt-144 {
			padding-top: 144px;
		}
		.sm-pt-145 {
			padding-top: 145px;
		}
		.sm-pt-146 {
			padding-top: 146px;
		}
		.sm-pt-147 {
			padding-top: 147px;
		}
		.sm-pt-148 {
			padding-top: 148px;
		}
		.sm-pt-149 {
			padding-top: 149px;
		}
		.sm-pt-150 {
			padding-top: 150px;
		}
		.sm-pt-151 {
			padding-top: 151px;
		}
		.sm-pt-152 {
			padding-top: 152px;
		}
		.sm-pt-153 {
			padding-top: 153px;
		}
		.sm-pt-154 {
			padding-top: 154px;
		}
		.sm-pt-155 {
			padding-top: 155px;
		}
		.sm-pt-156 {
			padding-top: 156px;
		}
		.sm-pt-157 {
			padding-top: 157px;
		}
		.sm-pt-158 {
			padding-top: 158px;
		}
		.sm-pt-159 {
			padding-top: 159px;
		}
		.sm-pt-160 {
			padding-top: 160px;
		}
		.sm-pt-161 {
			padding-top: 161px;
		}
		.sm-pt-162 {
			padding-top: 162px;
		}
		.sm-pt-163 {
			padding-top: 163px;
		}
		.sm-pt-164 {
			padding-top: 164px;
		}
		.sm-pt-165 {
			padding-top: 165px;
		}
		.sm-pt-166 {
			padding-top: 166px;
		}
		.sm-pt-167 {
			padding-top: 167px;
		}
		.sm-pt-168 {
			padding-top: 168px;
		}
		.sm-pt-169 {
			padding-top: 169px;
		}
		.sm-pt-170 {
			padding-top: 170px;
		}
		.sm-pt-171 {
			padding-top: 171px;
		}
		.sm-pt-172 {
			padding-top: 172px;
		}
		.sm-pt-173 {
			padding-top: 173px;
		}
		.sm-pt-174 {
			padding-top: 174px;
		}
		.sm-pt-175 {
			padding-top: 175px;
		}
		.sm-pt-176 {
			padding-top: 176px;
		}
		.sm-pt-177 {
			padding-top: 177px;
		}
		.sm-pt-178 {
			padding-top: 178px;
		}
		.sm-pt-179 {
			padding-top: 179px;
		}
		.sm-pt-180 {
			padding-top: 180px;
		}
		.sm-pt-181 {
			padding-top: 181px;
		}
		.sm-pt-182 {
			padding-top: 182px;
		}
		.sm-pt-183 {
			padding-top: 183px;
		}
		.sm-pt-184 {
			padding-top: 184px;
		}
		.sm-pt-185 {
			padding-top: 185px;
		}
		.sm-pt-186 {
			padding-top: 186px;
		}
		.sm-pt-187 {
			padding-top: 187px;
		}
		.sm-pt-188 {
			padding-top: 188px;
		}
		.sm-pt-189 {
			padding-top: 189px;
		}
		.sm-pt-190 {
			padding-top: 190px;
		}
		.sm-pt-191 {
			padding-top: 191px;
		}
		.sm-pt-192 {
			padding-top: 192px;
		}
		.sm-pt-193 {
			padding-top: 193px;
		}
		.sm-pt-194 {
			padding-top: 194px;
		}
		.sm-pt-195 {
			padding-top: 195px;
		}
		.sm-pt-196 {
			padding-top: 196px;
		}
		.sm-pt-197 {
			padding-top: 197px;
		}
		.sm-pt-198 {
			padding-top: 198px;
		}
		.sm-pt-199 {
			padding-top: 199px;
		}
		.sm-pt-200 {
			padding-top: 200px;
		}
	/*}*/

	/*------------------------------------------
		38. SM PADDING BOTTOM
	-------------------------------------------*/
	/*.sm-pb {*/
		.sm-pb-0 {
			padding-bottom: 0px;
		}
		.sm-pb-1 {
			padding-bottom: 1px;
		}
		.sm-pb-2 {
			padding-bottom: 2px;
		}
		.sm-pb-3 {
			padding-bottom: 3px;
		}
		.sm-pb-4 {
			padding-bottom: 4px;
		}
		.sm-pb-5 {
			padding-bottom: 5px;
		}
		.sm-pb-6 {
			padding-bottom: 6px;
		}
		.sm-pb-7 {
			padding-bottom: 7px;
		}
		.sm-pb-8 {
			padding-bottom: 8px;
		}
		.sm-pb-9 {
			padding-bottom: 9px;
		}
		.sm-pb-10 {
			padding-bottom: 10px;
		}
		.sm-pb-11 {
			padding-bottom: 11px;
		}
		.sm-pb-12 {
			padding-bottom: 12px;
		}
		.sm-pb-13 {
			padding-bottom: 13px;
		}
		.sm-pb-14 {
			padding-bottom: 14px;
		}
		.sm-pb-15 {
			padding-bottom: 15px;
		}
		.sm-pb-16 {
			padding-bottom: 16px;
		}
		.sm-pb-17 {
			padding-bottom: 17px;
		}
		.sm-pb-18 {
			padding-bottom: 18px;
		}
		.sm-pb-19 {
			padding-bottom: 19px;
		}
		.sm-pb-20 {
			padding-bottom: 20px!important
		}
		.sm-pb-21 {
			padding-bottom: 21px;
		}
		.sm-pb-22 {
			padding-bottom: 22px;
		}
		.sm-pb-23 {
			padding-bottom: 23px;
		}
		.sm-pb-24 {
			padding-bottom: 24px;
		}
		.sm-pb-25 {
			padding-bottom: 25px;
		}
		.sm-pb-26 {
			padding-bottom: 26px;
		}
		.sm-pb-27 {
			padding-bottom: 27px;
		}
		.sm-pb-28 {
			padding-bottom: 28px;
		}
		.sm-pb-29 {
			padding-bottom: 29px;
		}
		.sm-pb-30 {
			padding-bottom: 30px;
		}
		.sm-pb-31 {
			padding-bottom: 31px;
		}
		.sm-pb-32 {
			padding-bottom: 32px;
		}
		.sm-pb-33 {
			padding-bottom: 33px;
		}
		.sm-pb-34 {
			padding-bottom: 34px;
		}
		.sm-pb-35 {
			padding-bottom: 35px;
		}
		.sm-pb-36 {
			padding-bottom: 36px;
		}
		.sm-pb-37 {
			padding-bottom: 37px;
		}
		.sm-pb-38 {
			padding-bottom: 38px;
		}
		.sm-pb-39 {
			padding-bottom: 39px;
		}
		.sm-pb-40 {
			padding-bottom: 40px;
		}
		.sm-pb-41 {
			padding-bottom: 41px;
		}
		.sm-pb-42 {
			padding-bottom: 42px;
		}
		.sm-pb-43 {
			padding-bottom: 43px;
		}
		.sm-pb-44 {
			padding-bottom: 44px;
		}
		.sm-pb-45 {
			padding-bottom: 45px;
		}
		.sm-pb-46 {
			padding-bottom: 46px;
		}
		.sm-pb-47 {
			padding-bottom: 47px;
		}
		.sm-pb-48 {
			padding-bottom: 48px;
		}
		.sm-pb-49 {
			padding-bottom: 49px;
		}
		.sm-pb-50 {
			padding-bottom: 50px;
		}
		.sm-pb-51 {
			padding-bottom: 51px;
		}
		.sm-pb-52 {
			padding-bottom: 52px;
		}
		.sm-pb-53 {
			padding-bottom: 53px;
		}
		.sm-pb-54 {
			padding-bottom: 54px;
		}
		.sm-pb-55 {
			padding-bottom: 55px;
		}
		.sm-pb-56 {
			padding-bottom: 56px;
		}
		.sm-pb-57 {
			padding-bottom: 57px;
		}
		.sm-pb-58 {
			padding-bottom: 58px;
		}
		.sm-pb-59 {
			padding-bottom: 59px;
		}
		.sm-pb-60 {
			padding-bottom: 60px!important;
		}
		.sm-pb-61 {
			padding-bottom: 61px;
		}
		.sm-pb-62 {
			padding-bottom: 62px;
		}
		.sm-pb-63 {
			padding-bottom: 63px;
		}
		.sm-pb-64 {
			padding-bottom: 64px;
		}
		.sm-pb-65 {
			padding-bottom: 65px;
		}
		.sm-pb-66 {
			padding-bottom: 66px;
		}
		.sm-pb-67 {
			padding-bottom: 67px;
		}
		.sm-pb-68 {
			padding-bottom: 68px;
		}
		.sm-pb-69 {
			padding-bottom: 69px;
		}
		.sm-pb-70 {
			padding-bottom: 70px;
		}
		.sm-pb-71 {
			padding-bottom: 71px;
		}
		.sm-pb-72 {
			padding-bottom: 72px;
		}
		.sm-pb-73 {
			padding-bottom: 73px;
		}
		.sm-pb-74 {
			padding-bottom: 74px;
		}
		.sm-pb-75 {
			padding-bottom: 75px;
		}
		.sm-pb-76 {
			padding-bottom: 76px;
		}
		.sm-pb-77 {
			padding-bottom: 77px;
		}
		.sm-pb-78 {
			padding-bottom: 78px;
		}
		.sm-pb-79 {
			padding-bottom: 79px;
		}
		.sm-pb-80 {
			padding-bottom: 80px;
		}
		.sm-pb-81 {
			padding-bottom: 81px;
		}
		.sm-pb-82 {
			padding-bottom: 82px;
		}
		.sm-pb-83 {
			padding-bottom: 83px;
		}
		.sm-pb-84 {
			padding-bottom: 84px;
		}
		.sm-pb-85 {
			padding-bottom: 85px;
		}
		.sm-pb-86 {
			padding-bottom: 86px;
		}
		.sm-pb-87 {
			padding-bottom: 87px;
		}
		.sm-pb-88 {
			padding-bottom: 88px;
		}
		.sm-pb-89 {
			padding-bottom: 89px;
		}
		.sm-pb-90 {
			padding-bottom: 90px;
		}
		.sm-pb-91 {
			padding-bottom: 91px;
		}
		.sm-pb-92 {
			padding-bottom: 92px;
		}
		.sm-pb-93 {
			padding-bottom: 93px;
		}
		.sm-pb-94 {
			padding-bottom: 94px;
		}
		.sm-pb-95 {
			padding-bottom: 95px;
		}
		.sm-pb-96 {
			padding-bottom: 96px;
		}
		.sm-pb-97 {
			padding-bottom: 97px;
		}
		.sm-pb-98 {
			padding-bottom: 98px;
		}
		.sm-pb-99 {
			padding-bottom: 99px;
		}
		.sm-pb-100 {
			padding-bottom: 100px;
		}

		.sm-pb-101 {
			padding-bottom: 101px;
		}
		.sm-pb-102 {
			padding-bottom: 102px;
		}
		.sm-pb-103 {
			padding-bottom: 103px;
		}
		.sm-pb-104 {
			padding-bottom: 104px;
		}
		.sm-pb-105 {
			padding-bottom: 105px;
		}
		.sm-pb-106 {
			padding-bottom: 106px;
		}
		.sm-pb-107 {
			padding-bottom: 107px;
		}
		.sm-pb-108 {
			padding-bottom: 108px;
		}
		.sm-pb-109 {
			padding-bottom: 109px;
		}
		.sm-pb-110 {
			padding-bottom: 110px;
		}
		.sm-pb-111 {
			padding-bottom: 111px;
		}
		.sm-pb-112 {
			padding-bottom: 112px;
		}
		.sm-pb-113 {
			padding-bottom: 113px;
		}
		.sm-pb-114 {
			padding-bottom: 114px;
		}
		.sm-pb-115 {
			padding-bottom: 115px;
		}
		.sm-pb-116 {
			padding-bottom: 116px;
		}
		.sm-pb-117 {
			padding-bottom: 117px;
		}
		.sm-pb-118 {
			padding-bottom: 118px;
		}
		.sm-pb-119 {
			padding-bottom: 119px;
		}
		.sm-pb-120 {
			padding-bottom: 120px;
		}
		.sm-pb-121 {
			padding-bottom: 121px;
		}
		.sm-pb-122 {
			padding-bottom: 122px;
		}
		.sm-pb-123 {
			padding-bottom: 123px;
		}
		.sm-pb-124 {
			padding-bottom: 124px;
		}
		.sm-pb-125 {
			padding-bottom: 125px;
		}
		.sm-pb-126 {
			padding-bottom: 126px;
		}
		.sm-pb-127 {
			padding-bottom: 127px;
		}
		.sm-pb-128 {
			padding-bottom: 128px;
		}
		.sm-pb-129 {
			padding-bottom: 129px;
		}
		.sm-pb-130 {
			padding-bottom: 130px;
		}
		.sm-pb-131 {
			padding-bottom: 131px;
		}
		.sm-pb-132 {
			padding-bottom: 132px;
		}
		.sm-pb-133 {
			padding-bottom: 133px;
		}
		.sm-pb-134 {
			padding-bottom: 134px;
		}
		.sm-pb-135 {
			padding-bottom: 135px;
		}
		.sm-pb-136 {
			padding-bottom: 136px;
		}
		.sm-pb-137 {
			padding-bottom: 137px;
		}
		.sm-pb-138 {
			padding-bottom: 138px;
		}
		.sm-pb-139 {
			padding-bottom: 139px;
		}
		.sm-pb-140 {
			padding-bottom: 140px;
		}
		.sm-pb-141 {
			padding-bottom: 141px;
		}
		.sm-pb-142 {
			padding-bottom: 142px;
		}
		.sm-pb-143 {
			padding-bottom: 143px;
		}
		.sm-pb-144 {
			padding-bottom: 144px;
		}
		.sm-pb-145 {
			padding-bottom: 145px;
		}
		.sm-pb-146 {
			padding-bottom: 146px;
		}
		.sm-pb-147 {
			padding-bottom: 147px;
		}
		.sm-pb-148 {
			padding-bottom: 148px;
		}
		.sm-pb-149 {
			padding-bottom: 149px;
		}
		.sm-pb-150 {
			padding-bottom: 150px;
		}
		.sm-pb-151 {
			padding-bottom: 151px;
		}
		.sm-pb-152 {
			padding-bottom: 152px;
		}
		.sm-pb-153 {
			padding-bottom: 153px;
		}
		.sm-pb-154 {
			padding-bottom: 154px;
		}
		.sm-pb-155 {
			padding-bottom: 155px;
		}
		.sm-pb-156 {
			padding-bottom: 156px;
		}
		.sm-pb-157 {
			padding-bottom: 157px;
		}
		.sm-pb-158 {
			padding-bottom: 158px;
		}
		.sm-pb-159 {
			padding-bottom: 159px;
		}
		.sm-pb-160 {
			padding-bottom: 160px;
		}
		.sm-pb-161 {
			padding-bottom: 161px;
		}
		.sm-pb-162 {
			padding-bottom: 162px;
		}
		.sm-pb-163 {
			padding-bottom: 163px;
		}
		.sm-pb-164 {
			padding-bottom: 164px;
		}
		.sm-pb-165 {
			padding-bottom: 165px;
		}
		.sm-pb-166 {
			padding-bottom: 166px;
		}
		.sm-pb-167 {
			padding-bottom: 167px;
		}
		.sm-pb-168 {
			padding-bottom: 168px;
		}
		.sm-pb-169 {
			padding-bottom: 169px;
		}
		.sm-pb-170 {
			padding-bottom: 170px;
		}
		.sm-pb-171 {
			padding-bottom: 171px;
		}
		.sm-pb-172 {
			padding-bottom: 172px;
		}
		.sm-pb-173 {
			padding-bottom: 173px;
		}
		.sm-pb-174 {
			padding-bottom: 174px;
		}
		.sm-pb-175 {
			padding-bottom: 175px;
		}
		.sm-pb-176 {
			padding-bottom: 176px;
		}
		.sm-pb-177 {
			padding-bottom: 177px;
		}
		.sm-pb-178 {
			padding-bottom: 178px;
		}
		.sm-pb-179 {
			padding-bottom: 179px;
		}
		.sm-pb-180 {
			padding-bottom: 180px;
		}
		.sm-pb-181 {
			padding-bottom: 181px;
		}
		.sm-pb-182 {
			padding-bottom: 182px;
		}
		.sm-pb-183 {
			padding-bottom: 183px;
		}
		.sm-pb-184 {
			padding-bottom: 184px;
		}
		.sm-pb-185 {
			padding-bottom: 185px;
		}
		.sm-pb-186 {
			padding-bottom: 186px;
		}
		.sm-pb-187 {
			padding-bottom: 187px;
		}
		.sm-pb-188 {
			padding-bottom: 188px;
		}
		.sm-pb-189 {
			padding-bottom: 189px;
		}
		.sm-pb-190 {
			padding-bottom: 190px;
		}
		.sm-pb-191 {
			padding-bottom: 191px;
		}
		.sm-pb-192 {
			padding-bottom: 192px;
		}
		.sm-pb-193 {
			padding-bottom: 193px;
		}
		.sm-pb-194 {
			padding-bottom: 194px;
		}
		.sm-pb-195 {
			padding-bottom: 195px;
		}
		.sm-pb-196 {
			padding-bottom: 196px;
		}
		.sm-pb-197 {
			padding-bottom: 197px;
		}
		.sm-pb-198 {
			padding-bottom: 198px;
		}
		.sm-pb-199 {
			padding-bottom: 199px;
		}
		.sm-pb-200 {
			padding-bottom: 200px;
		}
	/*}*/

	/*------------------------------------------
		39. SM PADDING RIGHT
	-------------------------------------------*/
	/*.sm-pr {*/
		.sm-pr-0 {
			padding-right: 0px;
		}
		.sm-pr-1 {
			padding-right: 1px;
		}
		.sm-pr-2 {
			padding-right: 2px;
		}
		.pb-3 {
			padding-bottom: 3px;
		}
		.sm-pr-4 {
			padding-right: 4px;
		}
		.sm-pr-5 {
			padding-right: 5px;
		}
		.sm-pr-6 {
			padding-right: 6px;
		}
		.sm-pr-7 {
			padding-right: 7px;
		}
		.sm-pr-8 {
			padding-right: 8px;
		}
		.sm-pr-9 {
			padding-right: 9px;
		}
		.sm-pr-10 {
			padding-right: 10px;
		}
		.sm-pr-11 {
			padding-right: 11px;
		}
		.sm-pr-12 {
			padding-right: 12px;
		}
		.sm-pr-13 {
			padding-right: 13px;
		}
		.sm-pr-14 {
			padding-right: 14px;
		}
		.sm-pr-15 {
			padding-right: 15px;
		}
		.sm-pr-16 {
			padding-right: 16px;
		}
		.sm-pr-17 {
			padding-right: 17px;
		}
		.sm-pr-18 {
			padding-right: 18px;
		}
		.sm-pr-19 {
			padding-right: 19px;
		}
		.sm-pr-20 {
			padding-right: 20px;
		}
		.sm-pr-21 {
			padding-right: 21px;
		}
		.sm-pr-22 {
			padding-right: 22px;
		}
		.sm-pr-23 {
			padding-right: 23px;
		}
		.sm-pr-24 {
			padding-right: 24px;
		}
		.sm-pr-25 {
			padding-right: 25px;
		}
		.sm-pr-26 {
			padding-right: 26px;
		}
		.sm-pr-27 {
			padding-right: 27px;
		}
		.sm-pr-28 {
			padding-right: 28px;
		}
		.sm-pr-29 {
			padding-right: 29px;
		}
		.sm-pr-30 {
			padding-right: 30px;
		}
		.sm-pr-31 {
			padding-right: 31px;
		}
		.sm-pr-32 {
			padding-right: 32px;
		}
		.sm-pr-33 {
			padding-right: 33px;
		}
		.sm-pr-34 {
			padding-right: 34px;
		}
		.sm-pr-35 {
			padding-right: 35px;
		}
		.sm-pr-36 {
			padding-right: 36px;
		}
		.sm-pr-37 {
			padding-right: 37px;
		}
		.sm-pr-38 {
			padding-right: 38px;
		}
		.sm-pr-39 {
			padding-right: 39px;
		}
		.sm-pr-40 {
			padding-right: 40px;
		}
		.sm-pr-41 {
			padding-right: 41px;
		}
		.sm-pr-42 {
			padding-right: 42px;
		}
		.sm-pr-43 {
			padding-right: 43px;
		}
		.sm-pr-44 {
			padding-right: 44px;
		}
		.sm-pr-45 {
			padding-right: 45px;
		}
		.sm-pr-46 {
			padding-right: 46px;
		}
		.sm-pr-47 {
			padding-right: 47px;
		}
		.sm-pr-48 {
			padding-right: 48px;
		}
		.sm-pr-49 {
			padding-right: 49px;
		}
		.sm-pr-50 {
			padding-right: 50px;
		}
		.sm-pr-51 {
			padding-right: 51px;
		}
		.sm-pr-52 {
			padding-right: 52px;
		}
		.sm-pr-53 {
			padding-right: 53px;
		}
		.sm-pr-54 {
			padding-right: 54px;
		}
		.sm-pr-55 {
			padding-right: 55px;
		}
		.sm-pr-56 {
			padding-right: 56px;
		}
		.sm-pr-57 {
			padding-right: 57px;
		}
		.sm-pr-58 {
			padding-right: 58px;
		}
		.sm-pr-59 {
			padding-right: 59px;
		}
		.sm-pr-60 {
			padding-right: 60px;
		}
		.sm-pr-61 {
			padding-right: 61px;
		}
		.sm-pr-62 {
			padding-right: 62px;
		}
		.sm-pr-63 {
			padding-right: 63px;
		}
		.sm-pr-64 {
			padding-right: 64px;
		}
		.sm-pr-65 {
			padding-right: 65px;
		}
		.sm-pr-66 {
			padding-right: 66px;
		}
		.sm-pr-67 {
			padding-right: 67px;
		}
		.sm-pr-68 {
			padding-right: 68px;
		}
		.sm-pr-69 {
			padding-right: 69px;
		}
		.sm-pr-70 {
			padding-right: 70px;
		}
		.sm-pr-71 {
			padding-right: 71px;
		}
		.sm-pr-72 {
			padding-right: 72px;
		}
		.sm-pr-73 {
			padding-right: 73px;
		}
		.sm-pr-74 {
			padding-right: 74px;
		}
		.sm-pr-75 {
			padding-right: 75px;
		}
		.sm-pr-76 {
			padding-right: 76px;
		}
		.sm-pr-77 {
			padding-right: 77px;
		}
		.sm-pr-78 {
			padding-right: 78px;
		}
		.sm-pr-79 {
			padding-right: 79px;
		}
		.sm-pr-80 {
			padding-right: 80px;
		}
		.sm-pr-81 {
			padding-right: 81px;
		}
		.sm-pr-82 {
			padding-right: 82px;
		}
		.sm-pr-83 {
			padding-right: 83px;
		}
		.sm-pr-84 {
			padding-right: 84px;
		}
		.sm-pr-85 {
			padding-right: 85px;
		}
		.sm-pr-86 {
			padding-right: 86px;
		}
		.sm-pr-87 {
			padding-right: 87px;
		}
		.sm-pr-88 {
			padding-right: 88px;
		}
		.sm-pr-89 {
			padding-right: 89px;
		}
		.sm-pr-90 {
			padding-right: 90px;
		}
		.sm-pr-91 {
			padding-right: 91px;
		}
		.sm-pr-92 {
			padding-right: 92px;
		}
		.sm-pr-93 {
			padding-right: 93px;
		}
		.sm-pr-94 {
			padding-right: 94px;
		}
		.sm-pr-95 {
			padding-right: 95px;
		}
		.sm-pr-96 {
			padding-right: 96px;
		}
		.sm-pr-97 {
			padding-right: 97px;
		}
		.sm-pr-98 {
			padding-right: 98px;
		}
		.sm-pr-99 {
			padding-right: 99px;
		}
		.sm-pr-100 {
			padding-right: 100px;
		}
		.sm-pr-101 {
			padding-right: 101px;
		}
		.sm-pr-102 {
			padding-right: 102px;
		}
		.sm-pb-103 {
			padding-bottom: 103px;
		}
		.sm-pr-104 {
			padding-right: 104px;
		}
		.sm-pr-105 {
			padding-right: 105px;
		}
		.sm-pr-106 {
			padding-right: 106px;
		}
		.sm-pr-107 {
			padding-right: 107px;
		}
		.sm-pr-108 {
			padding-right: 108px;
		}
		.sm-pr-109 {
			padding-right: 109px;
		}
		.sm-pr-110 {
			padding-right: 110px;
		}
		.sm-pr-111 {
			padding-right: 111px;
		}
		.sm-pr-112 {
			padding-right: 112px;
		}
		.sm-pr-113 {
			padding-right: 113px;
		}
		.sm-pr-114 {
			padding-right: 114px;
		}
		.sm-pr-115 {
			padding-right: 115px;
		}
		.sm-pr-116 {
			padding-right: 116px;
		}
		.sm-pr-117 {
			padding-right: 117px;
		}
		.sm-pr-118 {
			padding-right: 118px;
		}
		.sm-pr-119 {
			padding-right: 119px;
		}
		.sm-pr-120 {
			padding-right: 120px;
		}
		.sm-pr-121 {
			padding-right: 121px;
		}
		.sm-pr-122 {
			padding-right: 122px;
		}
		.sm-pr-123 {
			padding-right: 123px;
		}
		.sm-pr-124 {
			padding-right: 124px;
		}
		.sm-pr-125 {
			padding-right: 125px;
		}
		.sm-pr-126 {
			padding-right: 126px;
		}
		.sm-pr-127 {
			padding-right: 127px;
		}
		.sm-pr-128 {
			padding-right: 128px;
		}
		.sm-pr-129 {
			padding-right: 129px;
		}
		.sm-pr-130 {
			padding-right: 130px;
		}
		.sm-pr-131 {
			padding-right: 131px;
		}
		.sm-pr-132 {
			padding-right: 132px;
		}
		.sm-pr-133 {
			padding-right: 133px;
		}
		.sm-pr-134 {
			padding-right: 134px;
		}
		.sm-pr-135 {
			padding-right: 135px;
		}
		.sm-pr-136 {
			padding-right: 136px;
		}
		.sm-pr-137 {
			padding-right: 137px;
		}
		.sm-pr-138 {
			padding-right: 138px;
		}
		.sm-pr-139 {
			padding-right: 139px;
		}
		.sm-pr-140 {
			padding-right: 140px;
		}
		.sm-pr-141 {
			padding-right: 141px;
		}
		.sm-pr-142 {
			padding-right: 142px;
		}
		.sm-pr-143 {
			padding-right: 143px;
		}
		.sm-pr-144 {
			padding-right: 144px;
		}
		.sm-pr-145 {
			padding-right: 145px;
		}
		.sm-pr-146 {
			padding-right: 146px;
		}
		.sm-pr-147 {
			padding-right: 147px;
		}
		.sm-pr-148 {
			padding-right: 148px;
		}
		.sm-pr-149 {
			padding-right: 149px;
		}
		.sm-pr-150 {
			padding-right: 150px;
		}
		.sm-pr-151 {
			padding-right: 151px;
		}
		.sm-pr-152 {
			padding-right: 152px;
		}
		.sm-pr-153 {
			padding-right: 153px;
		}
		.sm-pr-154 {
			padding-right: 154px;
		}
		.sm-pr-155 {
			padding-right: 155px;
		}
		.sm-pr-156 {
			padding-right: 156px;
		}
		.sm-pr-157 {
			padding-right: 157px;
		}
		.sm-pr-158 {
			padding-right: 158px;
		}
		.sm-pr-159 {
			padding-right: 159px;
		}
		.sm-pr-160 {
			padding-right: 160px;
		}
		.sm-pr-161 {
			padding-right: 161px;
		}
		.sm-pr-162 {
			padding-right: 162px;
		}
		.sm-pr-163 {
			padding-right: 163px;
		}
		.sm-pr-164 {
			padding-right: 164px;
		}
		.sm-pr-165 {
			padding-right: 165px;
		}
		.sm-pr-166 {
			padding-right: 166px;
		}
		.sm-pr-167 {
			padding-right: 167px;
		}
		.sm-pr-168 {
			padding-right: 168px;
		}
		.sm-pr-169 {
			padding-right: 169px;
		}
		.sm-pr-170 {
			padding-right: 170px;
		}
		.sm-pr-171 {
			padding-right: 171px;
		}
		.sm-pr-172 {
			padding-right: 172px;
		}
		.sm-pr-173 {
			padding-right: 173px;
		}
		.sm-pr-174 {
			padding-right: 174px;
		}
		.sm-pr-175 {
			padding-right: 175px;
		}
		.sm-pr-176 {
			padding-right: 176px;
		}
		.sm-pr-177 {
			padding-right: 177px;
		}
		.sm-pr-178 {
			padding-right: 178px;
		}
		.sm-pr-179 {
			padding-right: 179px;
		}
		.sm-pr-180 {
			padding-right: 180px;
		}
		.sm-pr-181 {
			padding-right: 181px;
		}
		.sm-pr-182 {
			padding-right: 182px;
		}
		.sm-pr-183 {
			padding-right: 183px;
		}
		.sm-pr-184 {
			padding-right: 184px;
		}
		.sm-pr-185 {
			padding-right: 185px;
		}
		.sm-pr-186 {
			padding-right: 186px;
		}
		.sm-pr-187 {
			padding-right: 187px;
		}
		.sm-pr-188 {
			padding-right: 188px;
		}
		.sm-pr-189 {
			padding-right: 189px;
		}
		.sm-pr-190 {
			padding-right: 190px;
		}
		.sm-pr-191 {
			padding-right: 191px;
		}
		.sm-pr-192 {
			padding-right: 192px;
		}
		.sm-pr-193 {
			padding-right: 193px;
		}
		.sm-pr-194 {
			padding-right: 194px;
		}
		.sm-pr-195 {
			padding-right: 195px;
		}
		.sm-pr-196 {
			padding-right: 196px;
		}
		.sm-pr-197 {
			padding-right: 197px;
		}
		.sm-pr-198 {
			padding-right: 198px;
		}
		.sm-pr-199 {
			padding-right: 199px;
		}
		.sm-pr-200 {
			padding-right: 200px;
		}
	/*}*/

	/*------------------------------------------
		40. SM PADDING LEFT
	-------------------------------------------*/
	/*.sm-pl {*/
		.sm-pl-0 {
			padding-left: 0px;
		}
		.sm-pl-1 {
			padding-left: 1px;
		}
		.sm-pl-2 {
			padding-left: 2px;
		}
		.pb-3 {
			padding-bottom: 3px;
		}
		.sm-pl-4 {
			padding-left: 4px;
		}
		.sm-pl-5 {
			padding-left: 5px;
		}
		.sm-pl-6 {
			padding-left: 6px;
		}
		.sm-pl-7 {
			padding-left: 7px;
		}
		.sm-pl-8 {
			padding-left: 8px;
		}
		.sm-pl-9 {
			padding-left: 9px;
		}
		.sm-pl-10 {
			padding-left: 10px;
		}
		.sm-pl-11 {
			padding-left: 11px;
		}
		.sm-pl-12 {
			padding-left: 12px;
		}
		.sm-pl-13 {
			padding-left: 13px;
		}
		.sm-pl-14 {
			padding-left: 14px;
		}
		.sm-pl-15 {
			padding-left: 15px;
		}
		.sm-pl-16 {
			padding-left: 16px;
		}
		.sm-pl-17 {
			padding-left: 17px;
		}
		.sm-pl-18 {
			padding-left: 18px;
		}
		.sm-pl-19 {
			padding-left: 19px;
		}
		.sm-pl-20 {
			padding-left: 20px!important
		}
		.sm-pl-21 {
			padding-left: 21px;
		}
		.sm-pl-22 {
			padding-left: 22px;
		}
		.sm-pl-23 {
			padding-left: 23px;
		}
		.sm-pl-24 {
			padding-left: 24px;
		}
		.sm-pl-25 {
			padding-left: 25px;
		}
		.sm-pl-26 {
			padding-left: 26px;
		}
		.sm-pl-27 {
			padding-left: 27px;
		}
		.sm-pl-28 {
			padding-left: 28px;
		}
		.sm-pl-29 {
			padding-left: 29px;
		}
		.sm-pl-30 {
			padding-left: 30px;
		}
		.sm-pl-31 {
			padding-left: 31px;
		}
		.sm-pl-32 {
			padding-left: 32px;
		}
		.sm-pl-33 {
			padding-left: 33px;
		}
		.sm-pl-34 {
			padding-left: 34px;
		}
		.sm-pl-35 {
			padding-left: 35px;
		}
		.sm-pl-36 {
			padding-left: 36px;
		}
		.sm-pl-37 {
			padding-left: 37px;
		}
		.sm-pl-38 {
			padding-left: 38px;
		}
		.sm-pl-39 {
			padding-left: 39px;
		}
		.sm-pl-40 {
			padding-left: 40px;
		}
		.sm-pl-41 {
			padding-left: 41px;
		}
		.sm-pl-42 {
			padding-left: 42px;
		}
		.sm-pl-43 {
			padding-left: 43px;
		}
		.sm-pl-44 {
			padding-left: 44px;
		}
		.sm-pl-45 {
			padding-left: 45px;
		}
		.sm-pl-46 {
			padding-left: 46px;
		}
		.sm-pl-47 {
			padding-left: 47px;
		}
		.sm-pl-48 {
			padding-left: 48px;
		}
		.sm-pl-49 {
			padding-left: 49px;
		}
		.sm-pl-50 {
			padding-left: 50px;
		}
		.sm-pl-51 {
			padding-left: 51px;
		}
		.sm-pl-52 {
			padding-left: 52px;
		}
		.sm-pl-53 {
			padding-left: 53px;
		}
		.sm-pl-54 {
			padding-left: 54px;
		}
		.sm-pl-55 {
			padding-left: 55px;
		}
		.sm-pl-56 {
			padding-left: 56px;
		}
		.sm-pl-57 {
			padding-left: 57px;
		}
		.sm-pl-58 {
			padding-left: 58px;
		}
		.sm-pl-59 {
			padding-left: 59px;
		}
		.sm-pl-60 {
			padding-left: 60px;
		}
		.sm-pl-61 {
			padding-left: 61px;
		}
		.sm-pl-62 {
			padding-left: 62px;
		}
		.sm-pl-63 {
			padding-left: 63px;
		}
		.sm-pl-64 {
			padding-left: 64px;
		}
		.sm-pl-65 {
			padding-left: 65px;
		}
		.sm-pl-66 {
			padding-left: 66px;
		}
		.sm-pl-67 {
			padding-left: 67px;
		}
		.sm-pl-68 {
			padding-left: 68px;
		}
		.sm-pl-69 {
			padding-left: 69px;
		}
		.sm-pl-70 {
			padding-left: 70px;
		}
		.sm-pl-71 {
			padding-left: 71px;
		}
		.sm-pl-72 {
			padding-left: 72px;
		}
		.sm-pl-73 {
			padding-left: 73px;
		}
		.sm-pl-74 {
			padding-left: 74px;
		}
		.sm-pl-75 {
			padding-left: 75px;
		}
		.sm-pl-76 {
			padding-left: 76px;
		}
		.sm-pl-77 {
			padding-left: 77px;
		}
		.sm-pl-78 {
			padding-left: 78px;
		}
		.sm-pl-79 {
			padding-left: 79px;
		}
		.sm-pl-80 {
			padding-left: 80px;
		}
		.sm-pl-81 {
			padding-left: 81px;
		}
		.sm-pl-82 {
			padding-left: 82px;
		}
		.sm-pl-83 {
			padding-left: 83px;
		}
		.sm-pl-84 {
			padding-left: 84px;
		}
		.sm-pl-85 {
			padding-left: 85px;
		}
		.sm-pl-86 {
			padding-left: 86px;
		}
		.sm-pl-87 {
			padding-left: 87px;
		}
		.sm-pl-88 {
			padding-left: 88px;
		}
		.sm-pl-89 {
			padding-left: 89px;
		}
		.sm-pl-90 {
			padding-left: 90px;
		}
		.sm-pl-91 {
			padding-left: 91px;
		}
		.sm-pl-92 {
			padding-left: 92px;
		}
		.sm-pl-93 {
			padding-left: 93px;
		}
		.sm-pl-94 {
			padding-left: 94px;
		}
		.sm-pl-95 {
			padding-left: 95px;
		}
		.sm-pl-96 {
			padding-left: 96px;
		}
		.sm-pl-97 {
			padding-left: 97px;
		}
		.sm-pl-98 {
			padding-left: 98px;
		}
		.sm-pl-99 {
			padding-left: 99px;
		}
		.sm-pl-100 {
			padding-left: 100px;
		}
		.sm-pl-101 {
			padding-left: 101px;
		}
		.sm-pl-102 {
			padding-left: 102px;
		}
		.sm-pb-103 {
			padding-bottom: 103px;
		}
		.sm-pl-104 {
			padding-left: 104px;
		}
		.sm-pl-105 {
			padding-left: 105px;
		}
		.sm-pl-106 {
			padding-left: 106px;
		}
		.sm-pl-107 {
			padding-left: 107px;
		}
		.sm-pl-108 {
			padding-left: 108px;
		}
		.sm-pl-109 {
			padding-left: 109px;
		}
		.sm-pl-110 {
			padding-left: 110px;
		}
		.sm-pl-111 {
			padding-left: 111px;
		}
		.sm-pl-112 {
			padding-left: 112px;
		}
		.sm-pl-113 {
			padding-left: 113px;
		}
		.sm-pl-114 {
			padding-left: 114px;
		}
		.sm-pl-115 {
			padding-left: 115px;
		}
		.sm-pl-116 {
			padding-left: 116px;
		}
		.sm-pl-117 {
			padding-left: 117px;
		}
		.sm-pl-118 {
			padding-left: 118px;
		}
		.sm-pl-119 {
			padding-left: 119px;
		}
		.sm-pl-120 {
			padding-left: 120px;
		}
		.sm-pl-121 {
			padding-left: 121px;
		}
		.sm-pl-122 {
			padding-left: 122px;
		}
		.sm-pl-123 {
			padding-left: 123px;
		}
		.sm-pl-124 {
			padding-left: 124px;
		}
		.sm-pl-125 {
			padding-left: 125px;
		}
		.sm-pl-126 {
			padding-left: 126px;
		}
		.sm-pl-127 {
			padding-left: 127px;
		}
		.sm-pl-128 {
			padding-left: 128px;
		}
		.sm-pl-129 {
			padding-left: 129px;
		}
		.sm-pl-130 {
			padding-left: 130px;
		}
		.sm-pl-131 {
			padding-left: 131px;
		}
		.sm-pl-132 {
			padding-left: 132px;
		}
		.sm-pl-133 {
			padding-left: 133px;
		}
		.sm-pl-134 {
			padding-left: 134px;
		}
		.sm-pl-135 {
			padding-left: 135px;
		}
		.sm-pl-136 {
			padding-left: 136px;
		}
		.sm-pl-137 {
			padding-left: 137px;
		}
		.sm-pl-138 {
			padding-left: 138px;
		}
		.sm-pl-139 {
			padding-left: 139px;
		}
		.sm-pl-140 {
			padding-left: 140px;
		}
		.sm-pl-141 {
			padding-left: 141px;
		}
		.sm-pl-142 {
			padding-left: 142px;
		}
		.sm-pl-143 {
			padding-left: 143px;
		}
		.sm-pl-144 {
			padding-left: 144px;
		}
		.sm-pl-145 {
			padding-left: 145px;
		}
		.sm-pl-146 {
			padding-left: 146px;
		}
		.sm-pl-147 {
			padding-left: 147px;
		}
		.sm-pl-148 {
			padding-left: 148px;
		}
		.sm-pl-149 {
			padding-left: 149px;
		}
		.sm-pl-150 {
			padding-left: 150px;
		}
		.sm-pl-151 {
			padding-left: 151px;
		}
		.sm-pl-152 {
			padding-left: 152px;
		}
		.sm-pl-153 {
			padding-left: 153px;
		}
		.sm-pl-154 {
			padding-left: 154px;
		}
		.sm-pl-155 {
			padding-left: 155px;
		}
		.sm-pl-156 {
			padding-left: 156px;
		}
		.sm-pl-157 {
			padding-left: 157px;
		}
		.sm-pl-158 {
			padding-left: 158px;
		}
		.sm-pl-159 {
			padding-left: 159px;
		}
		.sm-pl-160 {
			padding-left: 160px;
		}
		.sm-pl-161 {
			padding-left: 161px;
		}
		.sm-pl-162 {
			padding-left: 162px;
		}
		.sm-pl-163 {
			padding-left: 163px;
		}
		.sm-pl-164 {
			padding-left: 164px;
		}
		.sm-pl-165 {
			padding-left: 165px;
		}
		.sm-pl-166 {
			padding-left: 166px;
		}
		.sm-pl-167 {
			padding-left: 167px;
		}
		.sm-pl-168 {
			padding-left: 168px;
		}
		.sm-pl-169 {
			padding-left: 169px;
		}
		.sm-pl-170 {
			padding-left: 170px;
		}
		.sm-pl-171 {
			padding-left: 171px;
		}
		.sm-pl-172 {
			padding-left: 172px;
		}
		.sm-pl-173 {
			padding-left: 173px;
		}
		.sm-pl-174 {
			padding-left: 174px;
		}
		.sm-pl-175 {
			padding-left: 175px;
		}
		.sm-pl-176 {
			padding-left: 176px;
		}
		.sm-pl-177 {
			padding-left: 177px;
		}
		.sm-pl-178 {
			padding-left: 178px;
		}
		.sm-pl-179 {
			padding-left: 179px;
		}
		.sm-pl-180 {
			padding-left: 180px;
		}
		.sm-pl-181 {
			padding-left: 181px;
		}
		.sm-pl-182 {
			padding-left: 182px;
		}
		.sm-pl-183 {
			padding-left: 183px;
		}
		.sm-pl-184 {
			padding-left: 184px;
		}
		.sm-pl-185 {
			padding-left: 185px;
		}
		.sm-pl-186 {
			padding-left: 186px;
		}
		.sm-pl-187 {
			padding-left: 187px;
		}
		.sm-pl-188 {
			padding-left: 188px;
		}
		.sm-pl-189 {
			padding-left: 189px;
		}
		.sm-pl-190 {
			padding-left: 190px;
		}
		.sm-pl-191 {
			padding-left: 191px;
		}
		.sm-pl-192 {
			padding-left: 192px;
		}
		.sm-pl-193 {
			padding-left: 193px;
		}
		.sm-pl-194 {
			padding-left: 194px;
		}
		.sm-pl-195 {
			padding-left: 195px;
		}
		.sm-pl-196 {
			padding-left: 196px;
		}
		.sm-pl-197 {
			padding-left: 197px;
		}
		.sm-pl-198 {
			padding-left: 198px;
		}
		.sm-pl-199 {
			padding-left: 199px;
		}
		.sm-pl-200 {
			padding-left: 200px;
		}
	/*}*/

	/*------------------------------------------
		41. SM MARGIN TOP
	-------------------------------------------*/
	/*.sm-mt {*/
		.sm-mt-0 {
			margin-top: 0px;
		}
		.sm-mt-1 {
			margin-top: 1px;
		}
		.sm-mt-2 {
			margin-top: 2px;
		}
		.sm-mt-3 {
			margin-top: 3px;
		}
		.sm-mt-4 {
			margin-top: 4px;
		}
		.sm-mt-5 {
			margin-top: 5px;
		}
		.sm-mt-6 {
			margin-top: 6px;
		}
		.sm-mt-7 {
			margin-top: 7px;
		}
		.sm-mt-8 {
			margin-top: 8px;
		}
		.sm-mt-9 {
			margin-top: 9px;
		}
		.sm-mt-10 {
			margin-top: 10px;
		}
		.sm-mt-11 {
			margin-top: 11px;
		}
		.sm-mt-12 {
			margin-top: 12px;
		}
		.sm-mt-13 {
			margin-top: 13px;
		}
		.sm-mt-14 {
			margin-top: 14px;
		}
		.sm-mt-15 {
			margin-top: 15px;
		}
		.sm-mt-16 {
			margin-top: 16px;
		}
		.sm-mt-17 {
			margin-top: 17px;
		}
		.sm-mt-18 {
			margin-top: 18px;
		}
		.sm-mt-19 {
			margin-top: 19px;
		}
		.sm-mt-20 {
			margin-top: 20px;
		}
		.sm-mt-21 {
			margin-top: 21px;
		}
		.sm-mt-22 {
			margin-top: 22px;
		}
		.sm-mt-23 {
			margin-top: 23px;
		}
		.sm-mt-24 {
			margin-top: 24px;
		}
		.sm-mt-25 {
			margin-top: 25px;
		}
		.sm-mt-26 {
			margin-top: 26px;
		}
		.sm-mt-27 {
			margin-top: 27px;
		}
		.sm-mt-28 {
			margin-top: 28px;
		}
		.sm-mt-29 {
			margin-top: 29px;
		}
		.sm-mt-30 {
			margin-top: 30px;
		}
		.sm-mt-31 {
			margin-top: 31px;
		}
		.sm-mt-32 {
			margin-top: 32px;
		}
		.sm-mt-33 {
			margin-top: 33px;
		}
		.sm-mt-34 {
			margin-top: 34px;
		}
		.sm-mt-35 {
			margin-top: 35px;
		}
		.sm-mt-36 {
			margin-top: 36px;
		}
		.sm-mt-37 {
			margin-top: 37px;
		}
		.sm-mt-38 {
			margin-top: 38px;
		}
		.sm-mt-39 {
			margin-top: 39px;
		}
		.sm-mt-40 {
			margin-top: 40px;
		}
		.sm-mt-41 {
			margin-top: 41px;
		}
		.sm-mt-42 {
			margin-top: 42px;
		}
		.sm-mt-43 {
			margin-top: 43px;
		}
		.sm-mt-44 {
			margin-top: 44px;
		}
		.sm-mt-45 {
			margin-top: 45px;
		}
		.sm-mt-46 {
			margin-top: 46px;
		}
		.sm-mt-47 {
			margin-top: 47px;
		}
		.sm-mt-48 {
			margin-top: 48px;
		}
		.sm-mt-49 {
			margin-top: 49px;
		}
		.sm-mt-50 {
			margin-top: 50px;
		}
		.sm-mt-51 {
			margin-top: 51px;
		}
		.sm-mt-52 {
			margin-top: 52px;
		}
		.sm-mt-53 {
			margin-top: 53px;
		}
		.sm-mt-54 {
			margin-top: 54px;
		}
		.sm-mt-55 {
			margin-top: 55px;
		}
		.sm-mt-56 {
			margin-top: 56px;
		}
		.sm-mt-57 {
			margin-top: 57px;
		}
		.sm-mt-58 {
			margin-top: 58px;
		}
		.sm-mt-59 {
			margin-top: 59px;
		}
		.sm-mt-60 {
			margin-top: 60px;
		}
		.sm-mt-61 {
			margin-top: 61px;
		}
		.sm-mt-62 {
			margin-top: 62px;
		}
		.sm-mt-63 {
			margin-top: 63px;
		}
		.sm-mt-64 {
			margin-top: 64px;
		}
		.sm-mt-65 {
			margin-top: 65px;
		}
		.sm-mt-66 {
			margin-top: 66px;
		}
		.sm-mt-67 {
			margin-top: 67px;
		}
		.sm-mt-68 {
			margin-top: 68px;
		}
		.sm-mt-69 {
			margin-top: 69px;
		}
		.sm-mt-70 {
			margin-top: 70px;
		}
		.sm-mt-71 {
			margin-top: 71px;
		}
		.sm-mt-72 {
			margin-top: 72px;
		}
		.sm-mt-73 {
			margin-top: 73px;
		}
		.sm-mt-74 {
			margin-top: 74px;
		}
		.sm-mt-75 {
			margin-top: 75px;
		}
		.sm-mt-76 {
			margin-top: 76px;
		}
		.sm-mt-77 {
			margin-top: 77px;
		}
		.sm-mt-78 {
			margin-top: 78px;
		}
		.sm-mt-79 {
			margin-top: 79px;
		}
		.sm-mt-80 {
			margin-top: 80px;
		}
		.sm-mt-81 {
			margin-top: 81px;
		}
		.sm-mt-82 {
			margin-top: 82px;
		}
		.sm-mt-83 {
			margin-top: 83px;
		}
		.sm-mt-84 {
			margin-top: 84px;
		}
		.sm-mt-85 {
			margin-top: 85px;
		}
		.sm-mt-86 {
			margin-top: 86px;
		}
		.sm-mt-87 {
			margin-top: 87px;
		}
		.sm-mt-88 {
			margin-top: 88px;
		}
		.sm-mt-89 {
			margin-top: 89px;
		}
		.sm-mt-90 {
			margin-top: 90px;
		}
		.sm-mt-91 {
			margin-top: 91px;
		}
		.sm-mt-92 {
			margin-top: 92px;
		}
		.sm-mt-93 {
			margin-top: 93px;
		}
		.sm-mt-94 {
			margin-top: 94px;
		}
		.sm-mt-95 {
			margin-top: 95px;
		}
		.sm-mt-96 {
			margin-top: 96px;
		}
		.sm-mt-97 {
			margin-top: 97px;
		}
		.sm-mt-98 {
			margin-top: 98px;
		}
		.sm-mt-99 {
			margin-top: 99px;
		}
		.sm-mt-100 {
			margin-top: 100px;
		}
		.sm-mt-101 {
			margin-top: 101px;
		}
		.sm-mt-102 {
			margin-top: 102px;
		}
		.sm-mt-103 {
			margin-top: 103px;
		}
		.sm-mt-104 {
			margin-top: 104px;
		}
		.sm-mt-105 {
			margin-top: 105px;
		}
		.sm-mt-106 {
			margin-top: 106px;
		}
		.sm-mt-107 {
			margin-top: 107px;
		}
		.sm-mt-108 {
			margin-top: 108px;
		}
		.sm-mt-109 {
			margin-top: 109px;
		}
		.sm-mt-110 {
			margin-top: 110px;
		}
		.sm-mt-111 {
			margin-top: 111px;
		}
		.sm-mt-112 {
			margin-top: 112px;
		}
		.sm-mt-113 {
			margin-top: 113px;
		}
		.sm-mt-114 {
			margin-top: 114px;
		}
		.sm-mt-115 {
			margin-top: 115px;
		}
		.sm-mt-116 {
			margin-top: 116px;
		}
		.sm-mt-117 {
			margin-top: 117px;
		}
		.sm-mt-118 {
			margin-top: 118px;
		}
		.sm-mt-119 {
			margin-top: 119px;
		}
		.sm-mt-120 {
			margin-top: 120px;
		}
		.sm-mt-121 {
			margin-top: 121px;
		}
		.sm-mt-122 {
			margin-top: 122px;
		}
		.sm-mt-123 {
			margin-top: 123px;
		}
		.sm-mt-124 {
			margin-top: 124px;
		}
		.sm-mt-125 {
			margin-top: 125px;
		}
		.sm-mt-126 {
			margin-top: 126px;
		}
		.sm-mt-127 {
			margin-top: 127px;
		}
		.sm-mt-128 {
			margin-top: 128px;
		}
		.sm-mt-129 {
			margin-top: 129px;
		}
		.sm-mt-130 {
			margin-top: 130px;
		}
		.sm-mt-131 {
			margin-top: 131px;
		}
		.sm-mt-132 {
			margin-top: 132px;
		}
		.sm-mt-133 {
			margin-top: 133px;
		}
		.sm-mt-134 {
			margin-top: 134px;
		}
		.sm-mt-135 {
			margin-top: 135px;
		}
		.sm-mt-136 {
			margin-top: 136px;
		}
		.sm-mt-137 {
			margin-top: 137px;
		}
		.sm-mt-138 {
			margin-top: 138px;
		}
		.sm-mt-139 {
			margin-top: 139px;
		}
		.sm-mt-140 {
			margin-top: 140px;
		}
		.sm-mt-141 {
			margin-top: 141px;
		}
		.sm-mt-142 {
			margin-top: 142px;
		}
		.sm-mt-143 {
			margin-top: 143px;
		}
		.sm-mt-144 {
			margin-top: 144px;
		}
		.sm-mt-145 {
			margin-top: 145px;
		}
		.sm-mt-146 {
			margin-top: 146px;
		}
		.sm-mt-147 {
			margin-top: 147px;
		}
		.sm-mt-148 {
			margin-top: 148px;
		}
		.sm-mt-149 {
			margin-top: 149px;
		}
		.sm-mt-150 {
			margin-top: 150px;
		}
		.sm-mt-151 {
			margin-top: 151px;
		}
		.sm-mt-152 {
			margin-top: 152px;
		}
		.sm-mt-153 {
			margin-top: 153px;
		}
		.sm-mt-154 {
			margin-top: 154px;
		}
		.sm-mt-155 {
			margin-top: 155px;
		}
		.sm-mt-156 {
			margin-top: 156px;
		}
		.sm-mt-157 {
			margin-top: 157px;
		}
		.sm-mt-158 {
			margin-top: 158px;
		}
		.sm-mt-159 {
			margin-top: 159px;
		}
		.sm-mt-160 {
			margin-top: 160px;
		}
		.sm-mt-161 {
			margin-top: 161px;
		}
		.sm-mt-162 {
			margin-top: 162px;
		}
		.sm-mt-163 {
			margin-top: 163px;
		}
		.sm-mt-164 {
			margin-top: 164px;
		}
		.sm-mt-165 {
			margin-top: 165px;
		}
		.sm-mt-166 {
			margin-top: 166px;
		}
		.sm-mt-167 {
			margin-top: 167px;
		}
		.sm-mt-168 {
			margin-top: 168px;
		}
		.sm-mt-169 {
			margin-top: 169px;
		}
		.sm-mt-170 {
			margin-top: 170px;
		}
		.sm-mt-171 {
			margin-top: 171px;
		}
		.sm-mt-172 {
			margin-top: 172px;
		}
		.sm-mt-173 {
			margin-top: 173px;
		}
		.sm-mt-174 {
			margin-top: 174px;
		}
		.sm-mt-175 {
			margin-top: 175px;
		}
		.sm-mt-176 {
			margin-top: 176px;
		}
		.sm-mt-177 {
			margin-top: 177px;
		}
		.sm-mt-178 {
			margin-top: 178px;
		}
		.sm-mt-179 {
			margin-top: 179px;
		}
		.sm-mt-180 {
			margin-top: 180px;
		}
		.sm-mt-181 {
			margin-top: 181px;
		}
		.sm-mt-182 {
			margin-top: 182px;
		}
		.sm-mt-183 {
			margin-top: 183px;
		}
		.sm-mt-184 {
			margin-top: 184px;
		}
		.sm-mt-185 {
			margin-top: 185px;
		}
		.sm-mt-186 {
			margin-top: 186px;
		}
		.sm-mt-187 {
			margin-top: 187px;
		}
		.sm-mt-188 {
			margin-top: 188px;
		}
		.sm-mt-189 {
			margin-top: 189px;
		}
		.sm-mt-190 {
			margin-top: 190px;
		}
		.sm-mt-191 {
			margin-top: 191px;
		}
		.sm-mt-192 {
			margin-top: 192px;
		}
		.sm-mt-193 {
			margin-top: 193px;
		}
		.sm-mt-194 {
			margin-top: 194px;
		}
		.sm-mt-195 {
			margin-top: 195px;
		}
		.sm-mt-196 {
			margin-top: 196px;
		}
		.sm-mt-197 {
			margin-top: 197px;
		}
		.sm-mt-198 {
			margin-top: 198px;
		}
		.sm-mt-199 {
			margin-top: 199px;
		}
		.sm-mt-200 {
			margin-top: 200px;
		}
	/*}*/

	/*------------------------------------------
		42. SM MARGIN BOTTOM
	-------------------------------------------*/
	/*.sm-mb {*/
		.sm-mb-0 {
			margin-bottom: 0px;
		}
		.sm-mb-1 {
			margin-bottom: 1px;
		}
		.sm-mb-2 {
			margin-bottom: 2px;
		}
		.sm-mb-3 {
			margin-bottom: 3px;
		}
		.sm-mb-4 {
			margin-bottom: 4px;
		}
		.sm-mb-5 {
			margin-bottom: 5px;
		}
		.sm-mb-6 {
			margin-bottom: 6px;
		}
		.sm-mb-7 {
			margin-bottom: 7px;
		}
		.sm-mb-8 {
			margin-bottom: 8px;
		}
		.sm-mb-9 {
			margin-bottom: 9px;
		}
		.sm-mb-10 {
			margin-bottom: 10px;
		}
		.sm-mb-11 {
			margin-bottom: 11px;
		}
		.sm-mb-12 {
			margin-bottom: 12px;
		}
		.sm-mb-13 {
			margin-bottom: 13px;
		}
		.sm-mb-14 {
			margin-bottom: 14px;
		}
		.sm-mb-15 {
			margin-bottom: 15px;
		}
		.sm-mb-16 {
			margin-bottom: 16px;
		}
		.sm-mb-17 {
			margin-bottom: 17px;
		}
		.sm-mb-18 {
			margin-bottom: 18px;
		}
		.sm-mb-19 {
			margin-bottom: 19px;
		}
		.sm-mb-20 {
			margin-bottom: 20px;
		}
		.sm-mb-21 {
			margin-bottom: 21px;
		}
		.sm-mb-22 {
			margin-bottom: 22px;
		}
		.sm-mb-23 {
			margin-bottom: 23px;
		}
		.sm-mb-24 {
			margin-bottom: 24px;
		}
		.sm-mb-25 {
			margin-bottom: 25px;
		}
		.sm-mb-26 {
			margin-bottom: 26px;
		}
		.sm-mb-27 {
			margin-bottom: 27px;
		}
		.sm-mb-28 {
			margin-bottom: 28px;
		}
		.sm-mb-29 {
			margin-bottom: 29px;
		}
		.sm-mb-30 {
			margin-bottom: 30px;
		}
		.sm-mb-31 {
			margin-bottom: 31px;
		}
		.sm-mb-32 {
			margin-bottom: 32px;
		}
		.sm-mb-33 {
			margin-bottom: 33px;
		}
		.sm-mb-34 {
			margin-bottom: 34px;
		}
		.sm-mb-35 {
			margin-bottom: 35px;
		}
		.sm-mb-36 {
			margin-bottom: 36px;
		}
		.sm-mb-37 {
			margin-bottom: 37px;
		}
		.sm-mb-38 {
			margin-bottom: 38px;
		}
		.sm-mb-39 {
			margin-bottom: 39px;
		}
		.sm-mb-40 {
			margin-bottom: 40px;
		}
		.sm-mb-41 {
			margin-bottom: 41px;
		}
		.sm-mb-42 {
			margin-bottom: 42px;
		}
		.sm-mb-43 {
			margin-bottom: 43px;
		}
		.sm-mb-44 {
			margin-bottom: 44px;
		}
		.sm-mb-45 {
			margin-bottom: 45px;
		}
		.sm-mb-46 {
			margin-bottom: 46px;
		}
		.sm-mb-47 {
			margin-bottom: 47px;
		}
		.sm-mb-48 {
			margin-bottom: 48px;
		}
		.sm-mb-49 {
			margin-bottom: 49px;
		}
		.sm-mb-50 {
			margin-bottom: 50px;
		}
		.sm-mb-51 {
			margin-bottom: 51px;
		}
		.sm-mb-52 {
			margin-bottom: 52px;
		}
		.sm-mb-53 {
			margin-bottom: 53px;
		}
		.sm-mb-54 {
			margin-bottom: 54px;
		}
		.sm-mb-55 {
			margin-bottom: 55px;
		}
		.sm-mb-56 {
			margin-bottom: 56px;
		}
		.sm-mb-57 {
			margin-bottom: 57px;
		}
		.sm-mb-58 {
			margin-bottom: 58px;
		}
		.sm-mb-59 {
			margin-bottom: 59px;
		}
		.sm-mb-60 {
			margin-bottom: 60px;
		}
		.sm-mb-61 {
			margin-bottom: 61px;
		}
		.sm-mb-62 {
			margin-bottom: 62px;
		}
		.sm-mb-63 {
			margin-bottom: 63px;
		}
		.sm-mb-64 {
			margin-bottom: 64px;
		}
		.sm-mb-65 {
			margin-bottom: 65px;
		}
		.sm-mb-66 {
			margin-bottom: 66px;
		}
		.sm-mb-67 {
			margin-bottom: 67px;
		}
		.sm-mb-68 {
			margin-bottom: 68px;
		}
		.sm-mb-69 {
			margin-bottom: 69px;
		}
		.sm-mb-70 {
			margin-bottom: 70px;
		}
		.sm-mb-71 {
			margin-bottom: 71px;
		}
		.sm-mb-72 {
			margin-bottom: 72px;
		}
		.sm-mb-73 {
			margin-bottom: 73px;
		}
		.sm-mb-74 {
			margin-bottom: 74px;
		}
		.sm-mb-75 {
			margin-bottom: 75px;
		}
		.sm-mb-76 {
			margin-bottom: 76px;
		}
		.sm-mb-77 {
			margin-bottom: 77px;
		}
		.sm-mb-78 {
			margin-bottom: 78px;
		}
		.sm-mb-79 {
			margin-bottom: 79px;
		}
		.sm-mb-80 {
			margin-bottom: 80px;
		}
		.sm-mb-81 {
			margin-bottom: 81px;
		}
		.sm-mb-82 {
			margin-bottom: 82px;
		}
		.sm-mb-83 {
			margin-bottom: 83px;
		}
		.sm-mb-84 {
			margin-bottom: 84px;
		}
		.sm-mb-85 {
			margin-bottom: 85px;
		}
		.sm-mb-86 {
			margin-bottom: 86px;
		}
		.sm-mb-87 {
			margin-bottom: 87px;
		}
		.sm-mb-88 {
			margin-bottom: 88px;
		}
		.sm-mb-89 {
			margin-bottom: 89px;
		}
		.sm-mb-90 {
			margin-bottom: 90px;
		}
		.sm-mb-91 {
			margin-bottom: 91px;
		}
		.sm-mb-92 {
			margin-bottom: 92px;
		}
		.sm-mb-93 {
			margin-bottom: 93px;
		}
		.sm-mb-94 {
			margin-bottom: 94px;
		}
		.sm-mb-95 {
			margin-bottom: 95px;
		}
		.sm-mb-96 {
			margin-bottom: 96px;
		}
		.sm-mb-97 {
			margin-bottom: 97px;
		}
		.sm-mb-98 {
			margin-bottom: 98px;
		}
		.sm-mb-99 {
			margin-bottom: 99px;
		}
		.sm-mb-100 {
			margin-bottom: 100px;
		}

		.sm-mb-101 {
			margin-bottom: 101px;
		}
		.sm-mb-102 {
			margin-bottom: 102px;
		}
		.sm-mb-103 {
			margin-bottom: 103px;
		}
		.sm-mb-104 {
			margin-bottom: 104px;
		}
		.sm-mb-105 {
			margin-bottom: 105px;
		}
		.sm-mb-106 {
			margin-bottom: 106px;
		}
		.sm-mb-107 {
			margin-bottom: 107px;
		}
		.sm-mb-108 {
			margin-bottom: 108px;
		}
		.sm-mb-109 {
			margin-bottom: 109px;
		}
		.sm-mb-110 {
			margin-bottom: 110px;
		}
		.sm-mb-111 {
			margin-bottom: 111px;
		}
		.sm-mb-112 {
			margin-bottom: 112px;
		}
		.sm-mb-113 {
			margin-bottom: 113px;
		}
		.sm-mb-114 {
			margin-bottom: 114px;
		}
		.sm-mb-115 {
			margin-bottom: 115px;
		}
		.sm-mb-116 {
			margin-bottom: 116px;
		}
		.sm-mb-117 {
			margin-bottom: 117px;
		}
		.sm-mb-118 {
			margin-bottom: 118px;
		}
		.sm-mb-119 {
			margin-bottom: 119px;
		}
		.sm-mb-120 {
			margin-bottom: 120px;
		}
		.sm-mb-121 {
			margin-bottom: 121px;
		}
		.sm-mb-122 {
			margin-bottom: 122px;
		}
		.sm-mb-123 {
			margin-bottom: 123px;
		}
		.sm-mb-124 {
			margin-bottom: 124px;
		}
		.sm-mb-125 {
			margin-bottom: 125px;
		}
		.sm-mb-126 {
			margin-bottom: 126px;
		}
		.sm-mb-127 {
			margin-bottom: 127px;
		}
		.sm-mb-128 {
			margin-bottom: 128px;
		}
		.sm-mb-129 {
			margin-bottom: 129px;
		}
		.sm-mb-130 {
			margin-bottom: 130px;
		}
		.sm-mb-131 {
			margin-bottom: 131px;
		}
		.sm-mb-132 {
			margin-bottom: 132px;
		}
		.sm-mb-133 {
			margin-bottom: 133px;
		}
		.sm-mb-134 {
			margin-bottom: 134px;
		}
		.sm-mb-135 {
			margin-bottom: 135px;
		}
		.sm-mb-136 {
			margin-bottom: 136px;
		}
		.sm-mb-137 {
			margin-bottom: 137px;
		}
		.sm-mb-138 {
			margin-bottom: 138px;
		}
		.sm-mb-139 {
			margin-bottom: 139px;
		}
		.sm-mb-140 {
			margin-bottom: 140px;
		}
		.sm-mb-141 {
			margin-bottom: 141px;
		}
		.sm-mb-142 {
			margin-bottom: 142px;
		}
		.sm-mb-143 {
			margin-bottom: 143px;
		}
		.sm-mb-144 {
			margin-bottom: 144px;
		}
		.sm-mb-145 {
			margin-bottom: 145px;
		}
		.sm-mb-146 {
			margin-bottom: 146px;
		}
		.sm-mb-147 {
			margin-bottom: 147px;
		}
		.sm-mb-148 {
			margin-bottom: 148px;
		}
		.sm-mb-149 {
			margin-bottom: 149px;
		}
		.sm-mb-150 {
			margin-bottom: 150px;
		}
		.sm-mb-151 {
			margin-bottom: 151px;
		}
		.sm-mb-152 {
			margin-bottom: 152px;
		}
		.sm-mb-153 {
			margin-bottom: 153px;
		}
		.sm-mb-154 {
			margin-bottom: 154px;
		}
		.sm-mb-155 {
			margin-bottom: 155px;
		}
		.sm-mb-156 {
			margin-bottom: 156px;
		}
		.sm-mb-157 {
			margin-bottom: 157px;
		}
		.sm-mb-158 {
			margin-bottom: 158px;
		}
		.sm-mb-159 {
			margin-bottom: 159px;
		}
		.sm-mb-160 {
			margin-bottom: 160px;
		}
		.sm-mb-161 {
			margin-bottom: 161px;
		}
		.sm-mb-162 {
			margin-bottom: 162px;
		}
		.sm-mb-163 {
			margin-bottom: 163px;
		}
		.sm-mb-164 {
			margin-bottom: 164px;
		}
		.sm-mb-165 {
			margin-bottom: 165px;
		}
		.sm-mb-166 {
			margin-bottom: 166px;
		}
		.sm-mb-167 {
			margin-bottom: 167px;
		}
		.sm-mb-168 {
			margin-bottom: 168px;
		}
		.sm-mb-169 {
			margin-bottom: 169px;
		}
		.sm-mb-170 {
			margin-bottom: 170px;
		}
		.sm-mb-171 {
			margin-bottom: 171px;
		}
		.sm-mb-172 {
			margin-bottom: 172px;
		}
		.sm-mb-173 {
			margin-bottom: 173px;
		}
		.sm-mb-174 {
			margin-bottom: 174px;
		}
		.sm-mb-175 {
			margin-bottom: 175px;
		}
		.sm-mb-176 {
			margin-bottom: 176px;
		}
		.sm-mb-177 {
			margin-bottom: 177px;
		}
		.sm-mb-178 {
			margin-bottom: 178px;
		}
		.sm-mb-179 {
			margin-bottom: 179px;
		}
		.sm-mb-180 {
			margin-bottom: 180px;
		}
		.sm-mb-181 {
			margin-bottom: 181px;
		}
		.sm-mb-182 {
			margin-bottom: 182px;
		}
		.sm-mb-183 {
			margin-bottom: 183px;
		}
		.sm-mb-184 {
			margin-bottom: 184px;
		}
		.sm-mb-185 {
			margin-bottom: 185px;
		}
		.sm-mb-186 {
			margin-bottom: 186px;
		}
		.sm-mb-187 {
			margin-bottom: 187px;
		}
		.sm-mb-188 {
			margin-bottom: 188px;
		}
		.sm-mb-189 {
			margin-bottom: 189px;
		}
		.sm-mb-190 {
			margin-bottom: 190px;
		}
		.sm-mb-191 {
			margin-bottom: 191px;
		}
		.sm-mb-192 {
			margin-bottom: 192px;
		}
		.sm-mb-193 {
			margin-bottom: 193px;
		}
		.sm-mb-194 {
			margin-bottom: 194px;
		}
		.sm-mb-195 {
			margin-bottom: 195px;
		}
		.sm-mb-196 {
			margin-bottom: 196px;
		}
		.sm-mb-197 {
			margin-bottom: 197px;
		}
		.sm-mb-198 {
			margin-bottom: 198px;
		}
		.sm-mb-199 {
			margin-bottom: 199px;
		}
		.sm-mb-200 {
			margin-bottom: 200px;
		}
	/*}*/

	/*------------------------------------------
		43. SM MARGIN RIGHT
	-------------------------------------------*/
	/*.sm-mr {*/
		.sm-mr-0 {
			margin-right: 0px;
		}
		.sm-mr-1 {
			margin-right: 1px;
		}
		.sm-mr-2 {
			margin-right: 2px;
		}
		.sm-mr-3 {
			margin-right: 3px;
		}
		.sm-mr-4 {
			margin-right: 4px;
		}
		.sm-mr-5 {
			margin-right: 5px;
		}
		.sm-mr-6 {
			margin-right: 6px;
		}
		.sm-mr-7 {
			margin-right: 7px;
		}
		.sm-mr-8 {
			margin-right: 8px;
		}
		.sm-mr-9 {
			margin-right: 9px;
		}
		.sm-mr-10 {
			margin-right: 10px;
		}
		.sm-mr-11 {
			margin-right: 11px;
		}
		.sm-mr-12 {
			margin-right: 12px;
		}
		.sm-mr-13 {
			margin-right: 13px;
		}
		.sm-mr-14 {
			margin-right: 14px;
		}
		.sm-mr-15 {
			margin-right: 15px;
		}
		.sm-mr-16 {
			margin-right: 16px;
		}
		.sm-mr-17 {
			margin-right: 17px;
		}
		.sm-mr-18 {
			margin-right: 18px;
		}
		.sm-mr-19 {
			margin-right: 19px;
		}
		.sm-mr-20 {
			margin-right: 20px;
		}
		.sm-mr-21 {
			margin-right: 21px;
		}
		.sm-mr-22 {
			margin-right: 22px;
		}
		.sm-mr-23 {
			margin-right: 23px;
		}
		.sm-mr-24 {
			margin-right: 24px;
		}
		.sm-mr-25 {
			margin-right: 25px;
		}
		.sm-mr-26 {
			margin-right: 26px;
		}
		.sm-mr-27 {
			margin-right: 27px;
		}
		.sm-mr-28 {
			margin-right: 28px;
		}
		.sm-mr-29 {
			margin-right: 29px;
		}
		.sm-mr-30 {
			margin-right: 30px;
		}
		.sm-mr-31 {
			margin-right: 31px;
		}
		.sm-mr-32 {
			margin-right: 32px;
		}
		.sm-mr-33 {
			margin-right: 33px;
		}
		.sm-mr-34 {
			margin-right: 34px;
		}
		.sm-mr-35 {
			margin-right: 35px;
		}
		.sm-mr-36 {
			margin-right: 36px;
		}
		.sm-mr-37 {
			margin-right: 37px;
		}
		.sm-mr-38 {
			margin-right: 38px;
		}
		.sm-mr-39 {
			margin-right: 39px;
		}
		.sm-mr-40 {
			margin-right: 40px;
		}
		.sm-mr-41 {
			margin-right: 41px;
		}
		.sm-mr-42 {
			margin-right: 42px;
		}
		.sm-mr-43 {
			margin-right: 43px;
		}
		.sm-mr-44 {
			margin-right: 44px;
		}
		.sm-mr-45 {
			margin-right: 45px;
		}
		.sm-mr-46 {
			margin-right: 46px;
		}
		.sm-mr-47 {
			margin-right: 47px;
		}
		.sm-mr-48 {
			margin-right: 48px;
		}
		.sm-mr-49 {
			margin-right: 49px;
		}
		.sm-mr-50 {
			margin-right: 50px;
		}
		.sm-mr-51 {
			margin-right: 51px;
		}
		.sm-mr-52 {
			margin-right: 52px;
		}
		.sm-mr-53 {
			margin-right: 53px;
		}
		.sm-mr-54 {
			margin-right: 54px;
		}
		.sm-mr-55 {
			margin-right: 55px;
		}
		.sm-mr-56 {
			margin-right: 56px;
		}
		.sm-mr-57 {
			margin-right: 57px;
		}
		.sm-mr-58 {
			margin-right: 58px;
		}
		.sm-mr-59 {
			margin-right: 59px;
		}
		.sm-mr-60 {
			margin-right: 60px;
		}
		.sm-mr-61 {
			margin-right: 61px;
		}
		.sm-mr-62 {
			margin-right: 62px;
		}
		.sm-mr-63 {
			margin-right: 63px;
		}
		.sm-mr-64 {
			margin-right: 64px;
		}
		.sm-mr-65 {
			margin-right: 65px;
		}
		.sm-mr-66 {
			margin-right: 66px;
		}
		.sm-mr-67 {
			margin-right: 67px;
		}
		.sm-mr-68 {
			margin-right: 68px;
		}
		.sm-mr-69 {
			margin-right: 69px;
		}
		.sm-mr-70 {
			margin-right: 70px;
		}
		.sm-mr-71 {
			margin-right: 71px;
		}
		.sm-mr-72 {
			margin-right: 72px;
		}
		.sm-mr-73 {
			margin-right: 73px;
		}
		.sm-mr-74 {
			margin-right: 74px;
		}
		.sm-mr-75 {
			margin-right: 75px;
		}
		.sm-mr-76 {
			margin-right: 76px;
		}
		.sm-mr-77 {
			margin-right: 77px;
		}
		.sm-mr-78 {
			margin-right: 78px;
		}
		.sm-mr-79 {
			margin-right: 79px;
		}
		.sm-mr-80 {
			margin-right: 80px;
		}
		.sm-mr-81 {
			margin-right: 81px;
		}
		.sm-mr-82 {
			margin-right: 82px;
		}
		.sm-mr-83 {
			margin-right: 83px;
		}
		.sm-mr-84 {
			margin-right: 84px;
		}
		.sm-mr-85 {
			margin-right: 85px;
		}
		.sm-mr-86 {
			margin-right: 86px;
		}
		.sm-mr-87 {
			margin-right: 87px;
		}
		.sm-mr-88 {
			margin-right: 88px;
		}
		.sm-mr-89 {
			margin-right: 89px;
		}
		.sm-mr-90 {
			margin-right: 90px;
		}
		.sm-mr-91 {
			margin-right: 91px;
		}
		.sm-mr-92 {
			margin-right: 92px;
		}
		.sm-mr-93 {
			margin-right: 93px;
		}
		.sm-mr-94 {
			margin-right: 94px;
		}
		.sm-mr-95 {
			margin-right: 95px;
		}
		.sm-mr-96 {
			margin-right: 96px;
		}
		.sm-mr-97 {
			margin-right: 97px;
		}
		.sm-mr-98 {
			margin-right: 98px;
		}
		.sm-mr-99 {
			margin-right: 99px;
		}
		.sm-mr-100 {
			margin-right: 100px;
		}
	/*}*/

	/*------------------------------------------
		44. SM MARGIN LEFT
	-------------------------------------------*/
	/*.sm-ml {*/
		.sm-ml-0 {
			margin-left: 0px;
		}
		.sm-ml-1 {
			margin-left: 1px;
		}
		.sm-ml-2 {
			margin-left: 2px;
		}
		.sm-ml-3 {
			margin-left: 3px;
		}
		.sm-ml-4 {
			margin-left: 4px;
		}
		.sm-ml-5 {
			margin-left: 5px;
		}
		.sm-ml-6 {
			margin-left: 6px;
		}
		.sm-ml-7 {
			margin-left: 7px;
		}
		.sm-ml-8 {
			margin-left: 8px;
		}
		.sm-ml-9 {
			margin-left: 9px;
		}
		.sm-ml-10 {
			margin-left: 10px;
		}
		.sm-ml-11 {
			margin-left: 11px;
		}
		.sm-ml-12 {
			margin-left: 12px;
		}
		.sm-ml-13 {
			margin-left: 13px;
		}
		.sm-ml-14 {
			margin-left: 14px;
		}
		.sm-ml-15 {
			margin-left: 15px;
		}
		.sm-ml-16 {
			margin-left: 16px;
		}
		.sm-ml-17 {
			margin-left: 17px;
		}
		.sm-ml-18 {
			margin-left: 18px;
		}
		.sm-ml-19 {
			margin-left: 19px;
		}
		.sm-ml-20 {
			margin-left: 20px;
		}
		.sm-ml-21 {
			margin-left: 21px;
		}
		.sm-ml-22 {
			margin-left: 22px;
		}
		.sm-ml-23 {
			margin-left: 23px;
		}
		.sm-ml-24 {
			margin-left: 24px;
		}
		.sm-ml-25 {
			margin-left: 25px;
		}
		.sm-ml-26 {
			margin-left: 26px;
		}
		.sm-ml-27 {
			margin-left: 27px;
		}
		.sm-ml-28 {
			margin-left: 28px;
		}
		.sm-ml-29 {
			margin-left: 29px;
		}
		.sm-ml-30 {
			margin-left: 30px;
		}
		.sm-ml-31 {
			margin-left: 31px;
		}
		.sm-ml-32 {
			margin-left: 32px;
		}
		.sm-ml-33 {
			margin-left: 33px;
		}
		.sm-ml-34 {
			margin-left: 34px;
		}
		.sm-ml-35 {
			margin-left: 35px;
		}
		.sm-ml-36 {
			margin-left: 36px;
		}
		.sm-ml-37 {
			margin-left: 37px;
		}
		.sm-ml-38 {
			margin-left: 38px;
		}
		.sm-ml-39 {
			margin-left: 39px;
		}
		.sm-ml-40 {
			margin-left: 40px;
		}
		.sm-ml-41 {
			margin-left: 41px;
		}
		.sm-ml-42 {
			margin-left: 42px;
		}
		.sm-ml-43 {
			margin-left: 43px;
		}
		.sm-ml-44 {
			margin-left: 44px;
		}
		.sm-ml-45 {
			margin-left: 45px;
		}
		.sm-ml-46 {
			margin-left: 46px;
		}
		.sm-ml-47 {
			margin-left: 47px;
		}
		.sm-ml-48 {
			margin-left: 48px;
		}
		.sm-ml-49 {
			margin-left: 49px;
		}
		.sm-ml-50 {
			margin-left: 50px;
		}
		.sm-ml-51 {
			margin-left: 51px;
		}
		.sm-ml-52 {
			margin-left: 52px;
		}
		.sm-ml-53 {
			margin-left: 53px;
		}
		.sm-ml-54 {
			margin-left: 54px;
		}
		.sm-ml-55 {
			margin-left: 55px;
		}
		.sm-ml-56 {
			margin-left: 56px;
		}
		.sm-ml-57 {
			margin-left: 57px;
		}
		.sm-ml-58 {
			margin-left: 58px;
		}
		.sm-ml-59 {
			margin-left: 59px;
		}
		.sm-ml-60 {
			margin-left: 60px;
		}
		.sm-ml-61 {
			margin-left: 61px;
		}
		.sm-ml-62 {
			margin-left: 62px;
		}
		.sm-ml-63 {
			margin-left: 63px;
		}
		.sm-ml-64 {
			margin-left: 64px;
		}
		.sm-ml-65 {
			margin-left: 65px;
		}
		.sm-ml-66 {
			margin-left: 66px;
		}
		.sm-ml-67 {
			margin-left: 67px;
		}
		.sm-ml-68 {
			margin-left: 68px;
		}
		.sm-ml-69 {
			margin-left: 69px;
		}
		.sm-ml-70 {
			margin-left: 70px;
		}
		.sm-ml-71 {
			margin-left: 71px;
		}
		.sm-ml-72 {
			margin-left: 72px;
		}
		.sm-ml-73 {
			margin-left: 73px;
		}
		.sm-ml-74 {
			margin-left: 74px;
		}
		.sm-ml-75 {
			margin-left: 75px;
		}
		.sm-ml-76 {
			margin-left: 76px;
		}
		.sm-ml-77 {
			margin-left: 77px;
		}
		.sm-ml-78 {
			margin-left: 78px;
		}
		.sm-ml-79 {
			margin-left: 79px;
		}
		.sm-ml-80 {
			margin-left: 80px;
		}
		.sm-ml-81 {
			margin-left: 81px;
		}
		.sm-ml-82 {
			margin-left: 82px;
		}
		.sm-ml-83 {
			margin-left: 83px;
		}
		.sm-ml-84 {
			margin-left: 84px;
		}
		.sm-ml-85 {
			margin-left: 85px;
		}
		.sm-ml-86 {
			margin-left: 86px;
		}
		.sm-ml-87 {
			margin-left: 87px;
		}
		.sm-ml-88 {
			margin-left: 88px;
		}
		.sm-ml-89 {
			margin-left: 89px;
		}
		.sm-ml-90 {
			margin-left: 90px;
		}
		.sm-ml-91 {
			margin-left: 91px;
		}
		.sm-ml-92 {
			margin-left: 92px;
		}
		.sm-ml-93 {
			margin-left: 93px;
		}
		.sm-ml-94 {
			margin-left: 94px;
		}
		.sm-ml-95 {
			margin-left: 95px;
		}
		.sm-ml-96 {
			margin-left: 96px;
		}
		.sm-ml-97 {
			margin-left: 97px;
		}
		.sm-ml-98 {
			margin-left: 98px;
		}
		.sm-ml-99 {
			margin-left: 99px;
		}
		.sm-ml-100 {
			margin-left: 100px;
		}
	/*}*/

	/*------------------------------------------
		45. SM MARGIN TOP REVERSE
	-------------------------------------------*/
	/*.sm-mt- {*/
		.sm-mt--1 {
			margin-top: -1px;
		}
		.sm-mt--2 {
			margin-top: -2px;
		}
		.sm-mt--3 {
			margin-top: -3px;
		}
		.sm-mt--4 {
			margin-top: -4px;
		}
		.sm-mt--5 {
			margin-top: -5px;
		}
		.sm-mt--6 {
			margin-top: -6px;
		}
		.sm-mt--7 {
			margin-top: -7px;
		}
		.sm-mt--8 {
			margin-top: -8px;
		}
		.sm-mt--9 {
			margin-top: -9px;
		}
		.sm-mt--10 {
			margin-top: -10px;
		}
		.sm-mt--11 {
			margin-top: -11px;
		}
		.sm-mt--12 {
			margin-top: -12px;
		}
		.sm-mt--13 {
			margin-top: -13px;
		}
		.sm-mt--14 {
			margin-top: -14px;
		}
		.sm-mt--15 {
			margin-top: -15px;
		}
		.sm-mt--16 {
			margin-top: -16px;
		}
		.sm-mt--17 {
			margin-top: -17px;
		}
		.sm-mt--18 {
			margin-top: -18px;
		}
		.sm-mt--19 {
			margin-top: -19px;
		}
		.sm-mt--20 {
			margin-top: -20px;
		}
		.sm-mt--21 {
			margin-top: -21px;
		}
		.sm-mt--22 {
			margin-top: -22px;
		}
		.sm-mt--23 {
			margin-top: -23px;
		}
		.sm-mt--24 {
			margin-top: -24px;
		}
		.sm-mt--25 {
			margin-top: -25px;
		}
		.sm-mt--26 {
			margin-top: -26px;
		}
		.sm-mt--27 {
			margin-top: -27px;
		}
		.sm-mt--28 {
			margin-top: -28px;
		}
		.sm-mt--29 {
			margin-top: -29px;
		}
		.sm-mt--30 {
			margin-top: -30px;
		}
		.sm-mt--31 {
			margin-top: -31px;
		}
		.sm-mt--32 {
			margin-top: -32px;
		}
		.sm-mt--33 {
			margin-top: -33px;
		}
		.sm-mt--34 {
			margin-top: -34px;
		}
		.sm-mt--35 {
			margin-top: -35px;
		}
		.sm-mt--36 {
			margin-top: -36px;
		}
		.sm-mt--37 {
			margin-top: -37px;
		}
		.sm-mt--38 {
			margin-top: -38px;
		}
		.sm-mt--39 {
			margin-top: -39px;
		}
		.sm-mt--40 {
			margin-top: -40px;
		}
		.sm-mt--41 {
			margin-top: -41px;
		}
		.sm-mt--42 {
			margin-top: -42px;
		}
		.sm-mt--43 {
			margin-top: -43px;
		}
		.sm-mt--44 {
			margin-top: -44px;
		}
		.sm-mt--45 {
			margin-top: -45px;
		}
		.sm-mt--46 {
			margin-top: -46px;
		}
		.sm-mt--47 {
			margin-top: -47px;
		}
		.sm-mt--48 {
			margin-top: -48px;
		}
		.sm-mt--49 {
			margin-top: -49px;
		}
		.sm-mt--50 {
			margin-top: -50px;
		}
		.sm-mt--51 {
			margin-top: -51px;
		}
		.sm-mt--52 {
			margin-top: -52px;
		}
		.sm-mt--53 {
			margin-top: -53px;
		}
		.sm-mt--54 {
			margin-top: -54px;
		}
		.sm-mt--55 {
			margin-top: -55px;
		}
		.sm-mt--56 {
			margin-top: -56px;
		}
		.sm-mt--57 {
			margin-top: -57px;
		}
		.sm-mt--58 {
			margin-top: -58px;
		}
		.sm-mt--59 {
			margin-top: -59px;
		}
		.sm-mt--60 {
			margin-top: -60px;
		}
		.sm-mt--61 {
			margin-top: -61px;
		}
		.sm-mt--62 {
			margin-top: -62px;
		}
		.sm-mt--63 {
			margin-top: -63px;
		}
		.sm-mt--64 {
			margin-top: -64px;
		}
		.sm-mt--65 {
			margin-top: -65px;
		}
		.sm-mt--66 {
			margin-top: -66px;
		}
		.sm-mt--67 {
			margin-top: -67px;
		}
		.sm-mt--68 {
			margin-top: -68px;
		}
		.sm-mt--69 {
			margin-top: -69px;
		}
		.sm-mt--70 {
			margin-top: -70px;
		}
		.sm-mt--71 {
			margin-top: -71px;
		}
		.sm-mt--72 {
			margin-top: -72px;
		}
		.sm-mt--73 {
			margin-top: -73px;
		}
		.sm-mt--74 {
			margin-top: -74px;
		}
		.sm-mt--75 {
			margin-top: -75px;
		}
		.sm-mt--76 {
			margin-top: -76px;
		}
		.sm-mt--77 {
			margin-top: -77px;
		}
		.sm-mt--78 {
			margin-top: -78px;
		}
		.sm-mt--79 {
			margin-top: -79px;
		}
		.sm-mt--80 {
			margin-top: -80px;
		}
		.sm-mt--81 {
			margin-top: -81px;
		}
		.sm-mt--82 {
			margin-top: -82px;
		}
		.sm-mt--83 {
			margin-top: -83px;
		}
		.sm-mt--84 {
			margin-top: -84px;
		}
		.sm-mt--85 {
			margin-top: -85px;
		}
		.sm-mt--86 {
			margin-top: -86px;
		}
		.sm-mt--87 {
			margin-top: -87px;
		}
		.sm-mt--88 {
			margin-top: -88px;
		}
		.sm-mt--89 {
			margin-top: -89px;
		}
		.sm-mt--90 {
			margin-top: -90px;
		}
		.sm-mt--91 {
			margin-top: -91px;
		}
		.sm-mt--92 {
			margin-top: -92px;
		}
		.sm-mt--93 {
			margin-top: -93px;
		}
		.sm-mt--94 {
			margin-top: -94px;
		}
		.sm-mt--95 {
			margin-top: -95px;
		}
		.sm-mt--96 {
			margin-top: -96px;
		}
		.sm-mt--97 {
			margin-top: -97px;
		}
		.sm-mt--98 {
			margin-top: -98px;
		}
		.sm-mt--99 {
			margin-top: -99px;
		}
		.sm-mt--100 {
			margin-top: -100px;
		}
		.sm-mt--101 {
			margin-top: -101px;
		}
		.sm-mt--102 {
			margin-top: -102px;
		}
		.sm-mt--103 {
			margin-top: -103px;
		}
		.sm-mt--104 {
			margin-top: -104px;
		}
		.sm-mt--105 {
			margin-top: -105px;
		}
		.sm-mt--106 {
			margin-top: -106px;
		}
		.sm-mt--107 {
			margin-top: -107px;
		}
		.sm-mt--108 {
			margin-top: -108px;
		}
		.sm-mt--109 {
			margin-top: -109px;
		}
		.sm-mt--110 {
			margin-top: -110px;
		}
		.sm-mt--111 {
			margin-top: -111px;
		}
		.sm-mt--112 {
			margin-top: -112px;
		}
		.sm-mt--113 {
			margin-top: -113px;
		}
		.sm-mt--114 {
			margin-top: -114px;
		}
		.sm-mt--115 {
			margin-top: -115px;
		}
		.sm-mt--116 {
			margin-top: -116px;
		}
		.sm-mt--117 {
			margin-top: -117px;
		}
		.sm-mt--118 {
			margin-top: -118px;
		}
		.sm-mt--119 {
			margin-top: -119px;
		}
		.sm-mt--120 {
			margin-top: -120px;
		}
		.sm-mt--121 {
			margin-top: -121px;
		}
		.sm-mt--122 {
			margin-top: -122px;
		}
		.sm-mt--123 {
			margin-top: -123px;
		}
		.sm-mt--124 {
			margin-top: -124px;
		}
		.sm-mt--125 {
			margin-top: -125px;
		}
		.sm-mt--126 {
			margin-top: -126px;
		}
		.sm-mt--127 {
			margin-top: -127px;
		}
		.sm-mt--128 {
			margin-top: -128px;
		}
		.sm-mt--129 {
			margin-top: -129px;
		}
		.sm-mt--130 {
			margin-top: -130px;
		}
		.sm-mt--131 {
			margin-top: -131px;
		}
		.sm-mt--132 {
			margin-top: -132px;
		}
		.sm-mt--133 {
			margin-top: -133px;
		}
		.sm-mt--134 {
			margin-top: -134px;
		}
		.sm-mt--135 {
			margin-top: -135px;
		}
		.sm-mt--136 {
			margin-top: -136px;
		}
		.sm-mt--137 {
			margin-top: -137px;
		}
		.sm-mt--138 {
			margin-top: -138px;
		}
		.sm-mt--139 {
			margin-top: -139px;
		}
		.sm-mt--140 {
			margin-top: -140px;
		}
		.sm-mt--141 {
			margin-top: -141px;
		}
		.sm-mt--142 {
			margin-top: -142px;
		}
		.sm-mt--143 {
			margin-top: -143px;
		}
		.sm-mt--144 {
			margin-top: -144px;
		}
		.sm-mt--145 {
			margin-top: -145px;
		}
		.sm-mt--146 {
			margin-top: -146px;
		}
		.sm-mt--147 {
			margin-top: -147px;
		}
		.sm-mt--148 {
			margin-top: -148px;
		}
		.sm-mt--149 {
			margin-top: -149px;
		}
		.sm-mt--150 {
			margin-top: -150px;
		}
		.sm-mt--151 {
			margin-top: -151px;
		}
		.sm-mt--152 {
			margin-top: -152px;
		}
		.sm-mt--153 {
			margin-top: -153px;
		}
		.sm-mt--154 {
			margin-top: -154px;
		}
		.sm-mt--155 {
			margin-top: -155px;
		}
		.sm-mt--156 {
			margin-top: -156px;
		}
		.sm-mt--157 {
			margin-top: -157px;
		}
		.sm-mt--158 {
			margin-top: -158px;
		}
		.sm-mt--159 {
			margin-top: -159px;
		}
		.sm-mt--160 {
			margin-top: -160px;
		}
		.sm-mt--161 {
			margin-top: -161px;
		}
		.sm-mt--162 {
			margin-top: -162px;
		}
		.sm-mt--163 {
			margin-top: -163px;
		}
		.sm-mt--164 {
			margin-top: -164px;
		}
		.sm-mt--165 {
			margin-top: -165px;
		}
		.sm-mt--166 {
			margin-top: -166px;
		}
		.sm-mt--167 {
			margin-top: -167px;
		}
		.sm-mt--168 {
			margin-top: -168px;
		}
		.sm-mt--169 {
			margin-top: -169px;
		}
		.sm-mt--170 {
			margin-top: -170px;
		}
		.sm-mt--171 {
			margin-top: -171px;
		}
		.sm-mt--172 {
			margin-top: -172px;
		}
		.sm-mt--173 {
			margin-top: -173px;
		}
		.sm-mt--174 {
			margin-top: -174px;
		}
		.sm-mt--175 {
			margin-top: -175px;
		}
		.sm-mt--176 {
			margin-top: -176px;
		}
		.sm-mt--177 {
			margin-top: -177px;
		}
		.sm-mt--178 {
			margin-top: -178px;
		}
		.sm-mt--179 {
			margin-top: -179px;
		}
		.sm-mt--180 {
			margin-top: -180px;
		}
		.sm-mt--181 {
			margin-top: -181px;
		}
		.sm-mt--182 {
			margin-top: -182px;
		}
		.sm-mt--183 {
			margin-top: -183px;
		}
		.sm-mt--184 {
			margin-top: -184px;
		}
		.sm-mt--185 {
			margin-top: -185px;
		}
		.sm-mt--186 {
			margin-top: -186px;
		}
		.sm-mt--187 {
			margin-top: -187px;
		}
		.sm-mt--188 {
			margin-top: -188px;
		}
		.sm-mt--189 {
			margin-top: -189px;
		}
		.sm-mt--190 {
			margin-top: -190px;
		}
		.sm-mt--191 {
			margin-top: -191px;
		}
		.sm-mt--192 {
			margin-top: -192px;
		}
		.sm-mt--193 {
			margin-top: -193px;
		}
		.sm-mt--194 {
			margin-top: -194px;
		}
		.sm-mt--195 {
			margin-top: -195px;
		}
		.sm-mt--196 {
			margin-top: -196px;
		}
		.sm-mt--197 {
			margin-top: -197px;
		}
		.sm-mt--198 {
			margin-top: -198px;
		}
		.sm-mt--199 {
			margin-top: -199px;
		}
		.sm-mt--200 {
			margin-top: -200px;
		}
	/*}*/

	/*------------------------------------------
		46. SM MARGIN BOTTOM REVERSE
	-------------------------------------------*/
	/*.sm-mb- {*/
		.sm-mb--1 {
			margin-bottom: -1px;
		}
		.sm-mb--2 {
			margin-bottom: -2px;
		}
		.sm-mb--3 {
			margin-bottom: -3px;
		}
		.sm-mb--4 {
			margin-bottom: -4px;
		}
		.sm-mb--5 {
			margin-bottom: -5px;
		}
		.sm-mb--6 {
			margin-bottom: -6px;
		}
		.sm-mb--7 {
			margin-bottom: -7px;
		}
		.sm-mb--8 {
			margin-bottom: -8px;
		}
		.sm-mb--9 {
			margin-bottom: -9px;
		}
		.sm-mb--10 {
			margin-bottom: -10px;
		}
		.sm-mb--11 {
			margin-bottom: -11px;
		}
		.sm-mb--12 {
			margin-bottom: -12px;
		}
		.sm-mb--13 {
			margin-bottom: -13px;
		}
		.sm-mb--14 {
			margin-bottom: -14px;
		}
		.sm-mb--15 {
			margin-bottom: -15px;
		}
		.sm-mb--16 {
			margin-bottom: -16px;
		}
		.sm-mb--17 {
			margin-bottom: -17px;
		}
		.sm-mb--18 {
			margin-bottom: -18px;
		}
		.sm-mb--19 {
			margin-bottom: -19px;
		}
		.sm-mb--20 {
			margin-bottom: -20px;
		}
		.sm-mb--21 {
			margin-bottom: -21px;
		}
		.sm-mb--22 {
			margin-bottom: -22px;
		}
		.sm-mb--23 {
			margin-bottom: -23px;
		}
		.sm-mb--24 {
			margin-bottom: -24px;
		}
		.sm-mb--25 {
			margin-bottom: -25px;
		}
		.sm-mb--26 {
			margin-bottom: -26px;
		}
		.sm-mb--27 {
			margin-bottom: -27px;
		}
		.sm-mb--28 {
			margin-bottom: -28px;
		}
		.sm-mb--29 {
			margin-bottom: -29px;
		}
		.sm-mb--30 {
			margin-bottom: -30px;
		}
		.sm-mb--31 {
			margin-bottom: -31px;
		}
		.sm-mb--32 {
			margin-bottom: -32px;
		}
		.sm-mb--33 {
			margin-bottom: -33px;
		}
		.sm-mb--34 {
			margin-bottom: -34px;
		}
		.sm-mb--35 {
			margin-bottom: -35px;
		}
		.sm-mb--36 {
			margin-bottom: -36px;
		}
		.sm-mb--37 {
			margin-bottom: -37px;
		}
		.sm-mb--38 {
			margin-bottom: -38px;
		}
		.sm-mb--39 {
			margin-bottom: -39px;
		}
		.sm-mb--40 {
			margin-bottom: -40px;
		}
		.sm-mb--41 {
			margin-bottom: -41px;
		}
		.sm-mb--42 {
			margin-bottom: -42px;
		}
		.sm-mb--43 {
			margin-bottom: -43px;
		}
		.sm-mb--44 {
			margin-bottom: -44px;
		}
		.sm-mb--45 {
			margin-bottom: -45px;
		}
		.sm-mb--46 {
			margin-bottom: -46px;
		}
		.sm-mb--47 {
			margin-bottom: -47px;
		}
		.sm-mb--48 {
			margin-bottom: -48px;
		}
		.sm-mb--49 {
			margin-bottom: -49px;
		}
		.sm-mb--50 {
			margin-bottom: -50px;
		}
		.sm-mb--51 {
			margin-bottom: -51px;
		}
		.sm-mb--52 {
			margin-bottom: -52px;
		}
		.sm-mb--53 {
			margin-bottom: -53px;
		}
		.sm-mb--54 {
			margin-bottom: -54px;
		}
		.sm-mb--55 {
			margin-bottom: -55px;
		}
		.sm-mb--56 {
			margin-bottom: -56px;
		}
		.sm-mb--57 {
			margin-bottom: -57px;
		}
		.sm-mb--58 {
			margin-bottom: -58px;
		}
		.sm-mb--59 {
			margin-bottom: -59px;
		}
		.sm-mb--60 {
			margin-bottom: -60px;
		}
		.sm-mb--61 {
			margin-bottom: -61px;
		}
		.sm-mb--62 {
			margin-bottom: -62px;
		}
		.sm-mb--63 {
			margin-bottom: -63px;
		}
		.sm-mb--64 {
			margin-bottom: -64px;
		}
		.sm-mb--65 {
			margin-bottom: -65px;
		}
		.sm-mb--66 {
			margin-bottom: -66px;
		}
		.sm-mb--67 {
			margin-bottom: -67px;
		}
		.sm-mb--68 {
			margin-bottom: -68px;
		}
		.sm-mb--69 {
			margin-bottom: -69px;
		}
		.sm-mb--70 {
			margin-bottom: -70px;
		}
		.sm-mb--71 {
			margin-bottom: -71px;
		}
		.sm-mb--72 {
			margin-bottom: -72px;
		}
		.sm-mb--73 {
			margin-bottom: -73px;
		}
		.sm-mb--74 {
			margin-bottom: -74px;
		}
		.sm-mb--75 {
			margin-bottom: -75px;
		}
		.sm-mb--76 {
			margin-bottom: -76px;
		}
		.sm-mb--77 {
			margin-bottom: -77px;
		}
		.sm-mb--78 {
			margin-bottom: -78px;
		}
		.sm-mb--79 {
			margin-bottom: -79px;
		}
		.sm-mb--80 {
			margin-bottom: -80px;
		}
		.sm-mb--81 {
			margin-bottom: -81px;
		}
		.sm-mb--82 {
			margin-bottom: -82px;
		}
		.sm-mb--83 {
			margin-bottom: -83px;
		}
		.sm-mb--84 {
			margin-bottom: -84px;
		}
		.sm-mb--85 {
			margin-bottom: -85px;
		}
		.sm-mb--86 {
			margin-bottom: -86px;
		}
		.sm-mb--87 {
			margin-bottom: -87px;
		}
		.sm-mb--88 {
			margin-bottom: -88px;
		}
		.sm-mb--89 {
			margin-bottom: -89px;
		}
		.sm-mb--90 {
			margin-bottom: -90px;
		}
		.sm-mb--91 {
			margin-bottom: -91px;
		}
		.sm-mb--92 {
			margin-bottom: -92px;
		}
		.sm-mb--93 {
			margin-bottom: -93px;
		}
		.sm-mb--94 {
			margin-bottom: -94px;
		}
		.sm-mb--95 {
			margin-bottom: -95px;
		}
		.sm-mb--96 {
			margin-bottom: -96px;
		}
		.sm-mb--97 {
			margin-bottom: -97px;
		}
		.sm-mb--98 {
			margin-bottom: -98px;
		}
		.sm-mb--99 {
			margin-bottom: -99px;
		}
		.sm-mb--100 {
			margin-bottom: -100px;
		}
		.sm-mb--101 {
			margin-bottom: -101px;
		}
		.sm-mb--102 {
			margin-bottom: -102px;
		}
		.sm-mb--103 {
			margin-bottom: -103px;
		}
		.sm-mb--104 {
			margin-bottom: -104px;
		}
		.sm-mb--105 {
			margin-bottom: -105px;
		}
		.sm-mb--106 {
			margin-bottom: -106px;
		}
		.sm-mb--107 {
			margin-bottom: -107px;
		}
		.sm-mb--108 {
			margin-bottom: -108px;
		}
		.sm-mb--109 {
			margin-bottom: -109px;
		}
		.sm-mb--110 {
			margin-bottom: -110px;
		}
		.sm-mb--111 {
			margin-bottom: -111px;
		}
		.sm-mb--112 {
			margin-bottom: -112px;
		}
		.sm-mb--113 {
			margin-bottom: -113px;
		}
		.sm-mb--114 {
			margin-bottom: -114px;
		}
		.sm-mb--115 {
			margin-bottom: -115px;
		}
		.sm-mb--116 {
			margin-bottom: -116px;
		}
		.sm-mb--117 {
			margin-bottom: -117px;
		}
		.sm-mb--118 {
			margin-bottom: -118px;
		}
		.sm-mb--119 {
			margin-bottom: -119px;
		}
		.sm-mb--120 {
			margin-bottom: -120px;
		}
		.sm-mb--121 {
			margin-bottom: -121px;
		}
		.sm-mb--122 {
			margin-bottom: -122px;
		}
		.sm-mb--123 {
			margin-bottom: -123px;
		}
		.sm-mb--124 {
			margin-bottom: -124px;
		}
		.sm-mb--125 {
			margin-bottom: -125px;
		}
		.sm-mb--126 {
			margin-bottom: -126px;
		}
		.sm-mb--127 {
			margin-bottom: -127px;
		}
		.sm-mb--128 {
			margin-bottom: -128px;
		}
		.sm-mb--129 {
			margin-bottom: -129px;
		}
		.sm-mb--130 {
			margin-bottom: -130px;
		}
		.sm-mb--131 {
			margin-bottom: -131px;
		}
		.sm-mb--132 {
			margin-bottom: -132px;
		}
		.sm-mb--133 {
			margin-bottom: -133px;
		}
		.sm-mb--134 {
			margin-bottom: -134px;
		}
		.sm-mb--135 {
			margin-bottom: -135px;
		}
		.sm-mb--136 {
			margin-bottom: -136px;
		}
		.sm-mb--137 {
			margin-bottom: -137px;
		}
		.sm-mb--138 {
			margin-bottom: -138px;
		}
		.sm-mb--139 {
			margin-bottom: -139px;
		}
		.sm-mb--140 {
			margin-bottom: -140px;
		}
		.sm-mb--141 {
			margin-bottom: -141px;
		}
		.sm-mb--142 {
			margin-bottom: -142px;
		}
		.sm-mb--143 {
			margin-bottom: -143px;
		}
		.sm-mb--144 {
			margin-bottom: -144px;
		}
		.sm-mb--145 {
			margin-bottom: -145px;
		}
		.sm-mb--146 {
			margin-bottom: -146px;
		}
		.sm-mb--147 {
			margin-bottom: -147px;
		}
		.sm-mb--148 {
			margin-bottom: -148px;
		}
		.sm-mb--149 {
			margin-bottom: -149px;
		}
		.sm-mb--150 {
			margin-bottom: -150px;
		}
		.sm-mb--151 {
			margin-bottom: -151px;
		}
		.sm-mb--152 {
			margin-bottom: -152px;
		}
		.sm-mb--153 {
			margin-bottom: -153px;
		}
		.sm-mb--154 {
			margin-bottom: -154px;
		}
		.sm-mb--155 {
			margin-bottom: -155px;
		}
		.sm-mb--156 {
			margin-bottom: -156px;
		}
		.sm-mb--157 {
			margin-bottom: -157px;
		}
		.sm-mb--158 {
			margin-bottom: -158px;
		}
		.sm-mb--159 {
			margin-bottom: -159px;
		}
		.sm-mb--160 {
			margin-bottom: -160px;
		}
		.sm-mb--161 {
			margin-bottom: -161px;
		}
		.sm-mb--162 {
			margin-bottom: -162px;
		}
		.sm-mb--163 {
			margin-bottom: -163px;
		}
		.sm-mb--164 {
			margin-bottom: -164px;
		}
		.sm-mb--165 {
			margin-bottom: -165px;
		}
		.sm-mb--166 {
			margin-bottom: -166px;
		}
		.sm-mb--167 {
			margin-bottom: -167px;
		}
		.sm-mb--168 {
			margin-bottom: -168px;
		}
		.sm-mb--169 {
			margin-bottom: -169px;
		}
		.sm-mb--170 {
			margin-bottom: -170px;
		}
		.sm-mb--171 {
			margin-bottom: -171px;
		}
		.sm-mb--172 {
			margin-bottom: -172px;
		}
		.sm-mb--173 {
			margin-bottom: -173px;
		}
		.sm-mb--174 {
			margin-bottom: -174px;
		}
		.sm-mb--175 {
			margin-bottom: -175px;
		}
		.sm-mb--176 {
			margin-bottom: -176px;
		}
		.sm-mb--177 {
			margin-bottom: -177px;
		}
		.sm-mb--178 {
			margin-bottom: -178px;
		}
		.sm-mb--179 {
			margin-bottom: -179px;
		}
		.sm-mb--180 {
			margin-bottom: -180px;
		}
		.sm-mb--181 {
			margin-bottom: -181px;
		}
		.sm-mb--182 {
			margin-bottom: -182px;
		}
		.sm-mb--183 {
			margin-bottom: -183px;
		}
		.sm-mb--184 {
			margin-bottom: -184px;
		}
		.sm-mb--185 {
			margin-bottom: -185px;
		}
		.sm-mb--186 {
			margin-bottom: -186px;
		}
		.sm-mb--187 {
			margin-bottom: -187px;
		}
		.sm-mb--188 {
			margin-bottom: -188px;
		}
		.sm-mb--189 {
			margin-bottom: -189px;
		}
		.sm-mb--190 {
			margin-bottom: -190px;
		}
		.sm-mb--191 {
			margin-bottom: -191px;
		}
		.sm-mb--192 {
			margin-bottom: -192px;
		}
		.sm-mb--193 {
			margin-bottom: -193px;
		}
		.sm-mb--194 {
			margin-bottom: -194px;
		}
		.sm-mb--195 {
			margin-bottom: -195px;
		}
		.sm-mb--196 {
			margin-bottom: -196px;
		}
		.sm-mb--197 {
			margin-bottom: -197px;
		}
		.sm-mb--198 {
			margin-bottom: -198px;
		}
		.sm-mb--199 {
			margin-bottom: -199px;
		}
		.sm-mb--200 {
			margin-bottom: -200px;
		}
	/*}*/

	/*------------------------------------------
		47. SM MARGIN LEFT REVERSE
	-------------------------------------------*/
	/*.sm-ml- {*/
		.sm-ml--1 {
			margin-left: -1px;
		}
		.sm-ml--2 {
			margin-left: -2px;
		}
		.sm-ml--3 {
			margin-left: -3px;
		}
		.sm-ml--4 {
			margin-left: -4px;
		}
		.sm-ml--5 {
			margin-left: -5px;
		}
		.sm-ml--6 {
			margin-left: -6px;
		}
		.sm-ml--7 {
			margin-left: -7px;
		}
		.sm-ml--8 {
			margin-left: -8px;
		}
		.sm-ml--9 {
			margin-left: -9px;
		}
		.sm-ml--10 {
			margin-left: -10px;
		}
		.sm-ml--11 {
			margin-left: -11px;
		}
		.sm-ml--12 {
			margin-left: -12px;
		}
		.sm-ml--13 {
			margin-left: -13px;
		}
		.sm-ml--14 {
			margin-left: -14px;
		}
		.sm-ml--15 {
			margin-left: -15px;
		}
		.sm-ml--16 {
			margin-left: -16px;
		}
		.sm-ml--17 {
			margin-left: -17px;
		}
		.sm-ml--18 {
			margin-left: -18px;
		}
		.sm-ml--19 {
			margin-left: -19px;
		}
		.sm-ml--20 {
			margin-left: -20px;
		}
		.sm-ml--21 {
			margin-left: -21px;
		}
		.sm-ml--22 {
			margin-left: -22px;
		}
		.sm-ml--23 {
			margin-left: -23px;
		}
		.sm-ml--24 {
			margin-left: -24px;
		}
		.sm-ml--25 {
			margin-left: -25px;
		}
		.sm-ml--26 {
			margin-left: -26px;
		}
		.sm-ml--27 {
			margin-left: -27px;
		}
		.sm-ml--28 {
			margin-left: -28px;
		}
		.sm-ml--29 {
			margin-left: -29px;
		}
		.sm-ml--30 {
			margin-left: -30px;
		}
		.sm-ml--31 {
			margin-left: -31px;
		}
		.sm-ml--32 {
			margin-left: -32px;
		}
		.sm-ml--33 {
			margin-left: -33px;
		}
		.sm-ml--34 {
			margin-left: -34px;
		}
		.sm-ml--35 {
			margin-left: -35px;
		}
		.sm-ml--36 {
			margin-left: -36px;
		}
		.sm-ml--37 {
			margin-left: -37px;
		}
		.sm-ml--38 {
			margin-left: -38px;
		}
		.sm-ml--39 {
			margin-left: -39px;
		}
		.sm-ml--40 {
			margin-left: -40px;
		}
		.sm-ml--41 {
			margin-left: -41px;
		}
		.sm-ml--42 {
			margin-left: -42px;
		}
		.sm-ml--43 {
			margin-left: -43px;
		}
		.sm-ml--44 {
			margin-left: -44px;
		}
		.sm-ml--45 {
			margin-left: -45px;
		}
		.sm-ml--46 {
			margin-left: -46px;
		}
		.sm-ml--47 {
			margin-left: -47px;
		}
		.sm-ml--48 {
			margin-left: -48px;
		}
		.sm-ml--49 {
			margin-left: -49px;
		}
		.sm-ml--50 {
			margin-left: -50px;
		}
		.sm-ml--51 {
			margin-left: -51px;
		}
		.sm-ml--52 {
			margin-left: -52px;
		}
		.sm-ml--53 {
			margin-left: -53px;
		}
		.sm-ml--54 {
			margin-left: -54px;
		}
		.sm-ml--55 {
			margin-left: -55px;
		}
		.sm-ml--56 {
			margin-left: -56px;
		}
		.sm-ml--57 {
			margin-left: -57px;
		}
		.sm-ml--58 {
			margin-left: -58px;
		}
		.sm-ml--59 {
			margin-left: -59px;
		}
		.sm-ml--60 {
			margin-left: -60px;
		}
		.sm-ml--61 {
			margin-left: -61px;
		}
		.sm-ml--62 {
			margin-left: -62px;
		}
		.sm-ml--63 {
			margin-left: -63px;
		}
		.sm-ml--64 {
			margin-left: -64px;
		}
		.sm-ml--65 {
			margin-left: -65px;
		}
		.sm-ml--66 {
			margin-left: -66px;
		}
		.sm-ml--67 {
			margin-left: -67px;
		}
		.sm-ml--68 {
			margin-left: -68px;
		}
		.sm-ml--69 {
			margin-left: -69px;
		}
		.sm-ml--70 {
			margin-left: -70px;
		}
		.sm-ml--71 {
			margin-left: -71px;
		}
		.sm-ml--72 {
			margin-left: -72px;
		}
		.sm-ml--73 {
			margin-left: -73px;
		}
		.sm-ml--74 {
			margin-left: -74px;
		}
		.sm-ml--75 {
			margin-left: -75px;
		}
		.sm-ml--76 {
			margin-left: -76px;
		}
		.sm-ml--77 {
			margin-left: -77px;
		}
		.sm-ml--78 {
			margin-left: -78px;
		}
		.sm-ml--79 {
			margin-left: -79px;
		}
		.sm-ml--80 {
			margin-left: -80px;
		}
		.sm-ml--81 {
			margin-left: -81px;
		}
		.sm-ml--82 {
			margin-left: -82px;
		}
		.sm-ml--83 {
			margin-left: -83px;
		}
		.sm-ml--84 {
			margin-left: -84px;
		}
		.sm-ml--85 {
			margin-left: -85px;
		}
		.sm-ml--86 {
			margin-left: -86px;
		}
		.sm-ml--87 {
			margin-left: -87px;
		}
		.sm-ml--88 {
			margin-left: -88px;
		}
		.sm-ml--89 {
			margin-left: -89px;
		}
		.sm-ml--90 {
			margin-left: -90px;
		}
		.sm-ml--91 {
			margin-left: -91px;
		}
		.sm-ml--92 {
			margin-left: -92px;
		}
		.sm-ml--93 {
			margin-left: -93px;
		}
		.sm-ml--94 {
			margin-left: -94px;
		}
		.sm-ml--95 {
			margin-left: -95px;
		}
		.sm-ml--96 {
			margin-left: -96px;
		}
		.sm-ml--97 {
			margin-left: -97px;
		}
		.sm-ml--98 {
			margin-left: -98px;
		}
		.sm-ml--99 {
			margin-left: -99px;
		}
		.sm-ml--100 {
			margin-left: -100px;
		}
		.sm-ml--101 {
			margin-left: -101px;
		}
		.sm-ml--102 {
			margin-left: -102px;
		}
		.sm-ml--103 {
			margin-left: -103px;
		}
		.sm-ml--104 {
			margin-left: -104px;
		}
		.sm-ml--105 {
			margin-left: -105px;
		}
		.sm-ml--106 {
			margin-left: -106px;
		}
		.sm-ml--107 {
			margin-left: -107px;
		}
		.sm-ml--108 {
			margin-left: -108px;
		}
		.sm-ml--109 {
			margin-left: -109px;
		}
		.sm-ml--110 {
			margin-left: -110px;
		}
		.sm-ml--111 {
			margin-left: -111px;
		}
		.sm-ml--112 {
			margin-left: -112px;
		}
		.sm-ml--113 {
			margin-left: -113px;
		}
		.sm-ml--114 {
			margin-left: -114px;
		}
		.sm-ml--115 {
			margin-left: -115px;
		}
		.sm-ml--116 {
			margin-left: -116px;
		}
		.sm-ml--117 {
			margin-left: -117px;
		}
		.sm-ml--118 {
			margin-left: -118px;
		}
		.sm-ml--119 {
			margin-left: -119px;
		}
		.sm-ml--120 {
			margin-left: -120px;
		}
		.sm-ml--121 {
			margin-left: -121px;
		}
		.sm-ml--122 {
			margin-left: -122px;
		}
		.sm-ml--123 {
			margin-left: -123px;
		}
		.sm-ml--124 {
			margin-left: -124px;
		}
		.sm-ml--125 {
			margin-left: -125px;
		}
		.sm-ml--126 {
			margin-left: -126px;
		}
		.sm-ml--127 {
			margin-left: -127px;
		}
		.sm-ml--128 {
			margin-left: -128px;
		}
		.sm-ml--129 {
			margin-left: -129px;
		}
		.sm-ml--130 {
			margin-left: -130px;
		}
		.sm-ml--131 {
			margin-left: -131px;
		}
		.sm-ml--132 {
			margin-left: -132px;
		}
		.sm-ml--133 {
			margin-left: -133px;
		}
		.sm-ml--134 {
			margin-left: -134px;
		}
		.sm-ml--135 {
			margin-left: -135px;
		}
		.sm-ml--136 {
			margin-left: -136px;
		}
		.sm-ml--137 {
			margin-left: -137px;
		}
		.sm-ml--138 {
			margin-left: -138px;
		}
		.sm-ml--139 {
			margin-left: -139px;
		}
		.sm-ml--140 {
			margin-left: -140px;
		}
		.sm-ml--141 {
			margin-left: -141px;
		}
		.sm-ml--142 {
			margin-left: -142px;
		}
		.sm-ml--143 {
			margin-left: -143px;
		}
		.sm-ml--144 {
			margin-left: -144px;
		}
		.sm-ml--145 {
			margin-left: -145px;
		}
		.sm-ml--146 {
			margin-left: -146px;
		}
		.sm-ml--147 {
			margin-left: -147px;
		}
		.sm-ml--148 {
			margin-left: -148px;
		}
		.sm-ml--149 {
			margin-left: -149px;
		}
		.sm-ml--150 {
			margin-left: -150px;
		}
		.sm-ml--151 {
			margin-left: -151px;
		}
		.sm-ml--152 {
			margin-left: -152px;
		}
		.sm-ml--153 {
			margin-left: -153px;
		}
		.sm-ml--154 {
			margin-left: -154px;
		}
		.sm-ml--155 {
			margin-left: -155px;
		}
		.sm-ml--156 {
			margin-left: -156px;
		}
		.sm-ml--157 {
			margin-left: -157px;
		}
		.sm-ml--158 {
			margin-left: -158px;
		}
		.sm-ml--159 {
			margin-left: -159px;
		}
		.sm-ml--160 {
			margin-left: -160px;
		}
		.sm-ml--161 {
			margin-left: -161px;
		}
		.sm-ml--162 {
			margin-left: -162px;
		}
		.sm-ml--163 {
			margin-left: -163px;
		}
		.sm-ml--164 {
			margin-left: -164px;
		}
		.sm-ml--165 {
			margin-left: -165px;
		}
		.sm-ml--166 {
			margin-left: -166px;
		}
		.sm-ml--167 {
			margin-left: -167px;
		}
		.sm-ml--168 {
			margin-left: -168px;
		}
		.sm-ml--169 {
			margin-left: -169px;
		}
		.sm-ml--170 {
			margin-left: -170px;
		}
		.sm-ml--171 {
			margin-left: -171px;
		}
		.sm-ml--172 {
			margin-left: -172px;
		}
		.sm-ml--173 {
			margin-left: -173px;
		}
		.sm-ml--174 {
			margin-left: -174px;
		}
		.sm-ml--175 {
			margin-left: -175px;
		}
		.sm-ml--176 {
			margin-left: -176px;
		}
		.sm-ml--177 {
			margin-left: -177px;
		}
		.sm-ml--178 {
			margin-left: -178px;
		}
		.sm-ml--179 {
			margin-left: -179px;
		}
		.sm-ml--180 {
			margin-left: -180px;
		}
		.sm-ml--181 {
			margin-left: -181px;
		}
		.sm-ml--182 {
			margin-left: -182px;
		}
		.sm-ml--183 {
			margin-left: -183px;
		}
		.sm-ml--184 {
			margin-left: -184px;
		}
		.sm-ml--185 {
			margin-left: -185px;
		}
		.sm-ml--186 {
			margin-left: -186px;
		}
		.sm-ml--187 {
			margin-left: -187px;
		}
		.sm-ml--188 {
			margin-left: -188px;
		}
		.sm-ml--189 {
			margin-left: -189px;
		}
		.sm-ml--190 {
			margin-left: -190px;
		}
		.sm-ml--191 {
			margin-left: -191px;
		}
		.sm-ml--192 {
			margin-left: -192px;
		}
		.sm-ml--193 {
			margin-left: -193px;
		}
		.sm-ml--194 {
			margin-left: -194px;
		}
		.sm-ml--195 {
			margin-left: -195px;
		}
		.sm-ml--196 {
			margin-left: -196px;
		}
		.sm-ml--197 {
			margin-left: -197px;
		}
		.sm-ml--198 {
			margin-left: -198px;
		}
		.sm-ml--199 {
			margin-left: -199px;
		}
		.sm-ml--200 {
			margin-left: -200px;
		}
	/*}*/

	/*------------------------------------------
		48. SM MARGIN RIGHT REVERSE
	-------------------------------------------*/
	/*.sm-mr- {*/
		.sm-mr--1 {
			margin-right: -1px;
		}
		.sm-mr--2 {
			margin-right: -2px;
		}
		.sm-mr--3 {
			margin-right: -3px;
		}
		.sm-mr--4 {
			margin-right: -4px;
		}
		.sm-mr--5 {
			margin-right: -5px;
		}
		.sm-mr--6 {
			margin-right: -6px;
		}
		.sm-mr--7 {
			margin-right: -7px;
		}
		.sm-mr--8 {
			margin-right: -8px;
		}
		.sm-mr--9 {
			margin-right: -9px;
		}
		.sm-mr--10 {
			margin-right: -10px;
		}
		.sm-mr--11 {
			margin-right: -11px;
		}
		.sm-mr--12 {
			margin-right: -12px;
		}
		.sm-mr--13 {
			margin-right: -13px;
		}
		.sm-mr--14 {
			margin-right: -14px;
		}
		.sm-mr--15 {
			margin-right: -15px;
		}
		.sm-mr--16 {
			margin-right: -16px;
		}
		.sm-mr--17 {
			margin-right: -17px;
		}
		.sm-mr--18 {
			margin-right: -18px;
		}
		.sm-mr--19 {
			margin-right: -19px;
		}
		.sm-mr--20 {
			margin-right: -20px;
		}
		.sm-mr--21 {
			margin-right: -21px;
		}
		.sm-mr--22 {
			margin-right: -22px;
		}
		.sm-mr--23 {
			margin-right: -23px;
		}
		.sm-mr--24 {
			margin-right: -24px;
		}
		.sm-mr--25 {
			margin-right: -25px;
		}
		.sm-mr--26 {
			margin-right: -26px;
		}
		.sm-mr--27 {
			margin-right: -27px;
		}
		.sm-mr--28 {
			margin-right: -28px;
		}
		.sm-mr--29 {
			margin-right: -29px;
		}
		.sm-mr--30 {
			margin-right: -30px;
		}
		.sm-mr--31 {
			margin-right: -31px;
		}
		.sm-mr--32 {
			margin-right: -32px;
		}
		.sm-mr--33 {
			margin-right: -33px;
		}
		.sm-mr--34 {
			margin-right: -34px;
		}
		.sm-mr--35 {
			margin-right: -35px;
		}
		.sm-mr--36 {
			margin-right: -36px;
		}
		.sm-mr--37 {
			margin-right: -37px;
		}
		.sm-mr--38 {
			margin-right: -38px;
		}
		.sm-mr--39 {
			margin-right: -39px;
		}
		.sm-mr--40 {
			margin-right: -40px;
		}
		.sm-mr--41 {
			margin-right: -41px;
		}
		.sm-mr--42 {
			margin-right: -42px;
		}
		.sm-mr--43 {
			margin-right: -43px;
		}
		.sm-mr--44 {
			margin-right: -44px;
		}
		.sm-mr--45 {
			margin-right: -45px;
		}
		.sm-mr--46 {
			margin-right: -46px;
		}
		.sm-mr--47 {
			margin-right: -47px;
		}
		.sm-mr--48 {
			margin-right: -48px;
		}
		.sm-mr--49 {
			margin-right: -49px;
		}
		.sm-mr--50 {
			margin-right: -50px;
		}
		.sm-mr--51 {
			margin-right: -51px;
		}
		.sm-mr--52 {
			margin-right: -52px;
		}
		.sm-mr--53 {
			margin-right: -53px;
		}
		.sm-mr--54 {
			margin-right: -54px;
		}
		.sm-mr--55 {
			margin-right: -55px;
		}
		.sm-mr--56 {
			margin-right: -56px;
		}
		.sm-mr--57 {
			margin-right: -57px;
		}
		.sm-mr--58 {
			margin-right: -58px;
		}
		.sm-mr--59 {
			margin-right: -59px;
		}
		.sm-mr--60 {
			margin-right: -60px;
		}
		.sm-mr--61 {
			margin-right: -61px;
		}
		.sm-mr--62 {
			margin-right: -62px;
		}
		.sm-mr--63 {
			margin-right: -63px;
		}
		.sm-mr--64 {
			margin-right: -64px;
		}
		.sm-mr--65 {
			margin-right: -65px;
		}
		.sm-mr--66 {
			margin-right: -66px;
		}
		.sm-mr--67 {
			margin-right: -67px;
		}
		.sm-mr--68 {
			margin-right: -68px;
		}
		.sm-mr--69 {
			margin-right: -69px;
		}
		.sm-mr--70 {
			margin-right: -70px;
		}
		.sm-mr--71 {
			margin-right: -71px;
		}
		.sm-mr--72 {
			margin-right: -72px;
		}
		.sm-mr--73 {
			margin-right: -73px;
		}
		.sm-mr--74 {
			margin-right: -74px;
		}
		.sm-mr--75 {
			margin-right: -75px;
		}
		.sm-mr--76 {
			margin-right: -76px;
		}
		.sm-mr--77 {
			margin-right: -77px;
		}
		.sm-mr--78 {
			margin-right: -78px;
		}
		.sm-mr--79 {
			margin-right: -79px;
		}
		.sm-mr--80 {
			margin-right: -80px;
		}
		.sm-mr--81 {
			margin-right: -81px;
		}
		.sm-mr--82 {
			margin-right: -82px;
		}
		.sm-mr--83 {
			margin-right: -83px;
		}
		.sm-mr--84 {
			margin-right: -84px;
		}
		.sm-mr--85 {
			margin-right: -85px;
		}
		.sm-mr--86 {
			margin-right: -86px;
		}
		.sm-mr--87 {
			margin-right: -87px;
		}
		.sm-mr--88 {
			margin-right: -88px;
		}
		.sm-mr--89 {
			margin-right: -89px;
		}
		.sm-mr--90 {
			margin-right: -90px;
		}
		.sm-mr--91 {
			margin-right: -91px;
		}
		.sm-mr--92 {
			margin-right: -92px;
		}
		.sm-mr--93 {
			margin-right: -93px;
		}
		.sm-mr--94 {
			margin-right: -94px;
		}
		.sm-mr--95 {
			margin-right: -95px;
		}
		.sm-mr--96 {
			margin-right: -96px;
		}
		.sm-mr--97 {
			margin-right: -97px;
		}
		.sm-mr--98 {
			margin-right: -98px;
		}
		.sm-mr--99 {
			margin-right: -99px;
		}
		.sm-mr--100 {
			margin-right: -100px;
		}
		.sm-mr--101 {
			margin-right: -101px;
		}
		.sm-mr--102 {
			margin-right: -102px;
		}
		.sm-mr--103 {
			margin-right: -103px;
		}
		.sm-mr--104 {
			margin-right: -104px;
		}
		.sm-mr--105 {
			margin-right: -105px;
		}
		.sm-mr--106 {
			margin-right: -106px;
		}
		.sm-mr--107 {
			margin-right: -107px;
		}
		.sm-mr--108 {
			margin-right: -108px;
		}
		.sm-mr--109 {
			margin-right: -109px;
		}
		.sm-mr--110 {
			margin-right: -110px;
		}
		.sm-mr--111 {
			margin-right: -111px;
		}
		.sm-mr--112 {
			margin-right: -112px;
		}
		.sm-mr--113 {
			margin-right: -113px;
		}
		.sm-mr--114 {
			margin-right: -114px;
		}
		.sm-mr--115 {
			margin-right: -115px;
		}
		.sm-mr--116 {
			margin-right: -116px;
		}
		.sm-mr--117 {
			margin-right: -117px;
		}
		.sm-mr--118 {
			margin-right: -118px;
		}
		.sm-mr--119 {
			margin-right: -119px;
		}
		.sm-mr--120 {
			margin-right: -120px;
		}
		.sm-mr--121 {
			margin-right: -121px;
		}
		.sm-mr--122 {
			margin-right: -122px;
		}
		.sm-mr--123 {
			margin-right: -123px;
		}
		.sm-mr--124 {
			margin-right: -124px;
		}
		.sm-mr--125 {
			margin-right: -125px;
		}
		.sm-mr--126 {
			margin-right: -126px;
		}
		.sm-mr--127 {
			margin-right: -127px;
		}
		.sm-mr--128 {
			margin-right: -128px;
		}
		.sm-mr--129 {
			margin-right: -129px;
		}
		.sm-mr--130 {
			margin-right: -130px;
		}
		.sm-mr--131 {
			margin-right: -131px;
		}
		.sm-mr--132 {
			margin-right: -132px;
		}
		.sm-mr--133 {
			margin-right: -133px;
		}
		.sm-mr--134 {
			margin-right: -134px;
		}
		.sm-mr--135 {
			margin-right: -135px;
		}
		.sm-mr--136 {
			margin-right: -136px;
		}
		.sm-mr--137 {
			margin-right: -137px;
		}
		.sm-mr--138 {
			margin-right: -138px;
		}
		.sm-mr--139 {
			margin-right: -139px;
		}
		.sm-mr--140 {
			margin-right: -140px;
		}
		.sm-mr--141 {
			margin-right: -141px;
		}
		.sm-mr--142 {
			margin-right: -142px;
		}
		.sm-mr--143 {
			margin-right: -143px;
		}
		.sm-mr--144 {
			margin-right: -144px;
		}
		.sm-mr--145 {
			margin-right: -145px;
		}
		.sm-mr--146 {
			margin-right: -146px;
		}
		.sm-mr--147 {
			margin-right: -147px;
		}
		.sm-mr--148 {
			margin-right: -148px;
		}
		.sm-mr--149 {
			margin-right: -149px;
		}
		.sm-mr--150 {
			margin-right: -150px;
		}
		.sm-mr--151 {
			margin-right: -151px;
		}
		.sm-mr--152 {
			margin-right: -152px;
		}
		.sm-mr--153 {
			margin-right: -153px;
		}
		.sm-mr--154 {
			margin-right: -154px;
		}
		.sm-mr--155 {
			margin-right: -155px;
		}
		.sm-mr--156 {
			margin-right: -156px;
		}
		.sm-mr--157 {
			margin-right: -157px;
		}
		.sm-mr--158 {
			margin-right: -158px;
		}
		.sm-mr--159 {
			margin-right: -159px;
		}
		.sm-mr--160 {
			margin-right: -160px;
		}
		.sm-mr--161 {
			margin-right: -161px;
		}
		.sm-mr--162 {
			margin-right: -162px;
		}
		.sm-mr--163 {
			margin-right: -163px;
		}
		.sm-mr--164 {
			margin-right: -164px;
		}
		.sm-mr--165 {
			margin-right: -165px;
		}
		.sm-mr--166 {
			margin-right: -166px;
		}
		.sm-mr--167 {
			margin-right: -167px;
		}
		.sm-mr--168 {
			margin-right: -168px;
		}
		.sm-mr--169 {
			margin-right: -169px;
		}
		.sm-mr--170 {
			margin-right: -170px;
		}
		.sm-mr--171 {
			margin-right: -171px;
		}
		.sm-mr--172 {
			margin-right: -172px;
		}
		.sm-mr--173 {
			margin-right: -173px;
		}
		.sm-mr--174 {
			margin-right: -174px;
		}
		.sm-mr--175 {
			margin-right: -175px;
		}
		.sm-mr--176 {
			margin-right: -176px;
		}
		.sm-mr--177 {
			margin-right: -177px;
		}
		.sm-mr--178 {
			margin-right: -178px;
		}
		.sm-mr--179 {
			margin-right: -179px;
		}
		.sm-mr--180 {
			margin-right: -180px;
		}
		.sm-mr--181 {
			margin-right: -181px;
		}
		.sm-mr--182 {
			margin-right: -182px;
		}
		.sm-mr--183 {
			margin-right: -183px;
		}
		.sm-mr--184 {
			margin-right: -184px;
		}
		.sm-mr--185 {
			margin-right: -185px;
		}
		.sm-mr--186 {
			margin-right: -186px;
		}
		.sm-mr--187 {
			margin-right: -187px;
		}
		.sm-mr--188 {
			margin-right: -188px;
		}
		.sm-mr--189 {
			margin-right: -189px;
		}
		.sm-mr--190 {
			margin-right: -190px;
		}
		.sm-mr--191 {
			margin-right: -191px;
		}
		.sm-mr--192 {
			margin-right: -192px;
		}
		.sm-mr--193 {
			margin-right: -193px;
		}
		.sm-mr--194 {
			margin-right: -194px;
		}
		.sm-mr--195 {
			margin-right: -195px;
		}
		.sm-mr--196 {
			margin-right: -196px;
		}
		.sm-mr--197 {
			margin-right: -197px;
		}
		.sm-mr--198 {
			margin-right: -198px;
		}
		.sm-mr--199 {
			margin-right: -199px;
		}
		.sm-mr--200 {
			margin-right: -200px;
		}
	/*}*/
}

@media only screen and (max-width: 575px){

	.xs-padding-0 {
		padding: 0;
	}
	.xs-margin-0 {
		margin: 0;
	}

	/*------------------------------------------
		49. XS PADDING TOP
	-------------------------------------------*/
	/*.xs-pt {*/
		.xs-pt-0 {
			padding-top: 0px;
		}
		.xs-pt-1 {
			padding-top: 1px;
		}
		.xs-pt-2 {
			padding-top: 2px;
		}
		.xs-pt-3 {
			padding-top: 3px;
		}
		.xs-pt-4 {
			padding-top: 4px;
		}
		.xs-pt-5 {
			padding-top: 5px;
		}
		.xs-pt-6 {
			padding-top: 6px;
		}
		.xs-pt-7 {
			padding-top: 7px;
		}
		.xs-pt-8 {
			padding-top: 8px;
		}
		.xs-pt-9 {
			padding-top: 9px;
		}
		.xs-pt-10 {
			padding-top: 10px;
		}
		.xs-pt-11 {
			padding-top: 11px;
		}
		.xs-pt-12 {
			padding-top: 12px;
		}
		.xs-pt-13 {
			padding-top: 13px;
		}
		.xs-pt-14 {
			padding-top: 14px;
		}
		.xs-pt-15 {
			padding-top: 15px;
		}
		.xs-pt-16 {
			padding-top: 16px;
		}
		.xs-pt-17 {
			padding-top: 17px;
		}
		.xs-pt-18 {
			padding-top: 18px;
		}
		.xs-pt-19 {
			padding-top: 19px;
		}
		.xs-pt-20 {
			padding-top: 20px;
		}
		.xs-pt-21 {
			padding-top: 21px;
		}
		.xs-pt-22 {
			padding-top: 22px;
		}
		.xs-pt-23 {
			padding-top: 23px;
		}
		.xs-pt-24 {
			padding-top: 24px;
		}
		.xs-pt-25 {
			padding-top: 25px;
		}
		.xs-pt-26 {
			padding-top: 26px;
		}
		.xs-pt-27 {
			padding-top: 27px;
		}
		.xs-pt-28 {
			padding-top: 28px;
		}
		.xs-pt-29 {
			padding-top: 29px;
		}
		.xs-pt-30 {
			padding-top: 30px;
		}
		.xs-pt-31 {
			padding-top: 31px;
		}
		.xs-pt-32 {
			padding-top: 32px;
		}
		.xs-pt-33 {
			padding-top: 33px;
		}
		.xs-pt-34 {
			padding-top: 34px;
		}
		.xs-pt-35 {
			padding-top: 35px;
		}
		.xs-pt-36 {
			padding-top: 36px;
		}
		.xs-pt-37 {
			padding-top: 37px;
		}
		.xs-pt-38 {
			padding-top: 38px;
		}
		.xs-pt-39 {
			padding-top: 39px;
		}
		.xs-pt-40 {
			padding-top: 40px;
		}
		.xs-pt-41 {
			padding-top: 41px;
		}
		.xs-pt-42 {
			padding-top: 42px;
		}
		.xs-pt-43 {
			padding-top: 43px;
		}
		.xs-pt-44 {
			padding-top: 44px;
		}
		.xs-pt-45 {
			padding-top: 45px;
		}
		.xs-pt-46 {
			padding-top: 46px;
		}
		.xs-pt-47 {
			padding-top: 47px;
		}
		.xs-pt-48 {
			padding-top: 48px;
		}
		.xs-pt-49 {
			padding-top: 49px;
		}
		.xs-pt-50 {
			padding-top: 50px;
		}
		.xs-pt-51 {
			padding-top: 51px;
		}
		.xs-pt-52 {
			padding-top: 52px;
		}
		.xs-pt-53 {
			padding-top: 53px;
		}
		.xs-pt-54 {
			padding-top: 54px;
		}
		.xs-pt-55 {
			padding-top: 55px;
		}
		.xs-pt-56 {
			padding-top: 56px;
		}
		.xs-pt-57 {
			padding-top: 57px;
		}
		.xs-pt-58 {
			padding-top: 58px;
		}
		.xs-pt-59 {
			padding-top: 59px;
		}
		.xs-pt-60 {
			padding-top: 60px;
		}
		.xs-pt-61 {
			padding-top: 61px;
		}
		.xs-pt-62 {
			padding-top: 62px;
		}
		.xs-pt-63 {
			padding-top: 63px;
		}
		.xs-pt-64 {
			padding-top: 64px;
		}
		.xs-pt-65 {
			padding-top: 65px;
		}
		.xs-pt-66 {
			padding-top: 66px;
		}
		.xs-pt-67 {
			padding-top: 67px;
		}
		.xs-pt-68 {
			padding-top: 68px;
		}
		.xs-pt-69 {
			padding-top: 69px;
		}
		.xs-pt-70 {
			padding-top: 70px;
		}
		.xs-pt-71 {
			padding-top: 71px;
		}
		.xs-pt-72 {
			padding-top: 72px;
		}
		.xs-pt-73 {
			padding-top: 73px;
		}
		.xs-pt-74 {
			padding-top: 74px;
		}
		.xs-pt-75 {
			padding-top: 75px;
		}
		.xs-pt-76 {
			padding-top: 76px;
		}
		.xs-pt-77 {
			padding-top: 77px;
		}
		.xs-pt-78 {
			padding-top: 78px;
		}
		.xs-pt-79 {
			padding-top: 79px;
		}
		.xs-pt-80 {
			padding-top: 80px;
		}
		.xs-pt-81 {
			padding-top: 81px;
		}
		.xs-pt-82 {
			padding-top: 82px;
		}
		.xs-pt-83 {
			padding-top: 83px;
		}
		.xs-pt-84 {
			padding-top: 84px;
		}
		.xs-pt-85 {
			padding-top: 85px;
		}
		.xs-pt-86 {
			padding-top: 86px;
		}
		.xs-pt-87 {
			padding-top: 87px;
		}
		.xs-pt-88 {
			padding-top: 88px;
		}
		.xs-pt-89 {
			padding-top: 89px;
		}
		.xs-pt-90 {
			padding-top: 90px;
		}
		.xs-pt-91 {
			padding-top: 91px;
		}
		.xs-pt-92 {
			padding-top: 92px;
		}
		.xs-pt-93 {
			padding-top: 93px;
		}
		.xs-pt-94 {
			padding-top: 94px;
		}
		.xs-pt-95 {
			padding-top: 95px;
		}
		.xs-pt-96 {
			padding-top: 96px;
		}
		.xs-pt-97 {
			padding-top: 97px;
		}
		.xs-pt-98 {
			padding-top: 98px;
		}
		.xs-pt-99 {
			padding-top: 99px;
		}
		.xs-pt-100 {
			padding-top: 100px;
		}

		.xs-pt-101 {
			padding-top: 101px;
		}
		.xs-pt-102 {
			padding-top: 102px;
		}
		.xs-pt-103 {
			padding-top: 103px;
		}
		.xs-pt-104 {
			padding-top: 104px;
		}
		.xs-pt-105 {
			padding-top: 105px;
		}
		.xs-pt-106 {
			padding-top: 106px;
		}
		.xs-pt-107 {
			padding-top: 107px;
		}
		.xs-pt-108 {
			padding-top: 108px;
		}
		.xs-pt-109 {
			padding-top: 109px;
		}
		.xs-pt-110 {
			padding-top: 110px;
		}
		.xs-pt-111 {
			padding-top: 111px;
		}
		.xs-pt-112 {
			padding-top: 112px;
		}
		.xs-pt-113 {
			padding-top: 113px;
		}
		.xs-pt-114 {
			padding-top: 114px;
		}
		.xs-pt-115 {
			padding-top: 115px;
		}
		.xs-pt-116 {
			padding-top: 116px;
		}
		.xs-pt-117 {
			padding-top: 117px;
		}
		.xs-pt-118 {
			padding-top: 118px;
		}
		.xs-pt-119 {
			padding-top: 119px;
		}
		.xs-pt-120 {
			padding-top: 120px;
		}
		.xs-pt-121 {
			padding-top: 121px;
		}
		.xs-pt-122 {
			padding-top: 122px;
		}
		.xs-pt-123 {
			padding-top: 123px;
		}
		.xs-pt-124 {
			padding-top: 124px;
		}
		.xs-pt-125 {
			padding-top: 125px;
		}
		.xs-pt-126 {
			padding-top: 126px;
		}
		.xs-pt-127 {
			padding-top: 127px;
		}
		.xs-pt-128 {
			padding-top: 128px;
		}
		.xs-pt-129 {
			padding-top: 129px;
		}
		.xs-pt-130 {
			padding-top: 130px;
		}
		.xs-pt-131 {
			padding-top: 131px;
		}
		.xs-pt-132 {
			padding-top: 132px;
		}
		.xs-pt-133 {
			padding-top: 133px;
		}
		.xs-pt-134 {
			padding-top: 134px;
		}
		.xs-pt-135 {
			padding-top: 135px;
		}
		.xs-pt-136 {
			padding-top: 136px;
		}
		.xs-pt-137 {
			padding-top: 137px;
		}
		.xs-pt-138 {
			padding-top: 138px;
		}
		.xs-pt-139 {
			padding-top: 139px;
		}
		.xs-pt-140 {
			padding-top: 140px;
		}
		.xs-pt-141 {
			padding-top: 141px;
		}
		.xs-pt-142 {
			padding-top: 142px;
		}
		.xs-pt-143 {
			padding-top: 143px;
		}
		.xs-pt-144 {
			padding-top: 144px;
		}
		.xs-pt-145 {
			padding-top: 145px;
		}
		.xs-pt-146 {
			padding-top: 146px;
		}
		.xs-pt-147 {
			padding-top: 147px;
		}
		.xs-pt-148 {
			padding-top: 148px;
		}
		.xs-pt-149 {
			padding-top: 149px;
		}
		.xs-pt-150 {
			padding-top: 150px;
		}
		.xs-pt-151 {
			padding-top: 151px;
		}
		.xs-pt-152 {
			padding-top: 152px;
		}
		.xs-pt-153 {
			padding-top: 153px;
		}
		.xs-pt-154 {
			padding-top: 154px;
		}
		.xs-pt-155 {
			padding-top: 155px;
		}
		.xs-pt-156 {
			padding-top: 156px;
		}
		.xs-pt-157 {
			padding-top: 157px;
		}
		.xs-pt-158 {
			padding-top: 158px;
		}
		.xs-pt-159 {
			padding-top: 159px;
		}
		.xs-pt-160 {
			padding-top: 160px;
		}
		.xs-pt-161 {
			padding-top: 161px;
		}
		.xs-pt-162 {
			padding-top: 162px;
		}
		.xs-pt-163 {
			padding-top: 163px;
		}
		.xs-pt-164 {
			padding-top: 164px;
		}
		.xs-pt-165 {
			padding-top: 165px;
		}
		.xs-pt-166 {
			padding-top: 166px;
		}
		.xs-pt-167 {
			padding-top: 167px;
		}
		.xs-pt-168 {
			padding-top: 168px;
		}
		.xs-pt-169 {
			padding-top: 169px;
		}
		.xs-pt-170 {
			padding-top: 170px;
		}
		.xs-pt-171 {
			padding-top: 171px;
		}
		.xs-pt-172 {
			padding-top: 172px;
		}
		.xs-pt-173 {
			padding-top: 173px;
		}
		.xs-pt-174 {
			padding-top: 174px;
		}
		.xs-pt-175 {
			padding-top: 175px;
		}
		.xs-pt-176 {
			padding-top: 176px;
		}
		.xs-pt-177 {
			padding-top: 177px;
		}
		.xs-pt-178 {
			padding-top: 178px;
		}
		.xs-pt-179 {
			padding-top: 179px;
		}
		.xs-pt-180 {
			padding-top: 180px;
		}
		.xs-pt-181 {
			padding-top: 181px;
		}
		.xs-pt-182 {
			padding-top: 182px;
		}
		.xs-pt-183 {
			padding-top: 183px;
		}
		.xs-pt-184 {
			padding-top: 184px;
		}
		.xs-pt-185 {
			padding-top: 185px;
		}
		.xs-pt-186 {
			padding-top: 186px;
		}
		.xs-pt-187 {
			padding-top: 187px;
		}
		.xs-pt-188 {
			padding-top: 188px;
		}
		.xs-pt-189 {
			padding-top: 189px;
		}
		.xs-pt-190 {
			padding-top: 190px;
		}
		.xs-pt-191 {
			padding-top: 191px;
		}
		.xs-pt-192 {
			padding-top: 192px;
		}
		.xs-pt-193 {
			padding-top: 193px;
		}
		.xs-pt-194 {
			padding-top: 194px;
		}
		.xs-pt-195 {
			padding-top: 195px;
		}
		.xs-pt-196 {
			padding-top: 196px;
		}
		.xs-pt-197 {
			padding-top: 197px;
		}
		.xs-pt-198 {
			padding-top: 198px;
		}
		.xs-pt-199 {
			padding-top: 199px;
		}
		.xs-pt-200 {
			padding-top: 200px;
		}
	/*}*/

	/*------------------------------------------
		50. XS PADDING BOTTOM
	-------------------------------------------*/
	/*.xs-pb {*/
		.xs-pb-0 {
			padding-bottom: 0px;
		}
		.xs-pb-1 {
			padding-bottom: 1px;
		}
		.xs-pb-2 {
			padding-bottom: 2px;
		}
		.xs-pb-3 {
			padding-bottom: 3px;
		}
		.xs-pb-4 {
			padding-bottom: 4px;
		}
		.xs-pb-5 {
			padding-bottom: 5px;
		}
		.xs-pb-6 {
			padding-bottom: 6px;
		}
		.xs-pb-7 {
			padding-bottom: 7px;
		}
		.xs-pb-8 {
			padding-bottom: 8px;
		}
		.xs-pb-9 {
			padding-bottom: 9px;
		}
		.xs-pb-10 {
			padding-bottom: 10px;
		}
		.xs-pb-11 {
			padding-bottom: 11px;
		}
		.xs-pb-12 {
			padding-bottom: 12px;
		}
		.xs-pb-13 {
			padding-bottom: 13px;
		}
		.xs-pb-14 {
			padding-bottom: 14px;
		}
		.xs-pb-15 {
			padding-bottom: 15px;
		}
		.xs-pb-16 {
			padding-bottom: 16px;
		}
		.xs-pb-17 {
			padding-bottom: 17px;
		}
		.xs-pb-18 {
			padding-bottom: 18px;
		}
		.xs-pb-19 {
			padding-bottom: 19px;
		}
		.xs-pb-20 {
			padding-bottom: 20px;
		}
		.xs-pb-21 {
			padding-bottom: 21px;
		}
		.xs-pb-22 {
			padding-bottom: 22px;
		}
		.xs-pb-23 {
			padding-bottom: 23px;
		}
		.xs-pb-24 {
			padding-bottom: 24px;
		}
		.xs-pb-25 {
			padding-bottom: 25px;
		}
		.xs-pb-26 {
			padding-bottom: 26px;
		}
		.xs-pb-27 {
			padding-bottom: 27px;
		}
		.xs-pb-28 {
			padding-bottom: 28px;
		}
		.xs-pb-29 {
			padding-bottom: 29px;
		}
		.xs-pb-30 {
			padding-bottom: 30px;
		}
		.xs-pb-31 {
			padding-bottom: 31px;
		}
		.xs-pb-32 {
			padding-bottom: 32px;
		}
		.xs-pb-33 {
			padding-bottom: 33px;
		}
		.xs-pb-34 {
			padding-bottom: 34px;
		}
		.xs-pb-35 {
			padding-bottom: 35px;
		}
		.xs-pb-36 {
			padding-bottom: 36px;
		}
		.xs-pb-37 {
			padding-bottom: 37px;
		}
		.xs-pb-38 {
			padding-bottom: 38px;
		}
		.xs-pb-39 {
			padding-bottom: 39px;
		}
		.xs-pb-40 {
			padding-bottom: 40px;
		}
		.xs-pb-41 {
			padding-bottom: 41px;
		}
		.xs-pb-42 {
			padding-bottom: 42px;
		}
		.xs-pb-43 {
			padding-bottom: 43px;
		}
		.xs-pb-44 {
			padding-bottom: 44px;
		}
		.xs-pb-45 {
			padding-bottom: 45px;
		}
		.xs-pb-46 {
			padding-bottom: 46px;
		}
		.xs-pb-47 {
			padding-bottom: 47px;
		}
		.xs-pb-48 {
			padding-bottom: 48px;
		}
		.xs-pb-49 {
			padding-bottom: 49px;
		}
		.xs-pb-50 {
			padding-bottom: 50px;
		}
		.xs-pb-51 {
			padding-bottom: 51px;
		}
		.xs-pb-52 {
			padding-bottom: 52px;
		}
		.xs-pb-53 {
			padding-bottom: 53px;
		}
		.xs-pb-54 {
			padding-bottom: 54px;
		}
		.xs-pb-55 {
			padding-bottom: 55px;
		}
		.xs-pb-56 {
			padding-bottom: 56px;
		}
		.xs-pb-57 {
			padding-bottom: 57px;
		}
		.xs-pb-58 {
			padding-bottom: 58px;
		}
		.xs-pb-59 {
			padding-bottom: 59px;
		}
		.xs-pb-60 {
			padding-bottom: 60px;
		}
		.xs-pb-61 {
			padding-bottom: 61px;
		}
		.xs-pb-62 {
			padding-bottom: 62px;
		}
		.xs-pb-63 {
			padding-bottom: 63px;
		}
		.xs-pb-64 {
			padding-bottom: 64px;
		}
		.xs-pb-65 {
			padding-bottom: 65px;
		}
		.xs-pb-66 {
			padding-bottom: 66px;
		}
		.xs-pb-67 {
			padding-bottom: 67px;
		}
		.xs-pb-68 {
			padding-bottom: 68px;
		}
		.xs-pb-69 {
			padding-bottom: 69px;
		}
		.xs-pb-70 {
			padding-bottom: 70px;
		}
		.xs-pb-71 {
			padding-bottom: 71px;
		}
		.xs-pb-72 {
			padding-bottom: 72px;
		}
		.xs-pb-73 {
			padding-bottom: 73px;
		}
		.xs-pb-74 {
			padding-bottom: 74px;
		}
		.xs-pb-75 {
			padding-bottom: 75px;
		}
		.xs-pb-76 {
			padding-bottom: 76px;
		}
		.xs-pb-77 {
			padding-bottom: 77px;
		}
		.xs-pb-78 {
			padding-bottom: 78px;
		}
		.xs-pb-79 {
			padding-bottom: 79px;
		}
		.xs-pb-80 {
			padding-bottom: 80px;
		}
		.xs-pb-81 {
			padding-bottom: 81px;
		}
		.xs-pb-82 {
			padding-bottom: 82px;
		}
		.xs-pb-83 {
			padding-bottom: 83px;
		}
		.xs-pb-84 {
			padding-bottom: 84px;
		}
		.xs-pb-85 {
			padding-bottom: 85px;
		}
		.xs-pb-86 {
			padding-bottom: 86px;
		}
		.xs-pb-87 {
			padding-bottom: 87px;
		}
		.xs-pb-88 {
			padding-bottom: 88px;
		}
		.xs-pb-89 {
			padding-bottom: 89px;
		}
		.xs-pb-90 {
			padding-bottom: 90px;
		}
		.xs-pb-91 {
			padding-bottom: 91px;
		}
		.xs-pb-92 {
			padding-bottom: 92px;
		}
		.xs-pb-93 {
			padding-bottom: 93px;
		}
		.xs-pb-94 {
			padding-bottom: 94px;
		}
		.xs-pb-95 {
			padding-bottom: 95px;
		}
		.xs-pb-96 {
			padding-bottom: 96px;
		}
		.xs-pb-97 {
			padding-bottom: 97px;
		}
		.xs-pb-98 {
			padding-bottom: 98px;
		}
		.xs-pb-99 {
			padding-bottom: 99px;
		}
		.xs-pb-100 {
			padding-bottom: 100px;
		}

		.xs-pb-101 {
			padding-bottom: 101px;
		}
		.xs-pb-102 {
			padding-bottom: 102px;
		}
		.xs-pb-103 {
			padding-bottom: 103px;
		}
		.xs-pb-104 {
			padding-bottom: 104px;
		}
		.xs-pb-105 {
			padding-bottom: 105px;
		}
		.xs-pb-106 {
			padding-bottom: 106px;
		}
		.xs-pb-107 {
			padding-bottom: 107px;
		}
		.xs-pb-108 {
			padding-bottom: 108px;
		}
		.xs-pb-109 {
			padding-bottom: 109px;
		}
		.xs-pb-110 {
			padding-bottom: 110px;
		}
		.xs-pb-111 {
			padding-bottom: 111px;
		}
		.xs-pb-112 {
			padding-bottom: 112px;
		}
		.xs-pb-113 {
			padding-bottom: 113px;
		}
		.xs-pb-114 {
			padding-bottom: 114px;
		}
		.xs-pb-115 {
			padding-bottom: 115px;
		}
		.xs-pb-116 {
			padding-bottom: 116px;
		}
		.xs-pb-117 {
			padding-bottom: 117px;
		}
		.xs-pb-118 {
			padding-bottom: 118px;
		}
		.xs-pb-119 {
			padding-bottom: 119px;
		}
		.xs-pb-120 {
			padding-bottom: 120px;
		}
		.xs-pb-121 {
			padding-bottom: 121px;
		}
		.xs-pb-122 {
			padding-bottom: 122px;
		}
		.xs-pb-123 {
			padding-bottom: 123px;
		}
		.xs-pb-124 {
			padding-bottom: 124px;
		}
		.xs-pb-125 {
			padding-bottom: 125px;
		}
		.xs-pb-126 {
			padding-bottom: 126px;
		}
		.xs-pb-127 {
			padding-bottom: 127px;
		}
		.xs-pb-128 {
			padding-bottom: 128px;
		}
		.xs-pb-129 {
			padding-bottom: 129px;
		}
		.xs-pb-130 {
			padding-bottom: 130px;
		}
		.xs-pb-131 {
			padding-bottom: 131px;
		}
		.xs-pb-132 {
			padding-bottom: 132px;
		}
		.xs-pb-133 {
			padding-bottom: 133px;
		}
		.xs-pb-134 {
			padding-bottom: 134px;
		}
		.xs-pb-135 {
			padding-bottom: 135px;
		}
		.xs-pb-136 {
			padding-bottom: 136px;
		}
		.xs-pb-137 {
			padding-bottom: 137px;
		}
		.xs-pb-138 {
			padding-bottom: 138px;
		}
		.xs-pb-139 {
			padding-bottom: 139px;
		}
		.xs-pb-140 {
			padding-bottom: 140px;
		}
		.xs-pb-141 {
			padding-bottom: 141px;
		}
		.xs-pb-142 {
			padding-bottom: 142px;
		}
		.xs-pb-143 {
			padding-bottom: 143px;
		}
		.xs-pb-144 {
			padding-bottom: 144px;
		}
		.xs-pb-145 {
			padding-bottom: 145px;
		}
		.xs-pb-146 {
			padding-bottom: 146px;
		}
		.xs-pb-147 {
			padding-bottom: 147px;
		}
		.xs-pb-148 {
			padding-bottom: 148px;
		}
		.xs-pb-149 {
			padding-bottom: 149px;
		}
		.xs-pb-150 {
			padding-bottom: 150px;
		}
		.xs-pb-151 {
			padding-bottom: 151px;
		}
		.xs-pb-152 {
			padding-bottom: 152px;
		}
		.xs-pb-153 {
			padding-bottom: 153px;
		}
		.xs-pb-154 {
			padding-bottom: 154px;
		}
		.xs-pb-155 {
			padding-bottom: 155px;
		}
		.xs-pb-156 {
			padding-bottom: 156px;
		}
		.xs-pb-157 {
			padding-bottom: 157px;
		}
		.xs-pb-158 {
			padding-bottom: 158px;
		}
		.xs-pb-159 {
			padding-bottom: 159px;
		}
		.xs-pb-160 {
			padding-bottom: 160px;
		}
		.xs-pb-161 {
			padding-bottom: 161px;
		}
		.xs-pb-162 {
			padding-bottom: 162px;
		}
		.xs-pb-163 {
			padding-bottom: 163px;
		}
		.xs-pb-164 {
			padding-bottom: 164px;
		}
		.xs-pb-165 {
			padding-bottom: 165px;
		}
		.xs-pb-166 {
			padding-bottom: 166px;
		}
		.xs-pb-167 {
			padding-bottom: 167px;
		}
		.xs-pb-168 {
			padding-bottom: 168px;
		}
		.xs-pb-169 {
			padding-bottom: 169px;
		}
		.xs-pb-170 {
			padding-bottom: 170px;
		}
		.xs-pb-171 {
			padding-bottom: 171px;
		}
		.xs-pb-172 {
			padding-bottom: 172px;
		}
		.xs-pb-173 {
			padding-bottom: 173px;
		}
		.xs-pb-174 {
			padding-bottom: 174px;
		}
		.xs-pb-175 {
			padding-bottom: 175px;
		}
		.xs-pb-176 {
			padding-bottom: 176px;
		}
		.xs-pb-177 {
			padding-bottom: 177px;
		}
		.xs-pb-178 {
			padding-bottom: 178px;
		}
		.xs-pb-179 {
			padding-bottom: 179px;
		}
		.xs-pb-180 {
			padding-bottom: 180px;
		}
		.xs-pb-181 {
			padding-bottom: 181px;
		}
		.xs-pb-182 {
			padding-bottom: 182px;
		}
		.xs-pb-183 {
			padding-bottom: 183px;
		}
		.xs-pb-184 {
			padding-bottom: 184px;
		}
		.xs-pb-185 {
			padding-bottom: 185px;
		}
		.xs-pb-186 {
			padding-bottom: 186px;
		}
		.xs-pb-187 {
			padding-bottom: 187px;
		}
		.xs-pb-188 {
			padding-bottom: 188px;
		}
		.xs-pb-189 {
			padding-bottom: 189px;
		}
		.xs-pb-190 {
			padding-bottom: 190px;
		}
		.xs-pb-191 {
			padding-bottom: 191px;
		}
		.xs-pb-192 {
			padding-bottom: 192px;
		}
		.xs-pb-193 {
			padding-bottom: 193px;
		}
		.xs-pb-194 {
			padding-bottom: 194px;
		}
		.xs-pb-195 {
			padding-bottom: 195px;
		}
		.xs-pb-196 {
			padding-bottom: 196px;
		}
		.xs-pb-197 {
			padding-bottom: 197px;
		}
		.xs-pb-198 {
			padding-bottom: 198px;
		}
		.xs-pb-199 {
			padding-bottom: 199px;
		}
		.xs-pb-200 {
			padding-bottom: 200px;
		}
	/*}*/

	/*------------------------------------------
		51. XS PADDING RIGHT
	-------------------------------------------*/
	/*.xs-pr {*/
		.xs-pr-0 {
			padding-right: 0px;
		}
		.xs-pr-1 {
			padding-right: 1px;
		}
		.xs-pr-2 {
			padding-right: 2px;
		}
		.pb-3 {
			padding-bottom: 3px;
		}
		.xs-pr-4 {
			padding-right: 4px;
		}
		.xs-pr-5 {
			padding-right: 5px;
		}
		.xs-pr-6 {
			padding-right: 6px;
		}
		.xs-pr-7 {
			padding-right: 7px;
		}
		.xs-pr-8 {
			padding-right: 8px;
		}
		.xs-pr-9 {
			padding-right: 9px;
		}
		.xs-pr-10 {
			padding-right: 10px;
		}
		.xs-pr-11 {
			padding-right: 11px;
		}
		.xs-pr-12 {
			padding-right: 12px;
		}
		.xs-pr-13 {
			padding-right: 13px;
		}
		.xs-pr-14 {
			padding-right: 14px;
		}
		.xs-pr-15 {
			padding-right: 15px;
		}
		.xs-pr-16 {
			padding-right: 16px;
		}
		.xs-pr-17 {
			padding-right: 17px;
		}
		.xs-pr-18 {
			padding-right: 18px;
		}
		.xs-pr-19 {
			padding-right: 19px;
		}
		.xs-pr-20 {
			padding-right: 20px;
		}
		.xs-pr-21 {
			padding-right: 21px;
		}
		.xs-pr-22 {
			padding-right: 22px;
		}
		.xs-pr-23 {
			padding-right: 23px;
		}
		.xs-pr-24 {
			padding-right: 24px;
		}
		.xs-pr-25 {
			padding-right: 25px;
		}
		.xs-pr-26 {
			padding-right: 26px;
		}
		.xs-pr-27 {
			padding-right: 27px;
		}
		.xs-pr-28 {
			padding-right: 28px;
		}
		.xs-pr-29 {
			padding-right: 29px;
		}
		.xs-pr-30 {
			padding-right: 30px;
		}
		.xs-pr-31 {
			padding-right: 31px;
		}
		.xs-pr-32 {
			padding-right: 32px;
		}
		.xs-pr-33 {
			padding-right: 33px;
		}
		.xs-pr-34 {
			padding-right: 34px;
		}
		.xs-pr-35 {
			padding-right: 35px;
		}
		.xs-pr-36 {
			padding-right: 36px;
		}
		.xs-pr-37 {
			padding-right: 37px;
		}
		.xs-pr-38 {
			padding-right: 38px;
		}
		.xs-pr-39 {
			padding-right: 39px;
		}
		.xs-pr-40 {
			padding-right: 40px;
		}
		.xs-pr-41 {
			padding-right: 41px;
		}
		.xs-pr-42 {
			padding-right: 42px;
		}
		.xs-pr-43 {
			padding-right: 43px;
		}
		.xs-pr-44 {
			padding-right: 44px;
		}
		.xs-pr-45 {
			padding-right: 45px;
		}
		.xs-pr-46 {
			padding-right: 46px;
		}
		.xs-pr-47 {
			padding-right: 47px;
		}
		.xs-pr-48 {
			padding-right: 48px;
		}
		.xs-pr-49 {
			padding-right: 49px;
		}
		.xs-pr-50 {
			padding-right: 50px;
		}
		.xs-pr-51 {
			padding-right: 51px;
		}
		.xs-pr-52 {
			padding-right: 52px;
		}
		.xs-pr-53 {
			padding-right: 53px;
		}
		.xs-pr-54 {
			padding-right: 54px;
		}
		.xs-pr-55 {
			padding-right: 55px;
		}
		.xs-pr-56 {
			padding-right: 56px;
		}
		.xs-pr-57 {
			padding-right: 57px;
		}
		.xs-pr-58 {
			padding-right: 58px;
		}
		.xs-pr-59 {
			padding-right: 59px;
		}
		.xs-pr-60 {
			padding-right: 60px;
		}
		.xs-pr-61 {
			padding-right: 61px;
		}
		.xs-pr-62 {
			padding-right: 62px;
		}
		.xs-pr-63 {
			padding-right: 63px;
		}
		.xs-pr-64 {
			padding-right: 64px;
		}
		.xs-pr-65 {
			padding-right: 65px;
		}
		.xs-pr-66 {
			padding-right: 66px;
		}
		.xs-pr-67 {
			padding-right: 67px;
		}
		.xs-pr-68 {
			padding-right: 68px;
		}
		.xs-pr-69 {
			padding-right: 69px;
		}
		.xs-pr-70 {
			padding-right: 70px;
		}
		.xs-pr-71 {
			padding-right: 71px;
		}
		.xs-pr-72 {
			padding-right: 72px;
		}
		.xs-pr-73 {
			padding-right: 73px;
		}
		.xs-pr-74 {
			padding-right: 74px;
		}
		.xs-pr-75 {
			padding-right: 75px;
		}
		.xs-pr-76 {
			padding-right: 76px;
		}
		.xs-pr-77 {
			padding-right: 77px;
		}
		.xs-pr-78 {
			padding-right: 78px;
		}
		.xs-pr-79 {
			padding-right: 79px;
		}
		.xs-pr-80 {
			padding-right: 80px;
		}
		.xs-pr-81 {
			padding-right: 81px;
		}
		.xs-pr-82 {
			padding-right: 82px;
		}
		.xs-pr-83 {
			padding-right: 83px;
		}
		.xs-pr-84 {
			padding-right: 84px;
		}
		.xs-pr-85 {
			padding-right: 85px;
		}
		.xs-pr-86 {
			padding-right: 86px;
		}
		.xs-pr-87 {
			padding-right: 87px;
		}
		.xs-pr-88 {
			padding-right: 88px;
		}
		.xs-pr-89 {
			padding-right: 89px;
		}
		.xs-pr-90 {
			padding-right: 90px;
		}
		.xs-pr-91 {
			padding-right: 91px;
		}
		.xs-pr-92 {
			padding-right: 92px;
		}
		.xs-pr-93 {
			padding-right: 93px;
		}
		.xs-pr-94 {
			padding-right: 94px;
		}
		.xs-pr-95 {
			padding-right: 95px;
		}
		.xs-pr-96 {
			padding-right: 96px;
		}
		.xs-pr-97 {
			padding-right: 97px;
		}
		.xs-pr-98 {
			padding-right: 98px;
		}
		.xs-pr-99 {
			padding-right: 99px;
		}

		.xs-pr-100 {
			padding-right: 100px;
		}
		.xs-pr-101 {
			padding-right: 101px;
		}
		.xs-pr-102 {
			padding-right: 102px;
		}
		.xs-pb-103 {
			padding-bottom: 103px;
		}
		.xs-pr-104 {
			padding-right: 104px;
		}
		.xs-pr-105 {
			padding-right: 105px;
		}
		.xs-pr-106 {
			padding-right: 106px;
		}
		.xs-pr-107 {
			padding-right: 107px;
		}
		.xs-pr-108 {
			padding-right: 108px;
		}
		.xs-pr-109 {
			padding-right: 109px;
		}
		.xs-pr-110 {
			padding-right: 110px;
		}
		.xs-pr-111 {
			padding-right: 111px;
		}
		.xs-pr-112 {
			padding-right: 112px;
		}
		.xs-pr-113 {
			padding-right: 113px;
		}
		.xs-pr-114 {
			padding-right: 114px;
		}
		.xs-pr-115 {
			padding-right: 115px;
		}
		.xs-pr-116 {
			padding-right: 116px;
		}
		.xs-pr-117 {
			padding-right: 117px;
		}
		.xs-pr-118 {
			padding-right: 118px;
		}
		.xs-pr-119 {
			padding-right: 119px;
		}
		.xs-pr-120 {
			padding-right: 120px;
		}
		.xs-pr-121 {
			padding-right: 121px;
		}
		.xs-pr-122 {
			padding-right: 122px;
		}
		.xs-pr-123 {
			padding-right: 123px;
		}
		.xs-pr-124 {
			padding-right: 124px;
		}
		.xs-pr-125 {
			padding-right: 125px;
		}
		.xs-pr-126 {
			padding-right: 126px;
		}
		.xs-pr-127 {
			padding-right: 127px;
		}
		.xs-pr-128 {
			padding-right: 128px;
		}
		.xs-pr-129 {
			padding-right: 129px;
		}
		.xs-pr-130 {
			padding-right: 130px;
		}
		.xs-pr-131 {
			padding-right: 131px;
		}
		.xs-pr-132 {
			padding-right: 132px;
		}
		.xs-pr-133 {
			padding-right: 133px;
		}
		.xs-pr-134 {
			padding-right: 134px;
		}
		.xs-pr-135 {
			padding-right: 135px;
		}
		.xs-pr-136 {
			padding-right: 136px;
		}
		.xs-pr-137 {
			padding-right: 137px;
		}
		.xs-pr-138 {
			padding-right: 138px;
		}
		.xs-pr-139 {
			padding-right: 139px;
		}
		.xs-pr-140 {
			padding-right: 140px;
		}
		.xs-pr-141 {
			padding-right: 141px;
		}
		.xs-pr-142 {
			padding-right: 142px;
		}
		.xs-pr-143 {
			padding-right: 143px;
		}
		.xs-pr-144 {
			padding-right: 144px;
		}
		.xs-pr-145 {
			padding-right: 145px;
		}
		.xs-pr-146 {
			padding-right: 146px;
		}
		.xs-pr-147 {
			padding-right: 147px;
		}
		.xs-pr-148 {
			padding-right: 148px;
		}
		.xs-pr-149 {
			padding-right: 149px;
		}
		.xs-pr-150 {
			padding-right: 150px;
		}
		.xs-pr-151 {
			padding-right: 151px;
		}
		.xs-pr-152 {
			padding-right: 152px;
		}
		.xs-pr-153 {
			padding-right: 153px;
		}
		.xs-pr-154 {
			padding-right: 154px;
		}
		.xs-pr-155 {
			padding-right: 155px;
		}
		.xs-pr-156 {
			padding-right: 156px;
		}
		.xs-pr-157 {
			padding-right: 157px;
		}
		.xs-pr-158 {
			padding-right: 158px;
		}
		.xs-pr-159 {
			padding-right: 159px;
		}
		.xs-pr-160 {
			padding-right: 160px;
		}
		.xs-pr-161 {
			padding-right: 161px;
		}
		.xs-pr-162 {
			padding-right: 162px;
		}
		.xs-pr-163 {
			padding-right: 163px;
		}
		.xs-pr-164 {
			padding-right: 164px;
		}
		.xs-pr-165 {
			padding-right: 165px;
		}
		.xs-pr-166 {
			padding-right: 166px;
		}
		.xs-pr-167 {
			padding-right: 167px;
		}
		.xs-pr-168 {
			padding-right: 168px;
		}
		.xs-pr-169 {
			padding-right: 169px;
		}
		.xs-pr-170 {
			padding-right: 170px;
		}
		.xs-pr-171 {
			padding-right: 171px;
		}
		.xs-pr-172 {
			padding-right: 172px;
		}
		.xs-pr-173 {
			padding-right: 173px;
		}
		.xs-pr-174 {
			padding-right: 174px;
		}
		.xs-pr-175 {
			padding-right: 175px;
		}
		.xs-pr-176 {
			padding-right: 176px;
		}
		.xs-pr-177 {
			padding-right: 177px;
		}
		.xs-pr-178 {
			padding-right: 178px;
		}
		.xs-pr-179 {
			padding-right: 179px;
		}
		.xs-pr-180 {
			padding-right: 180px;
		}
		.xs-pr-181 {
			padding-right: 181px;
		}
		.xs-pr-182 {
			padding-right: 182px;
		}
		.xs-pr-183 {
			padding-right: 183px;
		}
		.xs-pr-184 {
			padding-right: 184px;
		}
		.xs-pr-185 {
			padding-right: 185px;
		}
		.xs-pr-186 {
			padding-right: 186px;
		}
		.xs-pr-187 {
			padding-right: 187px;
		}
		.xs-pr-188 {
			padding-right: 188px;
		}
		.xs-pr-189 {
			padding-right: 189px;
		}
		.xs-pr-190 {
			padding-right: 190px;
		}
		.xs-pr-191 {
			padding-right: 191px;
		}
		.xs-pr-192 {
			padding-right: 192px;
		}
		.xs-pr-193 {
			padding-right: 193px;
		}
		.xs-pr-194 {
			padding-right: 194px;
		}
		.xs-pr-195 {
			padding-right: 195px;
		}
		.xs-pr-196 {
			padding-right: 196px;
		}
		.xs-pr-197 {
			padding-right: 197px;
		}
		.xs-pr-198 {
			padding-right: 198px;
		}
		.xs-pr-199 {
			padding-right: 199px;
		}
		.xs-pr-200 {
			padding-right: 200px;
		}
	/*}*/

	/*------------------------------------------
		52. XS PADDING LEFT
	-------------------------------------------*/
	/*.xs-pl {*/
		.xs-pl-0 {
			padding-left: 0px;
		}
		.xs-pl-1 {
			padding-left: 1px;
		}
		.xs-pl-2 {
			padding-left: 2px;
		}
		.pb-3 {
			padding-bottom: 3px;
		}
		.xs-pl-4 {
			padding-left: 4px;
		}
		.xs-pl-5 {
			padding-left: 5px;
		}
		.xs-pl-6 {
			padding-left: 6px;
		}
		.xs-pl-7 {
			padding-left: 7px;
		}
		.xs-pl-8 {
			padding-left: 8px;
		}
		.xs-pl-9 {
			padding-left: 9px;
		}
		.xs-pl-10 {
			padding-left: 10px;
		}
		.xs-pl-11 {
			padding-left: 11px;
		}
		.xs-pl-12 {
			padding-left: 12px;
		}
		.xs-pl-13 {
			padding-left: 13px;
		}
		.xs-pl-14 {
			padding-left: 14px;
		}
		.xs-pl-15 {
			padding-left: 15px;
		}
		.xs-pl-16 {
			padding-left: 16px;
		}
		.xs-pl-17 {
			padding-left: 17px;
		}
		.xs-pl-18 {
			padding-left: 18px;
		}
		.xs-pl-19 {
			padding-left: 19px;
		}
		.xs-pl-20 {
			padding-left: 20px;
		}
		.xs-pl-21 {
			padding-left: 21px;
		}
		.xs-pl-22 {
			padding-left: 22px;
		}
		.xs-pl-23 {
			padding-left: 23px;
		}
		.xs-pl-24 {
			padding-left: 24px;
		}
		.xs-pl-25 {
			padding-left: 25px;
		}
		.xs-pl-26 {
			padding-left: 26px;
		}
		.xs-pl-27 {
			padding-left: 27px;
		}
		.xs-pl-28 {
			padding-left: 28px;
		}
		.xs-pl-29 {
			padding-left: 29px;
		}
		.xs-pl-30 {
			padding-left: 30px;
		}
		.xs-pl-31 {
			padding-left: 31px;
		}
		.xs-pl-32 {
			padding-left: 32px;
		}
		.xs-pl-33 {
			padding-left: 33px;
		}
		.xs-pl-34 {
			padding-left: 34px;
		}
		.xs-pl-35 {
			padding-left: 35px;
		}
		.xs-pl-36 {
			padding-left: 36px;
		}
		.xs-pl-37 {
			padding-left: 37px;
		}
		.xs-pl-38 {
			padding-left: 38px;
		}
		.xs-pl-39 {
			padding-left: 39px;
		}
		.xs-pl-40 {
			padding-left: 40px;
		}
		.xs-pl-41 {
			padding-left: 41px;
		}
		.xs-pl-42 {
			padding-left: 42px;
		}
		.xs-pl-43 {
			padding-left: 43px;
		}
		.xs-pl-44 {
			padding-left: 44px;
		}
		.xs-pl-45 {
			padding-left: 45px;
		}
		.xs-pl-46 {
			padding-left: 46px;
		}
		.xs-pl-47 {
			padding-left: 47px;
		}
		.xs-pl-48 {
			padding-left: 48px;
		}
		.xs-pl-49 {
			padding-left: 49px;
		}
		.xs-pl-50 {
			padding-left: 50px;
		}
		.xs-pl-51 {
			padding-left: 51px;
		}
		.xs-pl-52 {
			padding-left: 52px;
		}
		.xs-pl-53 {
			padding-left: 53px;
		}
		.xs-pl-54 {
			padding-left: 54px;
		}
		.xs-pl-55 {
			padding-left: 55px;
		}
		.xs-pl-56 {
			padding-left: 56px;
		}
		.xs-pl-57 {
			padding-left: 57px;
		}
		.xs-pl-58 {
			padding-left: 58px;
		}
		.xs-pl-59 {
			padding-left: 59px;
		}
		.xs-pl-60 {
			padding-left: 60px;
		}
		.xs-pl-61 {
			padding-left: 61px;
		}
		.xs-pl-62 {
			padding-left: 62px;
		}
		.xs-pl-63 {
			padding-left: 63px;
		}
		.xs-pl-64 {
			padding-left: 64px;
		}
		.xs-pl-65 {
			padding-left: 65px;
		}
		.xs-pl-66 {
			padding-left: 66px;
		}
		.xs-pl-67 {
			padding-left: 67px;
		}
		.xs-pl-68 {
			padding-left: 68px;
		}
		.xs-pl-69 {
			padding-left: 69px;
		}
		.xs-pl-70 {
			padding-left: 70px;
		}
		.xs-pl-71 {
			padding-left: 71px;
		}
		.xs-pl-72 {
			padding-left: 72px;
		}
		.xs-pl-73 {
			padding-left: 73px;
		}
		.xs-pl-74 {
			padding-left: 74px;
		}
		.xs-pl-75 {
			padding-left: 75px;
		}
		.xs-pl-76 {
			padding-left: 76px;
		}
		.xs-pl-77 {
			padding-left: 77px;
		}
		.xs-pl-78 {
			padding-left: 78px;
		}
		.xs-pl-79 {
			padding-left: 79px;
		}
		.xs-pl-80 {
			padding-left: 80px;
		}
		.xs-pl-81 {
			padding-left: 81px;
		}
		.xs-pl-82 {
			padding-left: 82px;
		}
		.xs-pl-83 {
			padding-left: 83px;
		}
		.xs-pl-84 {
			padding-left: 84px;
		}
		.xs-pl-85 {
			padding-left: 85px;
		}
		.xs-pl-86 {
			padding-left: 86px;
		}
		.xs-pl-87 {
			padding-left: 87px;
		}
		.xs-pl-88 {
			padding-left: 88px;
		}
		.xs-pl-89 {
			padding-left: 89px;
		}
		.xs-pl-90 {
			padding-left: 90px;
		}
		.xs-pl-91 {
			padding-left: 91px;
		}
		.xs-pl-92 {
			padding-left: 92px;
		}
		.xs-pl-93 {
			padding-left: 93px;
		}
		.xs-pl-94 {
			padding-left: 94px;
		}
		.xs-pl-95 {
			padding-left: 95px;
		}
		.xs-pl-96 {
			padding-left: 96px;
		}
		.xs-pl-97 {
			padding-left: 97px;
		}
		.xs-pl-98 {
			padding-left: 98px;
		}
		.xs-pl-99 {
			padding-left: 99px;
		}

		.xs-pl-100 {
			padding-left: 100px;
		}
		.xs-pl-101 {
			padding-left: 101px;
		}
		.xs-pl-102 {
			padding-left: 102px;
		}
		.xs-pb-103 {
			padding-bottom: 103px;
		}
		.xs-pl-104 {
			padding-left: 104px;
		}
		.xs-pl-105 {
			padding-left: 105px;
		}
		.xs-pl-106 {
			padding-left: 106px;
		}
		.xs-pl-107 {
			padding-left: 107px;
		}
		.xs-pl-108 {
			padding-left: 108px;
		}
		.xs-pl-109 {
			padding-left: 109px;
		}
		.xs-pl-110 {
			padding-left: 110px;
		}
		.xs-pl-111 {
			padding-left: 111px;
		}
		.xs-pl-112 {
			padding-left: 112px;
		}
		.xs-pl-113 {
			padding-left: 113px;
		}
		.xs-pl-114 {
			padding-left: 114px;
		}
		.xs-pl-115 {
			padding-left: 115px;
		}
		.xs-pl-116 {
			padding-left: 116px;
		}
		.xs-pl-117 {
			padding-left: 117px;
		}
		.xs-pl-118 {
			padding-left: 118px;
		}
		.xs-pl-119 {
			padding-left: 119px;
		}
		.xs-pl-120 {
			padding-left: 120px;
		}
		.xs-pl-121 {
			padding-left: 121px;
		}
		.xs-pl-122 {
			padding-left: 122px;
		}
		.xs-pl-123 {
			padding-left: 123px;
		}
		.xs-pl-124 {
			padding-left: 124px;
		}
		.xs-pl-125 {
			padding-left: 125px;
		}
		.xs-pl-126 {
			padding-left: 126px;
		}
		.xs-pl-127 {
			padding-left: 127px;
		}
		.xs-pl-128 {
			padding-left: 128px;
		}
		.xs-pl-129 {
			padding-left: 129px;
		}
		.xs-pl-130 {
			padding-left: 130px;
		}
		.xs-pl-131 {
			padding-left: 131px;
		}
		.xs-pl-132 {
			padding-left: 132px;
		}
		.xs-pl-133 {
			padding-left: 133px;
		}
		.xs-pl-134 {
			padding-left: 134px;
		}
		.xs-pl-135 {
			padding-left: 135px;
		}
		.xs-pl-136 {
			padding-left: 136px;
		}
		.xs-pl-137 {
			padding-left: 137px;
		}
		.xs-pl-138 {
			padding-left: 138px;
		}
		.xs-pl-139 {
			padding-left: 139px;
		}
		.xs-pl-140 {
			padding-left: 140px;
		}
		.xs-pl-141 {
			padding-left: 141px;
		}
		.xs-pl-142 {
			padding-left: 142px;
		}
		.xs-pl-143 {
			padding-left: 143px;
		}
		.xs-pl-144 {
			padding-left: 144px;
		}
		.xs-pl-145 {
			padding-left: 145px;
		}
		.xs-pl-146 {
			padding-left: 146px;
		}
		.xs-pl-147 {
			padding-left: 147px;
		}
		.xs-pl-148 {
			padding-left: 148px;
		}
		.xs-pl-149 {
			padding-left: 149px;
		}
		.xs-pl-150 {
			padding-left: 150px;
		}
		.xs-pl-151 {
			padding-left: 151px;
		}
		.xs-pl-152 {
			padding-left: 152px;
		}
		.xs-pl-153 {
			padding-left: 153px;
		}
		.xs-pl-154 {
			padding-left: 154px;
		}
		.xs-pl-155 {
			padding-left: 155px;
		}
		.xs-pl-156 {
			padding-left: 156px;
		}
		.xs-pl-157 {
			padding-left: 157px;
		}
		.xs-pl-158 {
			padding-left: 158px;
		}
		.xs-pl-159 {
			padding-left: 159px;
		}
		.xs-pl-160 {
			padding-left: 160px;
		}
		.xs-pl-161 {
			padding-left: 161px;
		}
		.xs-pl-162 {
			padding-left: 162px;
		}
		.xs-pl-163 {
			padding-left: 163px;
		}
		.xs-pl-164 {
			padding-left: 164px;
		}
		.xs-pl-165 {
			padding-left: 165px;
		}
		.xs-pl-166 {
			padding-left: 166px;
		}
		.xs-pl-167 {
			padding-left: 167px;
		}
		.xs-pl-168 {
			padding-left: 168px;
		}
		.xs-pl-169 {
			padding-left: 169px;
		}
		.xs-pl-170 {
			padding-left: 170px;
		}
		.xs-pl-171 {
			padding-left: 171px;
		}
		.xs-pl-172 {
			padding-left: 172px;
		}
		.xs-pl-173 {
			padding-left: 173px;
		}
		.xs-pl-174 {
			padding-left: 174px;
		}
		.xs-pl-175 {
			padding-left: 175px;
		}
		.xs-pl-176 {
			padding-left: 176px;
		}
		.xs-pl-177 {
			padding-left: 177px;
		}
		.xs-pl-178 {
			padding-left: 178px;
		}
		.xs-pl-179 {
			padding-left: 179px;
		}
		.xs-pl-180 {
			padding-left: 180px;
		}
		.xs-pl-181 {
			padding-left: 181px;
		}
		.xs-pl-182 {
			padding-left: 182px;
		}
		.xs-pl-183 {
			padding-left: 183px;
		}
		.xs-pl-184 {
			padding-left: 184px;
		}
		.xs-pl-185 {
			padding-left: 185px;
		}
		.xs-pl-186 {
			padding-left: 186px;
		}
		.xs-pl-187 {
			padding-left: 187px;
		}
		.xs-pl-188 {
			padding-left: 188px;
		}
		.xs-pl-189 {
			padding-left: 189px;
		}
		.xs-pl-190 {
			padding-left: 190px;
		}
		.xs-pl-191 {
			padding-left: 191px;
		}
		.xs-pl-192 {
			padding-left: 192px;
		}
		.xs-pl-193 {
			padding-left: 193px;
		}
		.xs-pl-194 {
			padding-left: 194px;
		}
		.xs-pl-195 {
			padding-left: 195px;
		}
		.xs-pl-196 {
			padding-left: 196px;
		}
		.xs-pl-197 {
			padding-left: 197px;
		}
		.xs-pl-198 {
			padding-left: 198px;
		}
		.xs-pl-199 {
			padding-left: 199px;
		}
		.xs-pl-200 {
			padding-left: 200px;
		}
	/*}*/

	/*------------------------------------------
		53. XS MARGIN TOP
	-------------------------------------------*/
	/*.xs-mt {*/
		.xs-mt-0 {
			margin-top: 0px;
		}
		.xs-mt-1 {
			margin-top: 1px;
		}
		.xs-mt-2 {
			margin-top: 2px;
		}
		.xs-mt-3 {
			margin-top: 3px;
		}
		.xs-mt-4 {
			margin-top: 4px;
		}
		.xs-mt-5 {
			margin-top: 5px;
		}
		.xs-mt-6 {
			margin-top: 6px;
		}
		.xs-mt-7 {
			margin-top: 7px;
		}
		.xs-mt-8 {
			margin-top: 8px;
		}
		.xs-mt-9 {
			margin-top: 9px;
		}
		.xs-mt-10 {
			margin-top: 10px;
		}
		.xs-mt-11 {
			margin-top: 11px;
		}
		.xs-mt-12 {
			margin-top: 12px;
		}
		.xs-mt-13 {
			margin-top: 13px;
		}
		.xs-mt-14 {
			margin-top: 14px;
		}
		.xs-mt-15 {
			margin-top: 15px;
		}
		.xs-mt-16 {
			margin-top: 16px;
		}
		.xs-mt-17 {
			margin-top: 17px;
		}
		.xs-mt-18 {
			margin-top: 18px;
		}
		.xs-mt-19 {
			margin-top: 19px;
		}
		.xs-mt-20 {
			margin-top: 20px;
		}
		.xs-mt-21 {
			margin-top: 21px;
		}
		.xs-mt-22 {
			margin-top: 22px;
		}
		.xs-mt-23 {
			margin-top: 23px;
		}
		.xs-mt-24 {
			margin-top: 24px;
		}
		.xs-mt-25 {
			margin-top: 25px;
		}
		.xs-mt-26 {
			margin-top: 26px;
		}
		.xs-mt-27 {
			margin-top: 27px;
		}
		.xs-mt-28 {
			margin-top: 28px;
		}
		.xs-mt-29 {
			margin-top: 29px;
		}
		.xs-mt-30 {
			margin-top: 30px;
		}
		.xs-mt-31 {
			margin-top: 31px;
		}
		.xs-mt-32 {
			margin-top: 32px;
		}
		.xs-mt-33 {
			margin-top: 33px;
		}
		.xs-mt-34 {
			margin-top: 34px;
		}
		.xs-mt-35 {
			margin-top: 35px;
		}
		.xs-mt-36 {
			margin-top: 36px;
		}
		.xs-mt-37 {
			margin-top: 37px;
		}
		.xs-mt-38 {
			margin-top: 38px;
		}
		.xs-mt-39 {
			margin-top: 39px;
		}
		.xs-mt-40 {
			margin-top: 40px;
		}
		.xs-mt-41 {
			margin-top: 41px;
		}
		.xs-mt-42 {
			margin-top: 42px;
		}
		.xs-mt-43 {
			margin-top: 43px;
		}
		.xs-mt-44 {
			margin-top: 44px;
		}
		.xs-mt-45 {
			margin-top: 45px;
		}
		.xs-mt-46 {
			margin-top: 46px;
		}
		.xs-mt-47 {
			margin-top: 47px;
		}
		.xs-mt-48 {
			margin-top: 48px;
		}
		.xs-mt-49 {
			margin-top: 49px;
		}
		.xs-mt-50 {
			margin-top: 50px;
		}
		.xs-mt-51 {
			margin-top: 51px;
		}
		.xs-mt-52 {
			margin-top: 52px;
		}
		.xs-mt-53 {
			margin-top: 53px;
		}
		.xs-mt-54 {
			margin-top: 54px;
		}
		.xs-mt-55 {
			margin-top: 55px;
		}
		.xs-mt-56 {
			margin-top: 56px;
		}
		.xs-mt-57 {
			margin-top: 57px;
		}
		.xs-mt-58 {
			margin-top: 58px;
		}
		.xs-mt-59 {
			margin-top: 59px;
		}
		.xs-mt-60 {
			margin-top: 60px;
		}
		.xs-mt-61 {
			margin-top: 61px;
		}
		.xs-mt-62 {
			margin-top: 62px;
		}
		.xs-mt-63 {
			margin-top: 63px;
		}
		.xs-mt-64 {
			margin-top: 64px;
		}
		.xs-mt-65 {
			margin-top: 65px;
		}
		.xs-mt-66 {
			margin-top: 66px;
		}
		.xs-mt-67 {
			margin-top: 67px;
		}
		.xs-mt-68 {
			margin-top: 68px;
		}
		.xs-mt-69 {
			margin-top: 69px;
		}
		.xs-mt-70 {
			margin-top: 70px;
		}
		.xs-mt-71 {
			margin-top: 71px;
		}
		.xs-mt-72 {
			margin-top: 72px;
		}
		.xs-mt-73 {
			margin-top: 73px;
		}
		.xs-mt-74 {
			margin-top: 74px;
		}
		.xs-mt-75 {
			margin-top: 75px;
		}
		.xs-mt-76 {
			margin-top: 76px;
		}
		.xs-mt-77 {
			margin-top: 77px;
		}
		.xs-mt-78 {
			margin-top: 78px;
		}
		.xs-mt-79 {
			margin-top: 79px;
		}
		.xs-mt-80 {
			margin-top: 80px;
		}
		.xs-mt-81 {
			margin-top: 81px;
		}
		.xs-mt-82 {
			margin-top: 82px;
		}
		.xs-mt-83 {
			margin-top: 83px;
		}
		.xs-mt-84 {
			margin-top: 84px;
		}
		.xs-mt-85 {
			margin-top: 85px;
		}
		.xs-mt-86 {
			margin-top: 86px;
		}
		.xs-mt-87 {
			margin-top: 87px;
		}
		.xs-mt-88 {
			margin-top: 88px;
		}
		.xs-mt-89 {
			margin-top: 89px;
		}
		.xs-mt-90 {
			margin-top: 90px;
		}
		.xs-mt-91 {
			margin-top: 91px;
		}
		.xs-mt-92 {
			margin-top: 92px;
		}
		.xs-mt-93 {
			margin-top: 93px;
		}
		.xs-mt-94 {
			margin-top: 94px;
		}
		.xs-mt-95 {
			margin-top: 95px;
		}
		.xs-mt-96 {
			margin-top: 96px;
		}
		.xs-mt-97 {
			margin-top: 97px;
		}
		.xs-mt-98 {
			margin-top: 98px;
		}
		.xs-mt-99 {
			margin-top: 99px;
		}

		.xs-mt-100 {
			margin-top: 100px;
		}
		.xs-mt-101 {
			margin-top: 101px;
		}
		.xs-mt-102 {
			margin-top: 102px;
		}
		.xs-mt-103 {
			margin-top: 103px;
		}
		.xs-mt-104 {
			margin-top: 104px;
		}
		.xs-mt-105 {
			margin-top: 105px;
		}
		.xs-mt-106 {
			margin-top: 106px;
		}
		.xs-mt-107 {
			margin-top: 107px;
		}
		.xs-mt-108 {
			margin-top: 108px;
		}
		.xs-mt-109 {
			margin-top: 109px;
		}
		.xs-mt-110 {
			margin-top: 110px;
		}
		.xs-mt-111 {
			margin-top: 111px;
		}
		.xs-mt-112 {
			margin-top: 112px;
		}
		.xs-mt-113 {
			margin-top: 113px;
		}
		.xs-mt-114 {
			margin-top: 114px;
		}
		.xs-mt-115 {
			margin-top: 115px;
		}
		.xs-mt-116 {
			margin-top: 116px;
		}
		.xs-mt-117 {
			margin-top: 117px;
		}
		.xs-mt-118 {
			margin-top: 118px;
		}
		.xs-mt-119 {
			margin-top: 119px;
		}
		.xs-mt-120 {
			margin-top: 120px;
		}
		.xs-mt-121 {
			margin-top: 121px;
		}
		.xs-mt-122 {
			margin-top: 122px;
		}
		.xs-mt-123 {
			margin-top: 123px;
		}
		.xs-mt-124 {
			margin-top: 124px;
		}
		.xs-mt-125 {
			margin-top: 125px;
		}
		.xs-mt-126 {
			margin-top: 126px;
		}
		.xs-mt-127 {
			margin-top: 127px;
		}
		.xs-mt-128 {
			margin-top: 128px;
		}
		.xs-mt-129 {
			margin-top: 129px;
		}
		.xs-mt-130 {
			margin-top: 130px;
		}
		.xs-mt-131 {
			margin-top: 131px;
		}
		.xs-mt-132 {
			margin-top: 132px;
		}
		.xs-mt-133 {
			margin-top: 133px;
		}
		.xs-mt-134 {
			margin-top: 134px;
		}
		.xs-mt-135 {
			margin-top: 135px;
		}
		.xs-mt-136 {
			margin-top: 136px;
		}
		.xs-mt-137 {
			margin-top: 137px;
		}
		.xs-mt-138 {
			margin-top: 138px;
		}
		.xs-mt-139 {
			margin-top: 139px;
		}
		.xs-mt-140 {
			margin-top: 140px;
		}
		.xs-mt-141 {
			margin-top: 141px;
		}
		.xs-mt-142 {
			margin-top: 142px;
		}
		.xs-mt-143 {
			margin-top: 143px;
		}
		.xs-mt-144 {
			margin-top: 144px;
		}
		.xs-mt-145 {
			margin-top: 145px;
		}
		.xs-mt-146 {
			margin-top: 146px;
		}
		.xs-mt-147 {
			margin-top: 147px;
		}
		.xs-mt-148 {
			margin-top: 148px;
		}
		.xs-mt-149 {
			margin-top: 149px;
		}
		.xs-mt-150 {
			margin-top: 150px;
		}
		.xs-mt-151 {
			margin-top: 151px;
		}
		.xs-mt-152 {
			margin-top: 152px;
		}
		.xs-mt-153 {
			margin-top: 153px;
		}
		.xs-mt-154 {
			margin-top: 154px;
		}
		.xs-mt-155 {
			margin-top: 155px;
		}
		.xs-mt-156 {
			margin-top: 156px;
		}
		.xs-mt-157 {
			margin-top: 157px;
		}
		.xs-mt-158 {
			margin-top: 158px;
		}
		.xs-mt-159 {
			margin-top: 159px;
		}
		.xs-mt-160 {
			margin-top: 160px;
		}
		.xs-mt-161 {
			margin-top: 161px;
		}
		.xs-mt-162 {
			margin-top: 162px;
		}
		.xs-mt-163 {
			margin-top: 163px;
		}
		.xs-mt-164 {
			margin-top: 164px;
		}
		.xs-mt-165 {
			margin-top: 165px;
		}
		.xs-mt-166 {
			margin-top: 166px;
		}
		.xs-mt-167 {
			margin-top: 167px;
		}
		.xs-mt-168 {
			margin-top: 168px;
		}
		.xs-mt-169 {
			margin-top: 169px;
		}
		.xs-mt-170 {
			margin-top: 170px;
		}
		.xs-mt-171 {
			margin-top: 171px;
		}
		.xs-mt-172 {
			margin-top: 172px;
		}
		.xs-mt-173 {
			margin-top: 173px;
		}
		.xs-mt-174 {
			margin-top: 174px;
		}
		.xs-mt-175 {
			margin-top: 175px;
		}
		.xs-mt-176 {
			margin-top: 176px;
		}
		.xs-mt-177 {
			margin-top: 177px;
		}
		.xs-mt-178 {
			margin-top: 178px;
		}
		.xs-mt-179 {
			margin-top: 179px;
		}
		.xs-mt-180 {
			margin-top: 180px;
		}
		.xs-mt-181 {
			margin-top: 181px;
		}
		.xs-mt-182 {
			margin-top: 182px;
		}
		.xs-mt-183 {
			margin-top: 183px;
		}
		.xs-mt-184 {
			margin-top: 184px;
		}
		.xs-mt-185 {
			margin-top: 185px;
		}
		.xs-mt-186 {
			margin-top: 186px;
		}
		.xs-mt-187 {
			margin-top: 187px;
		}
		.xs-mt-188 {
			margin-top: 188px;
		}
		.xs-mt-189 {
			margin-top: 189px;
		}
		.xs-mt-190 {
			margin-top: 190px;
		}
		.xs-mt-191 {
			margin-top: 191px;
		}
		.xs-mt-192 {
			margin-top: 192px;
		}
		.xs-mt-193 {
			margin-top: 193px;
		}
		.xs-mt-194 {
			margin-top: 194px;
		}
		.xs-mt-195 {
			margin-top: 195px;
		}
		.xs-mt-196 {
			margin-top: 196px;
		}
		.xs-mt-197 {
			margin-top: 197px;
		}
		.xs-mt-198 {
			margin-top: 198px;
		}
		.xs-mt-199 {
			margin-top: 199px;
		}
		.xs-mt-200 {
			margin-top: 200px;
		}
	/*}*/

	/*------------------------------------------
		54. XS MARGIN BOTTOM
	-------------------------------------------*/
	/*.xs-mb {*/
		.xs-mb-0 {
			margin-bottom: 0px;
		}
		.xs-mb-1 {
			margin-bottom: 1px;
		}
		.xs-mb-2 {
			margin-bottom: 2px;
		}
		.xs-mb-3 {
			margin-bottom: 3px;
		}
		.xs-mb-4 {
			margin-bottom: 4px;
		}
		.xs-mb-5 {
			margin-bottom: 5px;
		}
		.xs-mb-6 {
			margin-bottom: 6px;
		}
		.xs-mb-7 {
			margin-bottom: 7px;
		}
		.xs-mb-8 {
			margin-bottom: 8px;
		}
		.xs-mb-9 {
			margin-bottom: 9px;
		}
		.xs-mb-10 {
			margin-bottom: 10px;
		}
		.xs-mb-11 {
			margin-bottom: 11px;
		}
		.xs-mb-12 {
			margin-bottom: 12px;
		}
		.xs-mb-13 {
			margin-bottom: 13px;
		}
		.xs-mb-14 {
			margin-bottom: 14px;
		}
		.xs-mb-15 {
			margin-bottom: 15px;
		}
		.xs-mb-16 {
			margin-bottom: 16px;
		}
		.xs-mb-17 {
			margin-bottom: 17px;
		}
		.xs-mb-18 {
			margin-bottom: 18px;
		}
		.xs-mb-19 {
			margin-bottom: 19px;
		}
		.xs-mb-20 {
			margin-bottom: 20px;
		}
		.xs-mb-21 {
			margin-bottom: 21px;
		}
		.xs-mb-22 {
			margin-bottom: 22px;
		}
		.xs-mb-23 {
			margin-bottom: 23px;
		}
		.xs-mb-24 {
			margin-bottom: 24px;
		}
		.xs-mb-25 {
			margin-bottom: 25px;
		}
		.xs-mb-26 {
			margin-bottom: 26px;
		}
		.xs-mb-27 {
			margin-bottom: 27px;
		}
		.xs-mb-28 {
			margin-bottom: 28px;
		}
		.xs-mb-29 {
			margin-bottom: 29px;
		}
		.xs-mb-30 {
			margin-bottom: 30px;
		}
		.xs-mb-31 {
			margin-bottom: 31px;
		}
		.xs-mb-32 {
			margin-bottom: 32px;
		}
		.xs-mb-33 {
			margin-bottom: 33px;
		}
		.xs-mb-34 {
			margin-bottom: 34px;
		}
		.xs-mb-35 {
			margin-bottom: 35px;
		}
		.xs-mb-36 {
			margin-bottom: 36px;
		}
		.xs-mb-37 {
			margin-bottom: 37px;
		}
		.xs-mb-38 {
			margin-bottom: 38px;
		}
		.xs-mb-39 {
			margin-bottom: 39px;
		}
		.xs-mb-40 {
			margin-bottom: 40px;
		}
		.xs-mb-41 {
			margin-bottom: 41px;
		}
		.xs-mb-42 {
			margin-bottom: 42px;
		}
		.xs-mb-43 {
			margin-bottom: 43px;
		}
		.xs-mb-44 {
			margin-bottom: 44px;
		}
		.xs-mb-45 {
			margin-bottom: 45px;
		}
		.xs-mb-46 {
			margin-bottom: 46px;
		}
		.xs-mb-47 {
			margin-bottom: 47px;
		}
		.xs-mb-48 {
			margin-bottom: 48px;
		}
		.xs-mb-49 {
			margin-bottom: 49px;
		}
		.xs-mb-50 {
			margin-bottom: 50px;
		}
		.xs-mb-51 {
			margin-bottom: 51px;
		}
		.xs-mb-52 {
			margin-bottom: 52px;
		}
		.xs-mb-53 {
			margin-bottom: 53px;
		}
		.xs-mb-54 {
			margin-bottom: 54px;
		}
		.xs-mb-55 {
			margin-bottom: 55px;
		}
		.xs-mb-56 {
			margin-bottom: 56px;
		}
		.xs-mb-57 {
			margin-bottom: 57px;
		}
		.xs-mb-58 {
			margin-bottom: 58px;
		}
		.xs-mb-59 {
			margin-bottom: 59px;
		}
		.xs-mb-60 {
			margin-bottom: 60px;
		}
		.xs-mb-61 {
			margin-bottom: 61px;
		}
		.xs-mb-62 {
			margin-bottom: 62px;
		}
		.xs-mb-63 {
			margin-bottom: 63px;
		}
		.xs-mb-64 {
			margin-bottom: 64px;
		}
		.xs-mb-65 {
			margin-bottom: 65px;
		}
		.xs-mb-66 {
			margin-bottom: 66px;
		}
		.xs-mb-67 {
			margin-bottom: 67px;
		}
		.xs-mb-68 {
			margin-bottom: 68px;
		}
		.xs-mb-69 {
			margin-bottom: 69px;
		}
		.xs-mb-70 {
			margin-bottom: 70px;
		}
		.xs-mb-71 {
			margin-bottom: 71px;
		}
		.xs-mb-72 {
			margin-bottom: 72px;
		}
		.xs-mb-73 {
			margin-bottom: 73px;
		}
		.xs-mb-74 {
			margin-bottom: 74px;
		}
		.xs-mb-75 {
			margin-bottom: 75px;
		}
		.xs-mb-76 {
			margin-bottom: 76px;
		}
		.xs-mb-77 {
			margin-bottom: 77px;
		}
		.xs-mb-78 {
			margin-bottom: 78px;
		}
		.xs-mb-79 {
			margin-bottom: 79px;
		}
		.xs-mb-80 {
			margin-bottom: 80px;
		}
		.xs-mb-81 {
			margin-bottom: 81px;
		}
		.xs-mb-82 {
			margin-bottom: 82px;
		}
		.xs-mb-83 {
			margin-bottom: 83px;
		}
		.xs-mb-84 {
			margin-bottom: 84px;
		}
		.xs-mb-85 {
			margin-bottom: 85px;
		}
		.xs-mb-86 {
			margin-bottom: 86px;
		}
		.xs-mb-87 {
			margin-bottom: 87px;
		}
		.xs-mb-88 {
			margin-bottom: 88px;
		}
		.xs-mb-89 {
			margin-bottom: 89px;
		}
		.xs-mb-90 {
			margin-bottom: 90px;
		}
		.xs-mb-91 {
			margin-bottom: 91px;
		}
		.xs-mb-92 {
			margin-bottom: 92px;
		}
		.xs-mb-93 {
			margin-bottom: 93px;
		}
		.xs-mb-94 {
			margin-bottom: 94px;
		}
		.xs-mb-95 {
			margin-bottom: 95px;
		}
		.xs-mb-96 {
			margin-bottom: 96px;
		}
		.xs-mb-97 {
			margin-bottom: 97px;
		}
		.xs-mb-98 {
			margin-bottom: 98px;
		}
		.xs-mb-99 {
			margin-bottom: 99px;
		}
		.xs-mb-100 {
			margin-bottom: 100px;
		}

		.xs-mb-101 {
			margin-bottom: 101px;
		}
		.xs-mb-102 {
			margin-bottom: 102px;
		}
		.xs-mb-103 {
			margin-bottom: 103px;
		}
		.xs-mb-104 {
			margin-bottom: 104px;
		}
		.xs-mb-105 {
			margin-bottom: 105px;
		}
		.xs-mb-106 {
			margin-bottom: 106px;
		}
		.xs-mb-107 {
			margin-bottom: 107px;
		}
		.xs-mb-108 {
			margin-bottom: 108px;
		}
		.xs-mb-109 {
			margin-bottom: 109px;
		}
		.xs-mb-110 {
			margin-bottom: 110px;
		}
		.xs-mb-111 {
			margin-bottom: 111px;
		}
		.xs-mb-112 {
			margin-bottom: 112px;
		}
		.xs-mb-113 {
			margin-bottom: 113px;
		}
		.xs-mb-114 {
			margin-bottom: 114px;
		}
		.xs-mb-115 {
			margin-bottom: 115px;
		}
		.xs-mb-116 {
			margin-bottom: 116px;
		}
		.xs-mb-117 {
			margin-bottom: 117px;
		}
		.xs-mb-118 {
			margin-bottom: 118px;
		}
		.xs-mb-119 {
			margin-bottom: 119px;
		}
		.xs-mb-120 {
			margin-bottom: 120px;
		}
		.xs-mb-121 {
			margin-bottom: 121px;
		}
		.xs-mb-122 {
			margin-bottom: 122px;
		}
		.xs-mb-123 {
			margin-bottom: 123px;
		}
		.xs-mb-124 {
			margin-bottom: 124px;
		}
		.xs-mb-125 {
			margin-bottom: 125px;
		}
		.xs-mb-126 {
			margin-bottom: 126px;
		}
		.xs-mb-127 {
			margin-bottom: 127px;
		}
		.xs-mb-128 {
			margin-bottom: 128px;
		}
		.xs-mb-129 {
			margin-bottom: 129px;
		}
		.xs-mb-130 {
			margin-bottom: 130px;
		}
		.xs-mb-131 {
			margin-bottom: 131px;
		}
		.xs-mb-132 {
			margin-bottom: 132px;
		}
		.xs-mb-133 {
			margin-bottom: 133px;
		}
		.xs-mb-134 {
			margin-bottom: 134px;
		}
		.xs-mb-135 {
			margin-bottom: 135px;
		}
		.xs-mb-136 {
			margin-bottom: 136px;
		}
		.xs-mb-137 {
			margin-bottom: 137px;
		}
		.xs-mb-138 {
			margin-bottom: 138px;
		}
		.xs-mb-139 {
			margin-bottom: 139px;
		}
		.xs-mb-140 {
			margin-bottom: 140px;
		}
		.xs-mb-141 {
			margin-bottom: 141px;
		}
		.xs-mb-142 {
			margin-bottom: 142px;
		}
		.xs-mb-143 {
			margin-bottom: 143px;
		}
		.xs-mb-144 {
			margin-bottom: 144px;
		}
		.xs-mb-145 {
			margin-bottom: 145px;
		}
		.xs-mb-146 {
			margin-bottom: 146px;
		}
		.xs-mb-147 {
			margin-bottom: 147px;
		}
		.xs-mb-148 {
			margin-bottom: 148px;
		}
		.xs-mb-149 {
			margin-bottom: 149px;
		}
		.xs-mb-150 {
			margin-bottom: 150px;
		}
		.xs-mb-151 {
			margin-bottom: 151px;
		}
		.xs-mb-152 {
			margin-bottom: 152px;
		}
		.xs-mb-153 {
			margin-bottom: 153px;
		}
		.xs-mb-154 {
			margin-bottom: 154px;
		}
		.xs-mb-155 {
			margin-bottom: 155px;
		}
		.xs-mb-156 {
			margin-bottom: 156px;
		}
		.xs-mb-157 {
			margin-bottom: 157px;
		}
		.xs-mb-158 {
			margin-bottom: 158px;
		}
		.xs-mb-159 {
			margin-bottom: 159px;
		}
		.xs-mb-160 {
			margin-bottom: 160px;
		}
		.xs-mb-161 {
			margin-bottom: 161px;
		}
		.xs-mb-162 {
			margin-bottom: 162px;
		}
		.xs-mb-163 {
			margin-bottom: 163px;
		}
		.xs-mb-164 {
			margin-bottom: 164px;
		}
		.xs-mb-165 {
			margin-bottom: 165px;
		}
		.xs-mb-166 {
			margin-bottom: 166px;
		}
		.xs-mb-167 {
			margin-bottom: 167px;
		}
		.xs-mb-168 {
			margin-bottom: 168px;
		}
		.xs-mb-169 {
			margin-bottom: 169px;
		}
		.xs-mb-170 {
			margin-bottom: 170px;
		}
		.xs-mb-171 {
			margin-bottom: 171px;
		}
		.xs-mb-172 {
			margin-bottom: 172px;
		}
		.xs-mb-173 {
			margin-bottom: 173px;
		}
		.xs-mb-174 {
			margin-bottom: 174px;
		}
		.xs-mb-175 {
			margin-bottom: 175px;
		}
		.xs-mb-176 {
			margin-bottom: 176px;
		}
		.xs-mb-177 {
			margin-bottom: 177px;
		}
		.xs-mb-178 {
			margin-bottom: 178px;
		}
		.xs-mb-179 {
			margin-bottom: 179px;
		}
		.xs-mb-180 {
			margin-bottom: 180px;
		}
		.xs-mb-181 {
			margin-bottom: 181px;
		}
		.xs-mb-182 {
			margin-bottom: 182px;
		}
		.xs-mb-183 {
			margin-bottom: 183px;
		}
		.xs-mb-184 {
			margin-bottom: 184px;
		}
		.xs-mb-185 {
			margin-bottom: 185px;
		}
		.xs-mb-186 {
			margin-bottom: 186px;
		}
		.xs-mb-187 {
			margin-bottom: 187px;
		}
		.xs-mb-188 {
			margin-bottom: 188px;
		}
		.xs-mb-189 {
			margin-bottom: 189px;
		}
		.xs-mb-190 {
			margin-bottom: 190px;
		}
		.xs-mb-191 {
			margin-bottom: 191px;
		}
		.xs-mb-192 {
			margin-bottom: 192px;
		}
		.xs-mb-193 {
			margin-bottom: 193px;
		}
		.xs-mb-194 {
			margin-bottom: 194px;
		}
		.xs-mb-195 {
			margin-bottom: 195px;
		}
		.xs-mb-196 {
			margin-bottom: 196px;
		}
		.xs-mb-197 {
			margin-bottom: 197px;
		}
		.xs-mb-198 {
			margin-bottom: 198px;
		}
		.xs-mb-199 {
			margin-bottom: 199px;
		}
		.xs-mb-200 {
			margin-bottom: 200px;
		}
	/*}*/

	/*------------------------------------------
		55. XS MARGIN RIGHT
	-------------------------------------------*/
	/*.xs-mr {*/
		.xs-mr-0 {
			margin-right: 0px;
		}
		.xs-mr-1 {
			margin-right: 1px;
		}
		.xs-mr-2 {
			margin-right: 2px;
		}
		.xs-mr-3 {
			margin-right: 3px;
		}
		.xs-mr-4 {
			margin-right: 4px;
		}
		.xs-mr-5 {
			margin-right: 5px;
		}
		.xs-mr-6 {
			margin-right: 6px;
		}
		.xs-mr-7 {
			margin-right: 7px;
		}
		.xs-mr-8 {
			margin-right: 8px;
		}
		.xs-mr-9 {
			margin-right: 9px;
		}
		.xs-mr-10 {
			margin-right: 10px;
		}
		.xs-mr-11 {
			margin-right: 11px;
		}
		.xs-mr-12 {
			margin-right: 12px;
		}
		.xs-mr-13 {
			margin-right: 13px;
		}
		.xs-mr-14 {
			margin-right: 14px;
		}
		.xs-mr-15 {
			margin-right: 15px;
		}
		.xs-mr-16 {
			margin-right: 16px;
		}
		.xs-mr-17 {
			margin-right: 17px;
		}
		.xs-mr-18 {
			margin-right: 18px;
		}
		.xs-mr-19 {
			margin-right: 19px;
		}
		.xs-mr-20 {
			margin-right: 20px;
		}
		.xs-mr-21 {
			margin-right: 21px;
		}
		.xs-mr-22 {
			margin-right: 22px;
		}
		.xs-mr-23 {
			margin-right: 23px;
		}
		.xs-mr-24 {
			margin-right: 24px;
		}
		.xs-mr-25 {
			margin-right: 25px;
		}
		.xs-mr-26 {
			margin-right: 26px;
		}
		.xs-mr-27 {
			margin-right: 27px;
		}
		.xs-mr-28 {
			margin-right: 28px;
		}
		.xs-mr-29 {
			margin-right: 29px;
		}
		.xs-mr-30 {
			margin-right: 30px;
		}
		.xs-mr-31 {
			margin-right: 31px;
		}
		.xs-mr-32 {
			margin-right: 32px;
		}
		.xs-mr-33 {
			margin-right: 33px;
		}
		.xs-mr-34 {
			margin-right: 34px;
		}
		.xs-mr-35 {
			margin-right: 35px;
		}
		.xs-mr-36 {
			margin-right: 36px;
		}
		.xs-mr-37 {
			margin-right: 37px;
		}
		.xs-mr-38 {
			margin-right: 38px;
		}
		.xs-mr-39 {
			margin-right: 39px;
		}
		.xs-mr-40 {
			margin-right: 40px;
		}
		.xs-mr-41 {
			margin-right: 41px;
		}
		.xs-mr-42 {
			margin-right: 42px;
		}
		.xs-mr-43 {
			margin-right: 43px;
		}
		.xs-mr-44 {
			margin-right: 44px;
		}
		.xs-mr-45 {
			margin-right: 45px;
		}
		.xs-mr-46 {
			margin-right: 46px;
		}
		.xs-mr-47 {
			margin-right: 47px;
		}
		.xs-mr-48 {
			margin-right: 48px;
		}
		.xs-mr-49 {
			margin-right: 49px;
		}
		.xs-mr-50 {
			margin-right: 50px;
		}
		.xs-mr-51 {
			margin-right: 51px;
		}
		.xs-mr-52 {
			margin-right: 52px;
		}
		.xs-mr-53 {
			margin-right: 53px;
		}
		.xs-mr-54 {
			margin-right: 54px;
		}
		.xs-mr-55 {
			margin-right: 55px;
		}
		.xs-mr-56 {
			margin-right: 56px;
		}
		.xs-mr-57 {
			margin-right: 57px;
		}
		.xs-mr-58 {
			margin-right: 58px;
		}
		.xs-mr-59 {
			margin-right: 59px;
		}
		.xs-mr-60 {
			margin-right: 60px;
		}
		.xs-mr-61 {
			margin-right: 61px;
		}
		.xs-mr-62 {
			margin-right: 62px;
		}
		.xs-mr-63 {
			margin-right: 63px;
		}
		.xs-mr-64 {
			margin-right: 64px;
		}
		.xs-mr-65 {
			margin-right: 65px;
		}
		.xs-mr-66 {
			margin-right: 66px;
		}
		.xs-mr-67 {
			margin-right: 67px;
		}
		.xs-mr-68 {
			margin-right: 68px;
		}
		.xs-mr-69 {
			margin-right: 69px;
		}
		.xs-mr-70 {
			margin-right: 70px;
		}
		.xs-mr-71 {
			margin-right: 71px;
		}
		.xs-mr-72 {
			margin-right: 72px;
		}
		.xs-mr-73 {
			margin-right: 73px;
		}
		.xs-mr-74 {
			margin-right: 74px;
		}
		.xs-mr-75 {
			margin-right: 75px;
		}
		.xs-mr-76 {
			margin-right: 76px;
		}
		.xs-mr-77 {
			margin-right: 77px;
		}
		.xs-mr-78 {
			margin-right: 78px;
		}
		.xs-mr-79 {
			margin-right: 79px;
		}
		.xs-mr-80 {
			margin-right: 80px;
		}
		.xs-mr-81 {
			margin-right: 81px;
		}
		.xs-mr-82 {
			margin-right: 82px;
		}
		.xs-mr-83 {
			margin-right: 83px;
		}
		.xs-mr-84 {
			margin-right: 84px;
		}
		.xs-mr-85 {
			margin-right: 85px;
		}
		.xs-mr-86 {
			margin-right: 86px;
		}
		.xs-mr-87 {
			margin-right: 87px;
		}
		.xs-mr-88 {
			margin-right: 88px;
		}
		.xs-mr-89 {
			margin-right: 89px;
		}
		.xs-mr-90 {
			margin-right: 90px;
		}
		.xs-mr-91 {
			margin-right: 91px;
		}
		.xs-mr-92 {
			margin-right: 92px;
		}
		.xs-mr-93 {
			margin-right: 93px;
		}
		.xs-mr-94 {
			margin-right: 94px;
		}
		.xs-mr-95 {
			margin-right: 95px;
		}
		.xs-mr-96 {
			margin-right: 96px;
		}
		.xs-mr-97 {
			margin-right: 97px;
		}
		.xs-mr-98 {
			margin-right: 98px;
		}
		.xs-mr-99 {
			margin-right: 99px;
		}
		.xs-mr-100 {
			margin-right: 100px;
		}
	/*}*/

	/*------------------------------------------
		56. XS MARGIN LEFT
	-------------------------------------------*/
	/*.xs-ml {*/
		.xs-ml-0 {
			margin-left: 0px;
		}
		.xs-ml-1 {
			margin-left: 1px;
		}
		.xs-ml-2 {
			margin-left: 2px;
		}
		.xs-ml-3 {
			margin-left: 3px;
		}
		.xs-ml-4 {
			margin-left: 4px;
		}
		.xs-ml-5 {
			margin-left: 5px;
		}
		.xs-ml-6 {
			margin-left: 6px;
		}
		.xs-ml-7 {
			margin-left: 7px;
		}
		.xs-ml-8 {
			margin-left: 8px;
		}
		.xs-ml-9 {
			margin-left: 9px;
		}
		.xs-ml-10 {
			margin-left: 10px;
		}
		.xs-ml-11 {
			margin-left: 11px;
		}
		.xs-ml-12 {
			margin-left: 12px;
		}
		.xs-ml-13 {
			margin-left: 13px;
		}
		.xs-ml-14 {
			margin-left: 14px;
		}
		.xs-ml-15 {
			margin-left: 15px;
		}
		.xs-ml-16 {
			margin-left: 16px;
		}
		.xs-ml-17 {
			margin-left: 17px;
		}
		.xs-ml-18 {
			margin-left: 18px;
		}
		.xs-ml-19 {
			margin-left: 19px;
		}
		.xs-ml-20 {
			margin-left: 20px;
		}
		.xs-ml-21 {
			margin-left: 21px;
		}
		.xs-ml-22 {
			margin-left: 22px;
		}
		.xs-ml-23 {
			margin-left: 23px;
		}
		.xs-ml-24 {
			margin-left: 24px;
		}
		.xs-ml-25 {
			margin-left: 25px;
		}
		.xs-ml-26 {
			margin-left: 26px;
		}
		.xs-ml-27 {
			margin-left: 27px;
		}
		.xs-ml-28 {
			margin-left: 28px;
		}
		.xs-ml-29 {
			margin-left: 29px;
		}
		.xs-ml-30 {
			margin-left: 30px;
		}
		.xs-ml-31 {
			margin-left: 31px;
		}
		.xs-ml-32 {
			margin-left: 32px;
		}
		.xs-ml-33 {
			margin-left: 33px;
		}
		.xs-ml-34 {
			margin-left: 34px;
		}
		.xs-ml-35 {
			margin-left: 35px;
		}
		.xs-ml-36 {
			margin-left: 36px;
		}
		.xs-ml-37 {
			margin-left: 37px;
		}
		.xs-ml-38 {
			margin-left: 38px;
		}
		.xs-ml-39 {
			margin-left: 39px;
		}
		.xs-ml-40 {
			margin-left: 40px;
		}
		.xs-ml-41 {
			margin-left: 41px;
		}
		.xs-ml-42 {
			margin-left: 42px;
		}
		.xs-ml-43 {
			margin-left: 43px;
		}
		.xs-ml-44 {
			margin-left: 44px;
		}
		.xs-ml-45 {
			margin-left: 45px;
		}
		.xs-ml-46 {
			margin-left: 46px;
		}
		.xs-ml-47 {
			margin-left: 47px;
		}
		.xs-ml-48 {
			margin-left: 48px;
		}
		.xs-ml-49 {
			margin-left: 49px;
		}
		.xs-ml-50 {
			margin-left: 50px;
		}
		.xs-ml-51 {
			margin-left: 51px;
		}
		.xs-ml-52 {
			margin-left: 52px;
		}
		.xs-ml-53 {
			margin-left: 53px;
		}
		.xs-ml-54 {
			margin-left: 54px;
		}
		.xs-ml-55 {
			margin-left: 55px;
		}
		.xs-ml-56 {
			margin-left: 56px;
		}
		.xs-ml-57 {
			margin-left: 57px;
		}
		.xs-ml-58 {
			margin-left: 58px;
		}
		.xs-ml-59 {
			margin-left: 59px;
		}
		.xs-ml-60 {
			margin-left: 60px;
		}
		.xs-ml-61 {
			margin-left: 61px;
		}
		.xs-ml-62 {
			margin-left: 62px;
		}
		.xs-ml-63 {
			margin-left: 63px;
		}
		.xs-ml-64 {
			margin-left: 64px;
		}
		.xs-ml-65 {
			margin-left: 65px;
		}
		.xs-ml-66 {
			margin-left: 66px;
		}
		.xs-ml-67 {
			margin-left: 67px;
		}
		.xs-ml-68 {
			margin-left: 68px;
		}
		.xs-ml-69 {
			margin-left: 69px;
		}
		.xs-ml-70 {
			margin-left: 70px;
		}
		.xs-ml-71 {
			margin-left: 71px;
		}
		.xs-ml-72 {
			margin-left: 72px;
		}
		.xs-ml-73 {
			margin-left: 73px;
		}
		.xs-ml-74 {
			margin-left: 74px;
		}
		.xs-ml-75 {
			margin-left: 75px;
		}
		.xs-ml-76 {
			margin-left: 76px;
		}
		.xs-ml-77 {
			margin-left: 77px;
		}
		.xs-ml-78 {
			margin-left: 78px;
		}
		.xs-ml-79 {
			margin-left: 79px;
		}
		.xs-ml-80 {
			margin-left: 80px;
		}
		.xs-ml-81 {
			margin-left: 81px;
		}
		.xs-ml-82 {
			margin-left: 82px;
		}
		.xs-ml-83 {
			margin-left: 83px;
		}
		.xs-ml-84 {
			margin-left: 84px;
		}
		.xs-ml-85 {
			margin-left: 85px;
		}
		.xs-ml-86 {
			margin-left: 86px;
		}
		.xs-ml-87 {
			margin-left: 87px;
		}
		.xs-ml-88 {
			margin-left: 88px;
		}
		.xs-ml-89 {
			margin-left: 89px;
		}
		.xs-ml-90 {
			margin-left: 90px;
		}
		.xs-ml-91 {
			margin-left: 91px;
		}
		.xs-ml-92 {
			margin-left: 92px;
		}
		.xs-ml-93 {
			margin-left: 93px;
		}
		.xs-ml-94 {
			margin-left: 94px;
		}
		.xs-ml-95 {
			margin-left: 95px;
		}
		.xs-ml-96 {
			margin-left: 96px;
		}
		.xs-ml-97 {
			margin-left: 97px;
		}
		.xs-ml-98 {
			margin-left: 98px;
		}
		.xs-ml-99 {
			margin-left: 99px;
		}
		.xs-ml-100 {
			margin-left: 100px;
		}
	/*}*/

	/*------------------------------------------
		57. XS MARGIN TOP REVERSE
	-------------------------------------------*/
	/*.xs-mt- {*/
		.xs-mt--1 {
			margin-top: -1px;
		}
		.xs-mt--2 {
			margin-top: -2px;
		}
		.xs-mt--3 {
			margin-top: -3px;
		}
		.xs-mt--4 {
			margin-top: -4px;
		}
		.xs-mt--5 {
			margin-top: -5px;
		}
		.xs-mt--6 {
			margin-top: -6px;
		}
		.xs-mt--7 {
			margin-top: -7px;
		}
		.xs-mt--8 {
			margin-top: -8px;
		}
		.xs-mt--9 {
			margin-top: -9px;
		}
		.xs-mt--10 {
			margin-top: -10px;
		}
		.xs-mt--11 {
			margin-top: -11px;
		}
		.xs-mt--12 {
			margin-top: -12px;
		}
		.xs-mt--13 {
			margin-top: -13px;
		}
		.xs-mt--14 {
			margin-top: -14px;
		}
		.xs-mt--15 {
			margin-top: -15px;
		}
		.xs-mt--16 {
			margin-top: -16px;
		}
		.xs-mt--17 {
			margin-top: -17px;
		}
		.xs-mt--18 {
			margin-top: -18px;
		}
		.xs-mt--19 {
			margin-top: -19px;
		}
		.xs-mt--20 {
			margin-top: -20px;
		}
		.xs-mt--21 {
			margin-top: -21px;
		}
		.xs-mt--22 {
			margin-top: -22px;
		}
		.xs-mt--23 {
			margin-top: -23px;
		}
		.xs-mt--24 {
			margin-top: -24px;
		}
		.xs-mt--25 {
			margin-top: -25px;
		}
		.xs-mt--26 {
			margin-top: -26px;
		}
		.xs-mt--27 {
			margin-top: -27px;
		}
		.xs-mt--28 {
			margin-top: -28px;
		}
		.xs-mt--29 {
			margin-top: -29px;
		}
		.xs-mt--30 {
			margin-top: -30px;
		}
		.xs-mt--31 {
			margin-top: -31px;
		}
		.xs-mt--32 {
			margin-top: -32px;
		}
		.xs-mt--33 {
			margin-top: -33px;
		}
		.xs-mt--34 {
			margin-top: -34px;
		}
		.xs-mt--35 {
			margin-top: -35px;
		}
		.xs-mt--36 {
			margin-top: -36px;
		}
		.xs-mt--37 {
			margin-top: -37px;
		}
		.xs-mt--38 {
			margin-top: -38px;
		}
		.xs-mt--39 {
			margin-top: -39px;
		}
		.xs-mt--40 {
			margin-top: -40px;
		}
		.xs-mt--41 {
			margin-top: -41px;
		}
		.xs-mt--42 {
			margin-top: -42px;
		}
		.xs-mt--43 {
			margin-top: -43px;
		}
		.xs-mt--44 {
			margin-top: -44px;
		}
		.xs-mt--45 {
			margin-top: -45px;
		}
		.xs-mt--46 {
			margin-top: -46px;
		}
		.xs-mt--47 {
			margin-top: -47px;
		}
		.xs-mt--48 {
			margin-top: -48px;
		}
		.xs-mt--49 {
			margin-top: -49px;
		}
		.xs-mt--50 {
			margin-top: -50px;
		}
		.xs-mt--51 {
			margin-top: -51px;
		}
		.xs-mt--52 {
			margin-top: -52px;
		}
		.xs-mt--53 {
			margin-top: -53px;
		}
		.xs-mt--54 {
			margin-top: -54px;
		}
		.xs-mt--55 {
			margin-top: -55px;
		}
		.xs-mt--56 {
			margin-top: -56px;
		}
		.xs-mt--57 {
			margin-top: -57px;
		}
		.xs-mt--58 {
			margin-top: -58px;
		}
		.xs-mt--59 {
			margin-top: -59px;
		}
		.xs-mt--60 {
			margin-top: -60px;
		}
		.xs-mt--61 {
			margin-top: -61px;
		}
		.xs-mt--62 {
			margin-top: -62px;
		}
		.xs-mt--63 {
			margin-top: -63px;
		}
		.xs-mt--64 {
			margin-top: -64px;
		}
		.xs-mt--65 {
			margin-top: -65px;
		}
		.xs-mt--66 {
			margin-top: -66px;
		}
		.xs-mt--67 {
			margin-top: -67px;
		}
		.xs-mt--68 {
			margin-top: -68px;
		}
		.xs-mt--69 {
			margin-top: -69px;
		}
		.xs-mt--70 {
			margin-top: -70px;
		}
		.xs-mt--71 {
			margin-top: -71px;
		}
		.xs-mt--72 {
			margin-top: -72px;
		}
		.xs-mt--73 {
			margin-top: -73px;
		}
		.xs-mt--74 {
			margin-top: -74px;
		}
		.xs-mt--75 {
			margin-top: -75px;
		}
		.xs-mt--76 {
			margin-top: -76px;
		}
		.xs-mt--77 {
			margin-top: -77px;
		}
		.xs-mt--78 {
			margin-top: -78px;
		}
		.xs-mt--79 {
			margin-top: -79px;
		}
		.xs-mt--80 {
			margin-top: -80px;
		}
		.xs-mt--81 {
			margin-top: -81px;
		}
		.xs-mt--82 {
			margin-top: -82px;
		}
		.xs-mt--83 {
			margin-top: -83px;
		}
		.xs-mt--84 {
			margin-top: -84px;
		}
		.xs-mt--85 {
			margin-top: -85px;
		}
		.xs-mt--86 {
			margin-top: -86px;
		}
		.xs-mt--87 {
			margin-top: -87px;
		}
		.xs-mt--88 {
			margin-top: -88px;
		}
		.xs-mt--89 {
			margin-top: -89px;
		}
		.xs-mt--90 {
			margin-top: -90px;
		}
		.xs-mt--91 {
			margin-top: -91px;
		}
		.xs-mt--92 {
			margin-top: -92px;
		}
		.xs-mt--93 {
			margin-top: -93px;
		}
		.xs-mt--94 {
			margin-top: -94px;
		}
		.xs-mt--95 {
			margin-top: -95px;
		}
		.xs-mt--96 {
			margin-top: -96px;
		}
		.xs-mt--97 {
			margin-top: -97px;
		}
		.xs-mt--98 {
			margin-top: -98px;
		}
		.xs-mt--99 {
			margin-top: -99px;
		}
		.xs-mt--100 {
			margin-top: -100px;
		}
		.xs-mt--101 {
			margin-top: -101px;
		}
		.xs-mt--102 {
			margin-top: -102px;
		}
		.xs-mt--103 {
			margin-top: -103px;
		}
		.xs-mt--104 {
			margin-top: -104px;
		}
		.xs-mt--105 {
			margin-top: -105px;
		}
		.xs-mt--106 {
			margin-top: -106px;
		}
		.xs-mt--107 {
			margin-top: -107px;
		}
		.xs-mt--108 {
			margin-top: -108px;
		}
		.xs-mt--109 {
			margin-top: -109px;
		}
		.xs-mt--110 {
			margin-top: -110px;
		}
		.xs-mt--111 {
			margin-top: -111px;
		}
		.xs-mt--112 {
			margin-top: -112px;
		}
		.xs-mt--113 {
			margin-top: -113px;
		}
		.xs-mt--114 {
			margin-top: -114px;
		}
		.xs-mt--115 {
			margin-top: -115px;
		}
		.xs-mt--116 {
			margin-top: -116px;
		}
		.xs-mt--117 {
			margin-top: -117px;
		}
		.xs-mt--118 {
			margin-top: -118px;
		}
		.xs-mt--119 {
			margin-top: -119px;
		}
		.xs-mt--120 {
			margin-top: -120px;
		}
		.xs-mt--121 {
			margin-top: -121px;
		}
		.xs-mt--122 {
			margin-top: -122px;
		}
		.xs-mt--123 {
			margin-top: -123px;
		}
		.xs-mt--124 {
			margin-top: -124px;
		}
		.xs-mt--125 {
			margin-top: -125px;
		}
		.xs-mt--126 {
			margin-top: -126px;
		}
		.xs-mt--127 {
			margin-top: -127px;
		}
		.xs-mt--128 {
			margin-top: -128px;
		}
		.xs-mt--129 {
			margin-top: -129px;
		}
		.xs-mt--130 {
			margin-top: -130px;
		}
		.xs-mt--131 {
			margin-top: -131px;
		}
		.xs-mt--132 {
			margin-top: -132px;
		}
		.xs-mt--133 {
			margin-top: -133px;
		}
		.xs-mt--134 {
			margin-top: -134px;
		}
		.xs-mt--135 {
			margin-top: -135px;
		}
		.xs-mt--136 {
			margin-top: -136px;
		}
		.xs-mt--137 {
			margin-top: -137px;
		}
		.xs-mt--138 {
			margin-top: -138px;
		}
		.xs-mt--139 {
			margin-top: -139px;
		}
		.xs-mt--140 {
			margin-top: -140px;
		}
		.xs-mt--141 {
			margin-top: -141px;
		}
		.xs-mt--142 {
			margin-top: -142px;
		}
		.xs-mt--143 {
			margin-top: -143px;
		}
		.xs-mt--144 {
			margin-top: -144px;
		}
		.xs-mt--145 {
			margin-top: -145px;
		}
		.xs-mt--146 {
			margin-top: -146px;
		}
		.xs-mt--147 {
			margin-top: -147px;
		}
		.xs-mt--148 {
			margin-top: -148px;
		}
		.xs-mt--149 {
			margin-top: -149px;
		}
		.xs-mt--150 {
			margin-top: -150px;
		}
		.xs-mt--151 {
			margin-top: -151px;
		}
		.xs-mt--152 {
			margin-top: -152px;
		}
		.xs-mt--153 {
			margin-top: -153px;
		}
		.xs-mt--154 {
			margin-top: -154px;
		}
		.xs-mt--155 {
			margin-top: -155px;
		}
		.xs-mt--156 {
			margin-top: -156px;
		}
		.xs-mt--157 {
			margin-top: -157px;
		}
		.xs-mt--158 {
			margin-top: -158px;
		}
		.xs-mt--159 {
			margin-top: -159px;
		}
		.xs-mt--160 {
			margin-top: -160px;
		}
		.xs-mt--161 {
			margin-top: -161px;
		}
		.xs-mt--162 {
			margin-top: -162px;
		}
		.xs-mt--163 {
			margin-top: -163px;
		}
		.xs-mt--164 {
			margin-top: -164px;
		}
		.xs-mt--165 {
			margin-top: -165px;
		}
		.xs-mt--166 {
			margin-top: -166px;
		}
		.xs-mt--167 {
			margin-top: -167px;
		}
		.xs-mt--168 {
			margin-top: -168px;
		}
		.xs-mt--169 {
			margin-top: -169px;
		}
		.xs-mt--170 {
			margin-top: -170px;
		}
		.xs-mt--171 {
			margin-top: -171px;
		}
		.xs-mt--172 {
			margin-top: -172px;
		}
		.xs-mt--173 {
			margin-top: -173px;
		}
		.xs-mt--174 {
			margin-top: -174px;
		}
		.xs-mt--175 {
			margin-top: -175px;
		}
		.xs-mt--176 {
			margin-top: -176px;
		}
		.xs-mt--177 {
			margin-top: -177px;
		}
		.xs-mt--178 {
			margin-top: -178px;
		}
		.xs-mt--179 {
			margin-top: -179px;
		}
		.xs-mt--180 {
			margin-top: -180px;
		}
		.xs-mt--181 {
			margin-top: -181px;
		}
		.xs-mt--182 {
			margin-top: -182px;
		}
		.xs-mt--183 {
			margin-top: -183px;
		}
		.xs-mt--184 {
			margin-top: -184px;
		}
		.xs-mt--185 {
			margin-top: -185px;
		}
		.xs-mt--186 {
			margin-top: -186px;
		}
		.xs-mt--187 {
			margin-top: -187px;
		}
		.xs-mt--188 {
			margin-top: -188px;
		}
		.xs-mt--189 {
			margin-top: -189px;
		}
		.xs-mt--190 {
			margin-top: -190px;
		}
		.xs-mt--191 {
			margin-top: -191px;
		}
		.xs-mt--192 {
			margin-top: -192px;
		}
		.xs-mt--193 {
			margin-top: -193px;
		}
		.xs-mt--194 {
			margin-top: -194px;
		}
		.xs-mt--195 {
			margin-top: -195px;
		}
		.xs-mt--196 {
			margin-top: -196px;
		}
		.xs-mt--197 {
			margin-top: -197px;
		}
		.xs-mt--198 {
			margin-top: -198px;
		}
		.xs-mt--199 {
			margin-top: -199px;
		}
		.xs-mt--200 {
			margin-top: -200px;
		}
	/*}*/

	/*------------------------------------------
		58. XS MARGIN BOTTOM REVERSE
	-------------------------------------------*/
	/*.xs-mb- {*/
		.xs-mb--1 {
			margin-bottom: -1px;
		}
		.xs-mb--2 {
			margin-bottom: -2px;
		}
		.xs-mb--3 {
			margin-bottom: -3px;
		}
		.xs-mb--4 {
			margin-bottom: -4px;
		}
		.xs-mb--5 {
			margin-bottom: -5px;
		}
		.xs-mb--6 {
			margin-bottom: -6px;
		}
		.xs-mb--7 {
			margin-bottom: -7px;
		}
		.xs-mb--8 {
			margin-bottom: -8px;
		}
		.xs-mb--9 {
			margin-bottom: -9px;
		}
		.xs-mb--10 {
			margin-bottom: -10px;
		}
		.xs-mb--11 {
			margin-bottom: -11px;
		}
		.xs-mb--12 {
			margin-bottom: -12px;
		}
		.xs-mb--13 {
			margin-bottom: -13px;
		}
		.xs-mb--14 {
			margin-bottom: -14px;
		}
		.xs-mb--15 {
			margin-bottom: -15px;
		}
		.xs-mb--16 {
			margin-bottom: -16px;
		}
		.xs-mb--17 {
			margin-bottom: -17px;
		}
		.xs-mb--18 {
			margin-bottom: -18px;
		}
		.xs-mb--19 {
			margin-bottom: -19px;
		}
		.xs-mb--20 {
			margin-bottom: -20px;
		}
		.xs-mb--21 {
			margin-bottom: -21px;
		}
		.xs-mb--22 {
			margin-bottom: -22px;
		}
		.xs-mb--23 {
			margin-bottom: -23px;
		}
		.xs-mb--24 {
			margin-bottom: -24px;
		}
		.xs-mb--25 {
			margin-bottom: -25px;
		}
		.xs-mb--26 {
			margin-bottom: -26px;
		}
		.xs-mb--27 {
			margin-bottom: -27px;
		}
		.xs-mb--28 {
			margin-bottom: -28px;
		}
		.xs-mb--29 {
			margin-bottom: -29px;
		}
		.xs-mb--30 {
			margin-bottom: -30px;
		}
		.xs-mb--31 {
			margin-bottom: -31px;
		}
		.xs-mb--32 {
			margin-bottom: -32px;
		}
		.xs-mb--33 {
			margin-bottom: -33px;
		}
		.xs-mb--34 {
			margin-bottom: -34px;
		}
		.xs-mb--35 {
			margin-bottom: -35px;
		}
		.xs-mb--36 {
			margin-bottom: -36px;
		}
		.xs-mb--37 {
			margin-bottom: -37px;
		}
		.xs-mb--38 {
			margin-bottom: -38px;
		}
		.xs-mb--39 {
			margin-bottom: -39px;
		}
		.xs-mb--40 {
			margin-bottom: -40px;
		}
		.xs-mb--41 {
			margin-bottom: -41px;
		}
		.xs-mb--42 {
			margin-bottom: -42px;
		}
		.xs-mb--43 {
			margin-bottom: -43px;
		}
		.xs-mb--44 {
			margin-bottom: -44px;
		}
		.xs-mb--45 {
			margin-bottom: -45px;
		}
		.xs-mb--46 {
			margin-bottom: -46px;
		}
		.xs-mb--47 {
			margin-bottom: -47px;
		}
		.xs-mb--48 {
			margin-bottom: -48px;
		}
		.xs-mb--49 {
			margin-bottom: -49px;
		}
		.xs-mb--50 {
			margin-bottom: -50px;
		}
		.xs-mb--51 {
			margin-bottom: -51px;
		}
		.xs-mb--52 {
			margin-bottom: -52px;
		}
		.xs-mb--53 {
			margin-bottom: -53px;
		}
		.xs-mb--54 {
			margin-bottom: -54px;
		}
		.xs-mb--55 {
			margin-bottom: -55px;
		}
		.xs-mb--56 {
			margin-bottom: -56px;
		}
		.xs-mb--57 {
			margin-bottom: -57px;
		}
		.xs-mb--58 {
			margin-bottom: -58px;
		}
		.xs-mb--59 {
			margin-bottom: -59px;
		}
		.xs-mb--60 {
			margin-bottom: -60px;
		}
		.xs-mb--61 {
			margin-bottom: -61px;
		}
		.xs-mb--62 {
			margin-bottom: -62px;
		}
		.xs-mb--63 {
			margin-bottom: -63px;
		}
		.xs-mb--64 {
			margin-bottom: -64px;
		}
		.xs-mb--65 {
			margin-bottom: -65px;
		}
		.xs-mb--66 {
			margin-bottom: -66px;
		}
		.xs-mb--67 {
			margin-bottom: -67px;
		}
		.xs-mb--68 {
			margin-bottom: -68px;
		}
		.xs-mb--69 {
			margin-bottom: -69px;
		}
		.xs-mb--70 {
			margin-bottom: -70px;
		}
		.xs-mb--71 {
			margin-bottom: -71px;
		}
		.xs-mb--72 {
			margin-bottom: -72px;
		}
		.xs-mb--73 {
			margin-bottom: -73px;
		}
		.xs-mb--74 {
			margin-bottom: -74px;
		}
		.xs-mb--75 {
			margin-bottom: -75px;
		}
		.xs-mb--76 {
			margin-bottom: -76px;
		}
		.xs-mb--77 {
			margin-bottom: -77px;
		}
		.xs-mb--78 {
			margin-bottom: -78px;
		}
		.xs-mb--79 {
			margin-bottom: -79px;
		}
		.xs-mb--80 {
			margin-bottom: -80px;
		}
		.xs-mb--81 {
			margin-bottom: -81px;
		}
		.xs-mb--82 {
			margin-bottom: -82px;
		}
		.xs-mb--83 {
			margin-bottom: -83px;
		}
		.xs-mb--84 {
			margin-bottom: -84px;
		}
		.xs-mb--85 {
			margin-bottom: -85px;
		}
		.xs-mb--86 {
			margin-bottom: -86px;
		}
		.xs-mb--87 {
			margin-bottom: -87px;
		}
		.xs-mb--88 {
			margin-bottom: -88px;
		}
		.xs-mb--89 {
			margin-bottom: -89px;
		}
		.xs-mb--90 {
			margin-bottom: -90px;
		}
		.xs-mb--91 {
			margin-bottom: -91px;
		}
		.xs-mb--92 {
			margin-bottom: -92px;
		}
		.xs-mb--93 {
			margin-bottom: -93px;
		}
		.xs-mb--94 {
			margin-bottom: -94px;
		}
		.xs-mb--95 {
			margin-bottom: -95px;
		}
		.xs-mb--96 {
			margin-bottom: -96px;
		}
		.xs-mb--97 {
			margin-bottom: -97px;
		}
		.xs-mb--98 {
			margin-bottom: -98px;
		}
		.xs-mb--99 {
			margin-bottom: -99px;
		}
		.xs-mb--100 {
			margin-bottom: -100px;
		}
		.xs-mb--101 {
			margin-bottom: -101px;
		}
		.xs-mb--102 {
			margin-bottom: -102px;
		}
		.xs-mb--103 {
			margin-bottom: -103px;
		}
		.xs-mb--104 {
			margin-bottom: -104px;
		}
		.xs-mb--105 {
			margin-bottom: -105px;
		}
		.xs-mb--106 {
			margin-bottom: -106px;
		}
		.xs-mb--107 {
			margin-bottom: -107px;
		}
		.xs-mb--108 {
			margin-bottom: -108px;
		}
		.xs-mb--109 {
			margin-bottom: -109px;
		}
		.xs-mb--110 {
			margin-bottom: -110px;
		}
		.xs-mb--111 {
			margin-bottom: -111px;
		}
		.xs-mb--112 {
			margin-bottom: -112px;
		}
		.xs-mb--113 {
			margin-bottom: -113px;
		}
		.xs-mb--114 {
			margin-bottom: -114px;
		}
		.xs-mb--115 {
			margin-bottom: -115px;
		}
		.xs-mb--116 {
			margin-bottom: -116px;
		}
		.xs-mb--117 {
			margin-bottom: -117px;
		}
		.xs-mb--118 {
			margin-bottom: -118px;
		}
		.xs-mb--119 {
			margin-bottom: -119px;
		}
		.xs-mb--120 {
			margin-bottom: -120px;
		}
		.xs-mb--121 {
			margin-bottom: -121px;
		}
		.xs-mb--122 {
			margin-bottom: -122px;
		}
		.xs-mb--123 {
			margin-bottom: -123px;
		}
		.xs-mb--124 {
			margin-bottom: -124px;
		}
		.xs-mb--125 {
			margin-bottom: -125px;
		}
		.xs-mb--126 {
			margin-bottom: -126px;
		}
		.xs-mb--127 {
			margin-bottom: -127px;
		}
		.xs-mb--128 {
			margin-bottom: -128px;
		}
		.xs-mb--129 {
			margin-bottom: -129px;
		}
		.xs-mb--130 {
			margin-bottom: -130px;
		}
		.xs-mb--131 {
			margin-bottom: -131px;
		}
		.xs-mb--132 {
			margin-bottom: -132px;
		}
		.xs-mb--133 {
			margin-bottom: -133px;
		}
		.xs-mb--134 {
			margin-bottom: -134px;
		}
		.xs-mb--135 {
			margin-bottom: -135px;
		}
		.xs-mb--136 {
			margin-bottom: -136px;
		}
		.xs-mb--137 {
			margin-bottom: -137px;
		}
		.xs-mb--138 {
			margin-bottom: -138px;
		}
		.xs-mb--139 {
			margin-bottom: -139px;
		}
		.xs-mb--140 {
			margin-bottom: -140px;
		}
		.xs-mb--141 {
			margin-bottom: -141px;
		}
		.xs-mb--142 {
			margin-bottom: -142px;
		}
		.xs-mb--143 {
			margin-bottom: -143px;
		}
		.xs-mb--144 {
			margin-bottom: -144px;
		}
		.xs-mb--145 {
			margin-bottom: -145px;
		}
		.xs-mb--146 {
			margin-bottom: -146px;
		}
		.xs-mb--147 {
			margin-bottom: -147px;
		}
		.xs-mb--148 {
			margin-bottom: -148px;
		}
		.xs-mb--149 {
			margin-bottom: -149px;
		}
		.xs-mb--150 {
			margin-bottom: -150px;
		}
		.xs-mb--151 {
			margin-bottom: -151px;
		}
		.xs-mb--152 {
			margin-bottom: -152px;
		}
		.xs-mb--153 {
			margin-bottom: -153px;
		}
		.xs-mb--154 {
			margin-bottom: -154px;
		}
		.xs-mb--155 {
			margin-bottom: -155px;
		}
		.xs-mb--156 {
			margin-bottom: -156px;
		}
		.xs-mb--157 {
			margin-bottom: -157px;
		}
		.xs-mb--158 {
			margin-bottom: -158px;
		}
		.xs-mb--159 {
			margin-bottom: -159px;
		}
		.xs-mb--160 {
			margin-bottom: -160px;
		}
		.xs-mb--161 {
			margin-bottom: -161px;
		}
		.xs-mb--162 {
			margin-bottom: -162px;
		}
		.xs-mb--163 {
			margin-bottom: -163px;
		}
		.xs-mb--164 {
			margin-bottom: -164px;
		}
		.xs-mb--165 {
			margin-bottom: -165px;
		}
		.xs-mb--166 {
			margin-bottom: -166px;
		}
		.xs-mb--167 {
			margin-bottom: -167px;
		}
		.xs-mb--168 {
			margin-bottom: -168px;
		}
		.xs-mb--169 {
			margin-bottom: -169px;
		}
		.xs-mb--170 {
			margin-bottom: -170px;
		}
		.xs-mb--171 {
			margin-bottom: -171px;
		}
		.xs-mb--172 {
			margin-bottom: -172px;
		}
		.xs-mb--173 {
			margin-bottom: -173px;
		}
		.xs-mb--174 {
			margin-bottom: -174px;
		}
		.xs-mb--175 {
			margin-bottom: -175px;
		}
		.xs-mb--176 {
			margin-bottom: -176px;
		}
		.xs-mb--177 {
			margin-bottom: -177px;
		}
		.xs-mb--178 {
			margin-bottom: -178px;
		}
		.xs-mb--179 {
			margin-bottom: -179px;
		}
		.xs-mb--180 {
			margin-bottom: -180px;
		}
		.xs-mb--181 {
			margin-bottom: -181px;
		}
		.xs-mb--182 {
			margin-bottom: -182px;
		}
		.xs-mb--183 {
			margin-bottom: -183px;
		}
		.xs-mb--184 {
			margin-bottom: -184px;
		}
		.xs-mb--185 {
			margin-bottom: -185px;
		}
		.xs-mb--186 {
			margin-bottom: -186px;
		}
		.xs-mb--187 {
			margin-bottom: -187px;
		}
		.xs-mb--188 {
			margin-bottom: -188px;
		}
		.xs-mb--189 {
			margin-bottom: -189px;
		}
		.xs-mb--190 {
			margin-bottom: -190px;
		}
		.xs-mb--191 {
			margin-bottom: -191px;
		}
		.xs-mb--192 {
			margin-bottom: -192px;
		}
		.xs-mb--193 {
			margin-bottom: -193px;
		}
		.xs-mb--194 {
			margin-bottom: -194px;
		}
		.xs-mb--195 {
			margin-bottom: -195px;
		}
		.xs-mb--196 {
			margin-bottom: -196px;
		}
		.xs-mb--197 {
			margin-bottom: -197px;
		}
		.xs-mb--198 {
			margin-bottom: -198px;
		}
		.xs-mb--199 {
			margin-bottom: -199px;
		}
		.xs-mb--200 {
			margin-bottom: -200px;
		}
	/*}*/

	/*------------------------------------------
		59. XS MARGIN LEFT REVERSE
	-------------------------------------------*/
	/*.xs-ml- {*/
		.xs-ml--1 {
			margin-left: -1px;
		}
		.xs-ml--2 {
			margin-left: -2px;
		}
		.xs-ml--3 {
			margin-left: -3px;
		}
		.xs-ml--4 {
			margin-left: -4px;
		}
		.xs-ml--5 {
			margin-left: -5px;
		}
		.xs-ml--6 {
			margin-left: -6px;
		}
		.xs-ml--7 {
			margin-left: -7px;
		}
		.xs-ml--8 {
			margin-left: -8px;
		}
		.xs-ml--9 {
			margin-left: -9px;
		}
		.xs-ml--10 {
			margin-left: -10px;
		}
		.xs-ml--11 {
			margin-left: -11px;
		}
		.xs-ml--12 {
			margin-left: -12px;
		}
		.xs-ml--13 {
			margin-left: -13px;
		}
		.xs-ml--14 {
			margin-left: -14px;
		}
		.xs-ml--15 {
			margin-left: -15px;
		}
		.xs-ml--16 {
			margin-left: -16px;
		}
		.xs-ml--17 {
			margin-left: -17px;
		}
		.xs-ml--18 {
			margin-left: -18px;
		}
		.xs-ml--19 {
			margin-left: -19px;
		}
		.xs-ml--20 {
			margin-left: -20px;
		}
		.xs-ml--21 {
			margin-left: -21px;
		}
		.xs-ml--22 {
			margin-left: -22px;
		}
		.xs-ml--23 {
			margin-left: -23px;
		}
		.xs-ml--24 {
			margin-left: -24px;
		}
		.xs-ml--25 {
			margin-left: -25px;
		}
		.xs-ml--26 {
			margin-left: -26px;
		}
		.xs-ml--27 {
			margin-left: -27px;
		}
		.xs-ml--28 {
			margin-left: -28px;
		}
		.xs-ml--29 {
			margin-left: -29px;
		}
		.xs-ml--30 {
			margin-left: -30px;
		}
		.xs-ml--31 {
			margin-left: -31px;
		}
		.xs-ml--32 {
			margin-left: -32px;
		}
		.xs-ml--33 {
			margin-left: -33px;
		}
		.xs-ml--34 {
			margin-left: -34px;
		}
		.xs-ml--35 {
			margin-left: -35px;
		}
		.xs-ml--36 {
			margin-left: -36px;
		}
		.xs-ml--37 {
			margin-left: -37px;
		}
		.xs-ml--38 {
			margin-left: -38px;
		}
		.xs-ml--39 {
			margin-left: -39px;
		}
		.xs-ml--40 {
			margin-left: -40px;
		}
		.xs-ml--41 {
			margin-left: -41px;
		}
		.xs-ml--42 {
			margin-left: -42px;
		}
		.xs-ml--43 {
			margin-left: -43px;
		}
		.xs-ml--44 {
			margin-left: -44px;
		}
		.xs-ml--45 {
			margin-left: -45px;
		}
		.xs-ml--46 {
			margin-left: -46px;
		}
		.xs-ml--47 {
			margin-left: -47px;
		}
		.xs-ml--48 {
			margin-left: -48px;
		}
		.xs-ml--49 {
			margin-left: -49px;
		}
		.xs-ml--50 {
			margin-left: -50px;
		}
		.xs-ml--51 {
			margin-left: -51px;
		}
		.xs-ml--52 {
			margin-left: -52px;
		}
		.xs-ml--53 {
			margin-left: -53px;
		}
		.xs-ml--54 {
			margin-left: -54px;
		}
		.xs-ml--55 {
			margin-left: -55px;
		}
		.xs-ml--56 {
			margin-left: -56px;
		}
		.xs-ml--57 {
			margin-left: -57px;
		}
		.xs-ml--58 {
			margin-left: -58px;
		}
		.xs-ml--59 {
			margin-left: -59px;
		}
		.xs-ml--60 {
			margin-left: -60px;
		}
		.xs-ml--61 {
			margin-left: -61px;
		}
		.xs-ml--62 {
			margin-left: -62px;
		}
		.xs-ml--63 {
			margin-left: -63px;
		}
		.xs-ml--64 {
			margin-left: -64px;
		}
		.xs-ml--65 {
			margin-left: -65px;
		}
		.xs-ml--66 {
			margin-left: -66px;
		}
		.xs-ml--67 {
			margin-left: -67px;
		}
		.xs-ml--68 {
			margin-left: -68px;
		}
		.xs-ml--69 {
			margin-left: -69px;
		}
		.xs-ml--70 {
			margin-left: -70px;
		}
		.xs-ml--71 {
			margin-left: -71px;
		}
		.xs-ml--72 {
			margin-left: -72px;
		}
		.xs-ml--73 {
			margin-left: -73px;
		}
		.xs-ml--74 {
			margin-left: -74px;
		}
		.xs-ml--75 {
			margin-left: -75px;
		}
		.xs-ml--76 {
			margin-left: -76px;
		}
		.xs-ml--77 {
			margin-left: -77px;
		}
		.xs-ml--78 {
			margin-left: -78px;
		}
		.xs-ml--79 {
			margin-left: -79px;
		}
		.xs-ml--80 {
			margin-left: -80px;
		}
		.xs-ml--81 {
			margin-left: -81px;
		}
		.xs-ml--82 {
			margin-left: -82px;
		}
		.xs-ml--83 {
			margin-left: -83px;
		}
		.xs-ml--84 {
			margin-left: -84px;
		}
		.xs-ml--85 {
			margin-left: -85px;
		}
		.xs-ml--86 {
			margin-left: -86px;
		}
		.xs-ml--87 {
			margin-left: -87px;
		}
		.xs-ml--88 {
			margin-left: -88px;
		}
		.xs-ml--89 {
			margin-left: -89px;
		}
		.xs-ml--90 {
			margin-left: -90px;
		}
		.xs-ml--91 {
			margin-left: -91px;
		}
		.xs-ml--92 {
			margin-left: -92px;
		}
		.xs-ml--93 {
			margin-left: -93px;
		}
		.xs-ml--94 {
			margin-left: -94px;
		}
		.xs-ml--95 {
			margin-left: -95px;
		}
		.xs-ml--96 {
			margin-left: -96px;
		}
		.xs-ml--97 {
			margin-left: -97px;
		}
		.xs-ml--98 {
			margin-left: -98px;
		}
		.xs-ml--99 {
			margin-left: -99px;
		}
		.xs-ml--100 {
			margin-left: -100px;
		}
		.xs-ml--101 {
			margin-left: -101px;
		}
		.xs-ml--102 {
			margin-left: -102px;
		}
		.xs-ml--103 {
			margin-left: -103px;
		}
		.xs-ml--104 {
			margin-left: -104px;
		}
		.xs-ml--105 {
			margin-left: -105px;
		}
		.xs-ml--106 {
			margin-left: -106px;
		}
		.xs-ml--107 {
			margin-left: -107px;
		}
		.xs-ml--108 {
			margin-left: -108px;
		}
		.xs-ml--109 {
			margin-left: -109px;
		}
		.xs-ml--110 {
			margin-left: -110px;
		}
		.xs-ml--111 {
			margin-left: -111px;
		}
		.xs-ml--112 {
			margin-left: -112px;
		}
		.xs-ml--113 {
			margin-left: -113px;
		}
		.xs-ml--114 {
			margin-left: -114px;
		}
		.xs-ml--115 {
			margin-left: -115px;
		}
		.xs-ml--116 {
			margin-left: -116px;
		}
		.xs-ml--117 {
			margin-left: -117px;
		}
		.xs-ml--118 {
			margin-left: -118px;
		}
		.xs-ml--119 {
			margin-left: -119px;
		}
		.xs-ml--120 {
			margin-left: -120px;
		}
		.xs-ml--121 {
			margin-left: -121px;
		}
		.xs-ml--122 {
			margin-left: -122px;
		}
		.xs-ml--123 {
			margin-left: -123px;
		}
		.xs-ml--124 {
			margin-left: -124px;
		}
		.xs-ml--125 {
			margin-left: -125px;
		}
		.xs-ml--126 {
			margin-left: -126px;
		}
		.xs-ml--127 {
			margin-left: -127px;
		}
		.xs-ml--128 {
			margin-left: -128px;
		}
		.xs-ml--129 {
			margin-left: -129px;
		}
		.xs-ml--130 {
			margin-left: -130px;
		}
		.xs-ml--131 {
			margin-left: -131px;
		}
		.xs-ml--132 {
			margin-left: -132px;
		}
		.xs-ml--133 {
			margin-left: -133px;
		}
		.xs-ml--134 {
			margin-left: -134px;
		}
		.xs-ml--135 {
			margin-left: -135px;
		}
		.xs-ml--136 {
			margin-left: -136px;
		}
		.xs-ml--137 {
			margin-left: -137px;
		}
		.xs-ml--138 {
			margin-left: -138px;
		}
		.xs-ml--139 {
			margin-left: -139px;
		}
		.xs-ml--140 {
			margin-left: -140px;
		}
		.xs-ml--141 {
			margin-left: -141px;
		}
		.xs-ml--142 {
			margin-left: -142px;
		}
		.xs-ml--143 {
			margin-left: -143px;
		}
		.xs-ml--144 {
			margin-left: -144px;
		}
		.xs-ml--145 {
			margin-left: -145px;
		}
		.xs-ml--146 {
			margin-left: -146px;
		}
		.xs-ml--147 {
			margin-left: -147px;
		}
		.xs-ml--148 {
			margin-left: -148px;
		}
		.xs-ml--149 {
			margin-left: -149px;
		}
		.xs-ml--150 {
			margin-left: -150px;
		}
		.xs-ml--151 {
			margin-left: -151px;
		}
		.xs-ml--152 {
			margin-left: -152px;
		}
		.xs-ml--153 {
			margin-left: -153px;
		}
		.xs-ml--154 {
			margin-left: -154px;
		}
		.xs-ml--155 {
			margin-left: -155px;
		}
		.xs-ml--156 {
			margin-left: -156px;
		}
		.xs-ml--157 {
			margin-left: -157px;
		}
		.xs-ml--158 {
			margin-left: -158px;
		}
		.xs-ml--159 {
			margin-left: -159px;
		}
		.xs-ml--160 {
			margin-left: -160px;
		}
		.xs-ml--161 {
			margin-left: -161px;
		}
		.xs-ml--162 {
			margin-left: -162px;
		}
		.xs-ml--163 {
			margin-left: -163px;
		}
		.xs-ml--164 {
			margin-left: -164px;
		}
		.xs-ml--165 {
			margin-left: -165px;
		}
		.xs-ml--166 {
			margin-left: -166px;
		}
		.xs-ml--167 {
			margin-left: -167px;
		}
		.xs-ml--168 {
			margin-left: -168px;
		}
		.xs-ml--169 {
			margin-left: -169px;
		}
		.xs-ml--170 {
			margin-left: -170px;
		}
		.xs-ml--171 {
			margin-left: -171px;
		}
		.xs-ml--172 {
			margin-left: -172px;
		}
		.xs-ml--173 {
			margin-left: -173px;
		}
		.xs-ml--174 {
			margin-left: -174px;
		}
		.xs-ml--175 {
			margin-left: -175px;
		}
		.xs-ml--176 {
			margin-left: -176px;
		}
		.xs-ml--177 {
			margin-left: -177px;
		}
		.xs-ml--178 {
			margin-left: -178px;
		}
		.xs-ml--179 {
			margin-left: -179px;
		}
		.xs-ml--180 {
			margin-left: -180px;
		}
		.xs-ml--181 {
			margin-left: -181px;
		}
		.xs-ml--182 {
			margin-left: -182px;
		}
		.xs-ml--183 {
			margin-left: -183px;
		}
		.xs-ml--184 {
			margin-left: -184px;
		}
		.xs-ml--185 {
			margin-left: -185px;
		}
		.xs-ml--186 {
			margin-left: -186px;
		}
		.xs-ml--187 {
			margin-left: -187px;
		}
		.xs-ml--188 {
			margin-left: -188px;
		}
		.xs-ml--189 {
			margin-left: -189px;
		}
		.xs-ml--190 {
			margin-left: -190px;
		}
		.xs-ml--191 {
			margin-left: -191px;
		}
		.xs-ml--192 {
			margin-left: -192px;
		}
		.xs-ml--193 {
			margin-left: -193px;
		}
		.xs-ml--194 {
			margin-left: -194px;
		}
		.xs-ml--195 {
			margin-left: -195px;
		}
		.xs-ml--196 {
			margin-left: -196px;
		}
		.xs-ml--197 {
			margin-left: -197px;
		}
		.xs-ml--198 {
			margin-left: -198px;
		}
		.xs-ml--199 {
			margin-left: -199px;
		}
		.xs-ml--200 {
			margin-left: -200px;
		}
	/*}*/

	/*------------------------------------------
		60. XS MARGIN RIGHT REVERSE
	-------------------------------------------*/
	/*.xs-mr- {*/
		.xs-mr--1 {
			margin-right: -1px;
		}
		.xs-mr--2 {
			margin-right: -2px;
		}
		.xs-mr--3 {
			margin-right: -3px;
		}
		.xs-mr--4 {
			margin-right: -4px;
		}
		.xs-mr--5 {
			margin-right: -5px;
		}
		.xs-mr--6 {
			margin-right: -6px;
		}
		.xs-mr--7 {
			margin-right: -7px;
		}
		.xs-mr--8 {
			margin-right: -8px;
		}
		.xs-mr--9 {
			margin-right: -9px;
		}
		.xs-mr--10 {
			margin-right: -10px;
		}
		.xs-mr--11 {
			margin-right: -11px;
		}
		.xs-mr--12 {
			margin-right: -12px;
		}
		.xs-mr--13 {
			margin-right: -13px;
		}
		.xs-mr--14 {
			margin-right: -14px;
		}
		.xs-mr--15 {
			margin-right: -15px;
		}
		.xs-mr--16 {
			margin-right: -16px;
		}
		.xs-mr--17 {
			margin-right: -17px;
		}
		.xs-mr--18 {
			margin-right: -18px;
		}
		.xs-mr--19 {
			margin-right: -19px;
		}
		.xs-mr--20 {
			margin-right: -20px;
		}
		.xs-mr--21 {
			margin-right: -21px;
		}
		.xs-mr--22 {
			margin-right: -22px;
		}
		.xs-mr--23 {
			margin-right: -23px;
		}
		.xs-mr--24 {
			margin-right: -24px;
		}
		.xs-mr--25 {
			margin-right: -25px;
		}
		.xs-mr--26 {
			margin-right: -26px;
		}
		.xs-mr--27 {
			margin-right: -27px;
		}
		.xs-mr--28 {
			margin-right: -28px;
		}
		.xs-mr--29 {
			margin-right: -29px;
		}
		.xs-mr--30 {
			margin-right: -30px;
		}
		.xs-mr--31 {
			margin-right: -31px;
		}
		.xs-mr--32 {
			margin-right: -32px;
		}
		.xs-mr--33 {
			margin-right: -33px;
		}
		.xs-mr--34 {
			margin-right: -34px;
		}
		.xs-mr--35 {
			margin-right: -35px;
		}
		.xs-mr--36 {
			margin-right: -36px;
		}
		.xs-mr--37 {
			margin-right: -37px;
		}
		.xs-mr--38 {
			margin-right: -38px;
		}
		.xs-mr--39 {
			margin-right: -39px;
		}
		.xs-mr--40 {
			margin-right: -40px;
		}
		.xs-mr--41 {
			margin-right: -41px;
		}
		.xs-mr--42 {
			margin-right: -42px;
		}
		.xs-mr--43 {
			margin-right: -43px;
		}
		.xs-mr--44 {
			margin-right: -44px;
		}
		.xs-mr--45 {
			margin-right: -45px;
		}
		.xs-mr--46 {
			margin-right: -46px;
		}
		.xs-mr--47 {
			margin-right: -47px;
		}
		.xs-mr--48 {
			margin-right: -48px;
		}
		.xs-mr--49 {
			margin-right: -49px;
		}
		.xs-mr--50 {
			margin-right: -50px;
		}
		.xs-mr--51 {
			margin-right: -51px;
		}
		.xs-mr--52 {
			margin-right: -52px;
		}
		.xs-mr--53 {
			margin-right: -53px;
		}
		.xs-mr--54 {
			margin-right: -54px;
		}
		.xs-mr--55 {
			margin-right: -55px;
		}
		.xs-mr--56 {
			margin-right: -56px;
		}
		.xs-mr--57 {
			margin-right: -57px;
		}
		.xs-mr--58 {
			margin-right: -58px;
		}
		.xs-mr--59 {
			margin-right: -59px;
		}
		.xs-mr--60 {
			margin-right: -60px;
		}
		.xs-mr--61 {
			margin-right: -61px;
		}
		.xs-mr--62 {
			margin-right: -62px;
		}
		.xs-mr--63 {
			margin-right: -63px;
		}
		.xs-mr--64 {
			margin-right: -64px;
		}
		.xs-mr--65 {
			margin-right: -65px;
		}
		.xs-mr--66 {
			margin-right: -66px;
		}
		.xs-mr--67 {
			margin-right: -67px;
		}
		.xs-mr--68 {
			margin-right: -68px;
		}
		.xs-mr--69 {
			margin-right: -69px;
		}
		.xs-mr--70 {
			margin-right: -70px;
		}
		.xs-mr--71 {
			margin-right: -71px;
		}
		.xs-mr--72 {
			margin-right: -72px;
		}
		.xs-mr--73 {
			margin-right: -73px;
		}
		.xs-mr--74 {
			margin-right: -74px;
		}
		.xs-mr--75 {
			margin-right: -75px;
		}
		.xs-mr--76 {
			margin-right: -76px;
		}
		.xs-mr--77 {
			margin-right: -77px;
		}
		.xs-mr--78 {
			margin-right: -78px;
		}
		.xs-mr--79 {
			margin-right: -79px;
		}
		.xs-mr--80 {
			margin-right: -80px;
		}
		.xs-mr--81 {
			margin-right: -81px;
		}
		.xs-mr--82 {
			margin-right: -82px;
		}
		.xs-mr--83 {
			margin-right: -83px;
		}
		.xs-mr--84 {
			margin-right: -84px;
		}
		.xs-mr--85 {
			margin-right: -85px;
		}
		.xs-mr--86 {
			margin-right: -86px;
		}
		.xs-mr--87 {
			margin-right: -87px;
		}
		.xs-mr--88 {
			margin-right: -88px;
		}
		.xs-mr--89 {
			margin-right: -89px;
		}
		.xs-mr--90 {
			margin-right: -90px;
		}
		.xs-mr--91 {
			margin-right: -91px;
		}
		.xs-mr--92 {
			margin-right: -92px;
		}
		.xs-mr--93 {
			margin-right: -93px;
		}
		.xs-mr--94 {
			margin-right: -94px;
		}
		.xs-mr--95 {
			margin-right: -95px;
		}
		.xs-mr--96 {
			margin-right: -96px;
		}
		.xs-mr--97 {
			margin-right: -97px;
		}
		.xs-mr--98 {
			margin-right: -98px;
		}
		.xs-mr--99 {
			margin-right: -99px;
		}
		.xs-mr--100 {
			margin-right: -100px;
		}
		.xs-mr--101 {
			margin-right: -101px;
		}
		.xs-mr--102 {
			margin-right: -102px;
		}
		.xs-mr--103 {
			margin-right: -103px;
		}
		.xs-mr--104 {
			margin-right: -104px;
		}
		.xs-mr--105 {
			margin-right: -105px;
		}
		.xs-mr--106 {
			margin-right: -106px;
		}
		.xs-mr--107 {
			margin-right: -107px;
		}
		.xs-mr--108 {
			margin-right: -108px;
		}
		.xs-mr--109 {
			margin-right: -109px;
		}
		.xs-mr--110 {
			margin-right: -110px;
		}
		.xs-mr--111 {
			margin-right: -111px;
		}
		.xs-mr--112 {
			margin-right: -112px;
		}
		.xs-mr--113 {
			margin-right: -113px;
		}
		.xs-mr--114 {
			margin-right: -114px;
		}
		.xs-mr--115 {
			margin-right: -115px;
		}
		.xs-mr--116 {
			margin-right: -116px;
		}
		.xs-mr--117 {
			margin-right: -117px;
		}
		.xs-mr--118 {
			margin-right: -118px;
		}
		.xs-mr--119 {
			margin-right: -119px;
		}
		.xs-mr--120 {
			margin-right: -120px;
		}
		.xs-mr--121 {
			margin-right: -121px;
		}
		.xs-mr--122 {
			margin-right: -122px;
		}
		.xs-mr--123 {
			margin-right: -123px;
		}
		.xs-mr--124 {
			margin-right: -124px;
		}
		.xs-mr--125 {
			margin-right: -125px;
		}
		.xs-mr--126 {
			margin-right: -126px;
		}
		.xs-mr--127 {
			margin-right: -127px;
		}
		.xs-mr--128 {
			margin-right: -128px;
		}
		.xs-mr--129 {
			margin-right: -129px;
		}
		.xs-mr--130 {
			margin-right: -130px;
		}
		.xs-mr--131 {
			margin-right: -131px;
		}
		.xs-mr--132 {
			margin-right: -132px;
		}
		.xs-mr--133 {
			margin-right: -133px;
		}
		.xs-mr--134 {
			margin-right: -134px;
		}
		.xs-mr--135 {
			margin-right: -135px;
		}
		.xs-mr--136 {
			margin-right: -136px;
		}
		.xs-mr--137 {
			margin-right: -137px;
		}
		.xs-mr--138 {
			margin-right: -138px;
		}
		.xs-mr--139 {
			margin-right: -139px;
		}
		.xs-mr--140 {
			margin-right: -140px;
		}
		.xs-mr--141 {
			margin-right: -141px;
		}
		.xs-mr--142 {
			margin-right: -142px;
		}
		.xs-mr--143 {
			margin-right: -143px;
		}
		.xs-mr--144 {
			margin-right: -144px;
		}
		.xs-mr--145 {
			margin-right: -145px;
		}
		.xs-mr--146 {
			margin-right: -146px;
		}
		.xs-mr--147 {
			margin-right: -147px;
		}
		.xs-mr--148 {
			margin-right: -148px;
		}
		.xs-mr--149 {
			margin-right: -149px;
		}
		.xs-mr--150 {
			margin-right: -150px;
		}
		.xs-mr--151 {
			margin-right: -151px;
		}
		.xs-mr--152 {
			margin-right: -152px;
		}
		.xs-mr--153 {
			margin-right: -153px;
		}
		.xs-mr--154 {
			margin-right: -154px;
		}
		.xs-mr--155 {
			margin-right: -155px;
		}
		.xs-mr--156 {
			margin-right: -156px;
		}
		.xs-mr--157 {
			margin-right: -157px;
		}
		.xs-mr--158 {
			margin-right: -158px;
		}
		.xs-mr--159 {
			margin-right: -159px;
		}
		.xs-mr--160 {
			margin-right: -160px;
		}
		.xs-mr--161 {
			margin-right: -161px;
		}
		.xs-mr--162 {
			margin-right: -162px;
		}
		.xs-mr--163 {
			margin-right: -163px;
		}
		.xs-mr--164 {
			margin-right: -164px;
		}
		.xs-mr--165 {
			margin-right: -165px;
		}
		.xs-mr--166 {
			margin-right: -166px;
		}
		.xs-mr--167 {
			margin-right: -167px;
		}
		.xs-mr--168 {
			margin-right: -168px;
		}
		.xs-mr--169 {
			margin-right: -169px;
		}
		.xs-mr--170 {
			margin-right: -170px;
		}
		.xs-mr--171 {
			margin-right: -171px;
		}
		.xs-mr--172 {
			margin-right: -172px;
		}
		.xs-mr--173 {
			margin-right: -173px;
		}
		.xs-mr--174 {
			margin-right: -174px;
		}
		.xs-mr--175 {
			margin-right: -175px;
		}
		.xs-mr--176 {
			margin-right: -176px;
		}
		.xs-mr--177 {
			margin-right: -177px;
		}
		.xs-mr--178 {
			margin-right: -178px;
		}
		.xs-mr--179 {
			margin-right: -179px;
		}
		.xs-mr--180 {
			margin-right: -180px;
		}
		.xs-mr--181 {
			margin-right: -181px;
		}
		.xs-mr--182 {
			margin-right: -182px;
		}
		.xs-mr--183 {
			margin-right: -183px;
		}
		.xs-mr--184 {
			margin-right: -184px;
		}
		.xs-mr--185 {
			margin-right: -185px;
		}
		.xs-mr--186 {
			margin-right: -186px;
		}
		.xs-mr--187 {
			margin-right: -187px;
		}
		.xs-mr--188 {
			margin-right: -188px;
		}
		.xs-mr--189 {
			margin-right: -189px;
		}
		.xs-mr--190 {
			margin-right: -190px;
		}
		.xs-mr--191 {
			margin-right: -191px;
		}
		.xs-mr--192 {
			margin-right: -192px;
		}
		.xs-mr--193 {
			margin-right: -193px;
		}
		.xs-mr--194 {
			margin-right: -194px;
		}
		.xs-mr--195 {
			margin-right: -195px;
		}
		.xs-mr--196 {
			margin-right: -196px;
		}
		.xs-mr--197 {
			margin-right: -197px;
		}
		.xs-mr--198 {
			margin-right: -198px;
		}
		.xs-mr--199 {
			margin-right: -199px;
		}
		.xs-mr--200 {
			margin-right: -200px;
		}
	/*}*/
}